import { FormControl, RadioGroup, Box, TextField } from "@mui/material";
import { TypographyAlertText } from "components/styles/TypographyCardTitle";
import { FIXED_DYNAMIC_VALUE_DEFAULT } from "Interfaces/CampaignBody";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import RewardDetailsItem from "./RewardDetailsItem";
import TextInputOption from "./TextInputOption";
import TextRadioOption from "./TextRadioOption";
import { DateAndTimeLabel } from "./SMSComponents/DaysAndTimeInputs";

interface Props {
    onNameChange: (event: any) => void;
    name: string;
    onValueChange?: (event: any) => void;
    value?: number;
    campaignChannel: string;
    getValueByKey: (value: string) => any;
    dynamicValueSpecs: {
        PayCondition: {
            basedOn: string[];
        },
        RunCondition: {
            basedOn: string[];
        };
    };
    onTimeLimitChange: (event: any) => void;
    timeLimit: number;
}

const CashOutRestriction = ({
    name,
    onNameChange,
    campaignChannel,
    getValueByKey,
    dynamicValueSpecs,
    timeLimit,
    onTimeLimitChange
}: Props) => {
    const { t } = useTranslation();

    const [, setValue] = getValueByKey("cashOutRestriction");
    const handleNameChange = (e: any) => {
        onNameChange(e);
        setValue(FIXED_DYNAMIC_VALUE_DEFAULT);
    };

    const handleTimeLimitChange = (e: any) => {
        setTimeLimit(e);
        onTimeLimitChange(e);
    }

    const [, setTimeLimit] = useState<number>(0);

    return (
        <RewardDetailsItem>
            <TypographyAlertText>
                <Trans>Player action needed for cashing out the reward</Trans>
            </TypographyAlertText>

            <FormControl>
                <RadioGroup onChange={handleNameChange}>
                    <TextRadioOption
                        name="None"
                        checked={name === "None"}
                        label={t("No condition")}
                    />
                    <TextInputOption
                        label={t('Pay')}
                        checked={name === "Deposit"}
                        name="Deposit"
                        valueKey="cashOutRestriction"
                        getValueByKey={getValueByKey}
                        disabled={name !== "Deposit"}
                        dynamicValueSpecs={dynamicValueSpecs['PayCondition'].basedOn}
                    />
                    <TextInputOption
                        label={t('Run')}
                        checked={name === "Play"}
                        name="Play"
                        valueKey="cashOutRestriction"
                        getValueByKey={getValueByKey}
                        disabled={name !== "Play"}
                        dynamicValueSpecs={dynamicValueSpecs['RunCondition'].basedOn}
                    />
                    <TextRadioOption
                        name="EnterPhoneNumber"
                        checked={name === "EnterPhoneNumber"}
                        label={t("Enter phone number")}
                        disabled={['Sms', 'SmsManual'].includes(campaignChannel)}
                    />
                </RadioGroup>
                <Box sx={{ marginTop: '10px' }}>
                    <Trans >Time limit</Trans>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        onChange={(e) => handleTimeLimitChange(Number(e.target.value))}
                        value={timeLimit}
                        sx={{ width: 100 , marginTop: '-8px'}}
                        size="small"
                        disabled={name === "None"}
                    />
                    &nbsp;&nbsp;
                    <Trans >minutes</Trans>
                </Box>

            </FormControl>
        </RewardDetailsItem>
    );
};

export default CashOutRestriction;