import { Box } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import { DANGER_COLOR, SUCCESS_COLOR } from "./styles/constants.styles";

interface Props {
  conversion: number;
  averageProfit: number;
}

export const CustomAiPerformance = ({
  conversion,
  averageProfit
}: Props) => {
  const ConversionNumber = () =>
    conversion !== 0 ? (
      <Box
        sx={{
          fontWeight: 600,
          color: conversion < 0 ? DANGER_COLOR.LIGHT : SUCCESS_COLOR.LIGHT,
          ml: 0.5
        }}
      >{`${conversion}%`}</Box>
    ) : (
      <>-</>
    );

  const AverageProfitNumber = () =>
    averageProfit !== 0 ? (
      <>
        <Box
          sx={{
            ml: 0.5,
            fontWeight: 600,
            color: averageProfit < 0 ? DANGER_COLOR.LIGHT : SUCCESS_COLOR.LIGHT
          }}
        >{` ${averageProfit} LEI`}</Box>
      </>
    ) : (
      <>-</>
    );

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Trans>Conversion</Trans>: <ConversionNumber />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Trans>Average Profit/session</Trans>: <AverageProfitNumber />
      </Box>
    </Box>
  );
};
