import { CampaignBody } from "Interfaces/CampaignBody";
import { Campaign } from "Interfaces/ICampaign";
import { CampaignDetailsInterface } from "Interfaces/ICampaignDetails";
import { CampaignParams } from "Interfaces/ICampaignParams";
import client from "./axios";

const url = "campaign-editor/Campaign";

export const getCampaigns = async (
  params: Partial<{
    page: number;
    pageSize: number;
    sortBy: string;
    descending: boolean;
    name: string;
    statuses: string[];
    channels: string[];
    operators?: string[];
  }>
): Promise<Campaign> => {
  const body: {
    page: number;
    pageSize: number;
    descending?: boolean;
    orderBy?: string;
    isPagingEnabled?: boolean;
    name?: string;
    channel?: string;
    status?: string;
    operators?: string[];
    locations?: string[];
  } = { page: 0, pageSize: 0 };

  if (params.page) {
    body.page = params.page;
  }

  if (params.pageSize) {
    body.pageSize = params.pageSize;
  }

  if (params.sortBy) {
    body.orderBy = params.sortBy;
  }

  body.descending = !!params.descending;

  if (params.name) {
    body.name = params.name;
  }

  if (params.channels?.length) {
    body.channel = params.channels.join(",");
  }

  if (params.statuses?.length) {
    body.status = params.statuses.join(",");
  }

  if (params.operators?.length) {
    body.operators = params.operators;
  }

  const res = await client.post(`${url}/list`, body);

  return await res.data;
};

export const getCampaignsByName = async (
  campaignName: string
): Promise<Campaign> => {
  const res = await client.get(`${url}/search?filter=${campaignName}`);

  return await res.data;
};

export const getCampaignParams = async (): Promise<CampaignParams> => {
  const res = await client.get(`${url}/params`);

  return await res.data;
};

export const getCampaignDetails = async (
  id: number,
  startDate?: Date | null,
  endDate?: Date | null,
  mock?: boolean,
): Promise<any> => {
  const urlParams: string[] = [];

  if (startDate && endDate) {
    urlParams.push(`start=${startDate.toUTCString()}`);
    urlParams.push(`end=${endDate.toUTCString()}`);
  }
  const reqUrl = mock ? `campaign-editor/Mock/details/${id}` : `${url}/details/${id}`;
  const res = await client.get(`${reqUrl}?${urlParams.join("&")}`);

  return await res.data;
};

// export const getCampaignTriggers = async (
//   id: number
// ): Promise<CampaignDetailsInterface> => {
//   const res = await client.get(`${url}/Test/mock-details/${id}`);

//   return await res.data;
// };

export const getSessionsById = async (
  id: number,
  page: number,
  orderBy: string
) => {
  const urlParams: string[] = [];

  if (orderBy) {
    urlParams.push(`OrderBy=${orderBy}`);
  }

  const res = await client.get(
    `${url}/sessions?CampaignId=${id}&${urlParams.join(
      "&"
    )}&Page=${page}&PageSize=7`
  );

  return await res.data;
};

export const postCampaign = async (campaignBody: CampaignBody) => {
  const res = await client.post(`${url}`, campaignBody);
  return res;
};

export const putCampaign = async (campaignBody: CampaignBody, id: string) => {
  const res = await client.put(`${url}/${id}`, campaignBody);
  return res;
};

export const downloadCSV = async (id: number) => {
  const res = await client.get(`${url}/export-all-sessions/${id}`);
  return await res;
};

export const getDashboardDetails = async (
  mockData: boolean,
  type?: string,
  startDate?: Date | null,
  endDate?: Date | null,
  kpi?: string,
  operators?: string[] | null,
  locations?: string[]
) => {
  const body = {
    start: startDate,
    end: endDate,
    kpi: kpi,
    operators: [],
    locations: locations,
    type: type
  };

  const res = await client.post(
    `/campaign-editor/${mockData ? "Mock/Dashboard/" : "Dashboard"}`,
    body
  );
  return await res.data;
};

export const getCampaignSettings = async () => {
  const res = await client.get(`${url}/settings`);

  return await res;
};

export const saveCampaignSettings = async (
  base: number,
  variations: number,
  ml: number,
  control: number
) => {
  const body = {
    base: base,
    variations: variations,
    ml: ml,
    control: control
  };

  const res = await client.post(`${url}/settings`, body);

  return await res;
}

export const deleteCampaign = async (id: number) => {
  const res = await client.delete(`${url}/${id}`);
  return await res.data;
};

export const changeCampaignStatus = async (id: string, status: string) => {
  const body = {
    id: id,
    status: status
  };

  const res = await client.put(`${url}/status`, body);
  return await res;
};

export const getCampaignTriggers = async (
  id: number
): Promise<CampaignDetailsInterface> => {
  const res = await client.get(`${url}/${id}`);

  return await res.data;
};


export const getRewardCodeConversion = async (
  id: number
): Promise<CampaignDetailsInterface> => {
  const res = await client.get(`${url}/reward-code-conversion/${id}`);

  return await res.data;
};
