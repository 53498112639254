import React from 'react';
import styled from 'styled-components';

import { gap, border, borderRadius, boxShadow, CONTENT_SPACING_GAP, MAIN_COLOR } from 'components/styles/constants.styles';
import './Chart.css';

const CardContainer = styled.div`
    box-shadow: ${boxShadow};
    border: ${border};
    border-radius: ${borderRadius};
    position: relative;
    background: #fff;
    height: 100%;

    @media (max-width: 768px) {
        margin-bottom: ${gap}
    }
`;

const ContainerTitle = styled.div`
    font-size: 15px;
    font-weight: 500;
    margin: ${CONTENT_SPACING_GAP};
    font-family: Montserrat,sans-serif;
    color: ${MAIN_COLOR.DARK};
    max-width: calc(100% - 80px);
`;

const ChartContainer = styled.div`
`;

const CenteredContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
`;

const NoDataContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    border-radius: ${borderRadius};
    padding: 24px;
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    color: #6E6B7B;
`;

type Props = {
    title?: string,
    children: any;
    noAvailableData?: boolean,
    noDataText?: string,
    centered?: boolean,
};

const ChartCard = ({ title, children, centered, noDataText = 'No data available', noAvailableData }: Props) => {
    const Container = centered ? CenteredContainer : ChartContainer;
    return (
        <CardContainer>
            {noAvailableData && (
                <NoDataContainer>
                    No data available
                </NoDataContainer>
            )}
            <ContainerTitle>{title}</ContainerTitle>
            <Container>
                {children}
            </Container>
        </CardContainer>
    );
};

export default ChartCard;