import { Box } from "@mui/material";
import ChartCard from "pages/DashboardCards/ChartCard";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { RadialBarChart, RadialBar, ResponsiveContainer, PolarAngleAxis } from "recharts";
import { MAIN_COLOR, CONTENT_SPACING_GAP } from "components/styles/constants.styles";
import { TypographyCardTitle, TypographyDescription } from "components/styles/TypographyCardTitle";
import CircleIcon from '@mui/icons-material/Circle';

const MOCK_LOADING_DATA = [
    {
        name: 'secondValue',
        uv: 82.69,
        pv: 4567,
        fill: MAIN_COLOR.NORMAL,
    },
    {
        name: 'firstValue',
        uv: 14.47,
        pv: 2400,
        fill: MAIN_COLOR.EXTRA_LIGTH,
    },
];

interface Data {
    secondValue: number;
    firstValue: number;
    firstColor: string;
    secondColor: string;
}
interface Props extends Data {
    title: string;
}

const getData = ({ firstValue, secondValue, firstColor, secondColor }: Data) => {
    if (!firstValue && !secondValue) {
        return MOCK_LOADING_DATA;
    }

    return [
        {
            name: 'secondValue',
            uv: secondValue,
            pv: 100,
            fill: secondColor,
        },
        {
            name: 'firstValue',
            uv: firstValue,
            pv: 100,
            fill: firstColor,
        },
    ];
};


const OverviewChart = ({ title, firstValue, secondValue, firstColor, secondColor }: Props) => {
    const { t } = useTranslation();

    const data = getData({ firstValue, secondValue, firstColor, secondColor });
    return (
        <ChartCard
            title={t(title)}
            noAvailableData={!firstValue && !secondValue}
        >
            <Box sx={{ p: CONTENT_SPACING_GAP }}>
                <ResponsiveContainer width="99%" height={250}>
                    <RadialBarChart
                        innerRadius={80}
                        outerRadius={140}
                        barSize={15}
                        data={data}
                    >
                        <PolarAngleAxis type="number" domain={[0, 100]} dataKey={'uv'} angleAxisId={0} tick={false} />
                        <RadialBar background dataKey="uv" angleAxisId={0} data={data} />
                    </RadialBarChart>
                </ResponsiveContainer>

                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "23px 1fr 100px",
                        alignItems: "center",
                        gridTemplateRows: "repeat(2, 1fr)",
                        gridGap: 1,
                        rowGap: 1,
                        marginTop: '12px',
                    }}
                >
                    <span style={{ color: firstColor, fontSize: '8px' }}>
                        <CircleIcon fontSize="inherit" color="inherit" />
                    </span>
                    <TypographyDescription>
                        <Trans>
                            Precision
                        </Trans>
                    </TypographyDescription>

                    <TypographyCardTitle sx={{ fontSize: 28, fontWeight: "bold", textAlign: 'right' }}>
                        {Math.round(firstValue)}%
                    </TypographyCardTitle>
                    <span style={{ color: secondColor, fontSize: '8px' }}>
                        <CircleIcon fontSize="inherit" color="inherit" />
                    </span>
                    <TypographyDescription>
                        <Trans>
                            Recall
                        </Trans>
                    </TypographyDescription>
                    <TypographyCardTitle sx={{ fontSize: 28, fontWeight: "bold", textAlign: 'right' }}>
                        {Math.round(secondValue)}%
                    </TypographyCardTitle>
                </Box>
            </Box>
        </ChartCard>
    );
};

export default OverviewChart;
