import styled from "styled-components";
import { MAIN_COLOR } from "./constants.styles";

const ResumeButton = styled.button`
  width: 80px;
  height: 28px;
  background: white;
  border-radius: 24px;
  color: ${MAIN_COLOR.EXTRA_LIGTH};
  border: 1px solid ${MAIN_COLOR.EXTRA_LIGTH};
  font-weight: 500;
  font-size: 11px;
  cursor: pointer;
  &:hover {
    box-shadow: none;
    background-color: rgba(115, 103, 240, 0.2);
  }
`;

export default ResumeButton;
