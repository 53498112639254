import React, { useCallback, useMemo, useState } from "react";
import { FormControl, RadioGroup } from "@mui/material";
import { TypographyAlertText } from "components/styles/TypographyCardTitle";
import { Trans, useTranslation } from "react-i18next";
import TextRadioOption from "../TextRadioOption";
import RewardDetailsItem from "../RewardDetailsItem";
import DaysAndTimeInputs from "./DaysAndTimeInputs";

interface Props {
    defaultValue?: string;
    getValueByKey: (key: string) => any;
}

const RewardAvailable = ({
    defaultValue = "Immediatly",
    getValueByKey,
}: Props) => {
    const { t } = useTranslation();

    const [delay, setDelay] = getValueByKey('availability.delay');
    const [timeOfActivation, setTimeOfActivation] = getValueByKey('availability.timeOfActivation');
    const [isImmediatly, setIsImmediatly] = useState<boolean>([null, '0:0:00'].includes(timeOfActivation) && [null, '00.00:00:00', '0.00:00:00'].includes(delay));
    const handleNameChange = useCallback((e: any) => {
        if (e.target.value === "Immediatly") {
            setDelay(null);
            setTimeOfActivation(null);
            setIsImmediatly(true);
            return;
        }

        setIsImmediatly(false);
        setDelay("00:00:00");
        setTimeOfActivation("0.00:00:00");
    }, [setTimeOfActivation]);

    const handleValueChange = (values: { delay: string; time: string; }) => {
        if (delay !== values.delay)
            setDelay(values.delay);
        if (timeOfActivation !== values.time)
            setTimeOfActivation(values.time);
    };

    return (
        <RewardDetailsItem>
            <TypographyAlertText>
                <Trans>Reward becomes available</Trans>
            </TypographyAlertText>

            <FormControl>
                <RadioGroup onChange={handleNameChange}>
                    <TextRadioOption
                        name="Immediatly"
                        checked={isImmediatly}
                        label={t("Immediatly")}
                    />
                    <DaysAndTimeInputs
                        onValueChange={handleValueChange}
                        value={{
                            delay,
                            time: timeOfActivation
                        }}
                        checked={!isImmediatly}
                        name="Custom"
                    />
                </RadioGroup>
            </FormControl>
        </RewardDetailsItem>
    );
};

export default RewardAvailable;