import { styled, TextField } from "@mui/material";
import { boxShadow, borderRadius, ACCENT_LIGHT_COLOR } from './constants.styles';

export const CreateCampaignInput = styled(TextField)({
  background: '#FFF',
  border: "none",
  boxShadow,
  borderRadius,
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      bgColor: ACCENT_LIGHT_COLOR
    }
  },
  input: {
    color: "black"
  }
});
