import styled from "styled-components";
import { MAIN_COLOR } from "./constants.styles";

export const MainButton = styled.button`
  width: 100%;
  height: 37px;
  background-color: ${MAIN_COLOR.EXTRA_LIGTH};
  border-radius: 5px;
  box-shadow: 0;
  letter-spacing: 0.4px;
  border-width: 0;
  color: #FFFFFF;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  max-width: 184px;
  .buttonText {
    color: ${MAIN_COLOR.NORMAL};
    size: 14px;
    line-height: 17px;
    font-weight: 501;
  }
  &:active, &:hover, &:focus {
    background-color: ${MAIN_COLOR.NORMAL};
  }

  &:disabled {
    background-color: rgba(19, 24, 38, 0.35);
    color: white;
    cursor: not-allowed;
  }
`;


export const FlatButton = styled(MainButton)`
    background: rgba(115, 103, 240, 0.2);
    color: ${MAIN_COLOR.EXTRA_LIGTH};

    &:active, &:hover, &:focus {
        background: rgba(115, 103, 240, 0.4);
    }
`;

