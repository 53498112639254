import {
  Box,
  Checkbox,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from "@mui/material";
import { Condition } from "Interfaces/ICondition";
import React from "react";
import { useTranslation } from "react-i18next";
import { ACCENT_LIGHT_COLOR, MAIN_COLOR } from "./styles/constants.styles";

interface Props {
  segments: string[];
  rules: string[];
  condition: Condition;
  setRule: (value: string) => void;
  setSegment: (value: string) => void;
  selectedSegments: string[];
  setSelectedSegments: any;
}

export const TriggerCondition = ({
  segments,
  rules,
  condition,
  setRule,
  setSegment,
  selectedSegments,
  setSelectedSegments
}: Props) => {
  const { rule, segment, trigger } = condition;
  const { t } = useTranslation();

  const handleSegmentChange = (event: SelectChangeEvent) => {
    // when having multiple select for segments
    if (rule === "=" && trigger.segments.length !== 2 && Array.isArray(event.target.value)) {
      setSelectedSegments(event.target.value);
      return;
    }

    // keep the value as array when selecting between True/False values
    if (typeof event.target.value === 'string') {
      setSelectedSegments([event.target.value]);
      return;
    }

    setSelectedSegments(event.target.value);
  };

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = event;

    // prevent letting select clear, force select 1st value
    if (Array.isArray(value) && value.length === 0) {
      setSelectedSegments([trigger.segments[0]]);
      return;
    }
    setSelectedSegments(typeof value === "string" ? value.split(",") : value);
  };

  const handleRulesChange = (event: SelectChangeEvent) => {
    setRule(event.target.value);
  };

  const handleSetSegment = (value: string) => {
    const parsedValue = Number(value).toString();
    const parsedNumberValue = Number(parsedValue);
    if (Number.isNaN(parsedNumberValue) || parsedNumberValue < 0) {
      return;
    }
    setSegment(parsedValue);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Select
        sx={{
          height: 40,
          ml: 1
        }}
        value={rule}
        autoWidth
        onChange={handleRulesChange}
      >
        {rules.map((rule: string, index: number) => (
          <MenuItem key={rule + index} value={rule}>
            {rule}
          </MenuItem>
        ))}
      </Select>
      {["int", "decimal", "uint", "udecimal", "number"].includes(trigger.segments[0]) ? (
        <Box sx={{ width: 90, ml: 1 }}>
          <TextField
            className="segmentInput"
            value={segment}
            onChange={(event) => handleSetSegment(event.target.value)}
            size="small"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
          />
        </Box>
      ) : (
        <>
          {rule === "=" && trigger.segments.length != 2 ? (
            <Select
              size="small"
              sx={{ bgcolor: ACCENT_LIGHT_COLOR, minWidth: 200, ml: 1 }}
              multiple
              value={selectedSegments || []}
              onChange={handleChange}
              renderValue={(selected: string[]) => {
                if (selected.length === 0) {
                  return <Box sx={{ color: 'text.disabled' }}></Box>;
                }

                if (selected.length === 1) {
                  return t(selected.join(''));
                }

                return t("Multiple values");
              }}
            >
              {segments.map((segment: string, index: number) => (
                <MenuItem key={segment + index} value={segment}>
                  <Checkbox checked={selectedSegments?.indexOf(segment) > -1} sx={{
                    "&.Mui-checked": {
                      color: MAIN_COLOR.EXTRA_LIGTH
                    }
                  }} />
                  {t(segment)}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              sx={{
                height: 40,
                ml: 1,
                color: "black",
                display: "flex"
              }}
              defaultValue="0"
              value={segment[0]}
              renderValue={() => segment}
              autoWidth
              onChange={handleSegmentChange}
            >
              {segments.map((segment: string, index: number) => (
                <MenuItem key={segment + index} value={segment}>
                  {t(`${segment}`)}
                </MenuItem>
              ))}
            </Select>
          )}
        </>
      )}
    </Box>
  );
};
