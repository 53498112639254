import { NavBar } from "components/NavBar";
import React, { useEffect, useMemo, useState, memo } from "react";
import {
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from 'moment';

import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";
import { SelectRange } from "components/SelectRange";
import SelectOperator from "components/SelectOperator";
import { getLocations } from "api/operators.service";
import SelectLocations from "components/SelectLocations";
import { getOperators } from "api/operators.service";
import { findLocationsId } from "shared/constants";
import ContentContainer from "components/styles/ContentContainer";
import { gap, MAIN_COLOR, SUCCESS_COLOR } from "components/styles/constants.styles";
import styled from "styled-components";
import ChartCard from "../DashboardCards/ChartCard";
import { FormContainer, OperatorTextField, SelectRangeForm } from "../SharedComponents/Dashboard";
import { TvOutlinedNumberCard } from "pages/DashboardCards/AvgCard";
import OverviewChart from "./charts/OverviewChart";
import LiveDetectionChart from "./charts/LiveDetectionChart";
import DetailsTable from "./DetailsTable";
import { getAIPerformanceDashboard } from "api/aiperformance.service";

import AirlineSeatReclineNormalOutlinedIcon from '@mui/icons-material/AirlineSeatReclineNormalOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';

const ChartsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: ${gap};
  grid-row-gap: ${gap};
  min-height: 420px;
  grid-template-areas:
    "a a a a a a  b b b  c c c"
    "a a a a a a  b b b  d d d"
    "a a a a a a  b b b  e e e";

  @media (max-width: 1280px) {
    grid-template-areas:
      "a a a a a a a a  b b b b"
      "a a a a a a a a  b b b b"
      "c c c c  d d d d  e e e e";
  }

  @media (max-width: 928px) {
    grid-template-areas:
    "a a a a a a a a  a a a a"
    "b b b b b b  c c c c c c"
    "b b b b b b  d d d d d d"
    "b b b b b b  e e e e e e";
  }
  
  @media (max-width: 768px) {
    display: block
  }
`;

const CardContainer = styled.div<{ name: string; }>`
  grid-area: ${props => props.name};
`;


const AiPerformance: React.FC = () => {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  const { t } = useTranslation();
  const [data, setData] = useState<any>(); //TO DO: Create interface
  const dateTo = moment().format('YYYY-MM-DD');
  const dateFrom = moment().subtract(7, 'd').format('YYYY-MM-DD');
  const [range, setRange] = useState<DateRange<Date>>([
    new Date(dateFrom),
    new Date(dateTo)
  ]);
  const [operators, setOperators] = useState<{ id: string; name: string; }[]>(
    []
  );
  const [operator, setOperator] = useState<string>("");
  const [locations, setLocations] = useState<string[]>([]);
  const [location, setLocation] = useState<string>("");
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const locationIds = useMemo(() => {
    return findLocationsId(selectedLocations, locations);
  }, [selectedLocations, locations]);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const a = await getOperators();
        setOperators(a);
      } catch (error) {
        console.log(error);
      }
    };

    fetchOperators();
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchDetails = async () => {
      try {
        const aiPerformanceData = await getAIPerformanceDashboard({
          startDate: range[0],
          endDate: range[1],
          operators: locationIds?.length === 0 && operator ? [operator] : [],
          locations: locationIds,
        });
        setData(aiPerformanceData);
        setLoading(false);
      } catch (error) {
        setError(true);
        setData(null);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [range, operator, locationIds.join(",")]);

  useEffect(() => {
    const getLocationData = async () => {
      try {
        if (operator) {
          const locations = await getLocations();
          setSelectedLocations([]);

          if (operator) {
            const operatorLocations = locations.data.filter((item: any) => item.operatorId === operator);
            setLocations(operatorLocations);
          } else {
            setLocations(locations.data);
          }
          setLocation("");
          setSelectedLocations([]);
        }
      } catch (error) {
        setError(true);
      }
    };
    getLocationData();
  }, [operator]);

  const loadingContainerStyle = {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  };
  return (
    <>
      <NavBar activeTab="ai-performance" />
      <ContentContainer>
        <Box sx={{ p: 4, ...loadingContainerStyle }}>
          <Typography sx={{ fontSize: 24, fontWeight: 500, mb: 3 }}>
            {t("AI Performance")}
          </Typography>
          <Box sx={{ mb: gap }}>
            <ChartCard>
              <FormContainer>
                <SelectRangeForm>
                  <SelectRange setRange={setRange} range={range} />
                </SelectRangeForm>
                <OperatorTextField>
                  <SelectOperator setOperator={setOperator} operators={operators} />
                </OperatorTextField>
                <SelectLocations
                  locations={locations}
                  selectedLocations={selectedLocations}
                  setSelectedLocations={setSelectedLocations}
                />
              </FormContainer>
            </ChartCard>
          </Box>
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 12 }}>
              <CircularProgress size={120} />
            </Box>
          )}
          {!loading && (
            <ChartsGridContainer>
              <CardContainer name="a">
                <LiveDetectionChart dateFrom={range[0]} dateTo={range[1]} data={data?.liveDetection} />
              </CardContainer>
              <CardContainer name="b">
                <OverviewChart
                  title="Overview"
                  firstValue={data?.overview?.precision}
                  secondValue={data?.overview?.recall}
                  firstColor={MAIN_COLOR.EXTRA_DARK}
                  secondColor={SUCCESS_COLOR.LIGHT}
                />
              </CardContainer>
              <CardContainer name="c">
                <TvOutlinedNumberCard
                  title="Total slots"
                  value={data?.totalSlots}
                />
              </CardContainer>
              <CardContainer name="d">
                <TvOutlinedNumberCard
                  title="Total played sessions"
                  value={data?.totalPlayedSessions}
                  icon={<AirlineSeatReclineNormalOutlinedIcon />}
                />
              </CardContainer>
              <CardContainer name="e">
                <TvOutlinedNumberCard
                  title="Total played spins"
                  value={data?.totalPlayedSpins}
                  icon={<TouchAppOutlinedIcon />}
                />
              </CardContainer>
            </ChartsGridContainer>
          )}
          <DetailsTable
            operator={operator ? [operator] : []}
            location={locationIds}
            startDate={range[0]}
            endDate={range[1]}
          />
        </Box>
      </ContentContainer>
    </>
  );
};

export default memo(AiPerformance);
