import { Pagination } from "@mui/material";
import styled from "styled-components";
import { MAIN_COLOR } from "./constants.styles";

const CustomPagination = styled(Pagination)`
  background-color: ${MAIN_COLOR.EXTRA_EXTRA_LIGHT};
  border-radius: 25px;
  margin-right: 55px;
  li:first-child,
  li:last-child {
    border-radius: 100;
  }
  & .Mui-selected {
    background-color: ${MAIN_COLOR.EXTRA_LIGTH};
    color: white;
  }

  @media only screen and (max-width: 611px)  {
    margin-right: 0;
  }
`;

export default CustomPagination;
