import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth/auth.reducer";
import campaignsReducer from "./campaigns/campaigns.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  campaigns: campaignsReducer
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
