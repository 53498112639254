import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { MAIN_COLOR } from "./styles/constants.styles";

interface Props {
  locationPerformances: { id: string; name: string; value: number; }[];
}

export const LocationsChart = ({ locationPerformances }: Props) => {
  const { t } = useTranslation();

  const data = locationPerformances.map((performance, index) => {
    return {
      name: performance.name.substring(0, 5) + "...",
      fullName: performance.name,
      value: performance.value
    };
  });

  return (
    <Box sx={{ p: 2 }}>
      <Typography sx={{ color: MAIN_COLOR.DARK, fontSize: 15, fontWeight: 500 }}>
        {t("Best Performing Locations")}
      </Typography>
      <ResponsiveContainer width="99%" height={300}>
        <BarChart
          barGap={150}
          layout="vertical"
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 30,
            bottom: 5
          }}
        >
          <CartesianGrid vertical={false} horizontal={false} />
          <XAxis axisLine={false} type="number" />
          <YAxis dataKey="fullName" axisLine={false} type="category" />
          <Bar dataKey="value" fill={MAIN_COLOR.EXTRA_LIGTH} barSize={15} />
          <Tooltip />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
