import { Box, Icon, Typography } from "@mui/material";
import ChartCard from "pages/DashboardCards/ChartCard";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { RadialBarChart, RadialBar, ResponsiveContainer, Legend, PolarAngleAxis } from "recharts";
import ConversionColor from "../assets/icons/totalConversionColor.svg";
import UsageColor from "../assets/icons/usageColor.svg";
import { MAIN_COLOR, CONTENT_SPACING_GAP } from "./styles/constants.styles";
import { TypographyCardTitle, TypographyDescription } from "./styles/TypographyCardTitle";

const MOCK_LOADING_DATA = [
  {
    name: 'usedPercent',
    uv: 82.69,
    pv: 4567,
    fill: MAIN_COLOR.NORMAL,
  },
  {
    name: 'claimedPercent',
    uv: 14.47,
    pv: 2400,
    fill: MAIN_COLOR.EXTRA_LIGTH,
  },
];


interface Props {
  usedPercent: number;
  claimedPercent: number;
}

const getData = ({ claimedPercent, usedPercent }: Props) => {
  if (!claimedPercent && !usedPercent) {
    return MOCK_LOADING_DATA;
  }

  return [
    {
      name: 'usedPercent',
      uv: usedPercent,
      pv: 100,
      fill: MAIN_COLOR.NORMAL,
    },
    {
      name: 'claimedPercent',
      uv: claimedPercent,
      pv: 100,
      fill: MAIN_COLOR.EXTRA_LIGTH,
    },
  ];
};


const RadialChart = ({ claimedPercent, usedPercent }: Props) => {
  const { t } = useTranslation();

  const data = getData({ claimedPercent, usedPercent });
  return (
    <ChartCard
      title={t("Statistics")}
      noAvailableData={!claimedPercent && !usedPercent}
    >
      <Box sx={{ p: CONTENT_SPACING_GAP }}>
        <ResponsiveContainer width="99%" height={250}>
          <RadialBarChart
            innerRadius={80}
            outerRadius={140}
            barSize={15}
            data={data}
          >
            <PolarAngleAxis type="number" domain={[0, 100]} dataKey={'uv'} angleAxisId={0} tick={false} />
            <RadialBar background dataKey="uv" angleAxisId={0} data={data} />
          </RadialBarChart>
        </ResponsiveContainer>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "23px 1fr 100px",
            alignItems: "center",
            gridTemplateRows: "repeat(2, 1fr)",
            gridGap: 1,
            rowGap: 1,
            marginTop: '12px',
          }}
        >
          <img src={ConversionColor} alt="1" />

          <TypographyDescription>
            <Trans>
              Total Campaign Conversions
            </Trans>
          </TypographyDescription>

          <TypographyCardTitle sx={{ fontSize: 28, fontWeight: "bold", textAlign: 'right' }}>
            {Math.round(claimedPercent)}%
          </TypographyCardTitle>
          <img src={UsageColor} alt="1" />
          <TypographyDescription>
            <Trans>
              Reward Usage
            </Trans>
          </TypographyDescription>
          <TypographyCardTitle sx={{ fontSize: 28, fontWeight: "bold", textAlign: 'right' }}>
            {Math.round(usedPercent)}%
          </TypographyCardTitle>
        </Box>
      </Box>
    </ChartCard>
  );
};

export default RadialChart;
