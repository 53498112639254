import { createAction } from "@reduxjs/toolkit";
import {
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_BY_NAME,
  GET_SORTED_CAMPAIGNS,
  GET_CAMPAIGNS_PARAMS,
} from "./campaigns.constants";

export const getCampaigns = createAction<{
  page?: number;
  pageSize?: number;
  sortBy?: string;
  descending?: boolean;
  name?: string;
  channels?: string[];
  statuses?: string[];
  operators?: string[];
}>(GET_CAMPAIGNS);

export const getSortedCampaigns = createAction<{
  sortBy: string;
  descending: boolean;
}>(GET_SORTED_CAMPAIGNS);

export const getCampaignsByName = createAction<{
  campaignName: string;
}>(GET_CAMPAIGNS_BY_NAME);

export const getCampaignParams = createAction(GET_CAMPAIGNS_PARAMS);
