import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Icon,
  Radio,
  RadioGroup,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NumberIcon from "../assets/icons/number-two-icon.svg";
import CheckedIcon from "../assets/icons/checked-icon.svg";
import { Trans, useTranslation } from "react-i18next";
import { CheckBoxTree } from "./CheckBoxTree";
import { CampaignAccordionSection } from "./styles/CampaignAccordionSection";
import { MAIN_COLOR } from "./styles/constants.styles";
import { isCampaignTargetValid } from "./campaignPreviewUtils";
import BorderedAccordionSummary from "./BorderedAccordionSummary";

interface Props {
  setTargets: React.Dispatch<React.SetStateAction<string>>;
  expanded: string | false;
  setOperators: React.Dispatch<React.SetStateAction<string[]>>;
  setLocations: React.Dispatch<React.SetStateAction<string[]>>;
  setSlots: React.Dispatch<React.SetStateAction<string[]>>;
  setOperatorsText: React.Dispatch<React.SetStateAction<string | undefined>>;
  allLocations?: boolean;
  handlePanelChange: (
    panel: string
  ) => (event: any, isExpanded: boolean) => void;
  campaignTargets: string | null;
  operators: string[];
  locations: string[];
  slots: string[];
}

export const SelectTargets = ({
  setTargets,
  expanded,
  handlePanelChange,
  setOperators,
  setLocations,
  setSlots,
  setOperatorsText,
  campaignTargets,
  operators,
  locations,
  slots
}: Props) => {
  const { t } = useTranslation();
  const [radioValue, setRadioValue] = useState(campaignTargets);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTargets((event.target as HTMLInputElement).value);
    setRadioValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    setRadioValue(campaignTargets);
  }, [campaignTargets]);

  return (
    <CampaignAccordionSection
      expanded={expanded === "targetsPannel"}
      onChange={handlePanelChange("targetsPannel")}
    >
      <BorderedAccordionSummary>
        <Box>
          <Box
            sx={{
              fontSize: 15,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center"
            }}
          >
            <Icon sx={{ overflow: "visible", mr: 1, mb: 0.8 }}>
              <img src={isCampaignTargetValid({ campaignTargets }) ? CheckedIcon : NumberIcon} alt="search" />
            </Icon>
            <Box>
              <Trans>Campaign Targets</Trans>
            </Box>
          </Box>
          <Box sx={{ fontSize: 13, ml: 4 }}>
            <Trans>Select the locations where the campaign will run</Trans>.
          </Box>
        </Box>
      </BorderedAccordionSummary>
      <AccordionDetails>
        <FormControl>
          <RadioGroup onChange={handleChange} value={radioValue}>
            <FormControlLabel
              value="All Locations"
              control={
                <Radio
                  checked={radioValue === "All Locations"}
                  sx={{
                    "&.Mui-checked": {
                      color: MAIN_COLOR.EXTRA_LIGTH
                    }
                  }}
                />
              }
              label={
                <strong>
                  <Trans>All Locations</Trans>
                </strong>
              }
            />
            <FormControlLabel
              value="Custom Locations"
              control={
                <Radio
                  checked={radioValue === "Custom Locations"}
                  sx={{
                    "&.Mui-checked": {
                      color: MAIN_COLOR.EXTRA_LIGTH
                    }
                  }}
                />
              }
              label={
                <strong>
                  <Trans>Custom Locations</Trans>
                </strong>
              }
            />
            {radioValue === "Custom Locations" ? (
              <>
                <Accordion sx={{ width: 500 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Trans>Select</Trans>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ height: 600, overflow: "auto" }}>
                      <CheckBoxTree
                        operators={operators}
                        locations={locations}
                        slots={slots}
                        setOperators={setOperators}
                        setLocations={setLocations}
                        setSlots={setSlots}
                        setOperatorsText={setOperatorsText}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </>
            ) : (
              <></>
            )}
          </RadioGroup>
        </FormControl>
      </AccordionDetails>
    </CampaignAccordionSection>
  );
};
