import { styled, Accordion } from "@mui/material";
import { boxShadow, borderRadius } from './constants.styles';

export const CampaignAccordionSection = styled(Accordion)({
    mt: 1,
    p: 0,
    boxShadow,
    borderRadius,
    border: "0",
    marginTop: "24px",
    padding: "0",
    "& .Mui-expanded": {
        marginTop: "16px",
    },
    "&:before": {
        height: 0,
    }
});
