import { Box } from "@mui/material";
import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import { GREY_COLOR, MAIN_COLOR, SUCCESS_COLOR } from "./styles/constants.styles";

interface Props {
  data: {
    name: string;
    v1?: number;
    v2?: number;
    v3?: number;
    v4?: number;
    v5?: number;
  }[];
}

export const CampaignComparisonChart = ({ data }: Props) => {
  return (
    <Box sx={{ width: "99%", height: 250 }}>
      <ResponsiveContainer width="99%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 35,
            right: 30,
            left: 40,
            bottom: 12
          }}
        >
          <CartesianGrid />
          <XAxis dataKey="name" axisLine={false} interval={1} dy={20} />
          <YAxis axisLine={false} unit="LEI" dx={-20} />
          <Line
            type="monotone"
            dataKey="v1"
            stroke={GREY_COLOR.EXTRA_DARK}
            strokeWidth={2}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="v2"
            stroke={MAIN_COLOR.EXTRA_LIGTH}
            dot={false}
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="v3"
            stroke="#3C2BEA"
            dot={false}
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="v4"
            stroke="#A8A1F5"
            dot={false}
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="v5"
            stroke={SUCCESS_COLOR.LIGHT}
            dot={false}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
