import { Box, Icon, Typography, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip
} from "recharts";
import FreeSpinsColor from "../assets/icons/FreeSpinsColor.svg";
import FreeMoneyColor from "../assets/icons/FreeMoneyColor.svg";
import PercentBackColor from "../assets/icons/PercentBackColor.svg";
import WheelOfFortuneColor from "../assets/icons/WheelOfFortuneColor.svg";
import RaffleTicketColor from "../assets/icons/RaffleTicketColor.svg";
import BuyABonusColor from "../assets/icons/BuyABonusColor.svg";
import { ACCENT_LIGHT_COLOR, MAIN_COLOR } from "./styles/constants.styles";

const COLORS = [
  "#A8A0F5",
  MAIN_COLOR.EXTRA_DARK,
  "#CB6E15",
  "#FF9F43",
  "#3ACB7A",
  "#0D8E46"
];

const pieColors = [
  FreeSpinsColor,
  FreeMoneyColor,
  PercentBackColor,
  WheelOfFortuneColor,
  RaffleTicketColor,
  BuyABonusColor
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (props: any, total: number) => {
  const radius =
    props.innerRadius + (props.outerRadius - props.innerRadius) * 0.5;
  const x = props.cx + radius * Math.cos(-props.midAngle * RADIAN);
  const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN);

  if (props.value < total * 0.05) {
    return <></>;
  }

  return (
    <text
      x={x}
      y={y}
      fill={ACCENT_LIGHT_COLOR}
      textAnchor={"middle"}
      dominantBaseline="central"
      fontSize={13}
    >
      <tspan x={x}>{`${props.value.toFixed(0)}`}</tspan>
      <tspan x={x} dy="1em">
        LEI
      </tspan>
    </text>
  );
};

const createPieData = (rewards: []) => {
  const data = rewards.map(
    (reward: {
      reward: string;
      value: number;
      earning: number;
      expense: number;
    }) => {
      return {
        name: reward.reward,
        value: reward.value
      };
    }
  );

  return data;
};

const renderActiveShape = (props: any, rewards: { value: number; }[]) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
    props;

  let total = 0;
  rewards.forEach((reward) => {
    total += reward.value;
  });

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill="black"
        fontWeight="bold"
        fontSize="1em"
      >
        {Math.floor(total)} LEI
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

interface TooltipProps {
  active: any;
  payload: any;
}

const CustomToolTip: React.FC<TooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          bgcolor: ACCENT_LIGHT_COLOR,
          width: 240,
          heigth: 76,
          p: 2,
          borderRadius: 6
        }}
      >
        <Box sx={{ display: "flex" }}>
          {payload[0].name}
          <Typography sx={{ color: MAIN_COLOR.EXTRA_LIGTH, marginLeft: ".5rem" }}>
            {payload[0].value} LEI
          </Typography>
        </Box>
      </Box>
    );
  }

  return null;
};

interface Props {
  rewards: any;
}

const ProfitIncreasePie = ({ rewards }: Props) => {
  const { t } = useTranslation();
  const [data, setData] = useState<{ name: string; value: number; }[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const tabletView = useMediaQuery("(max-width:1256px)");
  const onPieEnter = useCallback(
    (_: any, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const [active, setActive] = useState<string>("");

  if (!rewards) {
    return <></>;
  }

  let total = 0;
  rewards.forEach((reward: { value: number; }) => {
    total += reward.value;
  });
  useEffect(() => {
    setData(createPieData(rewards));
  }, [rewards]);

  return (
    <Box sx={{ p: 2 }}>
      <Typography sx={{ color: MAIN_COLOR.DARK, fontSize: 15, fontWeight: 500 }}>
        {t("Rewards Contribution for Profit Increase")}
      </Typography>
      <Box sx={{ width: "99%", height: "300px" }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={(props) => renderActiveShape(props, rewards)}
              data={data}
              labelLine={false}
              label={(props: any) => renderCustomizedLabel(props, total)}
              innerRadius={tabletView ? 45 : 60}
              outerRadius={tabletView ? 90 : 120}
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={(props, activeIndex) => {
                setActive(rewards[activeIndex].reward);
                onPieEnter(props, activeIndex);
              }}
            >
              {data.map((entry: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip
              content={<CustomToolTip active={undefined} payload={undefined} />}
            />
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <Box sx={{ display: "flex", flexFlow: "wrap", fontSize: 12 }}>
        {rewards?.map(
          (reward: { reward: string; id: string; }, index: number) => (
            <Box
              key={`pieColor-${index}`}
              sx={{
                borderBottom:
                  active === reward.reward ? `1px solid ${MAIN_COLOR.EXTRA_LIGTH}` : null
              }}
            >
              <Icon sx={{ overflow: "visible" }}>
                <img src={pieColors[index]} alt="1" />
              </Icon>
              {reward.reward}
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};

export default ProfitIncreasePie;
