import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  List,
  ListItemButton,
  Menu
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaigns } from "store/campaigns/campaign.actions";
import { campaignsPaginationSelector } from "store/campaigns/campaigns.selector";
import { useTranslation } from "react-i18next";
import { getOperators } from "api/operators.service";
import { MAIN_COLOR } from "./styles/constants.styles";

const statusArray = [
  {
    id: 0,
    label: "Ended"
  },
  {
    id: 1,
    label: "Draft"
  },
  {
    id: 2,
    label: "Running"
  },
  {
    id: 3,
    label: "Scheduled"
  },
  { id: 4, label: "Paused" }
];

const channelArray = [
  {
    id: 0,
    label: "LCD",
    value: "Lcd",
  },
  {
    id: 1,
    label: "SMS",
    value: "Sms",
  },
  {
    id: 2,
    label: "SMS Manual",
    value: "SmsManual",
  },
  {
    id: 3,
    label: "Voucher",
    value: "Voucher",
  },
  {
    id: 4,
    label: "Online",
    value: "Online",
  }
];

interface Props {
  anchorEl: any;
  setAnchorEl: any;
}

export const FilterMenu = ({ anchorEl, setAnchorEl }: Props) => {
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const paginationDetails = useSelector(campaignsPaginationSelector);
  const [openChannel, setOpenChannel] = useState(true);
  const [openStatus, setOpenStatus] = useState(true);
  const [openOperator, setOpenOperator] = useState(true);
  const [channels, setChannels] = useState<string[]>(
    paginationDetails.channels
  );
  const [statuses, setStatuses] = useState<string[]>(
    paginationDetails.statuses
  );
  const [operators, setOperators] = useState<{ id: string; name: string; }[]>(
    []
  );
  const [selectedOperators, setSelectedOperators] = useState<string[]>([]);

  const { t } = useTranslation();

  const handleChannelClick = () => {
    setOpenChannel(!openChannel);
  };

  const handleStatusClick = () => {
    setOpenStatus(!openStatus);
  };

  const handleOperatorClick = () => {
    setOpenOperator(!openOperator);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeOperators = (operatorId: string) => {
    const nextOperators = selectedOperators.includes(operatorId)
      ? [...selectedOperators].filter((opId: string) => opId !== operatorId)
      : [...selectedOperators, operatorId];

    setSelectedOperators(nextOperators);

    dispatch(
      getCampaigns({
        statuses: statuses,
        channels: channels,
        page: 1,
        pageSize: paginationDetails.pageSize,
        sortBy: paginationDetails.sortBy,
        descending: paginationDetails.descending,
        operators: nextOperators
      })
    );
  };

  const handleClear = () => {
    setAnchorEl(null);
    dispatch(
      getCampaigns({
        page: 1,
        pageSize: paginationDetails.pageSize,
        sortBy: paginationDetails.sortBy,
        descending: paginationDetails.sortBy,
        operators: []
      })
    );
    setChannels([]);
    setStatuses([]);
  };

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const operators = await getOperators();

        setOperators(operators);
      } catch (error) {
        console.log(error);
      }
    };

    fetchOperators();
  }, []);

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      MenuListProps={{
        "aria-labelledby": "basic-button"
      }}
      onClose={handleClose}
    >
      <List sx={{ width: 226 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ ml: 2 }}>
            <b>{t("Filter")}</b>
          </Box>
          <Box
            sx={{ mr: 2, fontSize: 12, cursor: "pointer" }}
            onClick={handleClear}
          >
            {t("Clear")}
          </Box>
        </Box>
        <ListItemButton onClick={handleChannelClick} sx={{ mt: 2 }}>
          {openChannel ? <ExpandLess /> : <ExpandMore />}
          <Box sx={{ fontSize: 14, fontWeight: "bold" }}>{t("Channel")}</Box>
        </ListItemButton>
        <Collapse in={openChannel} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Box
              sx={{
                ml: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                fontSize: 14
              }}
            >
              {channelArray.map((channel) => (
                <FormControlLabel
                  key={channel.id}
                  label={channel.label}
                  onChange={() => {
                    if (channels.indexOf(channel.value) === -1) {
                      setChannels(() => {
                        dispatch(
                          getCampaigns({
                            statuses: statuses,
                            channels: [...channels, channel.value],
                            page: 1,
                            pageSize: paginationDetails.pageSize,
                            sortBy: paginationDetails.sortBy,
                            descending: paginationDetails.descending,
                            operators: selectedOperators
                          })
                        );

                        return [...channels, channel.value];
                      });
                    } else {
                      setChannels(() => {
                        dispatch(
                          getCampaigns({
                            statuses: statuses,
                            channels: channels.filter(
                              (item) => item !== channel.value
                            ),
                            page: 1,
                            pageSize: paginationDetails.pageSize,
                            sortBy: paginationDetails.sortBy,
                            descending: paginationDetails.descending,
                            operators: selectedOperators
                          })
                        );
                        return channels.filter(
                          (item) => item !== channel.value
                        );
                      });
                    }
                  }}
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: MAIN_COLOR.EXTRA_LIGTH
                        }
                      }}
                      checked={!!channels.find((ch) => channel.value === ch)}
                    />
                  }
                />
              ))}
            </Box>
          </List>
        </Collapse>
        <ListItemButton onClick={handleStatusClick} sx={{ mt: 2 }}>
          {openStatus ? <ExpandLess /> : <ExpandMore />}
          <Box sx={{ fontSize: 14, fontWeight: "bold" }}>{t("Status")}</Box>
        </ListItemButton>
        <Collapse in={openStatus} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Box
              sx={{
                ml: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "start"
              }}
            >
              {statusArray.map((status) => (
                <FormControlLabel
                  sx={{ fontSize: 14 }}
                  key={status.id}
                  label={t(status.label).toString()}
                  onChange={() => {
                    if (statuses.indexOf(status.label) === -1) {
                      setStatuses(() => {
                        dispatch(
                          getCampaigns({
                            statuses: [...statuses, status.label],
                            channels: channels,
                            page: 1,
                            pageSize: paginationDetails.pageSize,
                            sortBy: paginationDetails.sortBy,
                            descending: paginationDetails.descending,
                            operators: selectedOperators
                          })
                        );
                        return [...statuses, status.label];
                      });
                    } else {
                      setStatuses(() => {
                        dispatch(
                          getCampaigns({
                            statuses: statuses.filter(
                              (item) => item !== status.label
                            ),
                            channels: channels,
                            page: 1,
                            pageSize: paginationDetails.pageSize,
                            sortBy: paginationDetails.sortBy,
                            descending: paginationDetails.descending,
                            operators: selectedOperators
                          })
                        );
                        return statuses.filter((item) => item !== status.label);
                      });
                    }
                  }}
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: MAIN_COLOR.EXTRA_LIGTH
                        }
                      }}
                      checked={!!statuses.find((ch) => status.label === ch)}
                    />
                  }
                />
              ))}
            </Box>
          </List>
        </Collapse>
        <ListItemButton onClick={handleOperatorClick} sx={{ mt: 2 }}>
          {openOperator ? <ExpandLess /> : <ExpandMore />}
          <Box sx={{ fontSize: 14, fontWeight: "bold" }}>{t("Operator")}</Box>
        </ListItemButton>
        <Collapse in={openOperator} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Box
              sx={{
                ml: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "start"
              }}
            >
              {operators.map((operator) => (
                <FormControlLabel
                  sx={{ fontSize: 14 }}
                  key={operator.id}
                  label={t(operator.name).toString()}
                  // onChange={() => {
                  //   if (statuses.indexOf(operator.name) === -1) {
                  //     setStatuses(() => {
                  //       dispatch(
                  //         getCampaigns({
                  //           statuses: [...statuses, status.label],
                  //           channels: channels,
                  //           page: 1,
                  //           pageSize: paginationDetails.pageSize,
                  //           sortBy: paginationDetails.sortBy,
                  //           descending: paginationDetails.descending
                  //         })
                  //       );
                  //       return [...statuses, status.label];
                  //     });
                  //   } else {
                  //     setStatuses(() => {
                  //       dispatch(
                  //         getCampaigns({
                  //           statuses: statuses.filter(
                  //             (item) => item !== status.label
                  //           ),
                  //           channels: channels,
                  //           page: 1,
                  //           pageSize: paginationDetails.pageSize,
                  //           sortBy: paginationDetails.sortBy,
                  //           descending: paginationDetails.descending
                  //         })
                  //       );
                  //       return statuses.filter((item) => item !== status.label);
                  //     });
                  //   }
                  // }}
                  onChange={() => handleChangeOperators(operator.id)}
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: MAIN_COLOR.EXTRA_LIGTH
                        }
                      }}
                      checked={!!selectedOperators.find((so) => operator.id === so)}
                    />
                  }
                />
              ))}
            </Box>
          </List>
        </Collapse>
      </List>
    </Menu>
  );
};
