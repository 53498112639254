import React, { useEffect, useMemo } from "react";
import { Button, FormControl, FormControlLabel, Menu, MenuItem, Popover, Radio, RadioGroup, Select, TextField } from "@mui/material";
import { TypographyAlertText, TypographyDescription } from "components/styles/TypographyCardTitle";
import { Trans } from "react-i18next";

import { Box } from "@mui/system";
import { gap, CONTENT_SPACING_GAP, MAIN_COLOR } from "components/styles/constants.styles";
import styled from "styled-components";

import DynamicFormOutlinedIcon from '@mui/icons-material/DynamicFormOutlined';
import { FIXED_DYNAMIC_VALUE_DEFAULT } from "Interfaces/CampaignBody";

interface Props {
    getValueByKey: (key: string) => any;
    valueKey: string;
    disabled?: boolean;
    dynamicValueSpecs?: string[];
}

const CenteredContent = styled(Box)`
    display: flex;
    gap: ${CONTENT_SPACING_GAP};
    align-items: center;
`;


const GridContent = styled(Box)`
    display: grid;
    grid-template-columns: 148px auto;
    grid-column-gap: ${CONTENT_SPACING_GAP};
    grid-row-gap: 4px;
    align-items: center;
`;

const TriggerButton = styled(Button)`
    min-width: 192px;
    justify-content: left;
    height: 40px;
    background: #FFFFFF;
    border-radius: 4px;
    color: #B9B9C3;
    box-shadow: unset;
    border: 1px solid #B9B9C3;
    text-transform: none;

    &:hover, &:active, &:focus {
        box-shadow: unset;
        background: #FFFFFF;
        color: #B9B9C3;
        border: 1px solid #000;
    }
`;
const MULTIPLE_OF_VALUES = [1, 2, 5, 10, 25, 50, 100, 250, 500, 1000];

const FixedDynamicValue = ({
    getValueByKey,
    valueKey,
    disabled,
    dynamicValueSpecs,
}: Props) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: any) => {
        if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9) {
            return;
        }
        setAnchorEl(null);
    };

    const [values, setValues] = getValueByKey(valueKey);

    const previewValues = useMemo(() => disabled ? FIXED_DYNAMIC_VALUE_DEFAULT : values, [disabled, values]);

    const handleChange = (key: string) => (event: any) => {
        if (disabled) {
            event.preventDefault();
            return;
        }

        let resetValues = {};
        if (key === 'type') {
            if (event.target.value === 'Fixed') {
                resetValues = {
                    fixedValue: 0,
                    basedOn: "",
                    multipliedBy: 0,
                    minimum: 0,
                    maximum: 0,
                    multipleOf: 10,
                    aiAmount: 0,
                    roundingAffects: "multiplier"
                };
            }

            if (event.target.value === 'Dynamic') {
                resetValues = {
                    fixedValue: 0,
                    basedOn: "",
                    multipliedBy: 0,
                    minimum: 0,
                    maximum: 0,
                    multipleOf: 10,
                    aiAmount: 0,
                    roundingAffects: "multiplier"
                };
            }
        }

        if (['roundingAffects'].includes(key)) {    
            if (event.target.value === '') {
                setValues({
                    ...previewValues,
                    ...resetValues,
                    [key]: 0
                });
            }

            setValues({
                ...previewValues,
                ...resetValues,
                [key]: event.target.value
            });

            return;
        }

        if (['fixedValue', 'multipliedBy', 'minimum', 'maximum', 'aiAmount'].includes(key)) {
            const parsedValue = Number(event.target.value);
            const isInvalid = Number.isNaN(parsedValue) || parsedValue < 0 || parsedValue > 100000;

            if (isInvalid) {
                return;
            }

            if (event.target.value === '') {
                setValues({
                    ...previewValues,
                    ...resetValues,
                    [key]: 0
                });
            }

            const hasDecimals = event.target.value.indexOf('.') > -1;

            setValues({
                ...previewValues,
                ...resetValues,
                [key]: hasDecimals ? event.target.value : parsedValue
            });

            return;
        }

        setValues({
            ...previewValues,
            ...resetValues,
            [key]: event.target.value
        });
    };

    const isFixedValue = previewValues.type === 'Fixed';

    useEffect(() => {
        if (!isFixedValue && values.basedOn === '' && dynamicValueSpecs?.length) {
            setValues({
                ...values,
                basedOn: dynamicValueSpecs[0]
            });
        }
    }, [disabled, dynamicValueSpecs, values]);

    return (
        <>
            <Box sx={{ display: 'flex', gap }}>
                <TriggerButton
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={disabled ? undefined : handleClick}
                    variant="contained"
                    color="info"
                    startIcon={<DynamicFormOutlinedIcon />}
                    disabled={disabled}
                >
                    {isFixedValue ? previewValues.fixedValue : (<Trans>Dynamic value</Trans>)}
                </TriggerButton>
                <Popover
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box sx={{ m: gap }}>
                        <TypographyAlertText>
                            <Trans>
                                Define the way the value will be calculated
                            </Trans>
                        </TypographyAlertText>
                        <RadioGroup onChange={handleChange("type")} value={previewValues.type}>
                            <FormControlLabel
                                value='Fixed'
                                control={
                                    <Radio
                                        checked={isFixedValue}
                                        sx={{
                                            "&.Mui-checked": {
                                                color: MAIN_COLOR.EXTRA_LIGTH
                                            }
                                        }}
                                    />
                                }
                                label={
                                    <Box>
                                        <GridContent>
                                            <TypographyDescription>
                                                <CenteredContent>
                                                    <Trans>Fixed amount of</Trans>
                                                </CenteredContent>
                                            </TypographyDescription>
                                            <CenteredContent>
                                                <TextField
                                                    onChange={handleChange('fixedValue')}
                                                    value={isFixedValue ? previewValues.fixedValue : 0}
                                                    sx={{ width: 100 }}
                                                    size="small"
                                                    disabled={!isFixedValue}
                                                />
                                                <Trans>LEI</Trans>
                                            </CenteredContent>
                                        </GridContent>

                                    </Box>
                                }
                            />
                            <FormControlLabel
                                value='Dynamic'
                                control={
                                    <Radio
                                        checked={!isFixedValue}
                                        sx={{
                                            "&.Mui-checked": {
                                                color: MAIN_COLOR.EXTRA_LIGTH
                                            }
                                        }}
                                    />
                                }
                                label={
                                    <TypographyDescription>
                                        <CenteredContent>
                                            <Trans>Dynamically calculated amount based on</Trans>
                                            <FormControl>
                                                <Select
                                                    labelId="simple-select-label"
                                                    id="simple-select"
                                                    value={previewValues.basedOn}
                                                    onChange={handleChange('basedOn')}
                                                    size="small"
                                                    disabled={isFixedValue}
                                                    sx={{ width: 155 }}
                                                    inputProps={{ tabIndex: 1 }}
                                                >
                                                    {dynamicValueSpecs?.map(dynamicValueSpec => {
                                                        return (
                                                            <MenuItem value={dynamicValueSpec} key={dynamicValueSpec}>
                                                                <Trans>{dynamicValueSpec}</Trans>
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </CenteredContent>
                                    </TypographyDescription>
                                }
                            />
                        </RadioGroup>

                        <Box sx={{ marginLeft: '31px' }}>
                            <GridContent>
                                <TypographyDescription>
                                    <CenteredContent>
                                        <Trans>Multiplied by</Trans>
                                    </CenteredContent>
                                </TypographyDescription>
                                <CenteredContent>
                                    <TextField
                                        onChange={handleChange('multipliedBy')}
                                        value={!isFixedValue ? previewValues.multipliedBy : 0}
                                        sx={{ width: 100 }}
                                        size="small"
                                        disabled={isFixedValue}
                                        inputProps={{ tabIndex: 2 }}
                                    />
                                    <Trans>X</Trans>
                                </CenteredContent>
                                <TypographyDescription>
                                    <CenteredContent>
                                        <Trans>Minimum</Trans>
                                    </CenteredContent>
                                </TypographyDescription>
                                <CenteredContent>
                                    <TextField
                                        onChange={handleChange('minimum')}
                                        value={!isFixedValue ? previewValues.minimum : 0}
                                        sx={{ width: 100 }}
                                        size="small"
                                        disabled={isFixedValue}
                                        inputProps={{ tabIndex: 3 }}
                                    />
                                    <Trans>LEI</Trans>
                                </CenteredContent>
                                <TypographyDescription>
                                    <CenteredContent>
                                        <Trans>Maximum</Trans>
                                    </CenteredContent>
                                </TypographyDescription>
                                <CenteredContent>
                                    <TextField
                                        onChange={handleChange('maximum')}
                                        value={!isFixedValue ? previewValues.maximum : 0}
                                        sx={{ width: 100 }}
                                        size="small"
                                        disabled={isFixedValue}
                                        inputProps={{ tabIndex: 4 }}
                                    />
                                    <Trans>LEI</Trans>
                                </CenteredContent>

                                <TypographyDescription>
                                    <CenteredContent>
                                        <Trans>Multiple of</Trans>
                                    </CenteredContent>
                                </TypographyDescription>

                                <CenteredContent>
                                    <FormControl>
                                        <Select
                                            value={!isFixedValue ? previewValues.multipleOf : 10}
                                            onChange={handleChange('multipleOf')}
                                            size="small"
                                            disabled={isFixedValue}
                                            sx={{ width: 100 }}
                                            inputProps={{ tabIndex: 5 }}
                                        >
                                            {MULTIPLE_OF_VALUES.map(multipleValue => (
                                                <MenuItem
                                                    value={multipleValue}
                                                    key={multipleValue}
                                                >
                                                    {multipleValue}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Trans>X</Trans>
                                </CenteredContent>

                                <TypographyDescription>
                                    <CenteredContent>
                                        <Trans>Rounding affects</Trans>
                                    </CenteredContent>
                                </TypographyDescription>

                                <CenteredContent>
                                    <FormControl>
                                        <Select
                                            value={previewValues.roundingAffects}
                                            onChange={handleChange('roundingAffects')}
                                            size="small"
                                            disabled={isFixedValue}
                                            sx={{ width: 200 }}
                                            inputProps={{ tabIndex: 5 }}
                                        >                                            
                                            <MenuItem
                                                value="multiplier"
                                                key="multiplier"
                                            >
                                                <Trans>The multiplier</Trans>
                                            </MenuItem>
                                            <MenuItem
                                                value="finalAmount"
                                                key="finalAmount"
                                            >
                                                <Trans>The final amount</Trans>
                                            </MenuItem>
                                            
                                        </Select>
                                    </FormControl>
                                </CenteredContent>
                            </GridContent>
                        </Box>
                    </Box>

                    <Box sx={{ m: gap }}>
                        <TypographyAlertText>
                            <Trans>
                                AI-generated Variations
                            </Trans>
                        </TypographyAlertText>

                        <Box sx={{ marginLeft: '31px' }}>
                            <GridContent>
                                <TypographyDescription>
                                    <CenteredContent>
                                        <Trans>Variation +/-</Trans>
                                    </CenteredContent>
                                </TypographyDescription>
                                <CenteredContent>
                                    <TextField
                                        onChange={handleChange('aiAmount')}
                                        value={previewValues.aiAmount}
                                        sx={{ width: 100 }}
                                        size="small"
                                        disabled={false}
                                        inputProps={{ tabIndex: 2 }}
                                    />
                                    <Trans>%</Trans>
                                </CenteredContent>
                            </GridContent>
                        </Box>
                    </Box>
                </Popover>
            </Box >
        </>
    );
};

export default FixedDynamicValue;