import {
  Box,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { calculateTime } from "shared/constants";
import { ACCENT_DANGER_COLOR, MAIN_COLOR, SUCCESS_COLOR } from "./styles/constants.styles";
import ChartCard from "pages/DashboardCards/ChartCard";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

interface Data {
  tableData: {
    name: string;
    averageProfit: number;
    averageProfitIncrease: number;
    averageDuration: number;
    averageDurationIncrease: number;
    conversion: number;
    conversionIncrease: number;
    totalProfit: number;
    totalProfitIncrease: number;
  }[];
}

interface Column {
  id: "name" | "averageProfit" | "averageDuration" | "conversion" | "actions";
  label: string;
  minWidth?: number;
  align?: "left";
  cell: (value: number, percentege: number) => any;
}

export const ExperimentsTable: React.FC<Data> = ({ tableData }) => {
  const { t } = useTranslation();

  const handleCopy = (column: any) => {
    console.log(column);
  };

  const columns: Column[] = [
    {
      id: "name",
      label: t("Campaign Name"),
      minWidth: 170,
      cell: (value: number) => <Box>{value}</Box>
    },
    {
      id: "averageProfit",
      label: t("AVG PROFIT/SESSION"),
      minWidth: 100,
      cell: (value: number, percentege: number) => (
        <Box>
          <Box> {value} LEI</Box>
          <Typography
            sx={{
              color: percentege > 0 ? SUCCESS_COLOR.LIGHT : ACCENT_DANGER_COLOR,
              fontSize: 12,
              fontWeight: 500
            }}
          >
            {percentege > 0 ? "+" : null}
            {percentege}%
          </Typography>
        </Box>
      )
    },
    {
      id: "averageDuration",
      label: t("AVG PLAY TIME"),
      minWidth: 1,
      align: "left",
      cell: (value: number, percentege: number) => (
        <Box>
          <Box> {calculateTime(value)}</Box>

          <Typography
            sx={{
              color: percentege > 0 ? SUCCESS_COLOR.LIGHT : ACCENT_DANGER_COLOR,
              fontSize: 12,
              fontWeight: 500
            }}
          >
            {percentege > 0 ? "+" : null}
            {percentege}%
          </Typography>
        </Box>
      )
    },
    {
      id: "conversion",
      label: t("CONVERSIONS"),
      minWidth: 1,
      align: "left",
      cell: (value: number, percentege: number) => (
        <Box>
          <Box>{value}%</Box>
          <Typography
            sx={{
              color: percentege > 0 ? SUCCESS_COLOR.LIGHT : ACCENT_DANGER_COLOR,
              fontSize: 12,
              fontWeight: 500
            }}
          >
            {percentege > 0 ? "+" : null}
            {percentege}%
          </Typography>
        </Box>
      )
    },
    {
      id: "actions",
      label: t("ACTIONS"),
      minWidth: 1,
      align: "left",
      cell: (value: number) => (
        <Box>
          <Tooltip title={t("Campaign details").toString()}>
            <IconButton
              color="info"
              component="label"
              size="small"
              onClick={() => console.log("click")}
            >
              <VisibilityOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("Duplicate").toString()}>
            <IconButton
              color="info"
              component="label"
              size="small"
              onClick={() => console.log("click")}
            >
              <ContentCopyOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )
    }
  ];

  if (!tableData) {
    return (
      <ChartCard
        title={t("Statistics")}
        noAvailableData
      >
        Loading
      </ChartCard>
    );
  }

  return (
    <ChartCard title={t("Ai Experiments")}>
      <Box sx={{ px: 2, pb: 2 }}>
        <Typography sx={{ fontSize: 12 }}>
          {t(
            "There were generated 3 different campaigns based on the settings chosen by you. All data is in comparison to the Main campaign"
          )}
        </Typography>
      </Box>


      <TableContainer sx={{ maxHeight: 300 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sx={{ p: 2, bgcolor: MAIN_COLOR.EXTRA_EXTRA_LIGHT }}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row: any, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`${row.name}-${index}`}
                >
                  {columns.map((column, index) => {
                    const value = row[column.id];
                    const percentege = row[`${column.id}Increase`];
                    return (
                      <TableCell
                        sx={{ height: 50, p: '6px 16px' }}
                        key={column.id}
                        align={column.align}
                      >
                        {column.cell(value, percentege)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

    </ChartCard>
  );
};
