import { Box, Icon, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import FilterIcon from "../assets/icons/filter-icon.svg";
import SortIcon from "../assets/icons/sort-icon.svg";
import ArrowIcon from "../assets/icons/arrow-icon.svg";
import StyledFilterSection from "./styles/FIlterSection.styled";
import { SortingMenu } from "./SortingMenu";
import SearchInput from "./SearchInput";
import { FilterMenu } from "./FilterMenu";
import { Trans, useTranslation } from "react-i18next";

export const FilterSection: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const mobileView = useMediaQuery("(min-width:612px)");

  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick1 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  return (
    <StyledFilterSection>
      <Box
        onClick={handleClick1}
        sx={{
          display: "Flex",
          alignItems: "center",
          cursor: "pointer",
          ml: 2
        }}
      >
        <Icon sx={{ mb: 1.2, overflow: "visible" }}>
          <img src={FilterIcon} alt="filter" />
        </Icon>
        <Box sx={{ display: mobileView ? "" : "none" }}>
          <Trans>Filter</Trans>
        </Box>
        <Icon sx={{ mb: 1.5 }}>
          <img src={ArrowIcon} alt="Drop Down" />
        </Icon>
      </Box>
      <Box
        onClick={handleClick}
        sx={{
          mr: "auto",
          display: "Flex",
          alignItems: "center",
          cursor: "pointer"
        }}
      >
        <Icon sx={{ mb: 1.2, overflow: "visible" }}>
          <img src={SortIcon} alt="Sort" />
        </Icon>
        <Box sx={{ display: mobileView ? "" : "none" }}>
          <Trans>Sort</Trans>
        </Box>
        <Icon sx={{ mb: 1.5, mr: 2 }}>
          <img src={ArrowIcon} alt="Drop Down" />
        </Icon>
      </Box>
      <SortingMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <FilterMenu anchorEl={anchorEl1} setAnchorEl={setAnchorEl1} />
      <SearchInput />
    </StyledFilterSection>
  );
};
