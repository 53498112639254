import React from "react";
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './RevenueReportCard.css';
import ChartCard from "./ChartCard";

import { useTranslation } from "react-i18next";
import { splitInTheMiddleSpace } from "./utils";
import { MAIN_COLOR } from "components/styles/constants.styles";

const MOCK_OPTIONS: ApexOptions = {
    chart: {
        stacked: true,
        type: 'bar',
        toolbar: { show: false }
    },
    grid: {
        yaxis: {
            lines: { show: false }
        }
    },
    xaxis: {
        categories: ['Free Spins', 'After Wager', 'Buy a Bonus', 'Free Money'],
        labels: {
            style: {
                colors: '#6E6B7B',
                fontSize: '12px', fontFamily: "Montserrat,sans-serif"
            },
        },
        axisTicks: {
            show: false
        },
        axisBorder: {
            show: false
        },
    },
    legend: {
        show: false,
        fontSize: '12px', fontFamily: "Montserrat,sans-serif"
    },
    dataLabels: {
        enabled: false
    },
    colors: [MAIN_COLOR.EXTRA_LIGTH, '#FF9F43'],
    plotOptions: {
        bar: {
            columnWidth: '28px',
            borderRadius: [3],
        },
    },
    yaxis: {
        labels: {
            style: {
                colors: '#6E6B7B',
                fontSize: '12px', fontFamily: "Montserrat,sans-serif"
            },
        }
    },
    tooltip: {
        style: {
            fontSize: '12px', fontFamily: "Montserrat,sans-serif"
        },
        shared: false,
    },
};

const MOCK_SERIES = [
    {
        name: 'Earning',
        data: [95, 177, 284, 256]
    },
    {
        name: 'Expense',
        data: [-145, -80, -60, -180]
    }
];

interface Props {
    revenueReports: {
        earning: number;
        expense: number;
        isFocused: boolean;
        month: string;
        reward: string;
        value: number;
    }[];
    labels: string[];
}
const getSeries = (expenses: number[], earnings: number[]) => {
    return [{
        name: 'Earning',
        data: earnings
    },
    {
        name: 'Expense',
        data: expenses
    }];
};

const getOptions = (rewards: (string[] | string)[], t: any) => {
    const options: ApexOptions = {
        chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false }
        },
        grid: {
            yaxis: {
                lines: { show: false }
            }
        },
        xaxis: {
            categories: rewards,
            labels: {
                style: {
                    colors: '#6E6B7B',
                    fontSize: '12px', fontFamily: "Montserrat,sans-serif"
                },
            },
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
        },
        legend: {
            show: false,
            fontSize: '12px', fontFamily: "Montserrat,sans-serif"
        },
        dataLabels: {
            enabled: false
        },
        colors: [MAIN_COLOR.EXTRA_LIGTH, '#FF9F43'],
        plotOptions: {
            bar: {
                barHeight: '28px',
                borderRadius: [3],
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#6E6B7B',
                    fontSize: '12px', fontFamily: "Montserrat,sans-serif"
                },
                formatter: function (val: any) {
                    return `${val} ${t("LEI")}`;
                }
            }
        },
        tooltip: {
            style: {
                fontSize: '12px', fontFamily: "Montserrat,sans-serif"
            },
            shared: false,
            y: {
                formatter: function (val: any) {
                    return `${Math.abs(val)} ${t("LEI")}`;
                }
            },
        },
    };
    return options;
};

const getSortedValues = (revenueReports: {
    earning: number;
    expense: number;
    reward: string;
    value: number;
}[], t: any) => {
    const sortedValue = revenueReports.sort((a, b) => {
        if (a.value > b.value) {
            return -1;
        }
        if (a.value < b.value) {
            return 1;
        }
        return 0;
    }).reduce((
        acc: { rewards: (string[] | string)[], expenses: number[]; earnings: number[]; },
        item: { earning: number; expense: number; reward: string; }
    ) => {
        return {
            rewards: [...acc.rewards, splitInTheMiddleSpace(t(item.reward))],
            expenses: [...acc.expenses, -1 * item.expense],
            earnings: [...acc.earnings, item.earning]
        };
    }, { rewards: [], expenses: [], earnings: [] });

    return sortedValue;
};


const RevenueReportCard = ({ revenueReports, labels }: Props) => {
    const { t } = useTranslation();

    if (!revenueReports) {
        return (
            <ChartCard title={t("Revenue Report")} noAvailableData={true}>
                <Chart
                    id='chart_2'
                    type='bar'
                    height='350'
                    options={MOCK_OPTIONS}
                    series={MOCK_SERIES}
                />
            </ChartCard>
        );
    }

    const { rewards, expenses, earnings } = getSortedValues(revenueReports, t);
    const series = getSeries(expenses, earnings);
    const options = getOptions(rewards, t);

    return (
        <ChartCard title={t("Revenue Report")}>
            <Chart
                id='revenue-report'
                type='bar'
                height='320'
                options={options}
                series={series}
            />
        </ChartCard>
    );
};

export default RevenueReportCard;