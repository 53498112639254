import {
  Box,
  Divider,
  FormControlLabel,
  Icon,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import {
  getBudgets,
  getLocations,
  getOperators,
  saveBudgets
} from "api/operators.service";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { NavBar } from "components/NavBar";
import SelectOperator from "components/SelectOperator";
import SaveButton from "components/styles/DeleteButton";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContentContainer from "components/styles/ContentContainer";
import { gap, boxShadow, borderRadius, MAIN_COLOR, ACCENT_LIGHT_COLOR, CONTENT_SPACING_GAP } from "../components/styles/constants.styles";
import ChartCard from "./DashboardCards/ChartCard";


// reinveste profit percentage -> percentBack
// maximum reinvested amount per month -> dynamicBudgetLimit
// out-of-pocket investment -> guaranteedBudget
type LocationType = {
  id: string;
  name: string;
  dynamicBudgetLimit: number;
  guaranteedBudget: number;
};

type BudgetsLocationsValuesProps = {
  values: number[];
  onChangeHandler: (event: any, index: number) => void;
  disabled: boolean;
  locations: LocationType[];
};

const BudgetsLocationsValues = ({ values, onChangeHandler, disabled, locations }: BudgetsLocationsValuesProps) => {
  const mobileView = useMediaQuery("(min-width:630px)");

  return (
    <Box
      className="locationsTable"
      sx={{
        maxHeight: 550,
        overflowY: "scroll",
        display: locations?.length || "none"
      }}
    >
      {locations?.map(
        (location: LocationType, index: number) => (
          <Box
            key={location.id}
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: mobileView ? "center" : "start",
              flexDirection: mobileView ? "" : "column"
            }}
          >
            <Typography>{location.name}</Typography>
            <TextField
              value={values[index]?.toString()}
              size="small"
              sx={{ maxWidth: 150 }}
              disabled={disabled}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              onChange={(event) =>
                onChangeHandler(event, index)
              }
            />
          </Box>
        )
      )}
    </Box>
  );
};

const calculateBudgets = (value: number, length: number) => {
  const budgetFraction = Array(length).fill(
    +Math.floor(value / length),
    0,
    length
  ); // creates array of n values = investment / number of locations where n is the number of locations

  for (let i = 0; i < value % length; i++) {
    budgetFraction[i]++;
  }

  return budgetFraction;
};

const checkEqual = (budgets: number[]) => {
  return budgets.every((val) => val === budgets[0]);
};

export const Budgets: React.FC = () => {
  const [operator, setOperator] = useState<string>("");
  const [operators, setOperators] = useState<{ id: string; name: string; }[]>(
    []
  );
  const [investment, setInvestment] = useState<number>(0);
  const [budgets, setBudgets] = useState<number[]>([]);
  const [guaranteedBudgets, setGuaranteedBudgets] = useState<number[]>([]);
  const [outOfPocket, setOutOfPocket] = useState<number>(0);
  const [radioValue, setRadioValue] = useState<string>("");
  const [radioGuaranteedBudgetsValue, setRadioGuaranteedBudgetsValue] = useState<string>("");
  const [locations, setLocations] = useState<LocationType[]>([]);
  const [percent, setPercent] = useState<number>();
  const { t } = useTranslation();
  const tabletView = useMediaQuery("(min-width:1220px)");
  const mobileView = useMediaQuery("(min-width:630px)");

  const handleChangeInvestment = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = +event.target.value;

    setInvestment(newValue);
    setBudgets(calculateBudgets(newValue, budgets.length));
  };

  const handleChangePercent = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (+event.target.value < 0) {
      return;
    }

    setPercent(+event.target.value);
  };

  const handleSave = () => {
    const locationBudgets = locations.map(
      (location: { id: string; name: string; }, index: number) => {
        return {
          id: location.id,
          name: location.name,
          dynamicBudgetLimit: budgets[index],
          guaranteedBudget: guaranteedBudgets[index],
        };
      }
    );

    saveBudgets(operator, percent || 0, locationBudgets);
  };

  const handleChangeRadioValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "equal") {
      setBudgets(calculateBudgets(investment, budgets.length));
    }
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const handleChangeRadioValueGuaranteedBudgets = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "equal") {
      setGuaranteedBudgets(calculateBudgets(outOfPocket, guaranteedBudgets.length));
    }
    setRadioGuaranteedBudgetsValue((event.target as HTMLInputElement).value);
  };

  const handleMaximulOutOfPocketChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = +event.target.value;

    setOutOfPocket(newValue);
    setGuaranteedBudgets(calculateBudgets(newValue, guaranteedBudgets.length));
  };

  const handleChangeBudgets = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    if (+event.target.value < 0) {
      return;
    }

    const newBudgets = [...budgets];
    newBudgets[index] = +event.target.value;
    setBudgets(newBudgets);
    const sum = newBudgets.reduce(
      (partialSum: number, a: number) => partialSum + a,
      0
    );
    1;
    setInvestment(+sum.toFixed(2));
  };

  const handleChangeGuaranteedBudgets = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    if (+event.target.value < 0) {
      return;
    }

    const newBudgets = [...guaranteedBudgets];
    newBudgets[index] = +event.target.value;
    setGuaranteedBudgets(newBudgets);

    const sum = newBudgets.reduce(
      (partialSum: number, a: number) => partialSum + a,
      0
    );
    1;
    setOutOfPocket(+sum.toFixed(2));
  };


  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const operators = await getOperators();
        setOperators(operators);

        if (operators?.[0]) {
          setOperator(operators[0].id);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchOperators();
  }, []);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        if (operator) {
          const locations = await getBudgets(operator);
          // setBudgets(getBudgets(investment, locations.data.length));
          setLocations(locations.data.locationBudgets);
          let sum = 0;
          let sumGuaranteedBudgets = 0;
          const newBudgets = locations.data.locationBudgets.map(
            (location: LocationType) => {
              sum += location.dynamicBudgetLimit;
              return location.dynamicBudgetLimit;
            }
          );

          const newGuaranteedBudgets = locations.data.locationBudgets.map(
            (location: LocationType) => {
              sumGuaranteedBudgets += location.guaranteedBudget;
              return location.guaranteedBudget;
            }
          );
          setBudgets(newBudgets);
          setGuaranteedBudgets(newGuaranteedBudgets);

          setRadioValue(checkEqual(newBudgets) ? "equal" : "individual");
          setRadioGuaranteedBudgetsValue(checkEqual(newGuaranteedBudgets) ? "equal" : "individual");
          setInvestment(sum);
          setOutOfPocket(sumGuaranteedBudgets);
          setPercent(locations.data.percentBack);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (operator === undefined) {
      setLocations([]);
    }

    fetchLocations();
  }, [operator]);

  return (
    <Box>
      <NavBar activeTab="budgets" />
      <ContentContainer>
        <Box sx={{ p: 4, gap }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",

            }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: 24 }}>
              {t("Budgets for In-Game Campaigns")}
            </Typography>
            <Box sx={{ width: 120, gap }}>
              <SaveButton onClick={handleSave} disabled={!operator}>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <SaveOutlinedIcon sx={{ width: 19 }} />
                  <Typography sx={{ fontSize: 15 }}>{t("Save")}</Typography>
                </Box>
              </SaveButton>
            </Box>
          </Box>
          <ChartCard >
            <Box
              sx={{
                display: "flex",
                flexDirection: mobileView ? "" : "column",
                paddingLeft: 5,
                paddingBottom: CONTENT_SPACING_GAP,
                alignItems: 'center',
              }}
            >
              <Box sx={{ mr: CONTENT_SPACING_GAP }}>
                <Typography sx={{ fontWeight: 700, fontSize: 17 }}>
                  {t("Operator")}
                </Typography>
              </Box>
              <Box>
                <SelectOperator
                  setOperator={setOperator}
                  operator={operator}
                  operators={operators}
                  hasValue
                />
              </Box>
            </Box>
          </ChartCard>
          <Box
            sx={{
              width: "100%",
              heigth: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap,
              mt: 2,
            }}
          >
            <Box
              sx={{
                bgcolor: ACCENT_LIGHT_COLOR,
                flex: "1 1 0",
                heigth: "100%",
                paddingTop: 3,
                width: tabletView ? "" : "100%",
                boxShadow,
                borderRadius,
              }}
            >
              <Box sx={{ paddingLeft: 5, mb: 1 }}>
                <Typography sx={{ fontWeight: 700, fontSize: 17 }}>
                  {t("Reinvested Profit")}
                </Typography>
                <Typography sx={{ fontSize: 15 }}>
                  {t("Define how the profit should be reinvested in the rewards offered during gameplay")}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ maxWidth: '780px' }}>
                <Box
                  sx={{
                    px: 5,
                    paddingTop: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 4
                  }}
                >
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 164px 40px',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: 700, fontSize: 17 }}>
                        {t("Reinvested profit percentage")}
                      </Typography>
                      <Typography sx={{ fontSize: 13 }}>
                        {t(
                          "The percentage of profit that the operator will reinvest in rewards"
                        )}
                      </Typography>
                    </Box>
                    <TextField
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      size="small"
                      sx={{ width: 164 }}
                      value={percent || 0}
                      onChange={handleChangePercent}
                    />
                    <Typography sx={{ fontWeight: 400, textAlign: 'center' }}>%</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 164px 40px',
                      alignItems: 'center',
                    }}
                  >

                    <Box>
                      <Typography sx={{ fontWeight: 700, fontSize: 17 }}>
                        {t("Maximum reinvested amount per month")}
                      </Typography>
                      <Typography sx={{ fontSize: 13 }}>
                        {t(
                          "The maximul amount of money that the operator will reinvest out of the profit"
                        )}
                      </Typography>
                    </Box>
                    <TextField
                      value={investment.toString()}
                      placeholder={investment.toString()}
                      type="number"
                      size="small"
                      sx={{ width: 165, fontWeight: 600 }}
                      onChange={handleChangeInvestment}
                      InputProps={{ inputProps: { min: 0 } }}
                      disabled={radioValue !== 'equal'}
                    />
                    <Typography sx={{ fontWeight: 400, textAlign: 'center' }}>{t("LEI")}</Typography>
                  </Box>
                </Box>
                <Box sx={{ p: 5, paddingTop: 2, overflowX: 'auto' }}>
                  <RadioGroup value={radioValue} onChange={handleChangeRadioValue}>
                    <FormControlLabel
                      value="equal"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: MAIN_COLOR.EXTRA_LIGTH
                            }
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ fontWeight: 600 }}>
                          {t("Distribute the amount equally to all locations")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="individual"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: MAIN_COLOR.EXTRA_LIGTH
                            }
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ fontWeight: 600 }}>
                          {t("Individual amount for each location")}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                  <Box sx={{ paddingLeft: 3.8, marginTop: 2 }}>
                    <Box
                      sx={{
                        marginRight: '40px',
                        border: "1px solid #EBE9F1"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          bgcolor: MAIN_COLOR.EXTRA_EXTRA_LIGHT,
                          height: 40,
                          p: 2
                        }}
                      >
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                          {t("LOCATION NAME")}
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                          {t("Budget (LEI)")}
                        </Typography>
                      </Box>

                      <Box
                        className="locationsTable"
                        sx={{
                          // maxHeight: 550,
                          height: "50vh",
                          overflowY: "scroll",
                          display: locations?.length || "none"
                        }}
                      >
                        <BudgetsLocationsValues
                          values={budgets}
                          onChangeHandler={handleChangeBudgets}
                          disabled={radioValue === "equal"}
                          locations={locations}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              heigth: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap,
              mt: 2,
            }}
          >
            <Box
              sx={{
                bgcolor: ACCENT_LIGHT_COLOR,
                flex: "1 1 0",
                heigth: "100%",
                paddingTop: 3,
                width: tabletView ? "" : "100%",
                boxShadow,
                borderRadius,
              }}
            >
              <Box sx={{ paddingLeft: 5, mb: 1 }}>
                <Typography sx={{ fontWeight: 700, fontSize: 17 }}>
                  {t("Out-of-Pocket Investment")}
                </Typography>
                <Typography sx={{ fontSize: 15 }}>
                  {t("Define how the operator will contribute with their own money when there is not enough profit to power the campaings")}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ maxWidth: '780px' }}>
                <Box
                  sx={{
                    px: 5,
                    paddingTop: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 4
                  }}
                >
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 164px 40px',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: 700, fontSize: 17 }}>
                        {t("Maximum out-of-pocket investment per month")}
                      </Typography>
                      <Typography sx={{ fontSize: 13 }}>
                        {t(
                          "The maximum amount of money spent out of pocket"
                        )}
                      </Typography>
                    </Box>
                    <TextField
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      size="small"
                      sx={{ width: 164 }}
                      value={outOfPocket || 0}
                      onChange={handleMaximulOutOfPocketChange}
                      disabled={radioGuaranteedBudgetsValue !== "equal"}
                    />
                    <Typography sx={{ fontWeight: 400, textAlign: 'center' }}>LEI</Typography>
                  </Box>
                </Box>
                <Box sx={{ p: 5, paddingTop: 2, overflowX: 'auto' }}>
                  <RadioGroup value={radioGuaranteedBudgetsValue} onChange={handleChangeRadioValueGuaranteedBudgets}>
                    <FormControlLabel
                      value="equal"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: MAIN_COLOR.EXTRA_LIGTH
                            }
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ fontWeight: 600 }}>
                          {t("Distribute the amount equally to all locations")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="individual"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: MAIN_COLOR.EXTRA_LIGTH
                            }
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ fontWeight: 600 }}>
                          {t("Individual amount for each location")}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                  <Box sx={{ paddingLeft: 3.8, marginTop: 2 }}>
                    <Box
                      sx={{
                        marginRight: '40px',
                        border: "1px solid #EBE9F1"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          bgcolor: MAIN_COLOR.EXTRA_EXTRA_LIGHT,
                          height: 40,
                          p: 2
                        }}
                      >
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                          {t("LOCATION NAME")}
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                          {t("Budget (LEI)")}
                        </Typography>
                      </Box>

                      <Box
                        className="locationsTable"
                        sx={{
                          height: "50vh",
                          overflowY: "scroll",
                          display: locations?.length || "none"
                        }}
                      >
                        <BudgetsLocationsValues
                          values={guaranteedBudgets}
                          onChangeHandler={handleChangeGuaranteedBudgets}
                          disabled={radioGuaranteedBudgetsValue === "equal"}
                          locations={locations}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </ContentContainer>
    </Box>
  );
};
