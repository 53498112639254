import { Icon } from "@mui/material";
import { ACCENT_DANGER_COLOR, CONTENT_SPACING_GAP, MAIN_COLOR, SUCCESS_COLOR } from "components/styles/constants.styles";
import { TypographyAlertText, TypographyCardTitle } from "components/styles/TypographyCardTitle";
import React from "react";
import { useTranslation } from "react-i18next";
import ChartCard from "./ChartCard";
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';

import ChannelIcon from "components/styles/ChannelIcon";
import { Box } from "@mui/system";
import styled from "styled-components";
import { calculateTime } from "shared/constants";

type ContentCardProps = {
    title: string;
    icon?: any;
    increase?: number;
};

const Content = styled(Box)`
    margin: 0 ${CONTENT_SPACING_GAP} ${CONTENT_SPACING_GAP} ${CONTENT_SPACING_GAP};
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 59px;
`;

const IconContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    z-indez: 5
`;


const ContentCard: React.FC<ContentCardProps> = ({ title, increase, icon }) => {
    return (
        <Content>
            <div>
                <TypographyCardTitle sx={{ mb: "2px" }}>
                    {title}
                </TypographyCardTitle>
                {increase !== undefined && (
                    <TypographyAlertText
                        sx={{
                            color: increase > 0 ? SUCCESS_COLOR.LIGHT : ACCENT_DANGER_COLOR,
                        }}
                    >
                        {increase > 0 ? "+" : null}
                        {increase}%
                    </TypographyAlertText>
                )}
            </div>
            <IconContainer>
                <ChannelIcon size="46px">
                    {icon}
                </ChannelIcon>
            </IconContainer>
        </Content>
    );
};

interface Props {
    value?: number;
    increase?: number;
    title?: string;
    icon?: any;
}

export const AvgProfitPerSessionCard = ({ value, increase, title, icon }: Props) => {
    const { t } = useTranslation();
    const noAvailableData = value === undefined;

    return (
        <ChartCard
            title={title || t("Avg Profit per session")}
            noAvailableData={!value || noAvailableData}
        >
            <ContentCard
                title={noAvailableData ? '100 LEI' : `${value} ${t('LEI')}`}
                increase={increase}
                icon={icon || <AttachMoneyOutlinedIcon />}
            />
        </ChartCard>
    );
};

export const AvgSessionDurationCard = ({ value, increase, title }: Props) => {
    const { t } = useTranslation();
    const noAvailableData = value === undefined;
    const cardContentTitle = value ? calculateTime(value) : '20m 3s';
    return (
        <ChartCard
            title={title || t("Avg Session Duration")}
            noAvailableData={!value || noAvailableData}
        >
            <ContentCard
                title={cardContentTitle}
                increase={increase !== 0 ? increase : undefined}
                icon={<AccessTimeOutlinedIcon />}
            />
        </ChartCard>
    );
};


export const AvgTurnoverCard = ({ value, increase }: Props) => {
    const { t } = useTranslation();
    const noAvailableData = value === undefined;

    return (
        <ChartCard
            title={t("Avg Turnover per slot")}
            noAvailableData={noAvailableData}
        >
            <ContentCard
                title={noAvailableData ? '100 LEI' : `${value} ${t('LEI')}`}
                increase={increase}
                icon={<AttachMoneyOutlinedIcon />}
            />
        </ChartCard>
    );
};

export const AvgProfitPerLocationCard = ({ value, increase }: Props) => {
    const { t } = useTranslation();
    const noAvailableData = value === undefined;

    return (
        <ChartCard
            title={t("Avg Profit per location")}
            noAvailableData={noAvailableData}
        >
            <ContentCard
                title={noAvailableData ? '100 LEI' : `${value} ${t('LEI')}`}
                increase={increase}
                icon={<AttachMoneyOutlinedIcon />}
            />
        </ChartCard>
    );
};

export const TvOutlinedNumberCard = ({ value, title, icon }: Props) => {
    const { t } = useTranslation();
    const noAvailableData = value === undefined;
    return (
        <ChartCard
            title={t(title)}
            noAvailableData={noAvailableData}
        >
            <ContentCard
                title={noAvailableData ? "20.2" : value.toLocaleString()}
                icon={icon || <TvOutlinedIcon />}
            />
        </ChartCard>
    );
};


