import { Box, Checkbox, Icon, Typography, useMediaQuery } from "@mui/material";
import {
  changeCampaignStatus,
  deleteCampaign,
  getCampaignDetails,
  getRewardCodeConversion
} from "api/campaign.service";
import { CustomStatus } from "components/CustomStatus";
import { NavBar } from "components/NavBar";
import { SelectRange } from "components/SelectRange";
import CreateButton from "components/styles/CreateButton.styled";
import { CampaignDetailsInterface } from "Interfaces/ICampaignDetails";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DateRange } from "@mui/lab/DateRangePicker";
import { ExperimentsTable } from "components/ExperimentsTable";
import { AllSessionsTable } from "components/AllSessionsTable";
import { CampaignOverview } from "components/CampaignDetails/CampaignOverview";
import { useTranslation } from "react-i18next";
import { CampaignComparison } from "components/CampaignComparison";
import MissingData from "../assets/no-data-image.svg";
import { getCampaignTriggers } from "api/campaign.service";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import "../components/styles/DetailsPageStyle.css";
import RadialChart from "components/RadialChart";
import ContentContainer from "components/styles/ContentContainer";
import { ACCENT_LIGHT_COLOR, CONTENT_SPACING_GAP, gap, MAIN_COLOR } from "components/styles/constants.styles";
import TotalProfitIncreaseCard from "./DashboardCards/TotalProfitIncreaseCard";
import { AvgProfitPerSessionCard, AvgSessionDurationCard, AvgTurnoverCard } from "./DashboardCards/AvgCard";
import ChartCard from "./DashboardCards/ChartCard";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { createObject, getAllOperators, sortOperatorsAlphabetically } from "api/operators.service";
import LcdCharts from "./CampaignDetails/LcdCharts";
import SmsCharts from "./CampaignDetails/SmsCharts";
import ManualCharts from "./CampaignDetails/ManualCharts";


export const CampaignDetails: React.FC = () => {
  const [campaignDetails, setCampaignDetails] = useState<any>();
  const [conversionCodes, setConversionCodes] = useState<any>();
  const [range, setRange] = useState<DateRange<Date>>([null, null]);
  const { t } = useTranslation();
  const [campaignTriggers, setCampaignTriggers] =
    useState<CampaignDetailsInterface>();
  const mobileView = useMediaQuery("(min-width:549px)");
  const tabletView = useMediaQuery("(min-width:1240px)");
  const [updateStatus, setUpdateStatus] = useState(false);
  const [mockData, setMockData] = useState<boolean>(true);
  const [operators, setAllOperators] = useState<any>([]);
  const [nodes, setNodes] = useState<any>([]);
  const { id } = useParams();

  const navigate = useNavigate();

  const handleDuplicate = () => {
    navigate(`/${campaignDetails.id}/duplicate`);
  };

  const handleMockData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMockData(event.target.checked);
  };

  const resumeCampaign = () => {
    if (campaignDetails) {
      changeCampaignStatus(campaignDetails?.id.toString(), "Running");
      setUpdateStatus(true);
    }
  };

  const pauseCampaign = () => {
    if (campaignDetails) {
      changeCampaignStatus(campaignDetails?.id.toString(), "Paused");
      setUpdateStatus(true);
    }
  };

  const handleDeleteCampaign = () => {
    if (campaignDetails) {
      deleteCampaign(campaignDetails.id);
      navigate("/campaigns");
    }
  };

  useEffect(() => {
    const fetchRewardCodeConversion = async () => {
      try {
        const rewardCodeConversion = await getRewardCodeConversion(Number(id));
        setConversionCodes(rewardCodeConversion);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchDetails = async () => {
      const fetchOperators = async () => {
        try {
          const operators = await getAllOperators();

          const sortedOperators = operators.data.sort(sortOperatorsAlphabetically);
          setAllOperators(createObject(sortedOperators));
          setNodes(createObject(sortedOperators));
        } catch (error) {
          console.log(error);
        }
      };

      fetchOperators();
      try {
        const campaignDetails = await getCampaignDetails(
          Number(id),
          range[0],
          range[1],
          mockData,
        );

        if (['Sms', 'SmsManual', 'Voucher'].includes(campaignDetails?.overview?.channel)) {
          fetchRewardCodeConversion();
        }

        setCampaignDetails(campaignDetails);

        if (!range[0] && !range[1]) {
          if (!['SmsManual', 'Voucher'].includes(campaignDetails?.overview?.channel)) {
            setRange([
              new Date(campaignDetails.overview.startDate),
              new Date(campaignDetails.overview.endDate),
            ]);
          } else {
            setRange([
              new Date(campaignDetails.createdAt),
              null,
            ]);
          }
        }
      } catch (error) {
        setCampaignDetails(undefined);
        console.log(error);
      }
    };

    const fetchTriggers = async () => {
      try {
        const campaignTriggers = await getCampaignTriggers(Number(id));
        setCampaignTriggers(campaignTriggers);
      } catch (error) {
        console.log(error);
      }
    };

    fetchTriggers();
    fetchDetails();
    setUpdateStatus(false);
  }, [id, range, updateStatus, mockData]);

  if (!campaignDetails) {
    return (
      <>
        <NavBar activeTab="campaigns" />
        <ContentContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              p: 4,
              flexFlow: "wrap",
              gap: 2
            }}
          >
            <Box>
              <Checkbox checked={mockData} onChange={handleMockData} sx={{
                "&.Mui-checked": {
                  color: MAIN_COLOR.EXTRA_LIGTH
                }
              }} />
              Mock Data
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", p: 12 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <Typography sx={{ fontSize: 28, fontWeight: 600 }}>
                {t("There is no data available yet")}. <br />
                {t("Please come back later")}.
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                {t("The campaign hasn’t started yet or doesn’t have enough data")}
                .
                <br />{" "}
                {t("We are working on gathering useful information for you")}
              </Typography>
            </Box>
            <Box sx={{ width: 549, mt: 10, display: tabletView ? "" : "none" }}>
              <Icon sx={{ overflow: "visible", mr: 1, mb: 0.8 }}>
                <img src={MissingData} alt="no data" />
              </Icon>
            </Box>
          </Box>
          <Box sx={{ bgcolor: ACCENT_LIGHT_COLOR, ml: 4, mr: 4 }}>
            <CampaignOverview
              campaignTriggers={campaignTriggers}
              campaignDetails={campaignDetails}
              conversionCodes={conversionCodes}
              targetIds={campaignTriggers?.target}
              allOperators={operators}
            />
          </Box>
        </ContentContainer>
      </>
    );
  }

  return (
    <>
      <NavBar activeTab="campaigns" />
      <ContentContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 4,
            flexFlow: "wrap",
            gap: 2
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 500, display: "flex" }}>
            {campaignDetails?.overview.name}
            <Box sx={{ ml: 2 }}>
              <CustomStatus status={campaignDetails?.status} />
            </Box>
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            {campaignDetails?.overview.status === "Paused" ? (
              <Box onClick={resumeCampaign}>
                <CreateButton>
                  <Icon>
                    <PlayArrowIcon fontSize="small" />
                  </Icon>
                  {t("Resume")}
                </CreateButton>
              </Box>
            ) : (
              <></>
            )}

            {campaignDetails?.overview.status === "Running" ? (
              <Box onClick={pauseCampaign}>
                <CreateButton>
                  <Icon>
                    <PauseIcon fontSize="small" />
                  </Icon>
                  {t("Pause")}
                </CreateButton>
              </Box>
            ) : (
              <></>
            )}
            <Box sx={{ maxWidth: 200 }} onClick={handleDeleteCampaign}>
              {campaignDetails.overview.status === "Draft" ||
                campaignDetails.overview.status === "Scheduled" ? (
                <CreateButton>
                  <DeleteForeverOutlinedIcon fontSize="small" />
                  {t(`Delete Campaign`)}
                </CreateButton>
              ) : (
                <></>
              )}
            </Box>
            <Box sx={{ maxWidth: 200 }} onClick={handleDuplicate}>
              <CreateButton>
                <ContentCopyOutlinedIcon fontSize="small" />
                {t(`Duplicate Campaign`)}
              </CreateButton>
            </Box>
            {campaignDetails?.overview.channel === 'SmsManual' && (
              <Box sx={{ maxWidth: 240 }}>
                <CreateButton>
                  <ContentCopyOutlinedIcon fontSize="small" />
                  {t(`Download Phone Number List`)}
                </CreateButton>
              </Box>
            )}
            {campaignDetails?.overview.channel === 'Voucher' && (
              <Box sx={{ maxWidth: 240 }}>
                <CreateButton>
                  <ContentCopyOutlinedIcon fontSize="small" />
                  {t(`Download Voucher Code List`)}
                </CreateButton>
              </Box>
            )}
            <Box>
              <Checkbox checked={mockData} onChange={handleMockData} sx={{
                "&.Mui-checked": {
                  color: MAIN_COLOR.EXTRA_LIGTH
                }
              }} />
              Mock Data
            </Box>
          </Box>
        </Box>
        {campaignDetails?.overview.startDate ? (
          <Box sx={{ px: 4, pb: 4 }}>
            <Box sx={{ mb: gap }}>
              <ChartCard>
                <Box sx={{ mb: CONTENT_SPACING_GAP, mx: CONTENT_SPACING_GAP }}>
                  <SelectRange
                    setRange={setRange}
                    range={range}
                    minDate={campaignDetails.overview.startDate}
                    maxDate={campaignDetails.overview.endDate}
                  />
                </Box>
              </ChartCard>
            </Box>
            {campaignDetails?.overview.channel === 'Lcd' && (
              <LcdCharts campaignDetails={campaignDetails} range={range} />
            )}
            {campaignDetails?.overview.channel === 'Sms' && (
              <SmsCharts campaignDetails={campaignDetails} range={range} />
            )}
            {['SmsManual', 'Voucher'].includes(campaignDetails?.overview.channel) && (
              <ManualCharts campaignDetails={campaignDetails} range={range} />
            )}
            <Box
              sx={{ bgcolor: ACCENT_LIGHT_COLOR, mt: 2, display: mobileView ? "" : "none" }}
            >
              <AllSessionsTable campaignId={[campaignDetails.id.toString()]} />
            </Box>
          </Box>
        ) : (
          <></>
        )}
        <Box sx={{ bgcolor: ACCENT_LIGHT_COLOR, ml: 4, mr: 4, mb: 10 }}>
          <CampaignOverview
            campaignTriggers={campaignTriggers}
            campaignDetails={campaignDetails}
            conversionCodes={conversionCodes}
            targetIds={campaignTriggers?.target}
            allOperators={operators}
          />
        </Box>
      </ContentContainer>
    </>
  );
};
