import React from "react";
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './RevenueReportCard.css';
import ChartCard from "./ChartCard";
import { useTranslation } from "react-i18next";
import { MAIN_COLOR } from "components/styles/constants.styles";
import { splitInTheMiddleSpace } from "./utils";

const MOCK_DATA = [
    {
        name: 'Earning',
        data: [95, 177, 284, 256, 122, 55, 213]
    },
    {
        name: 'Expense',
        data: [-145, -80, -60, -180, - 20, -40, -20]
    },
    {
        name: 'Total',
        data: [-145, -80, -60, -180, - 20, -40, -20]
    }
];

interface Props {
    revenueReports: {
        earning: number;
        expense: number;
        isFocused: boolean;
        month: string;
        reward: string;
    }[];
    labels: string[];
}

const getParsedData = (revenueReports: any) => {
    if (!revenueReports) {
        return { earnings: [], expenses: [], labels: [] };
    }
    return revenueReports.reduce((acc: any, item: any) => {
        return {
            earnings: [...acc.earnings, item.earning],
            expenses: [...acc.expenses, -1 * item.expense], // -1 to show into the negatives side
            labels: [...acc.labels, splitInTheMiddleSpace(item.month, 4)]
        };
    }, { earnings: [], expenses: [], labels: [] });
};

const getOptions = (labels: any, t: any) => {
    const options: ApexOptions = {
        chart: {
            stacked: true,
            toolbar: { show: false }
        },
        grid: {
            padding: {
                top: -90,
                bottom: 0
            },
            yaxis: {
                lines: { show: false }
            }
        },
        xaxis: {
            categories: labels,
            labels: {
                style: {
                    colors: '#6E6B7B',
                    fontSize: '12px', fontFamily: "Montserrat,sans-serif"
                },
            },
            axisTicks: {
                show: true
            },
            axisBorder: {
                show: false
            },
        },
        legend: {
            show: false,
            fontSize: '12px',
            fontFamily: "Montserrat,sans-serif",
            markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
            },
        },
        dataLabels: {
            enabled: false,
        },
        colors: [MAIN_COLOR.EXTRA_LIGTH, '#FF9F43', '#3ACB7A'],
        plotOptions: {
            bar: {
                columnWidth: '28px',
                borderRadius: [3],
            },
        },
        stroke: {
            width: [2, 2, 4]
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#6E6B7B',
                    fontSize: '12px', fontFamily: "Montserrat,sans-serif"
                },
                formatter: function (val: any) {
                    return `${val} ${t("LEI")}`;
                }
            }
        },
        tooltip: {
            intersect: false,
            shared: true,
            style: {
                fontSize: '12px', fontFamily: "Montserrat,sans-serif"
            },
            x: {
                show: true,
                formatter: function (val: any) {
                    return val;
                }
            },
            y: {
                formatter: function (val: any, opts: any) {
                    if (opts.seriesIndex === 2) {
                        return `${val} ${t("LEI")}`;
                    }
                    return `${Math.abs(val)} ${t("LEI")}`;
                }
            },
        },
    };

    return options;
};

const getSeries = (earnings: any, expenses: any) => {
    if (!earnings.length && !earnings.length) {
        return MOCK_DATA;
    }

    const profit = expenses.map((expense: number, index: number) => Math.round((earnings[index] + expense) * 100) / 100);

    return [
        {
            name: 'Earning',
            data: earnings,
            type: 'column',
        },
        {
            name: 'Expense',
            data: expenses,
            type: 'column',
        },
        {
            name: 'Profit',
            data: profit,
            type: 'line',
        }
    ];
};

const RevenueReportTimelineCard = ({ revenueReports }: Props) => {
    const { t } = useTranslation();
    const { earnings, expenses, labels } = getParsedData(revenueReports);

    const series = getSeries(earnings, expenses);
    const options = getOptions(labels, t);

    return (
        <ChartCard title={t("Revenue Report")} noAvailableData={!revenueReports}>
            <Chart
                id='chart_2'
                type='bar'
                height='420'
                options={options}
                series={series}
            />
        </ChartCard>
    );
};

export default RevenueReportTimelineCard;