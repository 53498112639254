import { Box, Icon, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import OneIcon from "../assets/icons/one-icon.svg";
import TwoIcon from "../assets/icons/two-icon.svg";
import ThreeIcon from "../assets/icons/three-icon.svg";
import FourIcon from "../assets/icons/four-icon.svg";
import { MAIN_COLOR } from "./styles/constants.styles";

interface Props {
  aiFindings: string[];
}

export const ExperimentFindings = ({ aiFindings }: Props) => {
  const { t } = useTranslation();

  if (!aiFindings?.length) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography sx={{ color: MAIN_COLOR.DARK, fontSize: 15, fontWeight: 500 }}>
          {t("Experiment Findings")}
        </Typography>
      </Box>
    );
  }
  return (
    <Box sx={{ p: 2 }}>
      <Typography sx={{ color: MAIN_COLOR.DARK, fontSize: 15, fontWeight: 500 }}>
        {t("Experiment Findings")}
      </Typography>
      <Box sx={{ display: "flex", mt: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
          <Icon sx={{ overflow: "visible", mb: 2.4 }}>
            <img src={OneIcon} alt="1" />
          </Icon>
          <Typography sx={{ mt: 1 }}>{aiFindings[0]}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            ml: 5
          }}
        >
          <Icon sx={{ overflow: "visible", mb: 2.4 }}>
            <img src={TwoIcon} alt="1" />
          </Icon>
          <Typography sx={{ mt: 1 }}>
            <Typography sx={{ mt: 1 }}>{aiFindings[1]}</Typography>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", mt: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
          <Icon sx={{ overflow: "visible", mb: 2.4 }}>
            <img src={ThreeIcon} alt="1" />
          </Icon>
          <Typography sx={{ mt: 1 }}>{aiFindings[2]}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            ml: 5
          }}
        >
          <Icon sx={{ overflow: "visible", mb: 2.4 }}>
            <img src={FourIcon} alt="1" />
          </Icon>
          <Typography sx={{ mt: 1 }}>
            <Typography sx={{ mt: 1 }}>{aiFindings[3]}</Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
