import { NavBar } from "../../components/NavBar";
import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getDashboardDetails } from "api/campaign.service";
import { ProfitIncrease } from "components/ProfitIncrease";
import AvgProfitIcon from "../assets/icons/avg-profit.svg";
import { AverageProfit } from "components/AverageProfit";
import { AverageDuration } from "components/AverageDuration";
import { RevenueReport } from "components/RevenueReport";
import { ExperimentFindings } from "components/ExperimentFindings";
import { OperatorsChart } from "components/OperatorsChart";
import { LocationsChart } from "components/LocationsChart";
import { AllSessionsTable } from "components/AllSessionsTable";
import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";
import { SelectRange } from "components/SelectRange";
import { RewardContribution } from "../../components/RewardContribution";
import PlayersRetention from "components/PlayersRetention";
import ProfitIncreasePie from "components/ProfitIncreasePie";
import SelectOperator from "components/SelectOperator";
import { getLocations } from "api/operators.service";
import SelectLocations from "components/SelectLocations";
import "../../components/styles/AdminDashboardStyles.css";
import { ACCENT_LIGHT_COLOR } from "components/styles/constants.styles";

const findLocationsId = (selectedLocations: string[], locations: string[]) => {
  const selectedIds: any = [];

  selectedLocations.forEach((selectedLocation: string) => {
    selectedIds.push(
      locations.find((location: any) => location.name === selectedLocation)
    );
  });

  return selectedIds.map((location: any) => location.id);
};

const Dashboard: React.FC = () => {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  const dateString = date.toISOString().split("T")[0];
  const mobileView = useMediaQuery("(min-width:549px)");
  const { t } = useTranslation();
  const [data, setData] = useState<any>(); //TO DO: Create interface
  const [range, setRange] = useState<DateRange<Date>>([
    new Date(dateString),
    new Date()
  ]);
  const [operator, setOperator] = useState<string>("");
  const [locations, setLocations] = useState<string[]>([]);
  const [location, setLocation] = useState<string>("");
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const locationIds = useMemo(() => {
    return findLocationsId(selectedLocations, locations);
  }, [selectedLocations]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const dashboardData = await getDashboardDetails(
          false,
          "Admin",
          range[0],
          range[1],
          "",
          [operator || ""],
          locationIds
        );
        setData(dashboardData.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDetails();
  }, [range, operator, location, locationIds]);

  useEffect(() => {
    if (operator != "") {
      const fetchLocations = async () => {
        try {
          const locations = await getLocations([operator]);

          setLocations(locations.data);
          // setLocation("");
        } catch (error) {
          console.log(error);
        }
      };

      fetchLocations();
      // setSelectedLocations([]);
    }
  }, [operator]);

  if (!data) {
    return <>Loading...</>;
  }

  return (
    <>
      <NavBar activeTab="dashboard" />
      admin
      <Box sx={{ p: 4 }}>
        <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 3 }}>
          {t("Dashboard")}
        </Typography>
        <Box>
          <Box
            sx={{
              display: "flex",
              mb: 2,
              gap: 2,
              flexFlow: "wrap",
              flexDirection: mobileView ? "row" : "column"
            }}
          >
            <SelectRange setRange={setRange} range={range} />
            <SelectOperator setOperator={setOperator} />
            <SelectLocations
              locations={locations}
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
            />
          </Box>
        </Box>
        {data ? (
          <>
            <Box className="adminGrid">
              <Box className="admin-item1">
                <ProfitIncrease
                  profitIncrease={data.kpi.kpiList}
                  profitIncreaseLastMonth={data.kpi.kpiListLastMonth}
                  total={data.kpi.kpiTotal}
                  totalLastMonth={data.kpi.kpiTotalLastMonth}
                  increase={data.kpi.kpiIncrease}
                  startDate={0}
                />
              </Box>
              <Box className="admin-item2">
                <AverageProfit
                  averageProfit={data.averageProfit.value}
                  profitIncrease={data.averageProfit.increase}
                  mobileView={mobileView}
                />
              </Box>
              <Box className="admin-item3">
                <AverageDuration
                  averageDuration={data.averageDuration.value}
                  durationIncrease={data.averageDuration.increase}
                  mobileView={mobileView}
                />
              </Box>
              <Box className="admin-item4">
                <AverageProfit
                  averageProfit={data.averageProfit.value}
                  profitIncrease={data.averageProfit.increase}
                  mobileView={mobileView}
                />
              </Box>
              <Box className="admin-item5">
                <AverageProfit
                  averageProfit={data.averageProfit.value}
                  profitIncrease={data.averageProfit.increase}
                  mobileView={mobileView}
                />
              </Box>
            </Box>
            <Box className="admin-item6">
              <ProfitIncreasePie rewards={data.rewardsContribution} />
            </Box>
            <Box className="admin-item7">
              <ExperimentFindings aiFindings={data.aiFindings} />
            </Box>
            <Box className="admin-item8">
              <RevenueReport
                revenueReports={data.revenueReports.slice(0, 11)}
                labels={[
                  "Jan",
                  "Feb",
                  "Mar",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec"
                ]}
              />
            </Box>
            <Box className="admin-item9">
              <PlayersRetention retention={data.playersRetention} />
            </Box>
            <Box className="admin-item10">
              <LocationsChart
                locationPerformances={data.locationPerformances.slice(0, 7)}
              />
            </Box>
            {/* </Box> */}

            <Box
              sx={{
                mt: 2,
                bgcolor: ACCENT_LIGHT_COLOR,
                display: mobileView ? "inline" : "none"
              }}
            ></Box>
          </>
        ) : (
          <>Loading</>
        )}
      </Box>
    </>
  );
};

export default Dashboard;
