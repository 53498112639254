import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { MAIN_COLOR } from "components/styles/constants.styles";
import { TypographyAlertText, TypographyDescription } from "components/styles/TypographyCardTitle";
import React from "react";
import { Trans } from "react-i18next";

interface Props {
    label: string,
    checked: boolean,
    name: string;
    disabled?: boolean,
}

const TextRadioOption = ({
    label,
    checked,
    name,
    disabled,
}: Props) => {
    return (
        <FormControlLabel
            value={name}
            disabled={disabled}
            control={
                <Radio
                    checked={checked}
                    sx={{
                        "&.Mui-checked": {
                            color: MAIN_COLOR.EXTRA_LIGTH
                        }
                    }}
                />
            }
            label={
                <TypographyDescription>
                    {label}
                </TypographyDescription>
            }
        />
    );
};

export default TextRadioOption;