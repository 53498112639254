import React from "react";
import ChartCard from "./ChartCard";

import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CONTENT_SPACING_GAP, MAIN_COLOR, gap } from "components/styles/constants.styles";
import { TypographyDescription } from "components/styles/TypographyCardTitle";
import styled from "styled-components";

interface Props {
    retention: {
        withdraw: number;
        rewardActivation: number;
        moneyLost: number;
        deposit: number;
    };
}

const CardContainer = styled(Box)`
    padding: 0 ${gap};
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: ${gap};
    align-items: center;
`;

const TypographyTextValue = styled(Box)`
    text-align: right;
    color: ${MAIN_COLOR.EXTRA_LIGTH};
    font-weight: 700;
`;

const TEXT_BY_KEY = {
    withdraw: "Players wanted to withdraw money but continued playing",
    rewardActivation: "Players came in and played becase of the rewards we’ve sent by sms",
    moneyLost: "Users decided to keep playing even if they lost money before",
    deposit: "Players who decided to deposit more money when having no money left in the balance"
};

interface PropsPlayersRetentionValues {
    withdraw: number;
    rewardActivation: number;
    moneyLost: number;
    deposit: number;
}

const PlayersRetentionValues: React.FC<PropsPlayersRetentionValues> = ({ withdraw, rewardActivation, moneyLost, deposit }) => {
    const { t } = useTranslation();
    return (
        <CardContainer>
            <TypographyTextValue>
                {withdraw}
            </TypographyTextValue>
            <TypographyDescription>
                {t("Players wanted to withdraw money but continued playing")}
            </TypographyDescription>
            <TypographyTextValue>
                {rewardActivation}
            </TypographyTextValue>
            <TypographyDescription>
                {t(
                    "Players came in and played becase of the rewards we’ve sent by sms"
                )}
            </TypographyDescription>
            <TypographyTextValue>
                {moneyLost}
            </TypographyTextValue>
            <TypographyDescription>
                {t(
                    "Users decided to keep playing even if they lost money before"
                )}
            </TypographyDescription>
            <TypographyTextValue>
                {deposit}
            </TypographyTextValue>
            <TypographyDescription>
                {t(
                    "Players who decided to deposit more money when having no money left in the balance"
                )}
            </TypographyDescription>
        </CardContainer>
    );
};

const MOCK_DATA = {
    WITHDRAW: 10,
    REWARD_ACTIVATION: 100,
    MONEY_LOST: 0,
    DESPOSIT: 21,
};
const PlayersRetentionCard = ({ retention }: Props) => {
    const { t } = useTranslation();

    if (!retention) {
        return (
            <ChartCard
                title={t("Players Retention")}
                noAvailableData
            >
                <PlayersRetentionValues
                    withdraw={MOCK_DATA.WITHDRAW}
                    rewardActivation={MOCK_DATA.REWARD_ACTIVATION}
                    moneyLost={MOCK_DATA.MONEY_LOST}
                    deposit={MOCK_DATA.DESPOSIT}
                />
            </ChartCard>
        );
    }

    return (
        <ChartCard title={t("Players Retention")} centered>
            <PlayersRetentionValues
                withdraw={retention.withdraw}
                rewardActivation={retention.rewardActivation}
                moneyLost={retention.moneyLost}
                deposit={retention.deposit}
            />
        </ChartCard>
    );
};

export default PlayersRetentionCard;
