import client from "./axios";

const url = "campaign-editor/Auth";

export const loginRequest = async (username: string, password: string) => {
  const res = await client.post(`${url}/login`, {
    generate_refresh_token: true,
    username: username,
    password: password
  });
  return await res.data;
};

export const refreshToken = async (token: string) => {
  const res = await client.post(`${url}/refresh`, { refreshToken: token });

  return await res.data;
};

export const getUserData = async () => {
  const res = await client.get(`${url}/user-data`);

  return await res.data;
};
