import { FixedDynamicValueType, FIXED_DYNAMIC_VALUE_DEFAULT } from "Interfaces/CampaignBody";

export const isEmptyRewardValue = (value: FixedDynamicValueType) => {
    const { type, fixedValue, basedOn, multipliedBy, minimum, maximum, multipleOf, aiAmount } = value;

    return (
        type === FIXED_DYNAMIC_VALUE_DEFAULT.type &&
        fixedValue === FIXED_DYNAMIC_VALUE_DEFAULT.fixedValue &&
        basedOn === FIXED_DYNAMIC_VALUE_DEFAULT.basedOn &&
        multipliedBy === FIXED_DYNAMIC_VALUE_DEFAULT.multipliedBy &&
        minimum === FIXED_DYNAMIC_VALUE_DEFAULT.minimum &&
        maximum === FIXED_DYNAMIC_VALUE_DEFAULT.maximum &&
        multipleOf === FIXED_DYNAMIC_VALUE_DEFAULT.multipleOf &&
        aiAmount === FIXED_DYNAMIC_VALUE_DEFAULT.aiAmount
    );
};
