import React, { useState } from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { TypographyAlertText, TypographyDescription } from "components/styles/TypographyCardTitle";
import { Trans, useTranslation } from "react-i18next";
import TextRadioOption from "../TextRadioOption";
import RewardDetailsItem from "../RewardDetailsItem";
import styled from "styled-components";
import { DateLabel, TimeLabel } from "./DaysAndTimeInputs";
import { MAIN_COLOR } from "components/styles/constants.styles";

const CenteredContent = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

interface Props {
  getValueByKey: (key: string) => any;
}

const isDontSend = (value: any) => [null, '0:0:00'].includes(value);

const ReminderSelect = ({
  getValueByKey,
}: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('Direct');
  const [reminderMessage, setReminderMessage] = getValueByKey("smsDetails.reminderMessage");

  const handleRadioValueChange = (e: any) => {
    setValue(e.target.value);
    if (e.target.value === "Direct") {
      setReminderMessage({
        ...reminderMessage,
        relativeTime: null,
        absoluteTime: null
      });

      return;
    }

    if (e.target.value === "RelativeTime") {
      setReminderMessage({
        ...reminderMessage,
        relativeTime: '1.00:00:00',
        absoluteTime: null
      });

      return;
    }

    setReminderMessage({
      ...reminderMessage,
      relativeTime: null,
      absoluteTime: '1:00:00'
    });
  };

  const setRelativeTime = (relativeTime: string) => {
    setReminderMessage({
      ...reminderMessage,
      relativeTime,
      absoluteTime: null
    });
  };

  const setAbsoluteTime = (absoluteTime: string) => {
    setReminderMessage({
      ...reminderMessage,
      relativeTime: null,
      absoluteTime
    });
  };

  return (
    <RewardDetailsItem>
      <TypographyAlertText>
        <Trans>Reminder SMS</Trans>
      </TypographyAlertText>

      <FormControl>
        <RadioGroup value={value} onChange={handleRadioValueChange}>
          <TextRadioOption
            name="Direct"
            checked={isDontSend(reminderMessage.relativeTime) && isDontSend(reminderMessage.absoluteTime)}
            label={t("Don't send")}
          />
          <FormControlLabel
            value="RelativeTime"
            control={
              <Radio
                checked={!isDontSend(reminderMessage.relativeTime) && isDontSend(reminderMessage.absoluteTime)}
                sx={{
                  "&.Mui-checked": {
                    color: MAIN_COLOR.EXTRA_LIGTH
                  }
                }}
              />
            }
            label={
              <TypographyDescription>
                <CenteredContent>
                  <DateLabel
                    onValueChange={setRelativeTime}
                    value={reminderMessage.relativeTime}
                    checked={!isDontSend(reminderMessage.relativeTime) && isDontSend(reminderMessage.absoluteTime)}
                  />
                  <Trans>before the reward is available</Trans>
                </CenteredContent>
              </TypographyDescription>
            }
          />
          <FormControlLabel
            value="AbsoluteTime"
            control={
              <Radio
                checked={isDontSend(reminderMessage.relativeTime) && !isDontSend(reminderMessage.absoluteTime)}
                sx={{
                  "&.Mui-checked": {
                    color: MAIN_COLOR.EXTRA_LIGTH
                  }
                }}
              />
            }
            label={
              <TypographyDescription>
                <CenteredContent>
                  <Trans>At</Trans>
                  <TimeLabel
                    onValueChange={setAbsoluteTime}
                    value={reminderMessage.absoluteTime}
                    checked={isDontSend(reminderMessage.relativeTime) && !isDontSend(reminderMessage.absoluteTime)}
                  />
                  <Trans>on the day when the reward becomes available</Trans>
                </CenteredContent>
              </TypographyDescription>
            }
          />
        </RadioGroup>
      </FormControl>
    </RewardDetailsItem>
  );
};

export default ReminderSelect;