import { Box } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";

interface Props {
  conversion: number;
  averageProfit: number;
  reach: number;
  profit: number;
}

export const CustomPerformance = ({
  conversion,
  averageProfit,
  reach,
  profit
}: Props) => {
  const ConversionNumber = () =>
    conversion !== 0 ? (
      <Box sx={{ fontWeight: 600, ml: 0.5 }}>{`${conversion}%`}</Box>
    ) : (
      <>-</>
    );

  const AverageProfitNumber = () =>
    averageProfit !== 0 ? (
      <Box sx={{ fontWeight: 600, ml: 0.5 }}>{` ${averageProfit} LEI`}</Box>
    ) : (
      <>-</>
    );

  const ReachNumber = () =>
    reach !== 0 ? <Box sx={{ fontWeight: 600, ml: 0.5 }}>{reach}</Box> : <>-</>;

  const ProfitNumber = () =>
    profit !== 0 ? (
      <Box sx={{ fontWeight: 600, ml: 0.5 }}>{`${profit} LEI`}</Box>
    ) : (
      <>-</>
    );

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Trans>Conversion</Trans>:
        <ConversionNumber />
      </Box>
      <Box sx={{ display: "flex" }}>
        Average Profit/session: <AverageProfitNumber />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Trans>Reach</Trans>:
        <ReachNumber />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Trans>Profit</Trans>:
      </Box>
    </Box>
  );
};
