import { createSlice } from "@reduxjs/toolkit";

export interface IAuthState {
  token: string;
  userData?: { profilePicture?: string; userType: string; username?: string; },
}

const initialState: IAuthState = {
  token: "",
  userData: {
    userType: ''
  },
};

const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    setAuthData: (state, action) => {
      state.token = action.payload.token;
    },
    setAppUsername: (state, action) => {
      state.userData = action.payload.userData;
    },
  },
});

export default authSlice.reducer;
export const { setAuthData, setAppUsername } = authSlice.actions;
