import React from "react";
import ChartCard from "./ChartCard";
import Chart from 'react-apexcharts';
import { useTranslation } from "react-i18next";
import { MAIN_COLOR } from "components/styles/constants.styles";
import { ApexOptions } from "apexcharts";
import { truncateString } from "./utils";

const MOCK_SERIES = [
    {
        data: [700, 600, 550, 480, 350, 210, 150]
    }
];

interface Props {
    locationPerformances: { id: string; name: string; value: number; }[];
}

const getSortedValues = (locationPerformances: { id: string; name: string; value: number; }[]) => {
    const sortedValue = locationPerformances.sort((a, b) => {
        if (a.value > b.value) {
            return -1;
        }
        if (a.value < b.value) {
            return 1;
        }
        // a must be equal to b
        return 0;
    }).reduce((acc: { locations: string[], values: number[]; }, item: { id: string; name: string; value: number; }) => {
        return {
            locations: [...acc.locations, item.name],
            values: [...acc.values, item.value]
        };
    }, { locations: [], values: [] });

    return sortedValue;
};

const getSeries = (values: number[]) => {
    return [{ name: 'Value', data: values }];
};

const getOptions = (locations: string[], t: any) => {
    const options: ApexOptions = {
        chart: {
            parentHeightOffset: 0,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '45%',
                borderRadius: [3]
            }
        },
        grid: {
            show: false,
        },
        colors: [MAIN_COLOR.EXTRA_DARK],
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: locations,
            labels: {
                formatter: function (val: any) {
                    return `${val} LEI`;
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#6E6B7B',
                    fontSize: '12px', fontFamily: "Montserrat,sans-serif"
                },
            }
        },
        tooltip: {
            style: {
                fontSize: '12px', fontFamily: "Montserrat,sans-serif"
            },
            y: {
                formatter: function (val: any) {
                    return `${Math.abs(val)} ${t("LEI")}`;
                }
            },
            shared: false,
        },
        responsive: [{
            breakpoint: 768,
            options: {
                yaxis: {
                    labels: {
                        style: {
                            colors: '#6E6B7B',
                            fontSize: '12px', fontFamily: "Montserrat,sans-serif"
                        },
                        formatter: function (val: any) {
                            return truncateString(val.toString());
                        }
                    }
                },
            },
        }]

    };

    return options;
};

const BestPerformingLocationsCard = ({ locationPerformances }: Props) => {
    const { t } = useTranslation();

    if (!locationPerformances) {
        const options = getOptions(Array(7).fill('').map((item, index) => `Location ${index + 1}`), t);
        return (
            <ChartCard
                title={t("Best Performing Locations")}
                noAvailableData
            >
                <Chart options={options} series={MOCK_SERIES} type='bar' height={400} />
            </ChartCard>
        );
    }
    const { locations, values } = getSortedValues(locationPerformances);

    const series = getSeries(values);
    const options = getOptions(locations, t);

    return (
        <ChartCard
            title={t("Best Performing Locations")}
        >
            <Chart options={options} series={series} type='bar' height={380} />
        </ChartCard>
    );
};

export default BestPerformingLocationsCard;
