import client from "./axios";

const entity = "campaign-editor/AIPerformance";

type PerformanceDashboardOptions = {
    startDate?: Date | null,
    endDate?: Date | null,
    operators?: string[] | null,
    locations?: string[] | null,
};
export const getAIPerformanceDashboard = async (options: PerformanceDashboardOptions) => {
    const body = {
        ...options
    };
    const res = await client.post(`${entity}/get-dashboard`, body);

    return await res.data;
};

type LocationsOptions = {
    descending: boolean,
    orderBy: string,
    isPagingEnabled: true,
    page: number,
    pageSize: number,
    startDate?: Date | null,
    endDate?: Date | null,
    operators?: string[] | null,
    locations?: string[] | null,
};

export const getAIPerformanceLocations = async (options: LocationsOptions) => {
    const body = {
        ...options,
    };
    const res = await client.post(`${entity}/get-locations`, body);

    return await res.data;
};
