import { getCampaignTriggers } from "api/campaign.service";
import { CampaignDetailsInterface } from "Interfaces/ICampaignDetails";
import { CreateCampaign } from "pages/CreateCampaign";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CampaignWrapper: React.FC<{ action: string; }> = ({ action }) => {
  const [campaignDetails, setCampaignDetails] =
    useState<CampaignDetailsInterface>();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const work = async () => {
        try {
          const campaignDetails: CampaignDetailsInterface =
            await getCampaignTriggers(Number(id));

          setCampaignDetails(campaignDetails);
        } catch (error) {
          console.warn(error);
          navigate('/campaigns');
        }
      };
      work();
    }
  }, [id]);

  if (campaignDetails)
    return (
      <CreateCampaign action={action} campaignDetails={campaignDetails} />
    );

  return <CreateCampaign action={action} />;
};

export default CampaignWrapper;
