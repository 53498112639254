import { parseDateTime } from "components/RewardOffering/SMSComponents/DaysAndTimeInputs";
import { FixedDynamicValueType } from "Interfaces/CampaignBody";
import React from "react";
import { useTranslation } from "react-i18next";

export const findLocationsId = (
  selectedLocations: string[],
  locations: string[]
) => {
  const selectedIds: any = [];

  selectedLocations.forEach((selectedLocation: string) => {
    selectedIds.push(
      locations.find((location: any) => location.name === selectedLocation)
    );
  });

  return selectedIds.map((location: any) => location.id);
};

export const calculateTime = (averageDuration: number) => {
  let minutes = averageDuration / 60;
  let hours = 0;
  let seconds = 0;

  minutes = averageDuration / 60;
  while (minutes >= 60) {
    hours += 1;
    minutes = minutes / 60;
  }
  minutes = Math.floor(minutes);
  seconds = Math.floor(minutes % 60);

  return `${hours ? `${hours}h` : ""}
  ${minutes ? `${minutes}m` : ""} ${seconds}s`;
};

export const sortArray = [
  {
    id: 1,
    text: "Campaign ID Ascending",
    sortBy: "id",
    descending: false
  },
  {
    id: 2,
    text: "Campaign ID Descending",
    sortBy: "id",
    descending: true
  },
  {
    id: 3,
    text: "Campaign Name A-Z",
    sortBy: "name",
    value: "nameAsc",
    descending: false
  },
  {
    id: 4,
    text: "Campaign Name Z-A",
    sortBy: "name",
    descending: true
  },
  {
    id: 5,
    text: "Conversion Ascending",
    sortBy: "conversion",
    descending: false
  },
  {
    id: 6,
    text: "Conversion Descending",
    sortBy: "conversion",
    descending: true
  },
  {
    id: 7,
    text: "Avg Profit/session Ascending",
    sortBy: "avgProfit",
    descending: false
  },
  {
    id: 8,
    text: "Avg Profit/session Descending",
    sortBy: "avgProfit",
    descending: true
  },
  {
    id: 9,
    text: "Reach Ascending",
    sortBy: "reach",
    descending: false
  },
  {
    id: 10,
    text: "Reach Descending",
    sortBy: "reach",
    descending: true
  }
];

export const CURRENCY_NAME = 'LEI';

export const createTriggersPreview: any = (lng: string, t?: any) => {
  switch (lng) {
    case "en":
      return {
        no_more_credits: {
          "=": (value: string) =>
            value === "False"
              ? ` has more than zero credits left`
              : ` has no more credits left`
        },
        deposit_amount: {
          "=": (value: string) => ` has deposited ${value} ${CURRENCY_NAME}`,
          ">": (value: string) => ` has deposited more than ${value} ${CURRENCY_NAME}`,
          "<": (value: string) => ` has deposited less than ${value} ${CURRENCY_NAME}`,
          ">=": (value: string) => ` has deposited more or equal than ${value} ${CURRENCY_NAME}`,
          "<=": (value: string) => ` has deposited less or equal than ${value} ${CURRENCY_NAME}`
        },
        deposit_frequency: {
          "=": (value: string) => ` deposits money every ${value || 0} minutes`,
          ">": (value: string) =>
            ` deposits money more frequently than ${value} times per minute`,
          "<": (value: string) =>
            ` deposits money less frequently than ${value} times per minute`,
          ">=": (value: string) =>
            ` deposits money more or equal frequently than ${value} times per minute`,
          "<=": (value: string) =>
            ` deposits money less or equal frequently than ${value} times per minute`
        },
        deposit_trend: {
          "=": (value: string) => {
            const displayValue = Array.isArray(value) ? value.join('/') : value;
            return ` has a deposit trend that is ${displayValue}`;
          }
        },
        play_speed_trend: {
          "=": (value: string) => {
            const displayValue = Array.isArray(value) ? value.join('/') : value;
            return ` has a play speed trend that is ${displayValue}`;
          }
        },
        auto_spin_enabled: {
          "=": (value: string) => {
            const stringBoolValue = Array.isArray(value) ? value[0] : value;

            if (stringBoolValue === 'string') {
              return ' has auto-spin enabled';
            }
            if (!['True', 'False'].includes(stringBoolValue)) {
              throw new Error(`${value} segment not found`);
            }

            return stringBoolValue === "True"
              ? ` has auto-spin enabled`
              : ` doesn’t have auto-spin enabled`;
          }
        },
        bet_amount: {
          "=": (value: string) => {
            const displayValue = Array.isArray(value) ? value.join('/') : value;
            return `  has a ${displayValue} average bet`;
          },
          ">=": (value: string) => {
            const displayValue = Array.isArray(value) ? value.join('/') : value;
            return `  has more than or equal to ${displayValue} average bet`;
          },
          "<=": (value: string) => {
            const displayValue = Array.isArray(value) ? value.join('/') : value;
            return `  has less than or equal to ${displayValue} average bet`;
          },
        },
        initial_deposit: {
          "=": (value: string) => {
            const displayValue = Array.isArray(value) ? value.join('/') : value;
            return `  has a ${displayValue} initial deposit amount`;
          },
          ">=": (value: string) => {
            const displayValue = Array.isArray(value) ? value.join('/') : value;
            return `  has more than or equal to ${displayValue} initial deposit amount`;
          },
          "<=": (value: string) => {
            const displayValue = Array.isArray(value) ? value.join('/') : value;
            return `  has less than or equal to ${displayValue} initial deposit amount`;
          }
        },
        bet_trend: {
          "=": (value: string) => {
            const displayValue = Array.isArray(value) ? value.join('/') : value;
            return ` has a bet trend that is ${displayValue}`;
          }
        },
        total_reward_value: {
          "=": (value: string) => ` has played for ${value} days in a row`,
          ">": (value: string) => ` has played for more than ${value} days in a row`,
          "<": (value: string) => ` has played for less than ${value} days in a row`,
          ">=": (value: string) => ` has played for more or equal than ${value} days in a row`,
          "<=": (value: string) => ` has played for less or equal than ${value} days in a row`,
        },
        day_off: {
          "=": (value: string) => {
            switch (value) {
              case "Today":
                return ` has an average bet which is lower than the usual for that location`;
              case "Coming Soon":
                return ` has an average bet which is comparable to the usual one for that location`;
              case "Just Passed":
                return ` has an average bet which is lower than the usual for that location`;
              case "False":
                return `  false`;
              default:
                return ` has an average bet which is ${value} than the usual for that location`;
            }
          }
        },
        average_bet_amount_vs_city: {
          "=": (value: string) => {
            switch (value) {
              case "lower":
                return ` has an average bet which is lower than the usual for that location`;
              case "same":
                return ` has an average bet which is comparable to the usual one for that location`;
              case "higher":
                return ` has an average bet which is higher than the usual for that city`;
              default:
                return ` has an average bet which is ${value} than the usual for that city`;
            }
          }
        },
        average_bet_amount_vs_location: {
          "=": (value: string) => {
            const displayValue = Array.isArray(value) ? value.join('/') : value;
            return ` has an average bet amount which is ${displayValue} compared to the location average`;
          }
        },
        break_piggy_bank: {
          "=": (value: string) =>
            value === "True"
              ? ` broke the piggy-bank`
              : ` didn’t break the piggy-bank yet`
        },
        play_streak_several_days: {
          "=": (value: string) => ` has played for ${value} days in a row`,
          ">": (value: string) =>
            ` has played for more than ${value} days in a row`,
          "<": (value: string) =>
            ` has played for less than ${value} days in a row`,
          ">=": (value: string) =>
            ` has played for more or equal than ${value} days in a row`,
          "<=": (value: string) =>
            ` has played for less or equal than ${value} days in a row`
        },
        move_to_another_machine: {
          "=": (value: string) => {
            const stringBoolValue = Array.isArray(value) ? value[0] : value;

            if (!['True', 'False'].includes(stringBoolValue)) {
              throw new Error(`${value} segment not found`);
            }

            return stringBoolValue === "True"
              ? ` has moved to another slot machine`
              : ` didn’t move to another machine yet`;
          }
        },
        online_player: {
          "=": (value: string) => {
            const stringBoolValue = Array.isArray(value) ? value[0] : value;

            if (!['True', 'False'].includes(stringBoolValue)) {
              throw new Error(`${value} segment not found`);
            }

            return stringBoolValue === "True"
              ? ` is also an online player`
              : ` only plays offline`;
          }
        },
        winning_streak: {
          "=": (value: string) => ` has won the last consecutive ${value} bets`,
          ">": (value: string) =>
            ` has won more than ${value} of the last consecutive bets`,
          "<": (value: string) =>
            ` has won less than ${value} of the last consecutive bets`,
          ">=": (value: string) =>
            ` has won more or equal than ${value} of the last consecutive bets`,
          "<=": (value: string) =>
            ` has won less or equal than ${value} of the last consecutive bets`
        },
        losing_streak: {
          "=": (value: string) =>
            ` has lost the last consecutive ${value} bets`,
          ">": (value: string) =>
            ` has lost more than ${value} of the last consecutive bets`,
          "<": (value: string) =>
            ` has lost less than ${value} of the last consecutive bets`,
          ">=": (value: string) =>
            ` has lost more or equal than ${value} of the last consecutive bets`,
          "<=": (value: string) =>
            ` has lost less or equal than ${value} of the last consecutive bets`
        },
        false_win_ratio: {
          "=": (value: string) => ` has a ratio of ${value}% of false wins`,
          ">": (value: string) =>
            ` has a ratio of more than ${value}% of false wins`,
          "<": (value: string) =>
            ` has a ratio of less than ${value}% of false wins`,
          ">=": (value: string) =>
            ` has a ratio of more or equal than ${value}% of false wins`,
          "<=": (value: string) =>
            ` has a ratio of less or equal than ${value}% of false wins`
        },
        true_wins_ratio: {
          "=": (value: string) => ` has a ratio of ${value}% of True wins`,
          ">": (value: string) =>
            ` has a ratio of more than ${value}% of True wins`,
          "<": (value: string) =>
            ` has a ratio of less than ${value}% of True wins`,
          ">=": (value: string) =>
            ` has a ratio of more or equal than ${value}% of True wins`,
          "<=": (value: string) =>
            ` has a ratio of less or equal than ${value}% of True wins`
        },
        ggr_ratio: {
          "=": (value: string) => ` produced a GGR ratio of ${value}%`,
          ">": (value: string) =>
            ` produced a GGR ratio of more than ${value}%`,
          "<": (value: string) => ` produced a GGR ratio of less than ${value}%`
        },
        ggr_absolute: {
          "=": (value: string) => ` produced an absolute GGR ratio of ${value} ${CURRENCY_NAME}`,
          ">": (value: string) =>
            ` produced an absolute GGR ratio of more than ${value} ${CURRENCY_NAME}`,
          "<": (value: string) =>
            ` produced an absolute GGR ratio of less than ${value} ${CURRENCY_NAME}`,
          ">=": (value: string) =>
            ` produced an absolute GGR ratio of more or equal than ${value} ${CURRENCY_NAME}`,
          "<=": (value: string) =>
            ` produced an absolute GGR ratio of less or equal than ${value} ${CURRENCY_NAME}`
        },
        highest_win_ratio: {
          "=": (value: string) =>
            ` has a highest win which is ${value} times bigger than the bet which caused it`,
          ">": (value: string) =>
            ` has a highest win which is more than ${value} times bigger than the bet which caused it`,
          "<": (value: string) =>
            ` has a highest win which is less than ${value} times bigger than the bet which caused it`,
          ">=": (value: string) =>
            ` has a highest win which is more or equal than ${value} times bigger than the bet which caused it`,
          "<=": (value: string) =>
            ` has a highest win which is less or equal than ${value} times bigger than the bet which caused it`
        },
        highest_win_absolute: {
          "=": (value: string) => ` has a highest win of ${value} ${CURRENCY_NAME}`,
          ">": (value: string) =>
            ` has a highest win of more than ${value} ${CURRENCY_NAME}`,
          "<": (value: string) => ` has a highest win of less than ${value} ${CURRENCY_NAME}`
        },
        day_of_month: {
          "=": (value: string) => ` the day of the month is ${value}`,
          ">": (value: string) => ` the day of the month is after ${value}`,
          "<": (value: string) => ` the day of the month is before ${value}`
        },
        day_of_week: {
          "=": (value: string) => ` the day of the week is ${t(value)}`,
          ">": (value: string) => ` the day of the week is after ${t(value)}`,
          "<": (value: string) => ` the day of the week is before ${t(value)}`
        },
        payday: {
          "=": (value: string) => {
            const stringBoolValue = Array.isArray(value) ? value[0] : value;

            if (!['True', 'False'].includes(stringBoolValue)) {
              throw new Error(`${value} segment not found`);
            }

            return stringBoolValue === "True"
              ? ` the day when he’s playing is a payday`
              : ` the day when he’s playing is not a payday`;
          }
        },
        holiday: {
          "=": (value: string) => {
            const stringBoolValue = Array.isArray(value) ? value[0] : value;

            if (!['True', 'False'].includes(stringBoolValue)) {
              throw new Error(`${value} segment not found`);
            }

            return stringBoolValue === "True"
              ? ` the day when he’s playing is a legal holiday`
              : ` the day when he’s playing is not a legal holiday`;
          }
        },
        season: {
          "=": (value: string) => {
            return ` it's ${value}`;
          }
        },
        city_size: {
          "=": (value: string) =>
            ` the city has a number of ${value} inhabitants`,
          ">": (value: string) =>
            ` the city has a number of more than ${value} inhabitants`,
          "<": (value: string) =>
            ` the city has a number of less than ${value} inhabitants`
        },
        weather: {
          "=": (value: string) => ` the weather is ${value}`
        },
        time_of_day: {
          "=": (value: string) => {
            switch (value) {
              case "Morning":
                return ` it's morning`;
              case "Noon":
                return ` it's noon`;
              case "Evening":
                return ` it's evening`;
              case "Night":
                return ` it's night time`;
              default:
                return ` it's ${value}`;
            }
          }
        },
        cash_out: {
          "=": (value: string) => {
            const stringBoolValue = Array.isArray(value) ? value[0] : value;

            if (!['True', 'False'].includes(stringBoolValue)) {
              throw new Error(`${value} segment not found`);
            }

            return stringBoolValue === "True"
              ? ` performed a cash-out operation`
              : ` didn't perform a cash-out operation`;
          }
        },
        round_credit: {
          "=": () => {
            return ` has a round number of credits`;
          }
        },
      };
    case "ro":
      return {
        time_of_day: {
          "=": (value: string) => {
            switch (value) {
              case "Morning":
                return ` este dimineață `;
              case "Noon":
                return ` este prânz`;
              case "Evening":
                return ` este seară`;
              case "Night":
                return ` este noapte`;
              default:
                return ` este ${value}`;
            }
          }
        },
        cash_out: {
          "=": (value: string) => {
            const stringBoolValue = Array.isArray(value) ? value[0] : value;

            if (!['True', 'False'].includes(stringBoolValue)) {
              throw new Error(`${value} segment not found`);
            }

            return stringBoolValue === "True"
              ? ` a scos banii din aparat`
              : ` nu a scos banii din aparat incă`;
          }
        },
        no_more_credits: {
          "=": (value: string) =>
            value === "False"
              ? ` mai are credite rămase`
              : ` nu mai are credite rămase`
        },
        deposit_amount: {
          "=": (value: string) => ` a depozitat ${value} ${CURRENCY_NAME}`,
          ">": (value: string) => ` a depozitat mai mult de ${value} ${CURRENCY_NAME}`,
          "<": (value: string) => ` a depozitat mai puțin de ${value} ${CURRENCY_NAME}`,
          ">=": (value: string) => ` a depozitat mai mult sau egal de ${value} ${CURRENCY_NAME}`,
          "<=": (value: string) => ` a depozitat mai puțin sau egal de ${value} ${CURRENCY_NAME}`,
        },
        deposit_frequency: {
          "=": (value: string) => ` face depozite la fiecare ${value} minute`,
          ">": (value: string) => ` face depozite mai des de ${value}`,
          "<": (value: string) => ` face depozite la mai puțin de ${value}`,
          ">=": (value: string) => ` face depozite mai des sau egal de ${value}`,
          "<=": (value: string) => ` face depozite la mai puțin sau egal de ${value}`,
        },
        deposit_trend: {
          "=": (value: string) => {
            switch (value) {
              case "constant":
                return ` pariază aceeași sumă`;
              case "increasing":
                return ` pariază o sumă mai mare decât mai devreme`;
              case "decreasing":
                return ` pariază o sumă mai mică decât mai devreme`;
              default:
                return ` pariază o sumă ${value} decât mai devreme`;
            }
          }
        },
        play_speed_trend: {
          "=": (value: string) => {
            const values = {
              constant: 'constant',
              increasing: 'mai rapid',
              decreasing: 'mai lent',
            };
            const displayValue = Array.isArray(value)
              ? value.map((key: any) => {
                return values[key as keyof typeof values];
              }).join('/')
              : value;
            return ` joacă într-un ritm ${displayValue}`;
          }
        },
        auto_spin_enabled: {
          "=": (value: string) => {
            const stringBoolValue = Array.isArray(value) ? value[0] : value;

            if (stringBoolValue === 'string') {
              return ' are auto-spin activat';
            }
            if (!['True', 'False'].includes(stringBoolValue)) {
              throw new Error(`${value} segment not found`);
            }

            return stringBoolValue === "True"
              ? ` are auto-spin activat`
              : ` nu are auto-spin activat`;
          }
        },
        average_bet_amount_vs_city: {
          "=": (value: string) => {
            switch (value) {
              case "lower":
                return ` are o medie a sumelor pariate mai mică decât cea obișnuită pentru orașul în care joacă`;
              case "same":
                return ` are o medie a sumelor pariate similară cu cea obișnuită pentru orașul în care joacă`;
              case "higher":
                return ` are o medie a sumelor pariate mai mare decât cea obișnuită pentru orașul în care joacă`;
              default:
                return ` are o medie a sumelor pariate ${value} decât cea obișnuită pentru orașul în care joacă`;
            }
          }
        },
        average_bet_amount_vs_location: {
          "=": (value: string) => {
            const displayValue = Array.isArray(value) ? value.join('/') : value;
            switch (displayValue) {
              case "lower":
                return ` are o medie a sumelor pariate mai mică decât cea obișnuită pentru locatia în care joacă`;
              case "same":
                return ` are o medie a sumelor pariate similară cu cea obișnuită pentru locatia în care joacă`;
              case "higher":
                return ` are o medie a sumelor pariate mai mare decât cea obișnuită pentru locatia în care joacă`;
              default:
                return ` are o medie a sumelor pariate ${value} decât cea obișnuită pentru locatia în care joacă`;
            }
          }
        },
        break_piggy_bank: {
          "=": (value: string) =>
            value === "True"
              ? ` a spart pușculița`
              : ` încă nu a spart pușculița`
        },
        play_streak_several_days: {
          "=": (value: string) => ` a jucat ${value} zile la rând`,
          ">": (value: string) => ` a jucat mai mult de ${value} zile la rând`,
          "<": (value: string) => ` a jucat mai puțin de ${value} zile la rând`,
          ">=": (value: string) => ` a jucat mai mult sau egal de ${value} zile la rând`,
          "<=": (value: string) => ` a jucat mai puțin sau egal de ${value} zile la rând`
        },
        move_to_another_machine: {
          "=": (value: string) => {
            const stringBoolValue = Array.isArray(value) ? value[0] : value;

            if (!['True', 'False'].includes(stringBoolValue)) {
              throw new Error(`${value} segment not found`);
            }

            return stringBoolValue === "True"
              ? ` s-a mutat la alt slot`
              : ` încă nu s-a mutat la alt slot`;
          }
        },
        online_player: {
          "=": (value: string) => {
            const stringBoolValue = Array.isArray(value) ? value[0] : value;

            if (!['True', 'False'].includes(stringBoolValue)) {
              throw new Error(`${value} segment not found`);
            }

            return stringBoolValue === "True"
              ? `  este jucător online`
              : ` joacă doar offline`;
          }
        },
        winning_streak: {
          "=": (value: string) =>
            ` a câștigat la rând la ultimele ${value} rotiri`,
          ">": (value: string) =>
            ` a câștigat la rând la mai mult de ${value} din ultimele rotiri`,
          "<": (value: string) =>
            ` a câștigat la rând la mai puțin de ${value} din ultimele rotiri`
        },
        losing_streak: {
          "=": (value: string) =>
            ` a pierdut la rând la ultimele ${value} rotiri`,
          ">": (value: string) =>
            ` a pierdut la rând la mai mult de ${value} din ultimele rotiri`,
          "<": (value: string) =>
            ` a pierdut la rând la mai puțin de ${value} din ultimele rotiri`,
          ">=": (value: string) =>
            ` a pierdut la rând la mai mult sau egal de ${value} din ultimele rotiri`,
          "<=": (value: string) =>
            ` a pierdut la rând la mai puțin sau egal de ${value} din ultimele rotiri`
        },
        false_win_ratio: {
          "=": (value: string) =>
            ` are un procent de ${value}% de câștiguri false`,
          ">": (value: string) => ` are mai mult de ${value}% câștiguri false`,
          "<": (value: string) => ` are mai puțin de ${value}% câștiguri false`,
          ">=": (value: string) => ` are mai mult sau egal de ${value}% câștiguri false`,
          "<=": (value: string) => ` are mai puțin sau egal de ${value}% câștiguri false`
        },
        true_wins_ratio: {
          "=": (value: string) =>
            ` are un procent de ${value}% de câștiguri adevărate`,
          ">": (value: string) =>
            ` are mai mult de ${value}% câștiguri adevărate`,
          "<": (value: string) =>
            ` are mai puțin de ${value}% câștiguri adevărate`,
          ">=": (value: string) =>
            ` are mai mult sau egal de ${value}% câștiguri adevărate`,
          "<=": (value: string) =>
            ` are mai puțin sau egal de ${value}% câștiguri adevărate`
        },
        // @TODO
        ggr_ratio: {
          "=": (value: string) => ` a produs un GGR ratio de ${value}%`,
          ">": (value: string) => ` a produs un GGR ratio mai mic de ${value}%`,
          "<": (value: string) =>
            ` a produs un GGR ratio of less than ${value}%`
        },
        ggr_absolute: {
          "=": (value: string) => ` a produs un GGR absolut de ${value} ${CURRENCY_NAME}`,
          ">": (value: string) =>
            ` a produs un GGR absolut mai mare de ${value} ${CURRENCY_NAME}`,
          "<": (value: string) =>
            ` a produs un GGR absolut mai mic de ${value} ${CURRENCY_NAME}`,
          ">=": (value: string) =>
            ` a produs un GGR absolut mai mare sau egal de ${value} ${CURRENCY_NAME}`,
          "<=": (value: string) =>
            ` a produs un GGR absolut mai mic sau egal de ${value} ${CURRENCY_NAME}`
        },
        highest_win_ratio: {
          "=": (value: string) =>
            ` a avut un câștig maxim de ${value} ori mai mare decât suma pariată`,
          ">": (value: string) =>
            ` a avut un câștig maxim de mai mult de ${value} ori mai mare decât suma pariată`,
          "<": (value: string) =>
            ` ha avut un câștig maxim de mai puțin de ${value} ori mai mare decât suma pariată`,
          ">=": (value: string) =>
            ` a avut un câștig maxim de mai mult sau egal de ${value} ori mai mare decât suma pariată`,
          "<=": (value: string) =>
            ` ha avut un câștig maxim de mai puțin sau egal de ${value} ori mai mare decât suma pariată`
        },
        highest_win_absolute: {
          "=": (value: string) => ` a avut un câștig maxim de ${value} ${CURRENCY_NAME}`,
          ">": (value: string) =>
            ` a avut un câștig maxim de peste ${value} ${CURRENCY_NAME}`,
          "<": (value: string) =>
            ` a avut un câștig maxim mai mic de ${value} ${CURRENCY_NAME}`
        },
        day_of_month: {
          "=": (value: string) => ` ziua din lună este ${value} ale lunii`,
          ">": (value: string) => ` ziua din lună este după ${value} ale lunii`,
          "<": (value: string) =>
            ` ziua din lună este înainte de ${value} ale lunii`
        },
        day_of_week: {
          "=": (value: string) => ` ziua din săptămână este ${t(value)}`,
          ">": (value: string) => ` ziua din săptămână este după ${t(value)}`,
          "<": (value: string) =>
            ` ziua din săptămână este înainte de ${t(value)}`
        },
        payday: {
          "=": (value: string) => {
            const stringBoolValue = Array.isArray(value) ? value[0] : value;

            if (!['True', 'False'].includes(stringBoolValue)) {
              throw new Error(`${value} segment not found`);
            }

            return stringBoolValue === "True"
              ? ` este zi de salariu`
              : ` nu este zi de salariu`;
          }
        },
        holiday: {
          "=": (value: string) => {
            const stringBoolValue = Array.isArray(value) ? value[0] : value;

            if (!['True', 'False'].includes(stringBoolValue)) {
              throw new Error(`${value} segment not found`);
            }

            return stringBoolValue === "True"
              ? ` este zi liberă legală`
              : ` nu este zi liberă legală`;
          }
        },
        season: {
          "=": (value: string) => {
            return `it's ${value}`;
          }
        },
        city_size: {
          "=": (value: string) => ` orașul are ${value} locuitori`,
          ">": (value: string) => ` orașul are peste ${value} locuitori`,
          "<": (value: string) => ` orașul are mai puțin de ${value} locuitori`
        },
        weather: {
          "=": (value: string) => ` vremea de afară este ${value}`
        },
        round_credit: {
          "=": () => {
            return ` are o sumă rotundă în aparat`;
          }
        }
      };
  }
};

const getFixedDynamicValue = (t: any, value: any, lng: string) => {
  const { type, fixedValue, basedOn, multipliedBy, minimum, maximum, multipleOf, aiAmount, roundingAffects } = value;
  switch (lng) {
    case "en": {
      if (type === 'Fixed') {
        return `${fixedValue} LEI`;
      }

      return `
          Dynamic Value (based on: ${t(basedOn)}, multiplied by: ${multipliedBy}x, minimum: ${minimum} LEI, maximum: ${maximum} LEI, multiple of: ${multipleOf}x)
        `;
    }
    case "ro": {
      if (type === 'Fixed') {
        return `${fixedValue} LEI`;
      }

      return `
        Valoare calculată dinamic în funcție de ${t(basedOn)}, înmulțit cu: ${multipliedBy}x, minim: ${minimum} LEI, maxim: ${maximum} LEI, multiplu de: ${multipleOf}x)
      `;
    }
  }
};

export const createRewardsObject: any = (t: any, lng: string) => {
  switch (lng) {
    case "en":
      return {
        FreeSpins: (rewardQuantity: number, secondRewardQuantity?: number) =>
          `, he receives ${getFixedDynamicValue(t, rewardQuantity, lng)} Free spins.`,
        BonusCash: (value: number) => `, he gets BonusCash value: ${getFixedDynamicValue(t, value, lng)} (EN)`,

        // not used
        PercentBack: (rewardQuantity: number, secondRewardQuantity: number) =>
          `, he receives back ${rewardQuantity}% of the next ${secondRewardQuantity} spins.`,
        BuyABonus: (rewardQuantity: number, secondRewardQuantity: number) =>
          `, he receives a bonus of ${rewardQuantity} ${CURRENCY_NAME} if he deposits ${secondRewardQuantity} more ${CURRENCY_NAME}`,
        FreeMoney: (rewardQuantity: number, secondRewardQuantity?: number) =>
          `, he receives ${rewardQuantity} ${CURRENCY_NAME}.`,

        WheelOfFortune: () =>
          " he receives the opportunity to roll the WheelOfFortune.",
        RaffleTicket: () => " he receives a raffle ticket.",
        AfterWager: () => " he receives an After Wager.",
        CashBack: (rewardQuantity: number, secondRewardQuantity?: number) =>
          ` he gets back ${rewardQuantity} for each of the next ${secondRewardQuantity} spins`,
      };
    case "ro":
      return {
        FreeSpins: (rewardQuantity: number, secondRewardQuantity?: number) =>
          `, primeste ${getFixedDynamicValue(t, rewardQuantity, lng)} rotiri gratuite.`,
        BonusCash: (value: number) => `, he gets BonusCash value: ${getFixedDynamicValue(t, value, lng)} (RO)`,

        // not used
        PercentBack: (rewardQuantity: number, secondRewardQuantity: number) =>
          `, primește înapoi ${rewardQuantity}% din rulajul următoarelor ${secondRewardQuantity} rotiri.`,
        BuyABonus: (rewardQuantity: number, secondRewardQuantity: number) =>
          `, primește un bonus de ${rewardQuantity} ${CURRENCY_NAME} dacă mai depune încă ${secondRewardQuantity} ${CURRENCY_NAME}`,
        FreeMoney: (rewardQuantity: number, secondRewardQuantity?: number) =>
          `, primește un bonus de ${rewardQuantity} ${CURRENCY_NAME}.`,
        WheelOfFortune: () =>
          " primește oportunitatea de a învârti roata norocului.",
        RaffleTicket: () => " primește un bilet de tombolă.",
        AfterWager: () => " primește un After Wager.",
        CashBack: (rewardQuantity: number, secondRewardQuantity?: number) =>
          ` primește ${rewardQuantity} ${CURRENCY_NAME} înapoi pentru fiecare dintre următoarele ${secondRewardQuantity} rotiri`,
      };
  }
};

export const useRewardPresentationTrans: any = () => {
  const { t, i18n } = useTranslation();
  switch (i18n.language) {
    case "en":
      return {
        Direct: 'The reward is presented directly on the LCD.',
        WheelOfFortune: 'The reward is presented via the Lucky Wheel.',
      };
    case "ro":
      return {
        Direct: 'The reward is presented via the Lucky Wheel.',
        WheelOfFortune: 'Recompensa este prezentată pe Roata Norocului.'
      };
  }
};

export const useActivationConditionTrans: any = () => {
  const { t, i18n } = useTranslation();
  switch (i18n.language) {
    case "en":
      return {
        None: () => 'No action needed (EN)',
        Deposit: (value: FixedDynamicValueType) => `To claim the reward, the player must deposit ${getFixedDynamicValue(t, value, i18n.language)}.`,
        Play: (value: FixedDynamicValueType) => `To claim the reward, the player must run ${getFixedDynamicValue(t, value, i18n.language)}.`,
        EnterPhoneNumber: () => 'To claim the reward, the player must enter their phone number.',
      };
    case "ro":
      return {
        Direct: () => 'No action needed (RO)',
        Deposit: (value: FixedDynamicValueType) => `Pentru a revendica recompensa, jucătorul trebuie să depună ${getFixedDynamicValue(t, value, i18n.language)}.`,
        Play: (value: FixedDynamicValueType) => `Pentru a revendica recompensa, jucătorul trebuie să ruleze ${getFixedDynamicValue(t, value, i18n.language)}.`,
        EnterPhoneNumber: () => 'Pentru a revendica recompensa, jucătorul trebuie să introducă numărul de telefon.',
      };
  }
};

export const useCashOutRestrictionTrans: any = () => {
  const { t, i18n } = useTranslation();
  switch (i18n.language) {
    case "en":
      return {
        None: () => 'No condition (EN)',
        Deposit: (value: FixedDynamicValueType) => `To cash out the reward, the player must deposit ${getFixedDynamicValue(t, value, i18n.language)}.`,
        Play: (value: FixedDynamicValueType) => `To cash out the reward, the player must run ${getFixedDynamicValue(t, value, i18n.language)}.`,
        EnterPhoneNumber: () => 'To cash out the reward, the player must enter their phone number.',
      };
    case "ro":
      return {
        Direct: () => 'No condition (RO)',
        Deposit: (value: FixedDynamicValueType) => `Pentru a scoate banii aferenți recompensei, jucătorul trebuie să depună ${getFixedDynamicValue(t, value, i18n.language)}.`,
        Play: (value: FixedDynamicValueType) => `Pentru a scoate banii aferenți recompensei, jucătorul trebuie să ruleze ${getFixedDynamicValue(t, value, i18n.language)}.`,
        EnterPhoneNumber: () => 'Pentru a scoate banii aferenți recompensei, jucătorul trebuie să introducă numărul de telefon.',
      };
  }
};


export const useDelayTrans: any = (delay: string, timeOfActivation: string) => {
  const { t, i18n } = useTranslation();
  if (!delay || !timeOfActivation) {
    return '';
  }
  const isImmediatly = [null, '0:0:00'].includes(timeOfActivation) && [null, '00.00:00:00', '0.00:00:00'].includes(delay);

  if (isImmediatly) {
    return t('The reward becomes available immediately.');
  }

  const { type, numberValue, hours, minutes } = parseDateTime(delay, timeOfActivation);

  switch (i18n.language) {
    case "en":
      return type === 'Days' ?
        `The reward becomes available in ${numberValue} ${t(type)} at ${hours}:${minutes}.`
        : `The reward becomes available in ${numberValue} ${t(type)}.`;
    case "ro":
      return type === 'Days'
        ? `Recompensa devine disponibilă in ${numberValue} ${t(type)} la ora ${hours}:${minutes}.`
        : `Recompensa devine disponibilă in ${numberValue} ${t(type)}.`;
  }
};

export const useExpiryTrans: any = (delay: string, timeOfActivation: string) => {
  const { t, i18n } = useTranslation();

  if (!delay || !timeOfActivation) {
    return '';
  }

  const { type, numberValue, hours, minutes } = parseDateTime(delay, timeOfActivation);

  switch (i18n.language) {
    case "en":
      return `The reward expires in ${numberValue} ${t(type)} at ${hours}:${minutes}.`;
    case "ro":
      return `Recompensa expiră in ${numberValue} ${t(type)} la ora ${hours}:${minutes}.`;
  }
};

export const createSchedulePreview = (
  startDate: Date,
  endDate: Date,
  t: any
) => {
  if (startDate && endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    const campaignDuration: number = Math.floor(
      (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
    );
    const startMonth = new Intl.DateTimeFormat("en-US", {
      month: "long"
    }).format(startDate);
    const endMonth = new Intl.DateTimeFormat("en-US", {
      month: "long"
    }).format(endDate);

    if (campaignDuration < 1) {
      return campaignDuration < 0
        ? `Please select a valid date.`
        : `This campaign will last less than 1 day, on ${startDate.getDate()}
    ${startMonth}.`;
    }

    return `${t("The campaign will last")} ${campaignDuration !== 1
      ? `${campaignDuration} ${t("Days")}`
      : `${campaignDuration} ${t("Day")}`
      }, ${t("starting on")}
  ${startDate.getDate()}
  ${t(startMonth)} ${t("and ending on")}
  ${t(endMonth)}
  ${endDate.getDate()}.`;
  }
};

export const getCurrentDate = (): string => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  return dd + "/" + mm + "/" + yyyy;
};
