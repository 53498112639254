import styled from 'styled-components';
import { ACCENT_LIGHT_COLOR, CONTENT_SPACING_GAP, gap, MAIN_COLOR } from "components/styles/constants.styles";
import React, { useEffect, useState } from "react";
import TotalProfitIncreaseCard from 'pages/DashboardCards/TotalProfitIncreaseCard';
import RadialChart from 'components/RadialChart';
import { AvgTurnoverCard, AvgProfitPerSessionCard, AvgSessionDurationCard } from 'pages/DashboardCards/AvgCard';
import { ExperimentsTable } from 'components/ExperimentsTable';
import { CampaignComparison } from 'components/CampaignComparison';
import { DateRange } from "@mui/lab/DateRangePicker";

const ChartsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: ${gap};
  grid-row-gap: ${gap};
  min-height: 420px;
  grid-template-areas:
    "a a a a a a  b b b  c c c"
    "a a a a a a  b b b  d d d"
    "a a a a a a  b b b  e e e"
    "f f f f f f  g g g g g g";

  @media (max-width: 1280px) {
    grid-template-areas:
      "a a a a a a a a  b b b b"
      "a a a a a a a a  b b b b"
      "c c c c  d d d d  e e e e"
      "f f f f f f  g g g g g g";
  }

  
  @media (max-width: 1028px) {
    grid-template-areas:
      "a a a a a a a a  b b b b"
      "a a a a a a a a  b b b b"
      "c c c c  d d d d  e e e e"
      "f f f f f f  g g g g g g";
  }

  @media (max-width: 928px) {
    grid-template-areas:
    "a a a a a a a a  a a a a"
    "b b b b b b  c c c c c c"
    "b b b b b b  d d d d d d"
    "b b b b b b  e e e e e e"
    "f f f f f f  g g g g g g";
  }
  
  @media (max-width: 768px) {
    display: block
  }
`;


const GridItem = styled.div<{ area: string; }>`
  grid-area: ${props => props.area};
`;

type Props = {
    campaignDetails: any;
    range: DateRange<Date>;
};

const LcdCharts = ({ campaignDetails, range }: Props) => {
    const [dateFrom, dateTo] = range;

    return (
        <ChartsGridContainer>
            <GridItem area="a">
                <TotalProfitIncreaseCard data={campaignDetails?.kpi} dateFrom={dateFrom} dateTo={dateTo} />
            </GridItem>
            <GridItem area="b">
                <RadialChart
                    usedPercent={campaignDetails.conversion.usedPercent}
                    claimedPercent={campaignDetails.conversion.claimedPercent}
                />
            </GridItem>
            <GridItem area="c">
                <AvgProfitPerSessionCard
                    value={campaignDetails?.averageProfit.value}
                    increase={campaignDetails?.averageProfit.increase}
                />
            </GridItem>
            <GridItem area="d">
                <AvgSessionDurationCard
                    value={campaignDetails?.averageDuration.value}
                    increase={campaignDetails?.averageDuration.increase}
                />
            </GridItem>
            <GridItem area="e">
                <AvgTurnoverCard
                    value={campaignDetails?.averageTurnover.value}
                    increase={campaignDetails?.averageTurnover.increase}
                />
            </GridItem>
            <GridItem area="f">
                <CampaignComparison data={campaignDetails.comparison} />
            </GridItem>
            <GridItem area="g">
                <ExperimentsTable tableData={campaignDetails?.experiments} />
            </GridItem>
        </ChartsGridContainer>
    );
};

export default LcdCharts;