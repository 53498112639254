import { Autocomplete, ListItem, TextField } from "@mui/material";
import React, { useMemo, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ACCENT_LIGHT_COLOR } from "./styles/constants.styles";

interface Props {
  setOperator: (data: string) => void;
  operators?: { id: string; name: string; }[];
  operator?: string;
  hasValue?: boolean,
}

const SelectOperator = ({ setOperator, operators, operator, hasValue = false }: Props) => {
  let options: { name: string; id: string; }[] = [];
  const { t } = useTranslation();

  if (operators) {
    options = useMemo(
      () =>
        operators.sort((a, b) => {
          const aName = a.name.toLocaleLowerCase();
          const bName = b.name.toLocaleLowerCase();
          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        }).map((operator: { name: string; id: string; }) => ({
          name: operator.name,
          id: operator.id
        })),
      [operators]
    );
  }

  if (!operators) return <></>;

  const autocompleteProps = useMemo(() => {
    const value = operators.find(item => item.id === operator);
    if (hasValue) {
      return { value: value || { id: '', name: '' } };
    }

    return {};
  }, [hasValue, operator, operators]);

  return (
    <Autocomplete
      clearIcon={<></>}
      isOptionEqualToValue={() => true}
      sx={{ bgcolor: ACCENT_LIGHT_COLOR, minWidth: 200 }}
      size="small"
      options={options}
      renderOption={(props, option) => {
        const { name: title } = option;
        return (
          <span {...props} style={{ backgroundColor: ACCENT_LIGHT_COLOR }}>
            {title}
          </span>
        );
      }}
      getOptionLabel={(option) => option.name}
      renderInput={({ InputLabelProps, ...params }) => (
        <TextField {...params} sx={{ width: 240 }} placeholder={t("All operators")} label={t("All operators")} />
      )}
      {...autocompleteProps}
      onChange={(event: any, newValue: { name: string; id: string; } | null) => {
        if (newValue) {
          setOperator(newValue.id);
        } else {
          setOperator("");
        }
      }}
    />
  );
};

export default memo(SelectOperator);
