import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery
} from "@mui/material";
import StyledNav from "./styles/NavBar.styled";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { Trans } from "react-i18next";
import MobileMenu from "./Drawer";
import ContentContainer from "./styles/ContentContainer";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import styled from "styled-components";
import { getUserData } from "api/auth.service";
import { appUserDataSelector, appUsernameSelector } from "store/auth/auth.selector";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "store/auth/auth.actions";
import { ACCENT_LIGHT_COLOR } from "./styles/constants.styles";

const SelectLang = styled.div`
  fieldset {
    border: 0
  }
  svg {
    color: #FFF;
    opacity: 0.8;
  }
`;

interface Props {
  activeTab: string;
}

export const NavBar = ({ activeTab }: Props) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("lng")?.toString() || "en"
  );
  const dispatch = useDispatch();

  const mobileView = useMediaQuery("(min-width:771px)");
  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(() => {
      i18next.changeLanguage(event.target.value, (err, t) => {
        if (err) return console.log("something went wrong loading", err);
      });
      localStorage.setItem("lng", event.target.value);

      return event.target.value as string;
    });
  };

  const userData = useSelector(appUserDataSelector);
  console.log(userData)

  useEffect(() => {
    if (!userData) {
      getUserData().then(data => {
        dispatch(setUserDetails(data));
      });
    }
  }, [userData]);

  if (!mobileView) {
    return (
      <StyledNav>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center"
          }}
        >
          <Box sx={{ ml: 4, mr: "auto", fontWeight: 800, display: 'flex', alignItems: 'center' }}>MOMENTUM</Box>
          <Box sx={{ display: 'flex' }}>
            <SelectLang>
              <Select
                value={language}
                sx={{ color: "#fff" }}
                onChange={handleChange}
              >
                <MenuItem value={"en"}>EN</MenuItem>
                <MenuItem value={"ro"}>RO</MenuItem>
              </Select>
            </SelectLang>
            <MobileMenu activeTab={activeTab} />
          </Box>
        </Box>
      </StyledNav>);
  }

  return (
    <StyledNav centered block>
      <ContentContainer>
        <StyledNav>
          <Box sx={{ ml: 4, mr: "auto", fontWeight: 800 }}>MOMENTUM</Box>
          <Box
            sx={{
              fontWeight: activeTab === "dashboard" ? 600 : 400,
              fontSize: 14,
              cursor: "pointer"

            }}
            component={Link}
            to={"/dashboard"}
            style={{
              textDecoration: "none",
              color: "inherit"
            }}
          >
            <Trans>Dashboard</Trans>
          </Box>
          {userData.userType === 'Newton' && (
            <Box
              sx={{
                ml: "18px",
                fontWeight: activeTab === "ai-performance" ? 600 : 400,
                fontSize: 14,
                cursor: "pointer"

              }}
              component={Link}
              to={"/ai-performance"}
              style={{
                textDecoration: "none",
                color: "inherit"
              }}
            >
              <Trans>AI Performance</Trans>
            </Box>
          )}
          <Box
            sx={{
              fontWeight: activeTab === "budgets" ? 600 : 400,
              fontSize: 14,
              cursor: "pointer",
              ml: "18px"
            }}
            component={Link}
            to={"/budgets"}
            style={{
              textDecoration: "none",
              color: "inherit"
            }}
          >
            <Trans>Budgets</Trans>
          </Box>
          {userData.userType === 'Newton' && (
            <Box
              sx={{
                ml: "18px",
                fontWeight: activeTab === "campaigns" ? 600 : 400,
                fontSize: 14,
                cursor: "pointer"
              }}
              style={{
                textDecoration: "none",
                color: "inherit"
              }}
              component={Link}
              to={"/campaigns"}
            >
              <Trans>Campaigns</Trans>
            </Box>
          )}
          {userData.userType === 'Newton' && (
            <Box
              sx={{
                ml: "18px",
                fontWeight: activeTab === "settings" ? 600 : 400,
                fontSize: 14,
                cursor: "pointer"
              }}
              style={{
                textDecoration: "none",
                color: "inherit"
              }}
              component={Link}
              to={"/settings"}
            >
              <Trans>Settings</Trans>
            </Box>
          )}
          <Box
            sx={{
              ml: "32px",
              mr: "2px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column"
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "6px", cursor: "default" }}>
              <AccountCircleIcon
                sx={{ fontSize: 24 }}
              />
              <Box sx={{ fontWeight: "400", fontStyle: "italic", fontSize: 14 }}>
                <Trans>Hello</Trans>, {userData.username}
              </Box>
            </Box>
          </Box>
          <Box sx={{ mr: 5 }}>
            <SelectLang>
              <Select
                value={language}
                sx={{ color: "#fff" }}
                onChange={handleChange}
              >
                <MenuItem value={"en"}>EN</MenuItem>
                <MenuItem value={"ro"}>RO</MenuItem>
              </Select>
            </SelectLang>
          </Box>
        </StyledNav>
      </ContentContainer>
    </StyledNav>
  );
};
