import {
  Box,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery
} from "@mui/material";
import React, { useEffect, useState } from "react";
import StyledPagination from "./styles/Pagination.styled";
import { SessionsSortMenu } from "./SessionsSortMenu";
import DonwloadCSVButton from "./styles/DonwloadCSVButton";
import DownloadIcon from "../assets/icons/download-icon.svg";
import { Trans, useTranslation } from "react-i18next";
import { downloadSessionsCSV, getSessions } from "api/sessions.service";
import {
  createSessionsDetailsTable,
  createSessionsTable,
  createSessionsTableMobile
} from "shared/createColumns";
import { ACCENT_LIGHT_COLOR, MAIN_COLOR, gap } from "./styles/constants.styles";

interface Props {
  operator?: string[];
  location?: string[];
  startDate?: Date | null;
  endDate?: Date | null;
  campaignId?: string[];
}

export const AllSessionsTable = ({
  operator,
  location,
  startDate,
  endDate,
  campaignId
}: Props) => {
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("profitIncrease");
  const [sessions, setSessions] = useState<{
    data: [];
    totalPageNumber: number;
    currentPageNumber: number;
  }>();
  const tabletView = useMediaQuery("(min-width:1024px)");
  const { t } = useTranslation();
  let columns: any;
  if (campaignId) {
    columns = tabletView
      ? createSessionsDetailsTable(t)
      : createSessionsTableMobile(t);
  } else {
    columns = tabletView
      ? createSessionsTable(t)
      : createSessionsTableMobile(t);
  }

  const isMobileView = useMediaQuery('(max-width:620px)');

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const sessions = await getSessions(
          sortBy,
          page,
          7,
          startDate,
          endDate,
          operator || [],
          location || [],
          campaignId || []
        );

        setSessions(sessions);
      } catch (error) {
        console.log(error);
      }
    };

    fetchSessions();
  }, [operator, location, page, sortBy, startDate, endDate]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const download = async () => {
    const x = await downloadSessionsCSV(
      sortBy,
      startDate,
      endDate,
      operator || [],
      location || []
    );

    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(x);
    hiddenElement.target = "_blank";
    hiddenElement.download = `data.csv`;
    hiddenElement.click();
  };

  return (
    <>
      {sessions ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              bgcolor: ACCENT_LIGHT_COLOR,
              mt: gap
            }}
          >
            <Typography sx={{ p: 2 }}>
              <Trans>All Sessions</Trans>
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <SessionsSortMenu sortBy={sortBy} setSortBy={setSortBy} />
              <DonwloadCSVButton onClick={() => download()}>
                <Icon sx={{ cursor: "pointer", mb: 1.5, mr: !isMobileView ? 1 : 0 }}>
                  <img src={DownloadIcon} alt="donwload" />
                </Icon>
                {!isMobileView && (<>{t("Download CSV")}</>)}
              </DonwloadCSVButton>
            </Box>
          </Box>
          <Paper sx={{ width: "100%" }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map(
                      (column: {
                        id: string;
                        label: string;
                        minWidth: number;
                      }) => (
                        <TableCell
                          sx={{ p: 2, bgcolor: MAIN_COLOR.EXTRA_EXTRA_LIGHT }}
                          key={column.id}
                          align="left"
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sessions.data?.map((row: any, i: number) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`${row.name}-${i}`}
                      >
                        {columns.map(
                          (column: {
                            id: string;
                            label: string;
                            minWidth: number;
                            cell: (value: any) => React.ReactFragment;
                          }) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                sx={{ height: 80, p: 2 }}
                                key={column.id}
                                align="left"
                              >
                                <Box>
                                  {column.id === "id" ? (
                                    <Box
                                      sx={{
                                        color: MAIN_COLOR.EXTRA_LIGTH,
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {7 * page + i - 6}
                                    </Box>
                                  ) : (
                                    <>{column.cell(value)}</>
                                  )}
                                </Box>
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Box
            sx={{ display: "flex", justifyContent: "end", bgcolor: ACCENT_LIGHT_COLOR }}
          >
            <StyledPagination
              sx={{ mt: 3, mb: 2 }}
              count={sessions.totalPageNumber}
              page={sessions.currentPageNumber}
              onChange={handlePageChange}
              size="small"
            />
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
