import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Campaigns from "./pages/Campaigns";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import { Provider } from "react-redux";
import store from "./store/store";
import { RequireAuth } from "./store/auth/RequireAuth";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "components/Theme";
import React, { Suspense, useEffect, useState } from "react";
import { CampaignDetails } from "pages/CampaignDetails";
import client from "./api/axios";
import "./i18n.js";
import { refreshToken } from "api/auth.service";
import { Budgets } from "pages/Budgets";
import { Settings } from "pages/Settings"
import { SubscribePage } from "pages/Subscribe/SubscribePage";
import CampaignWrapper from "components/CampaignComponents/CampaignWrapper";
import AdminDashboard from "pages/AdminDashboard";
import AdminDashboardV1 from "pages/v1/AdminDashboard";
import './index.css';
import { RequireNonAuth } from 'store/auth/RequireNonAuth';
import RenewDashboard from 'pages/RenewDashboard';
import TempTranslationMissing from 'pages/TempTranslationMissing';
import AiPerformance from 'pages/AiPerformance';


const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback="loading...">
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Campaigns />
              </RequireAuth>
            }
          />
          <Route path="login" element={
            <RequireNonAuth>
              <Login />
            </RequireNonAuth>
          } />
          {/* <Route path="/campaigns" element={<Campaigns />} /> */}
          <Route
            path="/:id/campaign-details"
            element={
              <RequireAuth>
                <CampaignDetails />
              </RequireAuth>
            }
          />
          <Route
            path="/ai-performance"
            element={
              <RequireAuth>
                <AiPerformance />
              </RequireAuth>
            }
          />
          <Route
            path="/subscribe"
            element={
              <RequireAuth>
                <SubscribePage />
              </RequireAuth>
            }
          />
          <Route
            path="/renew"
            element={
              <RequireAuth>
                <RenewDashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/campaigns"
            element={
              <RequireAuth>
                <Campaigns />
              </RequireAuth>
            }
          />
          <Route
            path="/budgets"
            element={
              <RequireAuth>
                <Budgets />
              </RequireAuth>
            }
          />
          <Route
            path="/settings"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />
          <Route
            path="/create"
            element={
              <RequireAuth>
                <CampaignWrapper action="create" />
              </RequireAuth>
            }
          />
          <Route
            path="/:id/edit"
            element={
              <RequireAuth>
                <CampaignWrapper action="edit" />
              </RequireAuth>
            }
          />
          <Route
            path="/:id/duplicate"
            element={
              <RequireAuth>
                <CampaignWrapper action="duplicate" />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/temp-translation-missing"
            element={
              <RequireAuth>
                <TempTranslationMissing />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/admin"
            element={
              <RequireAuth>
                <AdminDashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/v1/dashboard/admin"
            element={
              <RequireAuth>
                <AdminDashboardV1 />
              </RequireAuth>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
const App: React.FC = () => {
  const [clientInit, setClientInit] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const refreshTokenString = localStorage.getItem("refreshToken");

  if (refreshTokenString === "") {
    localStorage.setItem("refreshToken", "null");
  }

  useEffect(() => {
    client.interceptors.request.use(
      function (config) {
        const token = localStorage.getItem("token");
        const type = localStorage.getItem("type") || "Newton";
        if (!config.headers) {
          config.headers = {};
        }
        if (token) {
          config.headers.authorization = `Bearer ${token}`;
          config.headers['Role'] = type;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    client.interceptors.response.use(
      function (response) {
        return response;
      },
      async (error) => {
        const originalConfig = error.config;
        if (error.response) {
          if (error.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            const token = localStorage.getItem("refreshToken");
            if (token) {
              const refreshData = await refreshToken(token);
              const nextToken = refreshData.data?.access_token || '';
              if (!nextToken) {
                localStorage.removeItem("type");
                console.log(window.location.pathname);
                // TODO: check redirect to login
                // window.location.href = '/login';
              }

              localStorage.setItem("token", nextToken);

              originalConfig.headers.authorization = `Bearer ${nextToken}`;
              return client(originalConfig);
            } else {
              localStorage.removeItem("type");
              console.log(window.location.pathname);
              // TODO: check redirect to login
              // window.location.href = '/login';
            }
          }

          return Promise.reject(error);
        }
      }
    );
    setClientInit(true);
  }, []);
  if (isRefreshing || !clientInit) return <Box sx={{}}></Box>;

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppRoutes />
      </ThemeProvider>
    </Provider>
  );
};

root.render(<App />);
