import { Typography } from "@mui/material";
import styled from "styled-components";
import { MAIN_COLOR } from "./constants.styles";

export const TypographyCardTitle = styled(Typography)({
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "34px",
});

interface TypographySmallTitleProps {
    bold?: boolean;
    color?: string,
}

export const TypographySmallTitle = styled(Typography) <TypographySmallTitleProps>`
    color: ${props => props.color || MAIN_COLOR.DARK};
    font-weight: ${props => props.bold ? '600' : '400'};
    font-size: 12px;
    line-height: 18px;
`;

export const TypographyDescription = styled(Typography)`
    font-size: 14px;
    line-height: 21px;
`;

export const TypographyAlertText = styled(Typography)({
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "23px",
});


