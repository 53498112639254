import { Box } from "@mui/system";
import { createSchedulePreview, useActivationConditionTrans, useCashOutRestrictionTrans, useDelayTrans, useExpiryTrans, useRewardPresentationTrans } from "shared/constants";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createTriggersPreview } from "shared/constants";
import { createRewardsObject } from "shared/constants";
import "../../components/styles/CampaignOverviewStyles.css";
import { MAIN_COLOR } from "../styles/constants.styles";

interface Trigger {
  name: string;
  segments: [];
  rule: string;
}

interface Props {
  campaignTriggers: any;
  campaignDetails: any;
  conversionCodes: any;
  targetIds?: any;
  allOperators?: any;
}

const getLocationsTree = (targetIds?: any, allOperators?: any) => {
  if (!targetIds || !allOperators) {
    return null;
  }

  const { locations, operators, slots } = targetIds;

  return allOperators.reduce((accOperators: any, operator: any) => {

    if (!operators?.includes(operator.value)) {
      return accOperators;
    }

    return [
      ...accOperators,
      {
        ...operator,
        children: operator.children.reduce((accLocations: any, location: any) => {
          const value = location.value.split('/');

          if (!locations.includes(value[value.length - 1])) {
            return accLocations;
          }

          return [
            ...accLocations,
            {
              ...location,
              children: location.children.filter((slot: any) => !slots.includes(slot.value)),
            }
          ];
        }, [])
      }];
  }, []);

};

export const CampaignOverview = ({ campaignTriggers, campaignDetails, conversionCodes, targetIds, allOperators }: Props) => {
  const { t } = useTranslation();
  const rewardPresentationTrans = useRewardPresentationTrans();
  const activationConditionTrans = useActivationConditionTrans();
  const cashOutRestrictionTrans = useCashOutRestrictionTrans();

  const triggersPreviewObject = useMemo(() => {
    return createTriggersPreview(
      localStorage.getItem("lng")?.toString() || "en"
    );
  }, [t]);

  const rewardsPreviewObject = useMemo(() => {
    return createRewardsObject(t, localStorage.getItem("lng")?.toString() || "en");
  }, [t]);

  const { delay, timeOfActivation, expiry, timeOfExpiry } = campaignDetails?.overview?.reward?.availability || {};

  const delayTrans = useDelayTrans(delay, timeOfActivation);
  const expiryTrans = useExpiryTrans(expiry, timeOfExpiry);

  if (!campaignTriggers) {
    return <>Loading...</>;
  }

  const rewardPresentation = campaignDetails?.overview?.reward?.presentation?.type;
  const activationCondition = campaignDetails?.overview?.reward?.activationCondition?.name;
  const activationConditionValue = campaignDetails?.overview?.reward?.activationCondition?.name;

  const cashOutRestriction = campaignDetails?.overview?.reward?.cashOutRestriction?.name;
  const cashOutRestrictionValue = campaignDetails?.overview?.reward?.cashOutRestriction?.name;
  const campaignChannel = campaignDetails?.overview?.channel;

  const { allLocations, numberOfLocations, numberOfOperators, numberOfSlots } = campaignDetails?.overview?.target || {};
  const locationsTree = allLocations ? getLocationsTree(targetIds, allOperators) : [];

  return (
    <Box sx={{ padding: 2 }}>
      <Trans>Campaign Overview</Trans>
      <Box
        className="grid"
        sx={{ bgcolor: "rgba(186, 191, 199, 0.12);", p: 5, mt: 2 }}
      >
        <Box className="item1">
          <Box sx={{ fontSize: 13, color: MAIN_COLOR.DARK }}>
            {t("CAMPAIGN NAME")}
          </Box>
          <Box sx={{ fontSize: 15, fontWeight: 600 }}>
            {campaignTriggers.name}
          </Box>
        </Box>
        <Box className="item2">
          <Box sx={{ fontSize: 13, color: MAIN_COLOR.DARK }}>
            <Trans>CHANNEL</Trans>
          </Box>
          <Box sx={{ fontSize: 15, fontWeight: 600 }}>
            {campaignTriggers.channel}
          </Box>
        </Box>
        <Box className="item3">
          <Box sx={{ fontSize: 13, color: MAIN_COLOR.DARK }}>
            <Trans>CAMPAIGN TARGETS</Trans>
          </Box>
          <Box sx={{ fontSize: 15, fontWeight: 600 }}>
            {allLocations && <Trans>This campaign will target all operators and locations</Trans>}
            {!allLocations && (
              <div>
                {t("This campaign will target {{numberOfLocations}} location(s) over {{numberOfOperators}} operator(s) ({{numberOfSlots}} slots)", {
                  numberOfLocations,
                  numberOfOperators,
                  numberOfSlots,
                })}
                <ul>
                  {locationsTree.map((operator: any) => (
                    <li key={operator.value}>
                      {operator.label}
                      <ul>
                        {operator.children.map((location: any) => (
                          <li key={location.value}>
                            {location.label}
                            <ul>
                              {location.children.map((slot: any) => (
                                <li key={slot.value}>{slot.label}</li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Box>
        </Box>
        <Box className="item4">
          {" "}
          <Box sx={{ fontSize: 13, color: MAIN_COLOR.DARK }}>
            <Trans>CAMPAIGN TRIGGERS</Trans>
          </Box>
          <Box sx={{ fontSize: 15, fontWeight: 600 }}>
            {campaignTriggers?.scenario?.behavioralTriggers.length > 0 ? (
              <>{t("If the customer")}</>
            ) : (
              <>{t("If")}</>
            )}
            {campaignTriggers?.scenario?.behavioralTriggers?.map(
              (trigger: Trigger, index: number) => {
                const displayName = triggersPreviewObject?.[trigger.name]?.[trigger.rule.toString()] ?
                  triggersPreviewObject[trigger.name][trigger.rule.toString()](
                    trigger.segments.toString()
                  )
                  : ` "${trigger.name} ${trigger.rule.toString()}"`;

                return (
                  <span key={`${trigger.name}-${index}`}>
                    {displayName}
                  </span>
                );
              }
            )}
            {campaignTriggers?.scenario?.environmentalTriggers.map(
              (trigger: Trigger, index: number) => (
                <span key={`${trigger.name}-${index}`}>
                  {index === 0 ? <></> : t(" and")}
                  {triggersPreviewObject?.[trigger.name]?.[trigger.rule.toString()] ?
                    triggersPreviewObject[trigger.name][trigger.rule.toString()](
                      trigger.segments.toString()
                    )
                    : ` "${trigger.name} ${trigger.rule.toString()}"`}
                </span>
              )
            )}
            {campaignTriggers?.scenario?.timeTriggers.map(
              (trigger: Trigger, index: number) => (
                <span key={`${trigger.name}-${index}`}>
                  {index === 0 ? <></> : t(" and")}
                  {triggersPreviewObject?.[trigger.name]?.[trigger.rule.toString()] ?
                    triggersPreviewObject[trigger.name][trigger.rule.toString()](
                      trigger.segments.toString()
                    )
                    : ` "${trigger.name} ${trigger.rule.toString()}"`}
                </span>
              )
            )}
            {rewardsPreviewObject[campaignTriggers.reward.kind](
              campaignTriggers.reward.value,
              campaignTriggers.reward.quantity,
            )}

            {rewardPresentation && (
              <><br />{rewardPresentationTrans[rewardPresentation]}</>
            )}

            {(activationCondition && activationCondition !== 'None') && (
              <><br />{activationConditionTrans[activationCondition](activationConditionValue)}</>
            )}
            {(cashOutRestriction && cashOutRestriction !== 'None') && (
              <><br />{cashOutRestrictionTrans[cashOutRestriction](cashOutRestrictionValue)}</>
            )}

            {['Sms', 'SmsManual'].includes(campaignChannel) && (
              <>
                <br />
                {delayTrans}{' '}
                {expiryTrans}
                {conversionCodes && (
                  <ul>
                    <li>{t('Codes sent')}: {conversionCodes.sent}</li>
                    <li>{t('Used codes')}: {conversionCodes.sent}</li>
                    <li>{t('Expired codes')}: {conversionCodes.sent}</li>
                  </ul>
                )}
              </>
            )}

            {['Voucher'].includes(campaignChannel) && (
              <>
                {conversionCodes && (
                  <ul>
                    <li>{t('Codes generated')}: {conversionCodes.sent}</li>
                    <li>{t('Used codes')}: {conversionCodes.used}</li>
                    <li>{t('Expired codes')}: {conversionCodes.expired}</li>
                  </ul>
                )}
              </>
            )}
          </Box>
        </Box>
        <Box className="item5">
          <Box sx={{ fontSize: 13, color: MAIN_COLOR.DARK }}>
            <Trans>{t("CAMPAIGN SCHEDULE")}</Trans>
          </Box>
          {campaignTriggers.schedule && (
            <Box sx={{ fontSize: 15, fontWeight: 600 }}>
              {createSchedulePreview(
                campaignTriggers.schedule.startDate,
                campaignTriggers.schedule.endDate,
                t
              )}
            </Box>
          )}
        </Box>
        <Box className="item6">
          <Box sx={{ fontSize: 13, color: MAIN_COLOR.DARK }}>
            <Trans>CAMPAIGN EXPERIMENT SETTINGS</Trans>
          </Box>
          <Box sx={{ fontSize: 15, fontWeight: 600 }}>
            <Trans>The campaign will reach around</Trans>{" "}
            {campaignTriggers.experimentSettings.target}{" "}
            <Trans>slot machines</Trans>. <Trans>From these</Trans>{" "}
            {campaignTriggers.experimentSettings.control}%{" "}
            <Trans>will be part of the Control Group and</Trans>{" "}
            {campaignTriggers.experimentSettings.experiment}%{" "}
            <Trans>will be used for AI experiments</Trans>.
          </Box>
        </Box>
      </Box>
    </Box >
  );
};
