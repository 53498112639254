import React, { ReactElement } from 'react';
import { AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
    children: ReactElement<any, any>;
};


const BorderedAccordionSummary = ({
    children
}: Props) => (
    <AccordionSummary
        sx={{ borderBottom: "1px solid #F2F3F7" }}
        expandIcon={<ExpandMoreIcon />}
    >
        {children}
    </AccordionSummary>
);

export default BorderedAccordionSummary;