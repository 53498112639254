import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Chart from 'react-apexcharts';
// import "./styles/RewardStyle.css";
import { useNavigate } from "react-router-dom";
import { MAIN_COLOR, ACCENT_LIGHT_COLOR } from "components/styles/constants.styles";
import ChartCard from "./ChartCard";
import { ApexOptions } from "apexcharts";
import { splitInTheMiddleSpace } from "./utils";

interface TooltipProps {
    active: any;
    payload: any;
    label: any;
}

interface Props {
    bestCampaigns: [];
}

const MOCK_SERIES = [{
    data: [44, 55, 41, 64, 22, 43, 21]
}, {
    data: [53, 32, 33, 52, 13, 44, 32]
}];

const MOCK_CATEGORIES = ["2001", "2002", "2003", "2004", "2005", "2006", "2007"];

type BestCampaign = {
    bestExperimentValue: number;
    id: string;
    mainCampaignValue: number;
    name: string;
};

const getSortedValues = (revenueReports: BestCampaign[]) => {
    const sortedValue = revenueReports.sort((a, b) => {
        const sumA = a.bestExperimentValue + a.mainCampaignValue;
        const subB = b.bestExperimentValue + b.mainCampaignValue;
        if (sumA > subB) {
            return -1;
        }

        if (sumA < subB) {
            return 1;
        }
        return 0;
    }).reduce((
        acc: { names: string[], bestExperimentValue: number[]; mainCampaignValue: number[]; ids: string[]; },
        item: BestCampaign
    ) => {
        return {
            names: [...acc.names, item.name],
            bestExperimentValue: [...acc.bestExperimentValue, item.bestExperimentValue],
            mainCampaignValue: [...acc.mainCampaignValue, item.mainCampaignValue],
            ids: [...acc.ids, item.id]
        };
    }, { bestExperimentValue: [], mainCampaignValue: [], names: [], ids: [] });

    return sortedValue;
};

const getSeries = (mainCampaignValue: number[], bestExperimentValue: number[], t: any) => {
    return [{
        name: t("Main Campaign"),
        data: mainCampaignValue
    }, {
        name: t("Best Performing AI Experiment"),
        data: bestExperimentValue
    }];

};

const getOptions = (categories: string[], t: any) => {
    const options: ApexOptions = {
        chart: {
            type: 'bar',
            height: 430,
            toolbar: {
                show: false,
            },
            events: {
                click: function (event, chartContext, config) {
                    console.log({ event, chartContext, config });
                    // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                }
            }
        },
        colors: [MAIN_COLOR.EXTRA_DARK, MAIN_COLOR.EXTRA_LIGTH],
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'top',
                },
                borderRadius: [3],
            },
        },
        dataLabels: {
            enabled: false,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['#fff']
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#6E6B7B',
                    fontSize: '12px', fontFamily: "Montserrat,sans-serif"
                },
                formatter: function (val: any) {
                    return `${val} ${t('LEI')}`;
                }
            }
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
        },
        tooltip: {
            style: {
                fontSize: '12px', fontFamily: "Montserrat,sans-serif"
            },
            x: {
                formatter: function (val: any) {
                    return categories[Number(val) - 1];
                }
            },
            y: {
                formatter: function (val: any) {
                    return `${Math.abs(val)} ${t("LEI")}`;
                }
            },
            shared: true,
            intersect: false
        },
        xaxis: {
            categories: categories.map((item, index) => `${index + 1}`),
        },
    };

    return options;
};

const TopPerformingCampaigns = ({ bestCampaigns }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    if (!bestCampaigns) {
        return (
            <ChartCard title={t("Top Performing Campaigns")} noAvailableData>
                <Chart options={getOptions(MOCK_CATEGORIES, t)} series={MOCK_SERIES} type='bar' height={350} />
            </ChartCard>
        );
    }

    const { bestExperimentValue, mainCampaignValue, names } = getSortedValues(bestCampaigns);

    const series = getSeries(mainCampaignValue, bestExperimentValue, t);
    const options = getOptions(names, t);

    return (
        <ChartCard title={t("Top Performing Campaigns")}>
            <Chart options={options} series={series} type='bar' height={350} />
        </ChartCard>
    );
};

export default TopPerformingCampaigns;