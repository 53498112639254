import { Box } from "@mui/system";
import StyledPagination from "./styles/Pagination.styled";
import { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import StyledTable, { CampaignsTableFooter, CampaignsTableFooterPerPage } from "./styles/Table.styled";
import { useDispatch, useSelector } from "react-redux";
import { getCampaigns } from "store/campaigns/campaign.actions";
import {
  campaignsPaginationSelector,
  campaignsSelector
} from "store/campaigns/campaigns.selector";
import {
  Hidden,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { createColumns, createTabletColumns } from "shared/createColumns";
import { CustomActionsCell } from "./CustomActionsCell";
import MobileCampaignsTable from "./MobileCampaignsTable";
import { CustomDetails } from "./CustomDetails";
import { useNavigate } from "react-router-dom";
import { border, boxShadow, borderRadius, CONTENT_SPACING_GAP } from "../components/styles/constants.styles";

interface CellProps {
  cell: any;
  id: any;
  status: any;
  setStatusChanged: any;
}

const DEFAULT_RESULTS_PER_PAGE = 25;

const getCampaignsPerPage = () => {
  const perPage = localStorage.getItem('campaigns_per_page');

  return perPage ? Number(perPage) : DEFAULT_RESULTS_PER_PAGE;
};

const setCampaignsPerPage = (value: number) => {
  localStorage.setItem('campaigns_per_page', value.toString());
};

const RenderCell: React.FC<CellProps> = ({
  cell,
  id,
  status,
  setStatusChanged
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/${id}/campaign-details`);
  };

  switch (cell.column.id) {
    case "actions":
      return (
        <CustomActionsCell
          campaignId={id}
          status={status}
          setStatusChanged={setStatusChanged}
        />
      );
    case "details":
      return (
        <Box
          sx={{ cursor: "pointer" }}
          {...cell.getCellProps()}
          onClick={handleNavigate}
        >
          {cell.render("Cell")}
        </Box>
      );
    case "channel":
      return (
        <Box
          sx={{ fontSize: 14 }}
          {...cell.getCellProps()}
        >
          {cell.render("Cell")}
        </Box>
      );
    default:
      return <Box {...cell.getCellProps()}>{cell.render("Cell")}</Box>;
  }
};

export const CampaignsTable: React.FC = () => {
  const dispatch = useDispatch();
  let data = useSelector(campaignsSelector || []);
  const paginationDetails = useSelector(campaignsPaginationSelector);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(getCampaignsPerPage());
  const [statusChanged, setStatusChanged] = useState(false);
  const { t } = useTranslation();
  const tabletView = useMediaQuery("(min-width:1024px)");
  const mobileView = useMediaQuery("(min-width:612px)");

  if (!data) {
    data = [];
  }

  const columns = useMemo(() => {
    return tabletView ? createColumns() : createTabletColumns();
  }, [t, tabletView]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data
    });

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const handlePageSizeChange = (event: SelectChangeEvent) => {
    const nextPageSizeValue = Number(event.target.value);
    setPageSize(nextPageSizeValue);
    setCampaignsPerPage(nextPageSizeValue);
    setPage(1);
  };

  useEffect(() => {
    dispatch(
      getCampaigns({
        page: page,
        pageSize: pageSize,
        statuses: paginationDetails.statuses,
        channels: paginationDetails.channels,
        sortBy: paginationDetails.sortBy,
        descending: paginationDetails.descending,
        name: paginationDetails.name
      })
    );
    setStatusChanged(false);
  }, [dispatch, page, pageSize, statusChanged]);

  const boxStyle = useMemo(() => {
    if (!mobileView) {
      return {};
    }

    return {
      boxShadow, border, borderRadius, m: "8px 32px 32px 32px"
    };
  }, [mobileView]);

  return (
    <Box sx={{ mr: 8, mb: 4, ...boxStyle }}>
      {mobileView ? (
        <>
          <StyledTable {...getTableProps()}>
            <thead className="TableHeader">
              {headerGroups?.map((headerGroup, index) => {
                return (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={`${index}-${headerGroup.id}-headerGroup`}
                  >
                    {headerGroup.headers.map((column: any, index) => (
                      <td
                        className="TableHeaderCell"
                        {...column.getHeaderProps()}
                        key={`${index}-${column.id}-header`}
                      >
                        {column.render("Header")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows?.map((row: any, index: number) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={`${index}-${row.id}-body`}>
                    {row.cells.map((cell: any, index: number) => {
                      return (
                        <td
                          className="TableRow"
                          key={`${index}-${cell.id}-cell`}
                        >
                          <RenderCell
                            cell={cell}
                            id={data[row.id].id}
                            status={data[row.id].status}
                            setStatusChanged={setStatusChanged}
                          />
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
        </>
      ) : (
        <MobileCampaignsTable
          campaigns={data}
          setStatusChanged={setStatusChanged}
        />
      )}

      <CampaignsTableFooter>
        <CampaignsTableFooterPerPage>
          {t("Show")}
          <Select
            sx={{ height: 38 }}
            autoWidth
            value={pageSize.toString()}
            onChange={handlePageSizeChange}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </CampaignsTableFooterPerPage>
        <Hidden smUp>
          <StyledPagination
            count={paginationDetails.totalPageNumber}
            page={paginationDetails.currentPageNumber}
            siblingCount={0}
            onChange={handlePageChange}
            size="small"
          />
        </Hidden>
        <Hidden smDown>
          <StyledPagination
            count={paginationDetails.totalPageNumber}
            page={paginationDetails.currentPageNumber}
            onChange={handlePageChange}
            size="small"
          />
        </Hidden>

      </CampaignsTableFooter>
    </Box>
  );
};
