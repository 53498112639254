import React from 'react';
import ChartCard from "./ChartCard";
import Chart from 'react-apexcharts';
import { useTranslation } from "react-i18next";
import { ApexOptions } from "apexcharts";
import { MAIN_COLOR } from 'components/styles/constants.styles';

const MOCK_OPTIONS = ['Operational', 'Networking', 'Hiring', 'R&D'];
const MOCK_SERIES = [85, 16, 50, 50];

interface Props {
    rewards: { reward: string, value: number, earning: number, expense: number; }[];
}

const getParsedData = (rewards: { reward: string, value: number, earning: number, expense: number; }[], t: any) => {
    const parsedData = rewards.reduce((acc: { labels: string[], series: number[]; total: number; }, item) => {
        return {
            labels: [...acc.labels, t(item.reward)],
            series: [...acc.series, item.value],
            total: acc.total + item.value,
        };
    }, { labels: [], series: [], total: 0 });

    return { ...parsedData, total: `${Math.floor(parsedData.total)} LEI` };
};

const getOptions = (labels: string[], total: string) => {
    const options: ApexOptions = {
        legend: {
            show: true,
            position: 'bottom'
        },
        labels,

        colors: ['#A8A0F5', MAIN_COLOR.EXTRA_DARK, '#CB6E15', '#FF9F43', '#0D8E46', '#3ACB7A'],
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '65%',
                    labels: {
                        show: true,
                        name: {
                            fontSize: '2rem',
                            fontFamily: 'Montserrat',
                        },
                        value: {
                            fontSize: '1rem',
                            fontFamily: 'Montserrat',
                            formatter(val) {
                                return `${parseInt(val)}`;
                            }
                        },
                        total: {
                            show: true,
                            fontSize: '16px',
                            fontFamily: 'Montserrat',
                            fontWeight: '600',
                            label: 'Total',
                            formatter() {
                                return total;
                            }
                        }
                    }
                }
            }
        },
        responsive: [
            {
                breakpoint: 992,
                options: {
                    chart: {
                        height: 380
                    },
                    legend: {
                        position: 'bottom',
                        fontSize: '12px',
                        fontFamily: "Montserrat,sans-serif"
                    }
                }
            },
            {
                breakpoint: 576,
                options: {
                    chart: {
                        height: 320
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        fontSize: '1.5rem'
                                    },
                                    value: {
                                        fontSize: '1rem'
                                    },
                                    total: {
                                        fontSize: '1.5rem'
                                    }
                                }
                            }
                        }
                    }
                }
            }
        ]
    };

    return options;
};

const RewardsContributionForProfitIncreaseCard = ({ rewards }: Props) => {
    const { t } = useTranslation();

    if (!rewards) {
        return <ChartCard
            title={t("Rewards Contribution for Profit Increase")}
            noAvailableData={true}
        >
            <Chart options={getOptions(MOCK_OPTIONS, "200 LEI")} series={MOCK_SERIES} type='donut' height={350} />
        </ChartCard>;
    }

    const { labels, series, total } = getParsedData(rewards, t);
    const options = getOptions(labels, total);

    return (
        <ChartCard
            title={t("Rewards Contribution for Profit Increase")}
        >
            <Chart options={options} series={series} type='donut' height={350} />
        </ChartCard>
    );
};

export default RewardsContributionForProfitIncreaseCard;
