import { Box, Icon, IconButton, Modal, Tooltip } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DeleteButton from "./styles/DeleteButton";
import CancelButton from "./styles/CancelButton";
import { changeCampaignStatus, deleteCampaign } from "api/campaign.service";
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { ACCENT_LIGHT_COLOR } from "./styles/constants.styles";

interface Props {
  campaignId: number;
  status?: string;
  setStatusChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CustomActionsCell = ({
  campaignId,
  status,
  setStatusChanged
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigate = (id: number) => {
    if (status && ["Scheduled", "Draft"].includes(status)) {
      navigate(`/${id}/edit`);

      return;
    }

    navigate(`/${id}/campaign-details`);
  };

  const handleCampaignDetailsNavigate = (id: number) => {
    navigate(`/${id}/campaign-details`);
  };

  const handleDuplicate = (id: number) => {
    navigate(`/${id}/duplicate`);
  };

  const handleDelete = async () => {
    const del = await deleteCampaign(campaignId);
    if (setStatusChanged) {
      setOpen(false);
      setStatusChanged(true);
    }
  };

  const handlePause = async () => {
    if (status && setStatusChanged) {
      changeCampaignStatus(campaignId.toString(), "Paused");
      setStatusChanged(true);
    }
  };

  const handlePlay = async () => {
    if (status && setStatusChanged) {
      changeCampaignStatus(campaignId.toString(), "Running");
      setStatusChanged(true);
    }
  };

  const renderStatusIcon = (status: string) => {
    switch (status) {
      case "Draft":
      case "Scheduled":
        return (
          <Tooltip title={t("Delete").toString()}>
            <IconButton
              color="info"
              component="label"
              size="small"
              onClick={handleOpen}
            >
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </Tooltip>
        );
      case "Running":
        return (
          <Tooltip title={t("Pause").toString()}>
            <IconButton
              color="info"
              component="label"
              size="small"
              onClick={handlePause}
            >
              <PauseCircleOutlineOutlinedIcon
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
        );
      case "Paused":
        return (
          <Tooltip title="Start">
            <IconButton
              color="info"
              component="label"
              size="small"
              onClick={handlePlay}
            >
              <PlayCircleOutlineOutlinedIcon
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
        );

      case "Ended":
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <Box
      sx={{
        display: "Flex",
        alignItems: "center",
        justifyContent: "start",
        minWidth: 100,
        gap: 1
      }}
    >
      {status && ["Scheduled", "Draft"].includes(status) && (
        <Tooltip title={t("Edit")}>
          <IconButton
            color="info"
            component="label"
            size="small"
            onClick={() => handleNavigate(campaignId)}
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {status !== "Draft" && (
        <Tooltip title={t("Campaign details")}>
          <IconButton
            color="info"
            component="label"
            size="small"
            onClick={() => handleCampaignDetailsNavigate(campaignId)}
          >
            <VisibilityOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title={t("Duplicate").toString()}>
        <IconButton
          color="info"
          component="label"
          size="small"
          onClick={() => handleDuplicate(campaignId)}
        >
          <ContentCopyOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      {renderStatusIcon(status || "")}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 648,
            height: 363,
            bgcolor: ACCENT_LIGHT_COLOR,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              display: "flex",
              mt: 8,
              justifyContent: "center",
              fontSize: 24,
              textAlign: "center"
            }}
          >
            <Trans>
              Are you sure you want to delete this campaign?
            </Trans>
          </Box>
          <Box sx={{ textAlign: "center", mt: 2 }}>
            {t('CAMPAIGN_WILL_BE_DELETED', { campaignId })}
          </Box>

          <Box sx={{ display: "flex", mt: 8 }}>
            <CancelButton onClick={() => setOpen(false)}>Cancel</CancelButton>
            <DeleteButton onClick={handleDelete}>
              {t("Delete Campaign")}
            </DeleteButton>
          </Box>
        </Box>
      </Modal>
    </Box >
  );
};
