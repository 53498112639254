import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import React from "react";
import { t } from "i18next";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ACCENT_LIGHT_COLOR } from "./styles/constants.styles";
import { Trans } from "react-i18next";

interface Props {
  locations: any;
  selectedLocations: string[];
  setSelectedLocations: React.Dispatch<React.SetStateAction<string[]>>;
}

const SelectLocations = ({
  locations,
  selectedLocations,
  setSelectedLocations
}: Props) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = event;
    setSelectedLocations(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl sx={{ width: 240 }} size="small">
      <InputLabel sx={{ color: 'text.disabled' }}>
        <Trans>All locations</Trans>
      </InputLabel>
      <Select
        sx={{ bgcolor: ACCENT_LIGHT_COLOR, minWidth: 200 }}
        multiple
        value={selectedLocations}
        onChange={handleChange}
        displayEmpty
        renderValue={(selected: string[]) => {
          if (selected.length === 0) {
            return <Box sx={{ color: 'text.disabled' }}><Trans>All locations</Trans></Box>;
          }

          if (selected.length === 1) {
            return selected.join('');
          }

          return <Trans>Multiple locations</Trans>;
        }}
        label={t("All locations").toString()}
      >
        {locations.map((location: { id: string; name: string; }) => (
          <MenuItem key={location.id} value={location.name}>
            <Checkbox checked={selectedLocations.indexOf(location.name) > -1} checkedIcon={<CheckBoxIcon style={{ fill: 'primary.main' }} />} />
            <ListItemText primary={location.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectLocations;
