import { Condition } from "Interfaces/ICondition";
import { FixedDynamicValueType, FIXED_DYNAMIC_VALUE_DEFAULT } from "Interfaces/CampaignBody";
import { createContext } from "react";

export type TriggerContent = {
  campaignConditions: Condition[];
  setCampaignConditions: (condition: Condition[]) => void;
  campaignReward: string;
  setCampaignReward: (value: string) => void;
  rewardQuantity: FixedDynamicValueType;
  setRewardQuantity: (value: FixedDynamicValueType) => void;
  secondRewardQuantity: FixedDynamicValueType;
  setSecondRewardQuantity: (value: FixedDynamicValueType) => void;
  reminder?: boolean,
  cashOutRestriction: string;
  rewardPresentation: string;
  activationCondition: string;
  onCashOutRestrictionNameChange: (value: string) => void;
  onRewardPresentationNameChange: (value: string) => void;
  onActivationConditionNameChange: (value: string) => void;
  activationConditionValue: number | FixedDynamicValueType,
  onActivationConditionValueChange: (value: number | FixedDynamicValueType) => void,
  activationConditionTimeLimit: number,
  onActivationConditionTimeLimitChange: (value: number) => void;
  cashOutRestrictionValue: number | FixedDynamicValueType,
  onCashOutRestrictionValueChange: (value: number | FixedDynamicValueType) => void,
  cashOutRestrictionTimeLimit: number,
  onCashOutRestrictionTimeLimitChange: (value: number) => void;
  getValueByKey: (key: string) => any;
  numberOfVouchers: number,
  setNumberOfVouchers: (value: number) => void;
  smsManualPhoneNumbers: string[],
  setSmsManualPhoneNumbers: (value: string[]) => void;
  status?: string;
};

export const TriggerContext = createContext<TriggerContent>({
  campaignConditions: [],
  setCampaignConditions: () => undefined,
  campaignReward: "",
  setCampaignReward: () => undefined,
  rewardQuantity: FIXED_DYNAMIC_VALUE_DEFAULT,
  setRewardQuantity: () => undefined,
  secondRewardQuantity: FIXED_DYNAMIC_VALUE_DEFAULT,
  setSecondRewardQuantity: () => undefined,
  reminder: false,
  cashOutRestriction: "Direct",
  rewardPresentation: "None",
  activationCondition: "None",
  onCashOutRestrictionNameChange: () => undefined,
  onRewardPresentationNameChange: () => undefined,
  onActivationConditionNameChange: () => undefined,
  activationConditionValue: FIXED_DYNAMIC_VALUE_DEFAULT,
  onActivationConditionValueChange: () => undefined,
  activationConditionTimeLimit: 0,
  onActivationConditionTimeLimitChange: () => undefined,
  cashOutRestrictionValue: FIXED_DYNAMIC_VALUE_DEFAULT,
  onCashOutRestrictionValueChange: () => undefined,
  cashOutRestrictionTimeLimit: 0,
  onCashOutRestrictionTimeLimitChange: () => undefined,
  getValueByKey: () => undefined,
  numberOfVouchers: 0,
  setNumberOfVouchers: () => undefined,
  smsManualPhoneNumbers: [],
  setSmsManualPhoneNumbers: () => undefined,
  status: undefined
});
