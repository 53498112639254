import React from "react";
import { Box, Icon, Typography } from "@mui/material";
import LeftBox from "./styles/LeftBox";
import SubscribeButton from "./styles/SubscribeButton";
import ArrowsRight from "../../assets/icons/chevrons-right.svg";
import { useTranslation } from "react-i18next";

export const LeftPanel = () => {
  const { t } = useTranslation();

  return (
    <LeftBox>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
        <Box sx={{ maxWidth: 330 }}>
          <Typography sx={{
            fontWeight: "700",
            fontSize: "44px",
            lineHeight: "54px",
            mb: '12px'
          }}>
            {t("What are you waiting for?")}
          </Typography>
          <Typography
            sx={{ fontWeight: 400, fontSize: "16px", lineHeight: "20px" }}
          >
            {t(
              "Let us do our magic so you can increase your profit. This is how much you could have made"
            )}
            :
          </Typography>
        </Box>
        <SubscribeButton>
          <Typography
            sx={{ fontWeight: 500, fontSize: "16px", lineHeight: "20px" }}
          >
            {t("Subscribe")}
          </Typography>
          <img src={ArrowsRight} alt="donwload" />
        </SubscribeButton>
      </Box>
    </LeftBox>
  );
};
