import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ChartCard from "./ChartCard";
import { ACCENT_DANGER_COLOR, CONTENT_SPACING_GAP, gap, GREY_COLOR, MAIN_COLOR, SUCCESS_COLOR } from "components/styles/constants.styles";
import { TypographyAlertText, TypographyCardTitle, TypographySmallTitle } from "components/styles/TypographyCardTitle";
import styled from "styled-components";
import ChannelIcon from "components/styles/ChannelIcon";
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

type ProgressProps = {
    color: string;
};

const ProgressContainer = styled(Box) <ProgressProps>`
    padding: 0;
    width: 100%;
    height: 22px;
    overflow: hidden;
    border-radius: 4px;
    margin-top: ${gap};
    margin: 12px 0;
    background: ${p => p.color};
`;

const PercentageBar = styled(Box) <ProgressProps>`
    position: relative;
    float: left;
    min-width: 1%;
    height: 100%;
    background: ${p => p.color};
`;

interface Props {
    conversion: {
        value: number;
        increase: number;
        lcdPercentage: number;
        lcdClaimed: number;
        lcdReceived: number;
        smsPercentage: number;
        smsClaimed: number;
        smsReceived: number;
    };
}

const NameBarContainer = styled(Box)({
    display: 'grid',
    gridTemplateColumns: '60px 1fr',
    alignItems: 'center',
});

type CampaignConversionTypeProps = {
    entry: 'LCD' | 'SMS' | 'SMS List' | 'Voucher';
    percentage: number;
    claimed: number;
    received: number;
};

const COLOR_BY_ENTRY = {
    LCD: {
        containerColor: 'rgba(115, 103, 240, 0.12)',
        percentageColor: '#7367F0',
    },
    SMS: {
        containerColor: 'rgba(255, 159, 67, 0.12)',
        percentageColor: '#FF9F43',
    },
    'SMS List': {
        containerColor: 'rgba(58, 203, 122, 0.12)',
        percentageColor: '#3ACB7A',
    },
    Voucher: {
        containerColor: 'rgba(168, 160, 245, 0.12)',
        percentageColor: '#A8A0F5',
    },
};

const CampaignConversionType = ({ entry, percentage, claimed, received }: CampaignConversionTypeProps) => {
    const { t } = useTranslation();

    const { containerColor, percentageColor } = COLOR_BY_ENTRY[entry];

    return (
        <Box sx={{ mt: '12px' }}>
            <NameBarContainer>
                <TypographySmallTitle>{entry}</TypographySmallTitle>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1
                    }}
                >
                    <ProgressContainer color={containerColor}>
                        <PercentageBar
                            sx={{ width: `${percentage}%` }}
                            color={percentageColor}
                        />
                    </ProgressContainer>
                    <TypographySmallTitle bold color={GREY_COLOR.EXTRA_DARK}>
                        {Math.floor(percentage)}%
                    </TypographySmallTitle>
                </Box>
            </NameBarContainer>
            <NameBarContainer>
                <Box />
                <TypographySmallTitle bold color={GREY_COLOR.EXTRA_DARK}>
                    {claimed} {t("rewards claimed out of")}{" "}
                    {received}
                </TypographySmallTitle>
            </NameBarContainer>
        </Box>
    );
};

const CampaignConversionsCard = ({
    conversion: conversionProp
}: Props) => {
    const { t } = useTranslation();

    const noAvailableData = !conversionProp;
    const conversion = noAvailableData ? {
        value: 12,
        increase: 3,
        lcdPercentage: 4,
        lcdClaimed: 23,
        lcdReceived: 56,
        smsPercentage: 76,
        smsClaimed: 15,
        smsReceived: 5,
    } : conversionProp;
    return (
        <ChartCard title={t("Campaign Conversions")} noAvailableData={noAvailableData}>
            <Box sx={{ height: "100%" }}>
                <Box
                    sx={{
                        p: `0 ${CONTENT_SPACING_GAP}`,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <Box>
                        <TypographyCardTitle sx={{ mb: "2px" }}>
                            {conversion.value}% <br />
                        </TypographyCardTitle>
                        <TypographyAlertText
                            sx={{
                                color: conversion.increase > 0 ? SUCCESS_COLOR.LIGHT : ACCENT_DANGER_COLOR,
                            }}
                        >
                            {conversion.increase > 0 ? "+" : null}
                            {conversion.increase}%
                        </TypographyAlertText>
                    </Box>
                    <CampaignConversionType
                        entry="LCD"
                        percentage={conversion.lcdPercentage}
                        claimed={conversion.lcdClaimed}
                        received={conversion.lcdReceived}
                    />
                    <CampaignConversionType
                        entry="SMS"
                        percentage={conversion.smsPercentage}
                        claimed={conversion.smsClaimed}
                        received={conversion.smsReceived}
                    />
                    <CampaignConversionType
                        entry="SMS List"
                        percentage={conversion.smsPercentage}
                        claimed={conversion.smsClaimed}
                        received={conversion.smsReceived}
                    />
                    <CampaignConversionType
                        entry="Voucher"
                        percentage={conversion.smsPercentage}
                        claimed={conversion.smsClaimed}
                        received={conversion.smsReceived}
                    />
                </Box>
            </Box>
        </ChartCard >
    );
};
export default CampaignConversionsCard;
