import React from "react";
import {
  Box,
  Icon,
  AccordionDetails,
  TextField,
  Typography,
  IconButton,
  Menu,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import NumberIcon from "../assets/icons/number-four-icon.svg";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CheckedIcon from "../assets/icons/checked-icon.svg";
import EventIcon from '@mui/icons-material/Event';
import { StaticDatePicker } from "@mui/lab";
import { Trans, useTranslation } from "react-i18next";
import { calculateCampaignDuration } from "shared/helpers";
import moment from "moment";
import { CampaignAccordionSection } from "./styles/CampaignAccordionSection";
import { isCampaignScheduleValid } from "./campaignPreviewUtils";
import BorderedAccordionSummary from "./BorderedAccordionSummary";

interface CustomDateTimePickerProps {
  value: any;
  label: string;
  onChange: (newDate: any) => void;
  minDate?: Date | null;
}

const isValidDate = (value: any) => {
  const momentValue = moment(value, 'DD/MM/YYYY hh:mm A');

  return value && momentValue.isValid();
};

const isDisplayValueValid = (value: any) => {
  return moment(value, "DD/MM/YYYY hh:mm A").isValid() && typeof value === 'string'
    ? moment(value, "DD/MM/YYYY hh:mm A").format('A') !== 'Invalid date' && moment(value, "DD/MM/YYYY hh:mm A").format("DD/MM/YYYY hh:mm A") === value
    : moment(value).isValid();
};

const CustomDateTimePicker: React.FC<CustomDateTimePickerProps> = ({ value, label, minDate, onChange }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeHandler = (value: any) => {
    if (!isValidDate(value)) {
      return;
    }
    onChange(value);
  };

  const dateTime = moment(value);

  const onHourChange = (hour: any) => {
    if (hour < 0 || hour >= 24 || !isValidDate(value)) {
      return;
    }

    if (!isValidDate(value)) {
      return;
    }

    const nextFormattedDate = moment(value).set('hour', hour).toDate();
    onChange(nextFormattedDate);
  };

  const onMinsChange = (mins: any) => {
    if (mins < 0 || mins >= 60 || !isValidDate(value)) {
      return;
    }

    const nextFormattedDate = moment(value).set('minute', mins).toDate();
    onChange(nextFormattedDate);
  };

  const momentValue = moment(value);
  const isValidValue = isDisplayValueValid(value);
  const displayValue = value ? momentValue.format('DD/MM/YYYY hh:mm A') : '';

  return (
    <TextField
      placeholder={label}
      value={displayValue}
      error={value && !isValidValue}
      InputProps={{
        endAdornment: (
          <div>
            <IconButton
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <EventIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <StaticDatePicker
                value={value}
                onChange={onChangeHandler}
                renderInput={(params: any) => (
                  <TextField {...params} sx={{ px: 4, pb: 4 }} />
                )}
                disablePast
                disableHighlightToday
                minDate={minDate}
              />
              {value && isValidValue && (
                <Box sx={{ px: '32px', pb: '32px' }}>
                  <Box sx={{
                    color: 'rgba(0, 0, 0, 0.6)', fontSize: '0.75rem',
                    lineHeight: '2.66',
                  }}>
                    {t('SELECT TIME')}
                  </Box>
                  <Box sx={{ display: "flex", alignItems: 'center' }}>
                    <TextField
                      type="number"
                      value={dateTime.hours()}
                      onChange={(e: any) => {
                        onHourChange(e.target.value);
                      }}
                      sx={{ width: 70 }}
                      size="small"
                    />
                    {' : '}
                    <TextField
                      type="number"
                      value={dateTime.minutes()}
                      onChange={(e: any) => {
                        onMinsChange(e.target.value);
                      }}
                      sx={{ width: 70 }}
                      size="small"
                    />
                  </Box>
                </Box>
              )}
            </Menu>
          </div>
        ),
        style: { cursor: "default" }
      }}
      sx={{ width: 300, caretColor: "transparent", cursor: "default" }}
    />
  );
};

interface Props {
  expanded: string | false;
  handlePanelChange: (
    panel: string
  ) => (event: any, isExpanded: boolean) => void;
  setStartDate: any;
  setEndDate: any;
  startDate: Date | null;
  endDate: Date | null;
  disabled: boolean,
  campaignChannel: string;
}

export const SelectSchedule = ({
  expanded,
  handlePanelChange,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  disabled,
  campaignChannel,
}: Props) => {
  const checked = calculateCampaignDuration(startDate, endDate);
  const { t } = useTranslation();

  return (
    <CampaignAccordionSection
      expanded={expanded === "schedulePanel"}
      onChange={handlePanelChange("schedulePanel")}
      disabled={disabled}
    >
      <BorderedAccordionSummary>
        <Box>
          <Box
            sx={{
              fontSize: 15,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center"
            }}
          >
            <Icon sx={{ overflow: "visible", mr: 1, mb: 0.8 }}>
              <img
                src={isCampaignScheduleValid({ campaignChannel, campaignStartDate: startDate, campaignEndDate: endDate }) ? CheckedIcon : NumberIcon}
                alt="search"
              />
            </Icon>
            <Box>
              <Trans>Campaign Schedule</Trans>
            </Box>
          </Box>
          <Box sx={{ fontSize: 13, ml: 4 }}>
            <Trans>Set the period for the campaign</Trans>.
          </Box>
        </Box>
      </BorderedAccordionSummary>
      <AccordionDetails>
        <Box sx={{ p: 4, display: "flex", flexFlow: "wrap", gap: 4 }}>
          <Box>
            <Typography sx={{ fontWeight: 600, fontSize: 16, mb: 2 }}>
              <Trans>Start</Trans>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomDateTimePicker
                label={t("Start").toString()}
                value={startDate}
                onChange={(newDate) => {
                  setStartDate(newDate);
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: 600, fontSize: 16, mb: 2 }}>
              <Trans>End</Trans>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomDateTimePicker
                label={t("End").toString()}
                value={endDate}
                minDate={startDate}
                onChange={(newDate) => {
                  setEndDate(newDate);
                }}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </AccordionDetails>
    </CampaignAccordionSection>
  );
};
