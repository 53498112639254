import { getAllOperators, sortOperatorsAlphabetically } from "api/operators.service";
import React, { useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import KeyboardArrowRightTwoToneIcon from "@mui/icons-material/KeyboardArrowRightTwoTone";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const operatorsDict: Record<string, string> = {};
const locationDict: Record<string, string> = {};
const slotsToLocations: Record<string, string> = {};
const locationToOperators: Record<string, string> = {};

const createObject = (operators: any): any => {
  let operatorsObject = [];

  if (operators.length) {
    operatorsObject = operators.map((operator: any, index: number) => {
      operatorsDict[operator.id] = operator.name;
      return {
        label: operator.name,
        value: operator.id,
        children: operator.locations.map((location: any, index: number) => {
          locationDict[location.id] = location.name;
          locationToOperators[location.id] = operator.id;
          return {
            label: location.name,
            value: `${operator.id}/${location.id}`,
            children: location.slots.map((slot: any, index: number) => {
              slotsToLocations[slot.id] = location.id;
              return {
                label: slot.name,
                value: `${operator.id}/${location.id}/${slot.id}`
              };
            })
          };
        })
      };
    });
  }

  return operatorsObject;
};

const createSelectedOperators = (
  operators: string[],
  locations: string[],
  slots: string[]
): string[] => {
  const selectedOperators: string[] = [];

  slots.forEach((slot: string) =>
    selectedOperators.push(
      `${locationToOperators[slotsToLocations[slot]]}/${slotsToLocations[slot]
      }/${slot}`
    )
  );

  return selectedOperators;
};

const createOperatorArray = (
  selectedSlots: string[],
  setOperators: React.Dispatch<React.SetStateAction<string[]>>,
  setLocations: React.Dispatch<React.SetStateAction<string[]>>,
  setSlots: React.Dispatch<React.SetStateAction<string[]>>,
  setOperatorsText: React.Dispatch<React.SetStateAction<string | undefined>>,
  t: any,
) => {
  const operators: string[] = [];
  const locations: string[] = [];
  const slots: string[] = [];

  selectedSlots.forEach((slot: string) => {
    const splitIds = slot?.split("/");

    if (!operators.includes(splitIds[0])) {
      operators.push(splitIds[0]);
    }

    if (!locations.includes(splitIds[1])) {
      locations.push(splitIds[1]);
    }

    if (!slots.includes(splitIds[2])) {
      slots.push(splitIds[2]);
    }
  });

  setOperators(operators);
  setLocations(locations);
  setSlots(slots);

  const text = t("This campaign will target {{numberOfLocations}} location(s) over {{numberOfOperators}} operator(s) ({{numberOfSlots}} slots)", {
    numberOfLocations: locations.length,
    numberOfOperators: operators.length,
    numberOfSlots: slots.length,
  });

  if (operators[0]) setOperatorsText(text);
  else setOperatorsText(undefined);
};

interface Props {
  setOperators: React.Dispatch<React.SetStateAction<string[]>>;
  setLocations: React.Dispatch<React.SetStateAction<string[]>>;
  setSlots: React.Dispatch<React.SetStateAction<string[]>>;
  setOperatorsText: React.Dispatch<React.SetStateAction<string | undefined>>;
  operators: string[];
  locations: string[];
  slots: string[];
}

export const CheckBoxTree = ({
  setOperators,
  setLocations,
  setSlots,
  setOperatorsText,
  operators,
  locations,
  slots
}: Props) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<string[]>([]);
  const [expanded, setExpanded] = useState(["all"]);
  const [allOperators, setAllOperators] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [nodes, setNodes] = useState([]);

  const handleFilter = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFilterValue(event.target.value);
  };

  useEffect(() => {
    filterTree();
  }, [filterValue]);

  const filterTree = () => {
    if (allOperators && !filterValue) {
      setNodes(allOperators);
      return;
    }

    setNodes(allOperators.reduce(filterNodes, []));
  };

  const filterNodes = (filtered: any, node: any) => {
    const children = (node.children || []).reduce(filterNodes, []);
    const allChildren = node.children;

    if (
      node.label.toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) >
      -1 ||
      children.length
    ) {
      filtered.push({ ...node, allChildren });
    }

    return filtered;
  };

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const operators = await getAllOperators();

        const sortedOperators = operators.data.sort(sortOperatorsAlphabetically);
        setAllOperators(createObject(sortedOperators));
        setNodes(createObject(sortedOperators));
      } catch (error) {
        console.log(error);
      }
    };

    fetchOperators();
  }, []);

  useEffect(() => {
    setChecked(createSelectedOperators(operators, locations, slots));
  }, [allOperators]);

  useEffect(() => {
    createOperatorArray(
      checked,
      setOperators,
      setLocations,
      setSlots,
      setOperatorsText,
      t,
    );
  }, [checked]);

  if (!nodes) {
    return <></>;
  }
  return (
    <>
      <TextField
        variant="outlined"
        size="small"
        sx={{ width: "96%", mb: 2 }}
        placeholder={t("Search for clients, locations, slots or games")}
        value={filterValue}
        onChange={(event) => handleFilter(event)}
      />
      <CheckboxTree
        icons={{
          expandClose: <KeyboardArrowRightTwoToneIcon />,
          expandOpen: <KeyboardArrowDownTwoToneIcon />,
          check: <CheckBoxOutlinedIcon style={{ marginBottom: '-6px' }} />,
          uncheck: <CheckBoxOutlineBlankIcon style={{ marginBottom: '-6px' }} />,
          halfCheck: <IndeterminateCheckBoxOutlinedIcon />
        }}
        showNodeIcon={false}
        nodes={nodes}
        showExpandAll={false}
        checked={checked}
        expanded={expanded}
        onCheck={(checked: any) => setChecked(checked)}
        onExpand={(expanded: any) => setExpanded(expanded)}
      />
    </>
  );
};
