import { Box, Icon, Typography } from "@mui/material";
import AvgDurationIcon from "../assets/icons/avg-session-icon.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import { calculateTime } from "shared/constants";
import { ACCENT_DANGER_COLOR, ACCENT_LIGHT_COLOR, MAIN_COLOR, SUCCESS_COLOR } from "./styles/constants.styles";

interface Props {
  averageDuration: number;
  durationIncrease: number;
  mobileView?: boolean;
}

export const AverageDuration = ({
  averageDuration,
  durationIncrease,
  mobileView
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        bgcolor: ACCENT_LIGHT_COLOR,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "6px",
        p: 2
      }}
    >
      <Box>
        <Typography sx={{ color: MAIN_COLOR.DARK, fontSize: 15, fontWeight: 500 }}>
          {t(`Avg Session Duration`)}
        </Typography>
        <Typography sx={{ fontWeight: "bold", fontSize: 28 }}>
          {averageDuration ? <>{calculateTime(averageDuration)}</> : <></>}
          <br />
        </Typography>
        {durationIncrease === 0 ? (
          <></>
        ) : (
          <Typography
            sx={{
              color: durationIncrease > 0 ? SUCCESS_COLOR.LIGHT : ACCENT_DANGER_COLOR,
              fontSize: 14
            }}
          >
            {durationIncrease > 0 ? "+" : null}
            {durationIncrease}%
          </Typography>
        )}
      </Box>
      <Icon
        sx={{
          width: 47,
          height: 47,
          display: !mobileView ? "none" : "inline-block"
        }}
      >
        <img src={AvgDurationIcon} alt="search" />
      </Icon>
    </Box>
  );
};
