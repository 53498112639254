import styled from 'styled-components';
import React from "react";
import { gap } from "components/styles/constants.styles";
import { ExperimentsTable } from 'components/ExperimentsTable';
import { CampaignComparison } from 'components/CampaignComparison';
import SingleVerticalBar from './charts/SingleVerticalBar';
import DoubleVerticalBar from './charts/DoubleVerticalBar';
import SingleSmoothLine from './charts/SingleSmoothLine';
import { DateRange } from "@mui/lab/DateRangePicker";

const ChartsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${gap};
  grid-row-gap: ${gap};
  
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 768px) {
    display: block
  }
`;

type Props = {
    campaignDetails: any;
    range: DateRange<Date>;
};

const SmsCharts = ({ campaignDetails, range }: Props) => {
    const [dateFrom, dateTo] = range;

    return (
        <ChartsGridContainer>
            <SingleSmoothLine
                title="Total Campaign Conversions"
                data={campaignDetails.conversionChart.values}
                percent={campaignDetails.conversionChart.conversionPercent}
                value={campaignDetails.conversionChart.claimed}
                total={campaignDetails.conversionChart.received}
                dateFrom={dateFrom}
                dateTo={dateTo}
            />

            <SingleSmoothLine
                title="Reward Usage"
                data={campaignDetails.usageChart.values}
                percent={campaignDetails.usageChart.usagePercent}
                value={campaignDetails.usageChart.used}
                total={campaignDetails.usageChart.claimed}
                dateFrom={dateFrom}
                dateTo={dateTo}
            />

            <DoubleVerticalBar
                bestCampaigns={campaignDetails.activationPlaceChart}
                title="Activation Place"
                value1Name="Same location as offered"
                value2Name="Different location"
            />

            <DoubleVerticalBar
                bestCampaigns={campaignDetails.offeredVsUsedPerLocationChart}
                title="Amount Offered vs Used per Location"
                value1Name="Amount offered"
                value2Name="Amount used"
            />

            <SingleVerticalBar
                data={campaignDetails.timeReceivedToUsedChart}
                title="Time: received to used"
                sortedKeyName="value"
                sortyType="desc"
            />

            <SingleVerticalBar
                data={campaignDetails.depositPerSessionChart}
                title="Total deposit per sessions started with code"
                sortedKeyName="label"
                sortyType="none"
            />
            <CampaignComparison data={campaignDetails.comparison} />
            <ExperimentsTable tableData={campaignDetails?.experiments} />
        </ChartsGridContainer>
    );
};

export default SmsCharts;