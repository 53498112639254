import { Box } from "@mui/material";
import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import { MAIN_COLOR } from "./styles/constants.styles";

interface Props {
  data: {
    name: string;
    pi?: number;
  }[];
}

export const ProfitIncreaseChart = ({ data }: Props) => {
  return (
    <Box sx={{ width: "99%", height: 230 }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 35,
            right: 0,
            left: -20,
            bottom: 5
          }}
        >
          <CartesianGrid vertical={false} horizontal={true} />
          <XAxis
            dataKey="name"
            axisLine={false}
            padding={{ left: 10 }}
            tick={{ dy: 15 }}
            interval={6}
          />
          <YAxis dataKey="pi" unit="%" axisLine={false} tick={{ dx: 10 }} />
          <Line
            type="monotone"
            dataKey="pi"
            stroke={MAIN_COLOR.NORMAL}
            strokeWidth={3}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="pl"
            stroke="#EBE9F1"
            strokeWidth={3}
            strokeDasharray="15 15"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
