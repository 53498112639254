import { Box, Typography } from "@mui/material";
import { getDashboardDetails } from "api/campaign.service";
import { NavBar } from "components/NavBar";
import ContentContainer from "components/styles/ContentContainer";
import { ISubscribeData } from "Interfaces/ISubscribeData";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LeftPanel } from "./LeftPanel";
import { RightPanel } from "./RightPanel";
import Container from "./styles/Container";

export const SubscribePage = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<ISubscribeData>();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getDashboardDetails(false, "AdminNewCustomer");
      await setData(data.data);
    };

    try {
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Box>
      <NavBar activeTab="dashboard" />
      <ContentContainer>
        <Typography
          sx={{ fontWeight: 600, fontSize: "28px", lineHeight: "34px", p: 3 }}
        >
          {t("Dashboard")}
        </Typography>

        <Container>
          <LeftPanel />
          <RightPanel
            averageProfit={data?.averageProfit}
            averageDuration={data?.averageDuration}
            averageTurnover={data?.averageTurnover}
            averageProfitPerLocation={data?.averageProfitPerLocation}
          />
        </Container>
      </ContentContainer>
    </Box>
  );
};
