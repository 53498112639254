import React, { useCallback, } from "react";
import { Button, Menu, TextareaAutosize } from "@mui/material";
import { TypographyAlertText } from "components/styles/TypographyCardTitle";
import { Trans } from "react-i18next";
import RewardDetailsItem from "../RewardDetailsItem";
import { Box } from "@mui/system";
import { gap, CONTENT_SPACING_GAP, border, borderRadius } from "components/styles/constants.styles";
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import styled from "styled-components";

interface Props {
    title: string,
    placeholder: string,
    getValueByKey: (key: string) => any;
    valyeKey: string;
}

const TEMPLATE_TAGS_OPTIONS = [
    {
        text: 'Selected operators',
        value: 'selected_operators'
    },
    {
        text: 'Selected locations',
        value: 'selected_locations'
    },
    {
        text: 'Reward name',
        value: 'reward_name'
    },
    {
        text: 'Reward quantity',
        value: 'reward_quantity'
    },
    {
        text: 'Reward availability start',
        value: 'reward_availability_start'
    },
    {
        text: 'Reward availability end',
        value: 'reward_availability_end'
    },
    {
        text: 'Reward expiration date',
        value: 'reward_expiration_date'
    },
    {
        text: 'Nr. days until reward expires',
        value: 'nr_days_until_reward_expires'
    },
    {
        text: 'Reward value',
        value: 'reward_value'
    },
    {
        text: 'Reward activation code',
        value: 'reward_activation_code'
    },
];

const ButtonContent = styled(Box)`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${CONTENT_SPACING_GAP};
    grid-row-gap: ${CONTENT_SPACING_GAP};
    margin-top: ${CONTENT_SPACING_GAP};
`;
const ReminderSMSText = ({
    title,
    placeholder,
    getValueByKey,
    valyeKey
}: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selection, setSelection] = React.useState<number[]>([]);
    const open = Boolean(anchorEl);
    const textRef = React.useRef() as React.Ref<HTMLTextAreaElement>;
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [activationMessage, setActivationMessage] = getValueByKey(valyeKey);

    const handleSelectionChange = (e: any) => {
        setSelection([e.target.selectionStart, e.target.selectionEnd]);
    };

    const handleNameChange = useCallback((e: any) => {
        setActivationMessage({
            ...activationMessage,
            text: e.target.value
        });

        handleSelectionChange(e);
    }, [activationMessage]);

    const handleTemplateTagClick = (value: string) => {
        if (!activationMessage.text) {
            setActivationMessage({
                ...activationMessage,
                text: `{{${value}}}`,
            });
        } else {
            const nextText = `${activationMessage.text.substring(0, selection[0])} {{${value}}} ${activationMessage.text.substring(selection[1], activationMessage.text.length)}`;
            setActivationMessage({
                ...activationMessage,
                text: nextText,
            });
        }

        handleClose();
    };

    return (
        <RewardDetailsItem>
            <TypographyAlertText>
                <Trans>{title}</Trans>
            </TypographyAlertText>

            <Box sx={{ marginRight: '34px', marginTop: '10px', display: 'flex', gap }}>
                <TextareaAutosize
                    value={activationMessage.text}
                    onChange={handleNameChange}
                    onClick={handleSelectionChange}
                    minRows={10}
                    placeholder={placeholder}
                    style={{ width: '100%', padding: CONTENT_SPACING_GAP, border, borderRadius }}
                    ref={textRef}
                />
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    variant="contained"
                    color="primary"
                    sx={{ minWidth: '46px', height: '42px' }}
                    size="small"
                >
                    <DataObjectOutlinedIcon />
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Box sx={{ m: gap }}>
                        <Box>Click any of the dynamic tags below to add them to the SMS text</Box>
                        <ButtonContent>
                            {TEMPLATE_TAGS_OPTIONS.map(({ text, value }) => (
                                <Button
                                    key={value}
                                    onClick={() => handleTemplateTagClick(value)}
                                    variant="contained"
                                    color="primary"
                                    sx={{ textTransform: 'capitalize' }}
                                >
                                    <Trans>{text}</Trans>
                                </Button>
                            ))}
                        </ButtonContent>
                    </Box>
                </Menu>
            </Box>
        </RewardDetailsItem>
    );
};

export default ReminderSMSText;