import { createTheme } from "@mui/material";
import { GREY_COLOR, MAIN_COLOR } from "./styles/constants.styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: MAIN_COLOR.NORMAL
    },
    info: {
      main: GREY_COLOR.LIGHT,
    },
    background: {
      default: "#f8f8f8",
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif"
  }
});
