import styled from "styled-components";
import { MAIN_COLOR } from "./constants.styles";

const NavBar = styled("div") <{ centered?: boolean; block?: boolean; }>`
  ${props => `
  display: ${props.block ? 'block' : 'flex'};;
  justify-content: ${props.centered ? 'center' : 'end'};
  align-items: center;
  background-color:  ${MAIN_COLOR.NORMAL};
  height: 63px;
  color: white;
  .profilePicture {
    border-radius: 25px;
    margin-left: 6px;
  }
  `}
`;

export default NavBar;
