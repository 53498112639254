import { Navigate, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { useJwt } from "react-jwt";
import { appUserDataSelector } from "./auth.selector";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "api/auth.service";
import { setUserDetails } from 'store/auth/auth.actions';

const RESTRICTION_ROUTE_BY_USER_TYPE: { [unit: string]: string; } = {
  AdminExpired: '/renew',
  AdminNewCustomer: '/subscribe'
};

export function RequireAuth({ children }: { children: JSX.Element; }) {
  const location = useLocation();
  const token = window.localStorage.getItem("token") || "";
  const { isExpired } = useJwt(token);
  const dispatch = useDispatch();
  const appUserData = useSelector(appUserDataSelector);

  useEffect(() => {
    if (!appUserData.userType) {
      getUserData().then(data => {
        dispatch(setUserDetails(data));
      });
    }
  }, [appUserData]);

  if (isExpired) {
    return <Navigate to={`/login?next=${location.pathname}`} state={{ from: location }} replace />;
  }

  const restrictionRoute = RESTRICTION_ROUTE_BY_USER_TYPE[appUserData.userType];
  if (restrictionRoute && location.pathname !== restrictionRoute) {
    return <Navigate to={restrictionRoute} replace />;
  }
  return children;
}
