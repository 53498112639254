import client from "./axios";

const url = "campaign-editor/Session";

export const getSessions = async (
  orderBy: string,
  page: number,
  pageSize?: number,
  startDate?: Date | null,
  endDate?: Date | null,
  operators?: string[],
  locations?: string[],
  campaign?: string[],
  slots?: string[]
) => {
  const body = {
    orderBy: orderBy,
    page: page,
    pageSize: pageSize,
    startDate: startDate,
    endDate: endDate,
    operators: !operators || operators[0] === "" ? [] : operators,
    locations: !locations || locations[0] === "" ? [] : locations,
    campaign: campaign,
    descending: true,
    isPagingEnabled: true,
    slots: [],
    campaigns: []
  };

  const res = await client.post(`${url}/list`, body);

  return await res.data;
};

export const downloadSessionsCSV = async (
  orderBy: string,
  startDate?: Date | null,
  endDate?: Date | null,
  operators?: string[],
  locations?: string[]
) => {
  const body = {
    orderBy: orderBy,
    startDate: startDate,
    endDate: endDate,
    operators: operators,
    locations: locations,
    slots: [],
    campaigns: []
  };

  const res = await client.post(`${url}/export`, body);

  return await res.data;
};
