import { borderRadius, boxShadow } from "components/styles/constants.styles";
import styled from "styled-components";

const SmallCard = styled.div`
  max-width: 300px;
  min-height: 80px;
  background-color: white;
  border-radius: ${borderRadius};
  padding: 8px 16px 8px 16px;
  box-shadow: ${boxShadow};
`;

export default SmallCard;
