import { FormControl, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { CONTENT_SPACING_GAP, gap } from "components/styles/constants.styles";
import styled from "styled-components";

export const TwoColumnsSpace = styled(Box)`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center;

    @media (max-width: 878px) {
        display: grid;
        grid-template-columns 240px ${gap} 240px;
    }

    @media (max-width: 600px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: ${gap};
        grid-row-gap: ${CONTENT_SPACING_GAP};
        grid-row-gap: 4px;
    }
`;

export const FormContainer = styled(Box)`
    display: flex;
    padding: 0 ${CONTENT_SPACING_GAP} ${CONTENT_SPACING_GAP};
    flex-flow: wrap;
    flex-direction: row;
    gap: ${CONTENT_SPACING_GAP};

    @media (max-width: 1118px) {
        display: grid;
        grid-template-columns: repeat(3, 240px);
        grid-column-gap: ${gap};
        grid-row-gap: ${CONTENT_SPACING_GAP};
    }

    @media (max-width: 878px) {
        display: grid;
        grid-template-columns: repeat(2, 240px);
        grid-column-gap: ${gap};
        grid-row-gap: ${CONTENT_SPACING_GAP};
    }

    @media (max-width: 600px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: ${gap};
        grid-row-gap: ${CONTENT_SPACING_GAP};
    }
`;

export const ProfitFormControl = styled(Box)`
`;

export const OperatorTextField = styled(Box)`

`;

export const SelectRangeForm = styled(Box)`
    @media (max-width: 1118px) {
        grid-column: 1 / 3;
    }

    @media (max-width: 600px) {
        grid-column: unset;
    }
`;