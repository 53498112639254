export const boxShadow = "0 4px 24px 0 rgb(34 41 47 / 10%)";
export const border = "1px solid #ebe9f1bd";
export const borderRadius = "6px";
export const gap = "28px";
export const CONTENT_SPACING_GAP = "16px";


export const ACCENT_LIGHT_COLOR = '#FFF';
export const ACCENT_DARK_COLOR = '#000';
export const ACCENT_DANGER_COLOR = '#ff0000';
export const ALERT_COLOR = '#ff9f43';

export const MAIN_COLOR = {
    EXTRA_EXTRA_LIGHT: '#f3f2f7',
    EXTRA_LIGTH: '#7367F0',
    NORMAL: '#574BD1',
    DARK: '#5E5873',
    EXTRA_DARK: '#3E31C2'
};

// red
export const DANGER_COLOR = {
    LIGHT: "#EA5455"
};

// green
export const SUCCESS_COLOR = {
    LIGHT: '#28C76F',
};

// grey
export const GREY_COLOR = {
    LIGHT_BLUE: '#F2F3F7',
    LIGHT: '#4B4B4B',
    EXTRA_DARK: '#161616'
};
