import React from "react";
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import ChartCard from "./ChartCard";
import moment from "moment";
import { Box } from "@mui/system";
import { ACCENT_DANGER_COLOR, CONTENT_SPACING_GAP, GREY_COLOR, MAIN_COLOR, SUCCESS_COLOR } from "components/styles/constants.styles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TypographyCardTitle, TypographySmallTitle } from "components/styles/TypographyCardTitle";
const DATA_MOCK = [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5];
const DEFAULT_NO_DATA = [
    {
        name: "This month",
        data: [0, 40, 28, 51, 42, 10, 100],
    },
    {
        name: "Last month",
        data: [11, 32, 45, 32, 34, 52, 41],
    }
];

const getSeries = (kpiList: any, kpiListLastMonth: any, renew?: boolean) => {
    if (renew) {
        return [
            {
                name: "This month",
                data: [...(kpiList || []), ...(kpiListLastMonth || [])],
            },
        ];
    }
    if (kpiList || kpiListLastMonth) {
        return [
            {
                name: "This month",
                data: kpiList === null ? [] : kpiList,
            },
            {
                name: "Last month",
                data: kpiListLastMonth === null ? [] : kpiListLastMonth,
            }
        ];
    }

    return renew ? [
        {
            name: 'Sales',
            data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5]
        }
    ] : DEFAULT_NO_DATA;
};

const getOptions = (dateFrom: Date | null, dateTo: Date | null, kpiList: any, kpiListLastMonth: any, chartOptions: any = {}) => {
    const maxLength = Math.max(kpiList?.length, kpiListLastMonth?.length);
    const options: ApexOptions = {
        chart: {
            zoom: {
                enabled: false,
            },
            toolbar: { show: false },

        },
        colors: [MAIN_COLOR.NORMAL, '#1E1E1E1F'],
        stroke: {
            curve: 'smooth',
            dashArray: [0, 5],
            width: [2, 2]
        },
        tooltip: {
            style: {
                fontSize: '12px', fontFamily: "Montserrat,sans-serif"
            },
            shared: false,
            x: {
                formatter: function (val: any, opts) {
                    const {
                        seriesIndex
                    } = opts;

                    const date = moment(dateFrom).subtract(seriesIndex * 30 - (val - 1), 'days').format('MMM Do');

                    return date;
                }
            },
            y: {
                formatter: function (val: any) {
                    return Math.abs(val) + "%";
                }
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#6E6B7B',
                    fontSize: '12px', fontFamily: "Montserrat, sans-serif"
                },
                formatter: function (val: any) {
                    return Math.abs(Math.round(Number(val))) + "%";
                }
            }
        },

        xaxis: {
            labels: {
                style: {
                    colors: '#6E6B7B',
                    fontSize: '12px', fontFamily: "Montserrat,sans-serif"
                },
                formatter: function (val: any, opts) {
                    if (maxLength <= 7) {
                        return moment(dateFrom).add(val - 1, 'days').format('MMM Do');
                    }
                    const nr = Number(val);
                    if ((nr - 1) % 7 === 0) {
                        return `w${(nr - 1) / 7 + 1}`;
                    }
                    return ' ';
                }
            }
        },
        legend: {
            fontSize: '12px',
            fontFamily: "Montserrat,sans-serif"
        },
        ...chartOptions,
    };

    return options;
};

type TotalProfitIncreaseDetailsProps = {
    total?: number;
    totalLastMonth?: number;
    increase?: number;
    estimated?: boolean;
};

const TotalProfitIncreaseDetails: React.FC<TotalProfitIncreaseDetailsProps> = ({ total, increase, totalLastMonth, estimated }) => {
    const { t } = useTranslation();
    return (
        <Box sx={{ display: "flex", gap: "40px", mx: CONTENT_SPACING_GAP }}>
            <Box>
                <TypographySmallTitle>{t(`This month`)}</TypographySmallTitle>
                <TypographyCardTitle
                    sx={{
                        color: estimated ? '#000' : MAIN_COLOR.EXTRA_LIGTH,
                        mb: "2px",
                        textDecoration: estimated ? 'line-through' : 'none',
                    }}>
                    {total?.toLocaleString()} {t(`LEI`)}
                </TypographyCardTitle>
                {increase ? (
                    <Typography
                        sx={{
                            color: increase > 0 ? SUCCESS_COLOR.LIGHT : ACCENT_DANGER_COLOR,
                            fontWeight: "700",
                            fontSize: "14px",
                            lineHeight: "23px",
                        }}>
                        {increase}%
                    </Typography>
                ) : (
                    <></>
                )}
            </Box>
            {totalLastMonth && !estimated ? (
                <Box>
                    <TypographySmallTitle>{t(`Last Month`)}</TypographySmallTitle>
                    <TypographyCardTitle
                        sx={{
                            color: GREY_COLOR.LIGHT,
                            mb: "2px"
                        }}
                    >
                        {totalLastMonth?.toLocaleString()} {t(`LEI`)}
                    </TypographyCardTitle>
                </Box>
            ) : (
                <></>
            )}
        </Box>
    );
};

type Props = {
    data?: any;
    dateFrom: Date | null,
    dateTo: Date | null;
};

export const TotalProfitIncreaseAdminExpiredCard = ({ data, dateFrom, dateTo }: Props) => {
    const { t } = useTranslation();
    const {
        kpiIncrease,
        kpiList,
        kpiTotal,
        kpiTotalLastMonth,
        kpiListEstimated,

    } = data || {};

    const total = kpiList?.length + kpiListEstimated?.length;

    const offset = Math.round((total - 1 - kpiListEstimated?.length) / (total - 1) * 100);

    const series = getSeries(kpiList, kpiListEstimated, true);
    const options = getOptions(dateFrom, dateTo, kpiList, kpiListEstimated, {
        stroke: {
            curve: 'smooth',
            width: [2, 2]
        },
        forecastDataPoints: {
            count: 7,
            fillOpacity: 1,
            strokeWidth: 4,
            dashArray: 0,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                shadeIntensity: 1,
                type: 'horizontal',
                opacityFrom: 1,
                opacityTo: 1,
                colorStops: [
                    [
                        {
                            offset,
                            color: '#7367F0',
                            opacity: 1
                        },
                        {
                            offset,
                            color: '#FF9F43',
                            opacity: 1
                        }
                    ],
                ]
            },
        },
    });

    return (
        <ChartCard title={t("Total Profit Increase")} noAvailableData={!data}>
            <TotalProfitIncreaseDetails
                total={kpiTotal}
                totalLastMonth={kpiTotalLastMonth}
                increase={kpiIncrease}
                estimated
            />
            <Chart id='chart_1'
                type="line"
                options={options}
                series={series}
                height={250}
            />
        </ChartCard>
    );
};

const TotalProfitIncreaseCard = ({ data, dateFrom, dateTo }: Props) => {
    const { t } = useTranslation();
    const {
        kpiIncrease,
        kpiList,
        kpiListLastMonth,
        kpiTotal,
        kpiTotalLastMonth
    } = data || {};

    const series = getSeries(kpiList, kpiListLastMonth);
    const options = getOptions(dateFrom, dateTo, kpiList, kpiListLastMonth);

    return (
        <ChartCard title={t("Total Profit Increase")} noAvailableData={!data}>
            <TotalProfitIncreaseDetails
                total={kpiTotal}
                totalLastMonth={kpiTotalLastMonth}
                increase={kpiIncrease}
            />
            <Chart id='chart_1'
                type="line"
                options={options}
                series={series}
                height={250}
            />
        </ChartCard>
    );
};

export default TotalProfitIncreaseCard;