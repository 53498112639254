import { NavBar } from "../components/NavBar";
import React, { useEffect, useMemo, useState, memo } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getDashboardDetails } from "api/campaign.service";
import { AllSessionsTable } from "components/AllSessionsTable";
import "../components/styles/DashboardStyles.css";
import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";
import { SelectRange } from "components/SelectRange";
import SelectOperator from "components/SelectOperator";
import { getLocations } from "api/operators.service";
import SelectLocations from "components/SelectLocations";
import { getOperators } from "api/operators.service";
import { findLocationsId } from "shared/constants";
import Checkbox from "@mui/material/Checkbox";
import ContentContainer from "components/styles/ContentContainer";
import RevenueReportCard from "./DashboardCards/RevenueReportCard";
import { ACCENT_LIGHT_COLOR, gap, MAIN_COLOR } from "components/styles/constants.styles";
import styled from "styled-components";
import CampaignConversionsCard from "./DashboardCards/CampaignConversionsCard";
import { AvgProfitPerSessionCard, AvgSessionDurationCard, TvOutlinedNumberCard } from "./DashboardCards/AvgCard";
import TotalProfitIncreaseCard from "./DashboardCards/TotalProfitIncreaseCard";
import RewardsContributionForProfitIncreaseCard from "./DashboardCards/RewardsContributionForProfitIncreaseCard";
import RevenueReportTimelineCard from "./DashboardCards/RevenueReportTimelineCard";
import BestPerformingLocationsCard from "./DashboardCards/BestPerformingLocationsCard";
import PlayersRetentionCard from "./DashboardCards/PlayersRetentionCard";
import BestPerformingOperatorsCard from "./DashboardCards/BestPerformingOperatorsCard";
import { ExperimentFindingsCard } from "./DashboardCards/ExperimentFindingsCard";
import ChartCard from "./DashboardCards/ChartCard";
import TopPerformingCampaigns from "./DashboardCards/TopPerformingCampaigns";
import { FormContainer, OperatorTextField, ProfitFormControl, SelectRangeForm } from "./SharedComponents/Dashboard";

const ChartsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: ${gap};
  grid-row-gap: ${gap};
  min-height: 420px;
  grid-template-areas:
    "a a a a a a  b b b  c c c"
    "a a a a a a  b b b  d d d"
    "a a a a a a  b b b  e e e"
    "f f f f  g g g g  h h h h"
    "i i i i i i  j j j j j j"
    "k k k k k k  l l l m m m";

  @media (max-width: 1280px) {
    grid-template-areas:
      "a a a a a a a a  b b b b"
      "a a a a a a a a  b b b b"
      "c c c c  d d d d  e e e e"
      "f f f f f f  g g g g g g"
      "h h h h h i  i i i i i i"
      "j j j j j j j j  l l l l"
      "k k k k k k k k  m m m m";
  }

  
  @media (max-width: 1028px) {
    grid-template-areas:
      "a a a a a a a a  b b b b"
      "a a a a a a a a  b b b b"
      "c c c c  d d d d  e e e e"
      "f f f f f f  g g g g g g"
      "i i i i i i i i i i i i"
      "h h h h h h  l l l l l l"
      "j j j j j j j j j j j j"
      "k k k k k k k k  m m m m";
  }

  @media (max-width: 928px) {
    grid-template-areas:
    "a a a a a a a a  a a a a"
    "b b b b b b  c c c c c c"
    "b b b b b b  d d d d d d"
    "b b b b b b  e e e e e e"
    "f f f f f f  g g g g g g"
    "i i i i i i i i i i i i"
    "h h h h h h  l l l l l l"
    "j j j j j j j j j j j j"
    "k k k k k k k k k k k k"
    "m m m m m m m m m m m m";
  }
  
  @media (max-width: 768px) {
    display: block
  }
`;

const ChartsGridContainerAvg = styled.div`
  display: grid;
  grid-row-columns: repeat(3, 1fr);
  grid-column-gap: ${gap};
  grid-row-gap: ${gap};
  @media (max-width: 768px) {
    display: block;
  }
`;


const ChartsTreeContainer = styled.div`
  grid-column: 1 / 3;
  grid-row: 2;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: ${gap};
  display: grid;

  @media (max-width: 768px) {
    grid-column: unset;
    grid-row: unset;
    display: block;
  }
`;

const TotalProfitIncreaseCardContainer = styled.div`
  grid-area: a;
`;

const CampaignConversionsCardContainer = styled.div`
grid-area: b;
`;
const AvgProfitPerSessionCardContainer = styled.div`
grid-area: c;
`;
const AvgSessionDurationCardContainer = styled.div`
grid-area: d;
`;
const TotalMachinesEnrolledCardContainer = styled.div`
grid-area: e;
`;
const RevenueReportCardContainer = styled.div`
grid-area: f;
`;
const RewardsContributionForProfitIncreaseCardContainer = styled.div`
grid-area: g;
`;
const ExperimentFindingsCardContainer = styled.div`
grid-area: h;
`;
const TopPerformingCampaignsContainer = styled.div`
grid-area: i;
`;
const RevenueReportTimelineCardContainer = styled.div`
grid-area: j;
`;
const BestPerformingLocationsCardContainer = styled.div`
grid-area: k;
`;
const PlayersRetentionCardContainer = styled.div`
grid-area: l;
`;
const BestPerformingOperatorsCardContainer = styled.div`
grid-area: m;
`;
const Dashboard: React.FC = () => {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  const mobileView = useMediaQuery("(min-width:549px)");
  const { t } = useTranslation();
  const [data, setData] = useState<any>(); //TO DO: Create interface
  const [range, setRange] = useState<DateRange<Date>>([
    new Date("2022-05-20"),
    new Date("2022-05-26")
  ]);
  const [kpi, setKpi] = useState("profitIncrease");
  const [operators, setOperators] = useState<{ id: string; name: string; }[]>(
    []
  );
  const [operator, setOperator] = useState<string>("");
  const [locations, setLocations] = useState<string[]>([]);
  const [location, setLocation] = useState<string>("");
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const locationIds = useMemo(() => {
    return findLocationsId(selectedLocations, locations);
  }, [selectedLocations, locations]);

  const handleChangeKpi = (event: SelectChangeEvent) => {
    setKpi(event.target.value);
  };
  const [mockData, setMockData] = useState<boolean>(true);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleMockData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMockData(event.target.checked);
  };

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const a = await getOperators();
        setOperators(a);
      } catch (error) {
        console.log(error);
      }
    };

    fetchOperators();
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchDetails = async () => {
      try {
        const dashboardData = await getDashboardDetails(
          mockData,
          "Newton",
          range[0],
          range[1],
          kpi,
          [operator || ""],
          locationIds
        );
        setData(dashboardData.data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setData(null);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [range, operator, kpi, locationIds.join(","), mockData]);

  useEffect(() => {
    const getLocationData = async () => {
      try {
        if (operator) {
          const locations = await getLocations([operator]);
          setSelectedLocations([]);
          setLocations(locations.data);
          setLocation("");
        }
      } catch (error) {
        setError(true);
      }
    };
    getLocationData();
  }, [operator]);

  const [dateFrom, dateTo] = range;

  const loadingContainerStyle = {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  };
  return (
    <>
      <NavBar activeTab="dashboard" />
      <ContentContainer>
        <Box sx={{ p: 4, ...loadingContainerStyle }}>
          <Typography sx={{ fontSize: 24, fontWeight: 500, mb: 3 }}>
            {t("Dashboard")}
          </Typography>
          <Box sx={{ mb: gap }}>
            <ChartCard>
              <FormContainer>
                <SelectRangeForm>
                  <SelectRange setRange={setRange} range={range} />
                </SelectRangeForm>
                <ProfitFormControl>
                  <FormControl sx={{ width: 240 }} size="small">
                    <InputLabel>{t("Profit")}</InputLabel>
                    <Select
                      value={kpi}
                      size="small"
                      sx={{ bgcolor: ACCENT_LIGHT_COLOR }}
                      onChange={handleChangeKpi}
                      label={t("Profit")}
                    >
                      <MenuItem value={"profitIncrease"}>Profit Increase</MenuItem>
                      <MenuItem value={"profitIncreasePerSession"}>
                        Profit Increase Per Session
                      </MenuItem>
                      <MenuItem value={"sessionDurationIncrease"}>
                        Session Duration Increase
                      </MenuItem>
                    </Select>
                  </FormControl>
                </ProfitFormControl>
                <OperatorTextField>
                  <SelectOperator setOperator={setOperator} operators={operators} />
                </OperatorTextField>
                <SelectLocations
                  locations={locations}
                  selectedLocations={selectedLocations}
                  setSelectedLocations={setSelectedLocations}
                />
                <Box>
                  <Checkbox checked={mockData} onChange={handleMockData} sx={{
                    "&.Mui-checked": {
                      color: MAIN_COLOR.EXTRA_LIGTH
                    }
                  }} />
                  Mock Data
                </Box>
              </FormContainer>
            </ChartCard>
          </Box>
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 12 }}>
              <CircularProgress size={120} />
            </Box>
          )}
          {!loading && (
            <>
              <ChartsGridContainer>
                <TotalProfitIncreaseCardContainer>
                  <TotalProfitIncreaseCard data={data?.kpi} dateFrom={dateFrom} dateTo={dateTo} />
                </TotalProfitIncreaseCardContainer>
                <CampaignConversionsCardContainer>
                  <CampaignConversionsCard
                    conversion={data?.conversion}
                  />
                </CampaignConversionsCardContainer>

                <AvgProfitPerSessionCardContainer>
                  <AvgProfitPerSessionCard
                    value={data?.averageProfit.value}
                    increase={data?.averageProfit.increase}
                  />
                </AvgProfitPerSessionCardContainer>
                <AvgSessionDurationCardContainer>
                  <AvgSessionDurationCard
                    value={data?.averageDuration.value}
                    increase={data?.averageDuration.increase}
                  />
                </AvgSessionDurationCardContainer>
                <TotalMachinesEnrolledCardContainer>
                  <TvOutlinedNumberCard
                    title="Total Machines enrolled"
                    value={data?.totalMachinesEnrolled.value}
                  />
                </TotalMachinesEnrolledCardContainer>

                <RevenueReportCardContainer>
                  <RevenueReportCard
                    revenueReports={data?.rewardsContribution.slice(0, 5)}
                    labels={["Spins", "Wheel", "Bonus", "SMS", "Raffle"]}
                  />
                </RevenueReportCardContainer>
                <RewardsContributionForProfitIncreaseCardContainer>
                  <RewardsContributionForProfitIncreaseCard
                    rewards={data?.rewardsContribution}
                  />
                </RewardsContributionForProfitIncreaseCardContainer>
                <ExperimentFindingsCardContainer>
                  <ExperimentFindingsCard aiFindings={data?.aiFindings} />
                </ExperimentFindingsCardContainer>
                <TopPerformingCampaignsContainer>
                  <TopPerformingCampaigns bestCampaigns={data?.bestCampaigns} />
                </TopPerformingCampaignsContainer>
                <RevenueReportTimelineCardContainer>
                  <RevenueReportTimelineCard
                    revenueReports={data?.revenueReports?.slice(0, 11)}
                    labels={[
                      "Jan",
                      "Feb",
                      "Mar",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec"
                    ]}
                  />
                </RevenueReportTimelineCardContainer>
                <BestPerformingLocationsCardContainer>
                  <BestPerformingLocationsCard
                    locationPerformances={data?.locationPerformances.slice(0, 7)}
                  />
                </BestPerformingLocationsCardContainer>
                <PlayersRetentionCardContainer>
                  <PlayersRetentionCard retention={data?.playersRetention} />
                </PlayersRetentionCardContainer>
                <BestPerformingOperatorsCardContainer>
                  <BestPerformingOperatorsCard operatorPerformances={data?.operatorPerformances.slice(0, 5)} />
                </BestPerformingOperatorsCardContainer>

              </ChartsGridContainer>

              <AllSessionsTable
                operator={[operator]}
                location={locationIds}
                startDate={range[0]}
                endDate={range[1]}
              />
            </>
          )}
        </Box>
      </ContentContainer>
    </>
  );
};

export default memo(Dashboard);
