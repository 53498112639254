import React, { useCallback, useState } from "react";
import { Box, FormControl, RadioGroup } from "@mui/material";
import { TypographyAlertText } from "components/styles/TypographyCardTitle";
import { Trans, useTranslation } from "react-i18next";
import TextRadioOption from "../TextRadioOption";
import RewardDetailsItem from "../RewardDetailsItem";
import { DateAndTimeLabel } from "./DaysAndTimeInputs";

interface Props {
    getValueByKey: (key: string) => any;
}

const RewardExpires = ({
    getValueByKey,
}: Props) => {
    const [expiry, setExpiry] = getValueByKey("availability.expiry");
    const [timeOfExpiry, setTimeOfExpiry] = getValueByKey("availability.timeOfExpiry");

    const handleValueChange = (values: { delay: string; time: string; }) => {
        setExpiry(values.delay);
        setTimeOfExpiry(values.time);
    };

    return (
        <RewardDetailsItem>
            <TypographyAlertText>
                <Trans>Reward expires</Trans>
            </TypographyAlertText>

            <FormControl>
                <Box sx={{ marginTop: '10px' }}>
                    <DateAndTimeLabel
                        onValueChange={handleValueChange}
                        value={{
                            delay: expiry,
                            time: timeOfExpiry
                        }}
                    />
                </Box>
            </FormControl>
        </RewardDetailsItem>
    );
};

export default RewardExpires;