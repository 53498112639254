import client from "./axios";

const url = "campaign-editor/Operator";

export const sortOperatorsAlphabetically = ((a: { id: string, name: string; locations?: { id: string; name: string; }[]; }, b: { id: string, name: string; locations?: { id: string; name: string; }[]; }) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
});

const operatorsDict: Record<string, string> = {};
const locationDict: Record<string, string> = {};
const slotsToLocations: Record<string, string> = {};
const locationToOperators: Record<string, string> = {};

export const createObject = (operators: any): any => {
  let operatorsObject = [];

  if (operators.length) {
    operatorsObject = operators.map((operator: any, index: number) => {
      operatorsDict[operator.id] = operator.name;
      return {
        label: operator.name,
        value: operator.id,
        children: operator.locations.map((location: any, index: number) => {
          locationDict[location.id] = location.name;
          locationToOperators[location.id] = operator.id;
          return {
            label: location.name,
            value: `${operator.id}/${location.id}`,
            children: location.slots.map((slot: any, index: number) => {
              slotsToLocations[slot.id] = location.id;
              return {
                label: slot.name,
                value: `${operator.id}/${location.id}/${slot.id}`
              };
            })
          };
        })
      };
    });
  }

  return operatorsObject;
};

export const getAllOperators = async () => {
  const res = await client.get(`${url}/get-all`);

  return await res;
};

export const getOperators = async () => {
  const res = await client.get(`${url}/list`);

  return await res.data.sort(sortOperatorsAlphabetically);
};

export const getLocations = async (operators?: string[]) => {
  const res = await client.post(`${url}/location/list`, { operators });

  return await res;
};

export const getBudgets = async (operatorId: string) => {
  const res = await client.get(`${url}/location/budgets?operatorId=${operatorId}`);

  return await res;
};

export const saveBudgetsV1 = async (
  operatorId: string,
  percentBack: number,
  locationBudgets: { id: string; name: string; budget: number; }[]
) => {
  const body = {
    operatorId: operatorId,
    percentBack: percentBack,
    locationBudgets: locationBudgets
  };

  const res = await client.post(`${url}/location/budgets/update`, body);

  return await res;
};


// reinveste profit percentage -> percentBack
// maximum reinvested amount per month -> dynamicBudgetLimit
// out-of-pocket investment -> guaranteedBudget

export const saveBudgets = async (
  operatorId: string,
  percentBack: number,
  locationBudgets: { id: string; name: string; dynamicBudgetLimit: number; }[]
) => {
  const body = {
    operatorId: operatorId,
    percentBack: percentBack,
    locationBudgets: locationBudgets
  };

  const res = await client.post(`${url}/location/budgets/update`, body);

  return await res;
};
