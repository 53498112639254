import { NavBar } from "../components/NavBar";
import React, { useEffect, useMemo, useState, memo } from "react";
import {
  Box,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getDashboardDetails } from "api/campaign.service";
import { ProfitIncrease } from "components/ProfitIncrease";
import AvgProfitIcon from "../assets/icons/avg-profit.svg";
import { AverageProfit } from "components/AverageProfit";
import { AverageDuration } from "components/AverageDuration";
import { RevenueReport } from "components/RevenueReport";
import { ExperimentFindings } from "components/ExperimentFindings";
import { OperatorsChart } from "components/OperatorsChart";
import { LocationsChart } from "components/LocationsChart";
import { AllSessionsTable } from "components/AllSessionsTable";
import "../components/styles/DashboardStyles.css";
import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";
import { SelectRange } from "components/SelectRange";
import SelectOperator from "components/SelectOperator";
import { getLocations } from "api/operators.service";
import SelectLocations from "components/SelectLocations";
import { getOperators } from "api/operators.service";
import { findLocationsId } from "shared/constants";
import Checkbox from "@mui/material/Checkbox";
import ContentContainer from "components/styles/ContentContainer";
import RevenueReportCard from "./DashboardCards/RevenueReportCard";
import { ACCENT_LIGHT_COLOR, gap, MAIN_COLOR } from "components/styles/constants.styles";
import styled from "styled-components";
import CampaignConversionsCard from "./DashboardCards/CampaignConversionsCard";
import { AvgProfitPerSessionCard, AvgSessionDurationCard, TvOutlinedNumberCard, AvgTurnoverCard, AvgProfitPerLocationCard } from "./DashboardCards/AvgCard";
import TotalProfitIncreaseCard from "./DashboardCards/TotalProfitIncreaseCard";
import RewardsContributionForProfitIncreaseCard from "./DashboardCards/RewardsContributionForProfitIncreaseCard";
import RevenueReportTimelineCard from "./DashboardCards/RevenueReportTimelineCard";
import BestPerformingLocationsCard from "./DashboardCards/BestPerformingLocationsCard";
import PlayersRetentionCard from "./DashboardCards/PlayersRetentionCard";
import BestPerformingOperatorsCard from "./DashboardCards/BestPerformingOperatorsCard";
import { ExperimentFindingsCard } from "./DashboardCards/ExperimentFindingsCard";
import ChartCard from "./DashboardCards/ChartCard";
import TopPerformingCampaigns from "./DashboardCards/TopPerformingCampaigns";
import { FormContainer, OperatorTextField, ProfitFormControl, SelectRangeForm, TwoColumnsSpace } from "./SharedComponents/Dashboard";

const ChartsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: ${gap};
  grid-row-gap: ${gap};
  min-height: 420px;
  grid-template-areas:
    "a a b c"
    "a a d e"
    "f g h h"
    "i i j j";

  @media (max-width: 1280px) {
    grid-template-areas: 
    "a a a a"
    "b b c c"
    "d d e e"
    "f f g g"
    "h h h h"
    "i i i i"
    "j j j j";
  }

  @media (max-width: 768px) {
    display: block
  }
`;

const CardContainer = styled.div<{ name: string; }>`
  grid-area: ${props => props.name};
`;

const Dashboard: React.FC = () => {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  const mobileView = useMediaQuery("(min-width:549px)");
  const { t } = useTranslation();
  const [data, setData] = useState<any>(); //TO DO: Create interface
  const [range, setRange] = useState<DateRange<Date>>([
    new Date("2023-08-20"),
    new Date("2023-08-26")
  ]);
  const [kpi, setKpi] = useState("profitIncrease");
  const [operators, setOperators] = useState<{ id: string; name: string; }[]>(
    []
  );
  const [operator, setOperator] = useState<string>("");
  const [locations, setLocations] = useState<string[]>([]);
  const [location, setLocation] = useState<string>("");
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const locationIds = useMemo(() => {
    return findLocationsId(selectedLocations, locations);
  }, [selectedLocations, locations]);

  const handleChangeKpi = (event: SelectChangeEvent) => {
    setKpi(event.target.value);
  };
  const [mockData, setMockData] = useState<boolean>(true);
  const [error, setError] = useState(false);

  const handleMockData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMockData(event.target.checked);
  };

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const a = await getOperators();
        setOperators(a);
      } catch (error) {
        console.log(error);
      }
    };

    fetchOperators();
  }, []);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const dashboardData = await getDashboardDetails(
          mockData,
          "Admin",
          range[0],
          range[1],
          kpi,
          [operator || ""],
          locationIds
        );
        setData(dashboardData.data);
      } catch (error) {
        setError(true);
        setData(null);
      }
    };
    fetchDetails();
  }, [range, operator, kpi, locationIds.join(","), mockData]);

  useEffect(() => {
    const getLocationData = async () => {
      try {
        if (operator) {
          const locations = await getLocations([operator]);
          setSelectedLocations([]);
          setLocations(locations.data);
          setLocation("");
        }
      } catch (error) {
        setError(true);
      }
    };
    getLocationData();
  }, [operator]);

  const [dateFrom, dateTo] = range;

  return (
    <>
      <NavBar activeTab="dashboard" />
      <ContentContainer>
        <Box sx={{ p: 4 }}>
          <Typography sx={{ fontSize: 24, fontWeight: 500, mb: 3 }}>
            {t("Dashboard")}
          </Typography>
          <Box sx={{ mb: gap }}>
            <ChartCard>
              <FormContainer>
                <SelectRangeForm>
                  <SelectRange setRange={setRange} range={range} />
                </SelectRangeForm>
                <Box sx={{ display: 'none' }}>
                  <OperatorTextField>
                    <SelectOperator setOperator={setOperator} operators={operators} />
                  </OperatorTextField>
                  <SelectLocations
                    locations={locations}
                    selectedLocations={selectedLocations}
                    setSelectedLocations={setSelectedLocations}
                  />
                  <Box>
                    <Checkbox checked={mockData} onChange={handleMockData} sx={{
                      "&.Mui-checked": {
                        color: MAIN_COLOR.EXTRA_LIGTH
                      }
                    }} />
                    Mock Data
                  </Box>
                </Box>
              </FormContainer>
            </ChartCard>
          </Box>
          <ChartsGridContainer>
            <CardContainer name="a">
              <TotalProfitIncreaseCard data={data?.kpi} dateFrom={dateFrom} dateTo={dateTo} />
            </CardContainer>

            <CardContainer name="b">
              <AvgProfitPerSessionCard
                value={data?.averageProfit.value}
                increase={data?.averageProfit.increase}
              />
            </CardContainer>
            <CardContainer name="c">
              <AvgSessionDurationCard
                value={data?.averageDuration.value}
                increase={data?.averageDuration.increase}
              />
            </CardContainer>

            <CardContainer name="d">
              <AvgTurnoverCard
                value={data?.averageTurnover.value}
                increase={data?.averageTurnover.increase}
              />
            </CardContainer>

            <CardContainer name="e">
              <AvgProfitPerLocationCard
                value={data?.averageProfitPerLocation.value}
                increase={data?.averageProfitPerLocation.increase}
              />
            </CardContainer>

            <CardContainer name="f">
              <RewardsContributionForProfitIncreaseCard
                rewards={data?.rewardsContribution}
              />
            </CardContainer>
            <CardContainer name="g">
              <PlayersRetentionCard retention={data?.playersRetention} />
            </CardContainer>

            <CardContainer name="h">
              <RevenueReportTimelineCard
                revenueReports={data?.revenueReports?.slice(0, 11)}
                labels={[
                  "Jan",
                  "Feb",
                  "Mar",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec"
                ]}
              />
            </CardContainer>

            <CardContainer name="i">
              <BestPerformingLocationsCard
                locationPerformances={data?.locationPerformances?.slice(0, 7)}
              />
            </CardContainer>

            <CardContainer name="j">
              <ExperimentFindingsCard aiFindings={data?.aiFindings} />
            </CardContainer>



          </ChartsGridContainer>
        </Box>
      </ContentContainer>
    </>
  );
};

export default memo(Dashboard);
