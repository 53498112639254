import { Box, Icon, Menu, MenuItem, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ArrowIcon from "../assets/icons/arrow-icon.svg";
import { ACCENT_DARK_COLOR, MAIN_COLOR } from "./styles/constants.styles";

interface Props {
  setSortBy: React.Dispatch<React.SetStateAction<string>>;
  sortBy: string;
}

export const SessionsSortMenu = ({ sortBy, setSortBy }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const sortSessionsArray = [
    { id: 0, text: t("Deposit"), sortBy: "deposit" },
    { id: 1, text: t("Win Amount"), sortBy: "winAmount" },
    { id: 2, text: t("Profit"), sortBy: "profit" },
    { id: 3, text: t("Play Time"), sortBy: "playTime" },
    { id: 4, text: t("Location"), sortBy: "location" },
    { id: 5, text: t("Date&Time"), sortBy: "dateTime" }
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{ cursor: "pointer", mr: 8, display: "flex", alignItems: "center" }}
        onClick={handleClick}
      >
        {t("Sort by")}{" "}
        {
          sortSessionsArray.find(
            (el: { id: number; text: string; sortBy: string; }) =>
              el.sortBy === sortBy
          )?.text
        }
        <Icon sx={{ mb: 1.6 }}>
          <img src={ArrowIcon} alt="search" />
        </Icon>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        onClick={() => setAnchorEl(null)}
        open={open}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              fontWeight: "bold",
              ml: 2,
              mt: 2,
              mb: 1
            }}
          >
            {t("Sort by")}
          </Box>
        </Box>
        <RadioGroup>
          {sortSessionsArray.map((el: any) => (
            <MenuItem
              sx={{
                width: 300,
                p: 0.2,
                display: "flex",
                alignItems: "center",
                cursor: "pointer"
              }}
              key={el.id}
              onClick={() => setSortBy(el.sortBy)}
            >
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: MAIN_COLOR.EXTRA_LIGTH
                  }
                }}
                size="small"
                value={el.sortBy}
                checked={el.sortBy === sortBy}
              />
              <Box
                sx={{
                  fontSize: 14,
                  color: el.sortBy === sortBy ? MAIN_COLOR.EXTRA_LIGTH : ACCENT_DARK_COLOR
                }}
              >
                {el.text}
              </Box>
            </MenuItem>
          ))}
        </RadioGroup>
      </Menu>
    </Box>
  );
};
