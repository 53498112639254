import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Column from "../components/styles/Column";
import LoginWrapper from "../components/styles/LoginWrapper";
import { useAppDispatch } from "../store/store";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserData, loginRequest } from "api/auth.service";
import { Token } from "Interfaces/IToken";
import { Avatar, Box, Checkbox, CssBaseline, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Copyright } from "@mui/icons-material";

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { setUserDetails } from "store/auth/auth.actions";


const RESTRICTION_ROUTE_BY_USER_TYPE: { [unit: string]: string; } = {
  Admin: '/dashboard/admin',
  AdminExpired: '/renew',
  AdminNewCustomer: '/subscribe'
};

const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // Newton, Admin, AdminExpired, AdminNewCustomer
  const [type, setType] = useState("Newton");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);

  const next = urlParams.get('next');

  const handleLogin = async (e: any) => {
    e.preventDefault();

    const token: Token = await loginRequest(username, password);

    if (token.success) {
      if (type !== "Newton") {
        localStorage.setItem("type", type);
      } else {
        localStorage.removeItem("type");
      }

      localStorage.setItem("token", token.data.access_token);
      localStorage.setItem("refreshToken", token.data.refresh_token);
      const restrictionRoute = RESTRICTION_ROUTE_BY_USER_TYPE[type];

      if (restrictionRoute) {
        navigate(restrictionRoute);
        return;
      }

      if (next) {
        navigate(next);
        return;
      }

      navigate("/campaigns");
    }
  };

  return (
    <LoginWrapper>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 2, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h4">
          MOMENTUM
        </Typography>
        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            placeholder="Email"
            autoComplete="email"
            onChange={(event) => setUsername(event.target.value)}
            autoFocus
            variant="outlined"
            sx={{ mt: 3, mb: '12px' }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            placeholder="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event) => setPassword(event.target.value)}
            variant="outlined"
            sx={{ mt: 0, mb: '12px' }}
          />
          <FormControl sx={{ mt: 0, mb: '34px' }} fullWidth>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <MenuItem value="Newton">Newton</MenuItem>
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="AdminExpired">Admin Expired</MenuItem>
              <MenuItem value="AdminNewCustomer">Admin New Customer</MenuItem>
            </Select>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 0, mb: 2 }}
            size="large"
          >
            Log In
          </Button>
        </Box>
      </Box>
    </LoginWrapper>
  );
};

export default Login;