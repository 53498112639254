import { Box } from "@mui/material";
import { CONTENT_SPACING_GAP, gap, MAIN_COLOR } from "components/styles/constants.styles";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import ChartCard from "./ChartCard";
import ChannelIcon from "components/styles/ChannelIcon";
import { TypographyDescription } from "components/styles/TypographyCardTitle";


const CardContent = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    padding: ${CONTENT_SPACING_GAP};
    grid-row-gap: ${CONTENT_SPACING_GAP};
    grid-column-gap: ${CONTENT_SPACING_GAP};
    text-align: center;
`;

const IconContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 46px 1fr;
    grid-column-gap: ${CONTENT_SPACING_GAP};
    align-items: center;
`;


const Line = styled.div`
    border-top: 1px solid ${MAIN_COLOR.EXTRA_LIGTH};    
    border-bottom: 1px solid ${MAIN_COLOR.EXTRA_LIGTH};    
    height: 4px;
    width: 100%;
    opacity: 0.2;
`;

interface Props {
    aiFindings: string[];
}

type ExperimentItem = {
    description: string;
    index: number;
};


const ExperimentItem: React.FC<ExperimentItem> = ({ description, index }) => {
    return (
        <Box>
            <IconContainer>
                <Line />
                <ChannelIcon size="46px">
                    {index + 1}
                </ChannelIcon>
                <Line />
            </IconContainer>
            <TypographyDescription sx={{ maxWidth: '220px', margin: "auto", marginTop: CONTENT_SPACING_GAP }}>{description}
            </TypographyDescription>
        </Box>
    );
};

const MOCK_AI_FINDINGS = [
    "Momentum campaigns converted 100 incognito users into permanent",
    "Momentum has figured out the best time to send an sms to the customers to ensure their return.",
    "Ai has increased the number of returning customers by 30%",
    "Sending an sms with reward 3 days after player has visited the operator has best chances of him coming again"
];

export const ExperimentFindingsCard = ({ aiFindings }: Props) => {
    const { t } = useTranslation();

    if (!aiFindings) {
        return (
            <ChartCard title={t("Experiment Findings")} noAvailableData={true}>
                <CardContent>
                    {MOCK_AI_FINDINGS.map((description, index) => {
                        return (
                            <ExperimentItem
                                key={description}
                                index={index}
                                description={description}
                            />
                        );
                    })}
                </CardContent>
            </ChartCard>
        );
    }

    return (
        <ChartCard title={t("Experiment Findings")}>
            <CardContent>
                {aiFindings.map((description, index) => {
                    return (
                        <ExperimentItem
                            key={description}
                            index={index}
                            description={description}
                        />
                    );
                })}
            </CardContent>
        </ChartCard >
    );
};
