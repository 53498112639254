import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from "react-i18next";
import { createTriggersPreview } from 'shared/constants';
import { Trigger } from 'Interfaces/ITriggers';
import { Alert } from '@mui/material';
import { getCampaignParams } from 'api/campaign.service';

const getTranslated = (triggersPreviewObject: any, trigger: any, rule: any) => {
    if (!triggersPreviewObject[trigger.name]?.[rule]) {
        return 'no-translation';
    }
    try {
        return triggersPreviewObject[trigger.name]?.[rule]?.(10);
    } catch {
        return 'returns-error';
    }
};


const getTranslatedSegment = (triggersPreviewObject: any, trigger: any, rule: any, segment: any) => {
    if (!triggersPreviewObject[trigger.name]?.[rule]) {
        return 'no-translation';
    }
    try {
        return triggersPreviewObject[trigger.name]?.[rule]?.(segment);
    } catch {
        return 'returns-error';
    }
};

const createRows = (triggers: Trigger[], triggersPreviewObject: any, t: any, lang: any) => {
    return triggers.reduce((acc: any, trigger: Trigger) => {
        let values: any[] = [];
        trigger.rules.forEach(rule => {
            trigger.segments.forEach(segment => {

                if (["udecimal", "uint", "int"].includes(segment)) {
                    if (values.length === 0) {
                        values = [
                            ...values,
                            {
                                name: trigger.name,
                                translatedName: t(trigger.name),
                                rule,
                                segment,
                                translated: getTranslated(triggersPreviewObject, trigger, rule),
                                rowSpan: values.length === 0 ? trigger.rules.length * trigger.segments.length : 1,
                            }
                        ];
                    } else {
                        values = [
                            ...values,
                            {
                                rule,
                                segment,
                                translated: getTranslated(triggersPreviewObject, trigger, rule),
                                rowSpan: values.length === 0 ? trigger.rules.length * trigger.segments.length : 1,
                            }
                        ];
                    }
                } else {
                    if (values.length === 0) {
                        values = [
                            ...values,
                            {
                                name: trigger.name,
                                translatedName: t(trigger.name, lang),
                                rule,
                                segment,
                                translated: getTranslatedSegment(triggersPreviewObject, trigger, rule, segment),
                                rowSpan: values.length === 0 ? trigger.rules.length * trigger.segments.length : 1,

                            }
                        ];
                    } else {
                        values = [
                            ...values,
                            {
                                rule,
                                segment,
                                translated: getTranslatedSegment(triggersPreviewObject, trigger, rule, segment),
                                rowSpan: values.length === 0 ? trigger.rules.length * trigger.segments.length : 1,
                            }
                        ];
                    }
                }
            });
        });

        return [
            ...acc,
            ...values
        ];
    }, []);
};

const BasicTable = () => {
    const { t } = useTranslation();
    const [triggers, setTriggers] = React.useState<Trigger[]>([]);

    React.useEffect(() => {
        const work = async () => {
            try {

                const params = await getCampaignParams();
                setTriggers(params.triggers);
            } catch (error) {
                console.warn(error);
            }
        };
        work();
    }, []);

    const triggersPreviewObjectEng = React.useMemo(() => createTriggersPreview("en", t), [t]);
    const triggersPreviewObjectRo = React.useMemo(() => createTriggersPreview("ro", t), [t]);

    const rowsEng = React.useMemo(() => createRows(triggers, triggersPreviewObjectEng, t, 'en'), [triggers, triggersPreviewObjectEng, createRows]);
    const rowsRo = React.useMemo(() => createRows(triggers, triggersPreviewObjectRo, t, 'ro'), [triggers, triggersPreviewObjectRo, createRows]);

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Rule</TableCell>
                            <TableCell>Segment</TableCell>
                            <TableCell>Preview</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsEng.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {row.name && (<TableCell component="th" scope="row" rowSpan={row.rowSpan}>
                                    {row.name}
                                </TableCell>)}
                                <TableCell>{row.rule}</TableCell>
                                <TableCell>{row.segment}</TableCell>
                                <TableCell>
                                    {row.translated === 'no-translation' && (
                                        <Alert severity="warning">
                                            No translation
                                        </Alert>
                                    )}

                                    {row.translated === 'returns-error' && (
                                        <Alert severity="error">
                                            Returns Error
                                        </Alert>
                                    )}

                                    {!['no-translation', 'returns-error'].includes(row.translated) && (
                                        <span>{row.translated}</span>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Rule</TableCell>
                            <TableCell>Segment</TableCell>
                            <TableCell>Preview</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsRo.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {row.name && (<TableCell component="th" scope="row" rowSpan={row.rowSpan}>
                                    {row.name}
                                </TableCell>)}
                                <TableCell>{row.rule}</TableCell>
                                <TableCell>{row.segment}</TableCell>
                                <TableCell>
                                    {row.translated === 'no-translation' && (
                                        <Alert severity="warning">
                                            No translation
                                        </Alert>
                                    )}

                                    {row.translated === 'returns-error' && (
                                        <Alert severity="error">
                                            Returns Error
                                        </Alert>
                                    )}

                                    {!['no-translation', 'returns-error'].includes(row.translated) && (
                                        <span>{row.translated}</span>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default React.memo(BasicTable);