import { put, takeLatest, all, debounce } from "redux-saga/effects";
import { setCampaigns, setSearchedCampaigns, setTriggersDetails } from "./campaigns.reducer";
import { Campaign } from "Interfaces/ICampaign";
import * as campaignService from "../../api/campaign.service";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_BY_NAME,
  GET_CAMPAIGNS_PARAMS,
  GET_SORTED_CAMPAIGNS
} from "./campaigns.constants";
import { Reward } from "Interfaces/IRewards";
import { Trigger } from "Interfaces/ITriggers";

function* handleGetCampaigns(
  action: PayloadAction<
    Partial<{
      page: number;
      pageSize: number;
      sortBy: string;
      descending: boolean;
      name: string;
      statuses: string[];
      channels: string[];
      totalPageNumber: number;
      operators: string[];
    }>
  >
) {
  try {
    const campaigns: Campaign = yield campaignService.getCampaigns(
      action.payload
    );

    yield put(
      setCampaigns({
        currentPageNumber: campaigns.currentPageNumber,
        pageSize: campaigns.pageSize,
        totalResults: campaigns.totalResults,
        data: campaigns.data,
        sortBy: action.payload.sortBy,
        descending: action.payload.descending,
        statuses: action.payload.statuses,
        channels: action.payload.channels,
        totalPageNumber: campaigns.totalPageNumber,
        name: action.payload.name
      })
    );
  } catch (error) {
    console.warn(error);
  }
}

function* handleSearchCampaigns(
  action: PayloadAction<{
    campaignName: string;
  }>
) {
  try {
    if (action.payload.campaignName === "") {
      return;
    }

    const campaigns: Campaign = yield campaignService.getCampaignsByName(
      action.payload.campaignName
    );

    yield put(
      setSearchedCampaigns({
        searchResult: campaigns,
        searchString: action.payload.campaignName
      })
    );
  } catch (error) {
    console.warn(error);
  }
}

type CampaignParams = {
  rewards: Reward[];
  triggers: Trigger[];
  dynamicValueSpecs: any;
  channels: string[];
};

function* handleGetCampaignParams() {
  try {
    const params: CampaignParams = yield campaignService.getCampaignParams();
    yield put(
      setTriggersDetails({
        rewards: params.rewards,
        allTriggers: params.triggers,
        loading: false,
        dynamicValueSpecs: params.dynamicValueSpecs,
        channels: params.channels,
      })
    );
  } catch (error) {
    console.warn(error);
  }
}

export default function* setCampaignsSaga() {
  yield all([
    takeLatest(GET_CAMPAIGNS, handleGetCampaigns),
    takeLatest(GET_SORTED_CAMPAIGNS, handleGetCampaigns),
    debounce(1000, GET_CAMPAIGNS_BY_NAME, handleSearchCampaigns),
    debounce(1000, GET_CAMPAIGNS_PARAMS, handleGetCampaignParams),
  ]);
}
