import { Box, Icon } from "@mui/material";
import { CustomActionsCell } from "components/CustomActionsCell";
import { Trans } from "react-i18next";
import { CustomAiPerformance } from "components/CustomAiPerformance";
import { CustomDetails } from "components/CustomDetails";
import { CustomPerformance } from "components/CustomPerformance";
import { CustomStatus } from "components/CustomStatus";
import React from "react";
import { calculateTime } from "./constants";
import DownloadIcon from "../assets/icons/download-icon.svg";
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';

import { ACCENT_DANGER_COLOR, ALERT_COLOR, MAIN_COLOR } from "components/styles/constants.styles";
import ChannelIcon from "components/styles/ChannelIcon";
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

export const createColumns = () => {
  return [
    {
      Header: " ",
      columns: [
        {
          Header: (
            <Box
              sx={{
                fontWeight: 600,
                textAlign: "center",
                ml: -4
              }}
            >
              #
            </Box>
          ),
          accessor: "id",
          Cell: ({ cell: { value } }: any) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: MAIN_COLOR.EXTRA_LIGTH,
                marginBottom: 6.8,
                fontWeight: 600
              }}
            >
              {value}
            </Box>
          )
        },
        {
          Header: <Trans>CAMPAIGN DETAILS</Trans>,
          accessor: "details",
          Cell: ({ cell: { value } }: any) => (
            <>
              {value ? (
                <CustomDetails
                  name={value.name}
                  startDate={value.startDate}
                  endDate={value.endDate}
                  numberOfDays={value.numberOfDays}
                  locations={value.locations}
                />
              ) : (
                <Trans>
                  No results yet,
                  <br /> check back later
                </Trans>
              )}
            </>
          )
        },
        {
          Header: <Trans>YOUR CAMPAIGN</Trans>,
          accessor: "performance",
          Cell: ({ cell: { value } }: any) => (
            <>
              {value ? (
                <CustomPerformance
                  conversion={value.conversionPercentage || null}
                  averageProfit={value.averageProfitPerSession || null}
                  reach={value.reach || null}
                  profit={value.profit || null}
                />
              ) : (
                <Trans>
                  No results yet,
                  <br /> check back later
                </Trans>
              )}
            </>
          )
        },
        {
          Header: <Trans>AI EXPERIMENTS</Trans>,
          accessor: "aiPerformance",
          Cell: ({ cell: { value } }: any) => (
            <>
              {value ? (
                <CustomAiPerformance
                  conversion={value.conversionPercentage}
                  averageProfit={value.averageProfitPerSession}
                />
              ) : (
                <Trans>
                  No results yet,
                  <br /> check back later
                </Trans>
              )}
            </>
          )
        },
        {
          Header: <Trans>CHANNEL</Trans>,
          accessor: "channel",
          Cell: ({ cell: { value } }: any) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <ChannelIcon type={value}>
                {value === 'Online' && <LanguageOutlinedIcon fontSize="small" />}
                {value === 'Lcd' && <TvOutlinedIcon fontSize="small" />}
                {value === 'Sms' && <MessageOutlinedIcon fontSize="small" />}
                {value === 'SmsManual' && <QuickreplyOutlinedIcon fontSize="small" />}
                {value === 'Voucher' && <ReceiptLongOutlinedIcon fontSize="small" />}
              </ChannelIcon>
              <Trans>{value}</Trans>
            </Box>
          )
        },
        {
          Header: <Trans>STATUS</Trans>,
          accessor: "status",
          Cell: ({ cell: { value } }: any) => (
            <>
              {value ? (
                <Box sx={{ minWidth: 100 }}>
                  <CustomStatus status={value} />
                </Box>
              ) : (
                <Trans>
                  No results yet,
                  <br /> check back later
                </Trans>
              )}
            </>
          )
        },
        {
          Header: <Trans>ACTIONS</Trans>,
          accessor: "actions",
          Cell: ({ cell: { value } }: any) => (
            <Box sx={{ minWidth: 100 }}>
              <CustomActionsCell campaignId={value} />
            </Box>
          )
        }
      ]
    }
  ];
};

export const createTabletColumns = () => {
  return [
    {
      Header: " ",
      columns: [
        {
          Header: (
            <Box
              sx={{
                fontWeight: 600,
                textAlign: "center",
                ml: -4
              }}
            >
              #
            </Box>
          ),
          accessor: "id",
          Cell: ({ cell: { value } }: any) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: MAIN_COLOR.EXTRA_LIGTH,
                marginBottom: 6.8,
                fontWeight: 600,
                minWidth: 80
              }}
            >
              {value}
            </Box>
          )
        },
        {
          Header: <Trans>CAMPAIGN DETAILS</Trans>,
          accessor: "details",
          Cell: ({ cell: { value } }: any) => (
            <>
              {value ? (
                <CustomDetails
                  name={value.name}
                  startDate={value.startDate}
                  endDate={value.endDate}
                  numberOfDays={value.numberOfDays}
                  locations={value.locations}
                />
              ) : (
                <Trans>
                  No results yet,
                  <br /> check back later
                </Trans>
              )}
            </>
          )
        },
        {
          Header: <Trans>STATUS</Trans>,
          accessor: "status",
          Cell: ({ cell: { value } }: any) => (
            <>
              {value ? (
                <Box sx={{ minWidth: 100 }}>
                  <CustomStatus status={value} />
                </Box>
              ) : (
                <Trans>
                  No results yet,
                  <br /> check back later
                </Trans>
              )}
            </>
          )
        },
        {
          Header: <Trans>ACTIONS</Trans>,
          accessor: "actions",
          Cell: ({ cell: { value } }: any) => (
            <CustomActionsCell campaignId={value} />
          )
        }
      ]
    }
  ];
};

export const createSessionsTable = (t: any) => {
  const parseDate = (date: Date) => {
    date = new Date(date);

    return `${date.getDay()}/${date.getMonth()}/${date.getFullYear()} ${date.getHours()}:${String(date.getMinutes()).length === 1
      ? `0${date.getMinutes()}`
      : `${date.getMinutes()}`
      }`;
  };

  return [
    {
      id: "id",
      label: <>#</>,
      minWidth: 70,
      cell: (value: number) => <Box>{value}</Box>
    },
    {
      id: "campaign",
      label: <>CAMPAIGN NAME</>,
      minWidth: 70,
      cell: (campaign: { name: string; }) => <Box>{campaign.name}</Box>
    },
    {
      id: "deposit",
      label: <Trans>DEPOSIT</Trans>,
      minWidth: 100,
      cell: (value: number) => (
        <Box>
          <Box> {value} LEI</Box>
        </Box>
      )
    },
    {
      id: "winAmount",
      label: t("WIN AMOUNT"),
      minWidth: 1,
      align: "left",
      cell: (value: number) => <Box>{value} LEI</Box>
    },
    {
      id: "profit",
      label: <Trans>PROFIT</Trans>,
      minWidth: 1,
      align: "left",
      cell: (value: number) => (
        <Box>
          <Box>{value} LEI</Box>
        </Box>
      )
    },
    {
      id: "playTime",
      label: <Trans>PLAY TIME</Trans>,
      minWidth: 1,
      align: "left",
      cell: (value: number) => <Box>{calculateTime(value)}</Box>
    },
    {
      id: "location",
      label: <Trans>LOCATION</Trans>,
      minWidth: 1,
      align: "left",
      cell: (value: any) => (
        <Box>{value ? value.name : "No location found"}</Box>
      )
    },
    {
      id: "dateTime",
      label: <Trans>DATE&TIME</Trans>,
      minWidth: 1,
      align: "left",
      cell: (value: Date) => <Box>{parseDate(value)}</Box>
    },
    {
      id: "download",
      label: <Trans>DOWNLOAD</Trans>,
      minWidth: 1,
      align: "left",
      cell: () => (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Icon sx={{ cursor: "pointer", mb: 1.5, mr: 4 }}>
            <img
              style={{ width: "20", height: 20 }}
              src={DownloadIcon}
              alt="donwload"
            />
          </Icon>
        </Box>
      )
    }
  ];
};

export const createSessionsTableMobile = (
  t: any
) => {
  const parseDate = (date: Date) => {
    date = new Date(date);

    return `${date.getDay()}/${date.getMonth()}/${date.getFullYear()} ${date.getHours()}:${String(date.getMinutes()).length === 1
      ? `0${date.getMinutes()}`
      : `${date.getMinutes()}`
      }`;
  };

  return [
    {
      id: "id",
      label: <>#</>,
      minWidth: 70,
      cell: (value: number) => <Box>{value}</Box>
    },
    {
      id: "deposit",
      label: <Trans>DEPOSIT</Trans>,
      minWidth: 100,
      cell: (value: number) => (
        <Box>
          <Box> {value} LEI</Box>
        </Box>
      )
    },
  ];
};

export const createSessionsDetailsTable = (
  t: any
) => {
  const parseDate = (date: Date) => {
    date = new Date(date);

    return `${date.getDay()}/${date.getMonth()}/${date.getFullYear()} ${date.getHours()}:${String(date.getMinutes()).length === 1
      ? `0${date.getMinutes()}`
      : `${date.getMinutes()}`
      }`;
  };

  return [
    {
      id: "id",
      label: <>#</>,
      minWidth: 70,
      cell: (value: number) => <Box>{value}</Box>
    },
    {
      id: "deposit",
      label: <Trans>DEPOSIT</Trans>,
      minWidth: 100,
      cell: (value: number) => (
        <Box>
          <Box> {value} LEI</Box>
        </Box>
      )
    },
    {
      id: "winAmount",
      label: t("WIN AMOUNT"),
      minWidth: 1,
      align: "left",
      cell: (value: number) => <Box>{value} LEI</Box>
    },
    {
      id: "profit",
      label: <Trans>PROFIT</Trans>,
      minWidth: 1,
      align: "left",
      cell: (value: number) => (
        <Box>
          <Box>{value} LEI</Box>
        </Box>
      )
    },
    {
      id: "playTime",
      label: <Trans>PLAY TIME</Trans>,
      minWidth: 1,
      align: "left",
      cell: (value: number) => <Box>{calculateTime(value)}</Box>
    },
    {
      id: "location",
      label: <Trans>LOCATION</Trans>,
      minWidth: 1,
      align: "left",
      cell: (value: any) => (
        <Box>{value ? value.name : "No location found"}</Box>
      )
    },
    {
      id: "dateTime",
      label: <Trans>DATE&TIME</Trans>,
      minWidth: 1,
      align: "left",
      cell: (value: Date) => <Box>{parseDate(value)}</Box>
    },
    {
      id: "download",
      label: <Trans>DOWNLOAD</Trans>,
      minWidth: 1,
      align: "left",
      cell: () => (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Icon sx={{ cursor: "pointer", mb: 1.5, mr: 4 }}>
            <img
              style={{ width: "20", height: 20 }}
              src={DownloadIcon}
              alt="donwload"
            />
          </Icon>
        </Box>
      )
    }
  ];
};

const NumberValue = ({ value }: { value: number; }) => {
  if (value < 30) {
    return (
      <Box
        sx={{
          color: ACCENT_DANGER_COLOR,
          fontWeight: "bold"
        }}
      >
        {value}
      </Box>
    );
  }

  if (value < 50) {
    return (
      <Box
        sx={{
          color: ALERT_COLOR,
          fontWeight: "bold"
        }}
      >
        {value}
      </Box>
    );
  }

  return <Box>{value}</Box>;
};

const FormattedNumber = ({ value }: { value: number; }) => {
  return <Box>{value.toLocaleString()}</Box>;
};

export const createAiPerformanceDetailsTable = (
  t: any
) => {
  return [
    {
      id: "operator",
      label: <Trans>OPERATOR</Trans>,
      minWidth: 100,
      cell: (value: number) => (
        <Box>
          <Box>{value}</Box>
        </Box>
      )
    },
    {
      id: "location",
      label: t("LOCATION"),
      minWidth: 1,
      align: "left",
      cell: (value: number) => <Box>{value}</Box>
    },
    {
      id: "precision",
      label: t("PRECISION"),
      minWidth: 1,
      align: "left",
      cell: (value: number) => <NumberValue value={value} />
    },
    {
      id: "recall",
      label: t("RECALL"),
      minWidth: 1,
      align: "left",
      cell: (value: number) => <NumberValue value={value} />
    },
    {
      id: "sessions",
      label: t("SESSIONS"),
      minWidth: 1,
      align: "left",
      cell: (value: number) => <FormattedNumber value={value} />
    },
    {
      id: "spins",
      label: <Trans>SPINS</Trans>,
      minWidth: 1,
      align: "left",
      cell: (value: number) => <FormattedNumber value={value} />
    },
  ];
};
