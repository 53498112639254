import styled from "styled-components";
import { MAIN_COLOR } from "components/styles/constants.styles";


interface ChannelIconProps {
  type?: string;
  size?: string;
}

// hex values for being able to use with rgb and rgba
const getColorByType = (type: string | undefined) => {
  if (type === 'Lcd') {
    return '239, 152, 47';
  }

  if (type === 'Sms') {
    return '255, 159, 67';
  }

  if (type === 'SmsManual') {
    return '40, 199, 111';
  }

  if (type === 'Voucher') {
    return '0, 207, 232';
  }

  return '115, 103, 240';
};

const ChannelIcon = styled.div<ChannelIconProps>`
  color: ${p => `rgb(${getColorByType(p.type)})`};
  background-color: ${p => `rgba(${getColorByType(p.type)}, 0.12)`}};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${p => p.size || '35px'};
  height: ${p => p.size || '35px'};
  margin-right: 12px;
  font-weight: 700;
`;

export default ChannelIcon;