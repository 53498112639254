import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import { Box, TextField } from "@mui/material";
import { TwoColumnsSpace } from "pages/SharedComponents/Dashboard";
import React from "react";
import { useTranslation } from "react-i18next";
import { gap } from "./styles/constants.styles";
import { ACCENT_LIGHT_COLOR } from "./styles/constants.styles";

interface Props {
  setRange: React.Dispatch<React.SetStateAction<DateRange<Date>>>;
  range: [null | Date, null | Date];
  minDate?: Date;
  maxDate?: Date;
}

export const SelectRange = ({
  setRange,
  range,
  minDate,
  maxDate
}: Props) => {
  const { t } = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ display: 'block' }}>
      <DateRangePicker
        value={range}
        disableHighlightToday
        minDate={minDate ? new Date(minDate) : null}
        maxDate={maxDate ? new Date(maxDate) : null}
        showDaysOutsideCurrentMonth
        inputFormat="dd/MM/yyyy"
        onChange={(newValue: any) => {
          setRange(newValue);
        }}
        renderInput={(startProps: any, endProps: any) => (
          <TwoColumnsSpace>
            <TextField
              {...startProps}
              label={t(`Start`)}
              size="small"
              sx={{ bgcolor: ACCENT_LIGHT_COLOR, width: 240 }}
            />
            <Box sx={{ textAlign: 'center', width: gap }}>{t(`to`)}</Box>
            <TextField
              {...endProps}
              label={t(`End`)}
              size="small"
              sx={{ bgcolor: ACCENT_LIGHT_COLOR, width: 240 }}
            />
          </TwoColumnsSpace>
        )}
      />
    </LocalizationProvider>
  );
};
