import { SelectedTrigger } from "Interfaces/ISelectedTrigger";
import { Trigger } from "Interfaces/ITriggers";
import { emptyCondition } from "pages/CreateCampaign";

export const calculateCampaignDuration = (
  startDate: Date | null,
  endDate: Date | null
): number => {
  if (startDate === null || endDate === null) {
    return -1;
  }

  const start = new Date(startDate);
  const end = new Date(endDate);

  return (start.getTime() - end.getTime()) / (1000 * 3600 * 24);
};

export const createTriggersArray = (
  behavioralTriggers: Trigger[],
  environmentalTriggers: Trigger[],
  timeTriggers: Trigger[],
  triggers: Trigger[]
): SelectedTrigger[] => {
  const newBehavioralConditions: SelectedTrigger[] = behavioralTriggers?.map(
    (trigger: any, index: number) => {
      return {
        name: trigger.name,
        rule: trigger.rule,
        segment: trigger.segments,
        type: trigger.type,
        id: index,
        trigger: triggers.find((tr: Trigger) => tr.name === trigger.name)
      };
    }
  ) || [];
  const newEnvironmentalConditions: SelectedTrigger[] =
    environmentalTriggers?.map((trigger: any, index: number) => {
      return {
        name: trigger.name,
        rule: trigger.rule,
        segment: trigger.segments,
        type: trigger.type,
        id: index,
        trigger: triggers.find((tr: Trigger) => tr.name === trigger.name)
      };
    });
  const newTimeConditions: SelectedTrigger[] = timeTriggers?.map(
    (trigger: any, index: number) => {
      return {
        name: trigger.name,
        rule: trigger.rule,
        segment: trigger.segments,
        type: trigger.type,
        id: index,
        trigger: triggers.find((tr: Trigger) => tr.name === trigger.name)
      };
    }
  );

  const allTriggerConditions = [
    ...newBehavioralConditions,
    ...newEnvironmentalConditions,
    ...newTimeConditions
  ];

  if (allTriggerConditions.length > 0)
    return [
      ...newBehavioralConditions,
      ...newEnvironmentalConditions,
      ...newTimeConditions
    ];

  return [emptyCondition];
};

export const createSelectedOperators = (
  slots: string[],
  locationToOperators: Record<string, string>,
  slotsToLocations: Record<string, string>
): string[] => {
  const selectedOperators: string[] = [];

  slots.forEach((slot: string) =>
    selectedOperators.push(
      `${locationToOperators[slotsToLocations[slot]]}/${slotsToLocations[slot]
      }/${slot}`
    )
  );

  return selectedOperators;
};
