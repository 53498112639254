import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware, { SagaMiddlewareOptions } from "redux-saga";

import { useDispatch } from "react-redux";
import rootSaga from "./rootSaga";
import rootReducer from "./rootReducer";

const initialState = {};

// Add any redux saga options needed
const reduxSagaMonitorOptions: SagaMiddlewareOptions = {};
const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

// sagaMiddleware: Makes redux-sagas work
const middlewares = [
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
  sagaMiddleware,
];

const store = configureStore({
  reducer: rootReducer,
  middleware: [...middlewares],
  preloadedState: initialState,
  devTools: process.env.NODE_ENV === "development",
});

sagaMiddleware
  .run(rootSaga)
  .toPromise()
  .catch((e) => {
    console.log(e.message, e.sagaStack);
  });

export type AppDispatch = typeof store.dispatch;
// use this hook to access the redux store's dispatch function
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
// use this to access the redux store ( for example in selectors )
export const { getState } = store;
