import { Divider, Drawer, IconButton, ListItem } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { appUserDataSelector } from "store/auth/auth.selector";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

interface Props {
  activeTab: string;
}

const MobileMenu = ({ activeTab }: Props) => {
  const [open, setOpen] = React.useState(false);
  const userData = useSelector(appUserDataSelector);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ mr: 5 }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerOpen}
        sx={{ ...(open && { display: "none" }) }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        sx={{
          width: "100%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 200
          }
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Box>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 2
          }}
        >
          <ListItem sx={{ pt: 0, pb: 0 }}>
            <Box sx={{ fontSize: 16, cursor: "pointer" }}>
              <Box sx={{ display: "flex", cursor: "pointer", alignItems: 'center', }}>
                <AccountCircleIcon
                  sx={{ fontSize: 24, mr: 1 }}
                />
                <Box sx={{ fontWeight: "400", fontSize: 14, fontStyle: "italic" }}><Trans>Hello</Trans>,{userData.username}</Box>
              </Box>
            </Box>
          </ListItem>
          <Divider />
          <ListItem>
            <Box
              sx={{
                fontSize: 16,
                cursor: "pointer",
                fontWeight: activeTab === "dashboard" ? 600 : 400
              }}
              component={Link}
              to={"/dashboard"}
              style={{
                textDecoration: "none",
                color: "inherit"
              }}
            >
              Dashboard
            </Box>
          </ListItem>
          {userData.userType === 'Newton' && (
            <>
              <ListItem>
                <Box
                  sx={{
                    fontSize: 16,
                    cursor: "pointer",
                    fontWeight: activeTab === "campaigns" ? 600 : 400
                  }}
                  component={Link}
                  to={"/campaigns"}
                  style={{
                    textDecoration: "none",
                    color: "inherit"
                  }}
                >
                  Campaigns
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  sx={{
                    fontSize: 16,
                    cursor: "pointer",
                    fontWeight: activeTab === "budgets" ? 600 : 400
                  }}
                  component={Link}
                  to={"/budgets"}
                  style={{
                    textDecoration: "none",
                    color: "inherit"
                  }}
                >
                  Budgets
                </Box>
              </ListItem>
            </>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileMenu;
