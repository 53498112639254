import { createAction } from "@reduxjs/toolkit";
import { APP_USERNAME, LOGIN, LOGOUT, RESET_PASSWORD } from "./auth.constants";

export const login = createAction<{ email: string; password: string; }>(LOGIN);
export const logout = createAction(LOGOUT);
export const resetPassword = createAction(RESET_PASSWORD);
export const setUserDetails = createAction<{
    username?: string;
    userType: string;
    profilePicture?: string;
}>(APP_USERNAME);
