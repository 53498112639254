import React from "react";
import ChartCard from "./ChartCard";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Chart from 'react-apexcharts';

import { MAIN_COLOR } from "components/styles/constants.styles";
import { splitInTheMiddleSpace } from "./utils";

interface Props {
    operatorPerformances: { id: string; name: string; value: number; }[];
}

const MOCK_SERIES = [{ name: 'Value', data: [12, 3, 56, 6] }];
const MOCK_CATEGORIES = ['Op 1', 'Op 2', 'Op 4', 'Op 5'];

const getSeries = (values: number[]) => {
    return [{ name: 'Value', data: values }];
};

const getOptions = (operators: (string | string[])[]) => {
    const options = {
        chart: {
            parentHeightOffset: 0,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                barHeight: '45%',
                borderRadius: [3],
            }
        },
        grid: {
            show: false,
        },
        colors: [MAIN_COLOR.NORMAL],
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: operators,
            style: {
                colors: '#6E6B7B',
            },
        },
        yaxis: {
            labels: {
                formatter: function (val: any) {
                    return `${val} LEI`;
                },
                style: {
                    colors: '#6E6B7B',
                    fontSize: '12px', fontFamily: "Montserrat,sans-serif"
                },
            }
        },
        tooltip: {
            style: {
                fontSize: '12px', fontFamily: "Montserrat,sans-serif"
            },
            shared: false,
        },
    };

    return options;
};

const getSortedValues = (operatorPerformances: { id: string; name: string; value: number; }[]) => {
    const sortedValue = operatorPerformances.sort((a, b) => {
        if (a.value > b.value) {
            return -1;
        }
        if (a.value < b.value) {
            return 1;
        }
        return 0;
    }).reduce((acc: { operators: (string | string[])[], values: number[]; }, item: { id: string; name: string; value: number; }) => {
        return {
            operators: [...acc.operators, splitInTheMiddleSpace(item.name, 8)],
            values: [...acc.values, item.value]
        };
    }, { operators: [], values: [] });

    return sortedValue;
};

const BestPerformingOperatorsCard = ({ operatorPerformances }: Props) => {
    const { t } = useTranslation();

    if (!operatorPerformances) {
        return (
            <ChartCard
                title={t("Best Performing Operators")}
                noAvailableData
            >
                <Chart
                    options={getOptions(MOCK_CATEGORIES)}
                    series={MOCK_SERIES}
                    type='bar'
                    height={380}
                />
            </ChartCard>
        );
    }

    const { operators, values } = getSortedValues(operatorPerformances);
    const series = getSeries(values);
    const options = getOptions(operators);

    return (
        <ChartCard
            title={t("Best Performing Operators")}
        >
            <Chart options={options} series={series} type='bar' height={380} />
        </ChartCard>
    );
};

export default BestPerformingOperatorsCard;