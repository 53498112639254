import { Alert, Box, } from "@mui/material";
import { TriggerContext } from "contexts/TriggerContext";
import { Condition } from "Interfaces/ICondition";
import React, { useCallback, useContext, useState } from "react";
import { CampaignBody } from "Interfaces/CampaignBody";
import { postCampaign, putCampaign } from "api/campaign.service";
import { TriggersPreview } from "./TriggersPreview";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { calculateCampaignDuration } from "shared/helpers";
import { createSchedulePreview } from "shared/constants";
import { Trans, useTranslation } from "react-i18next";
import { FlatButton, MainButton } from "./styles/MainButton.styled";
import { boxShadow, borderRadius } from "../components/styles/constants.styles";
import { isEmptyRewardValue } from "./utils";
import { isCampaignValidForSubmit } from "./campaignPreviewUtils";

interface Props {
  campaignName: string;
  campaignChannel: string;
  campaignTargets: string;
  campaignStartDate: Date;
  campaignEndDate: Date;
  campaignExperimentValue: number | null;
  campaignGroupValue: number | null;
  availableIn: string;
  smsText: string;
  seconds: number;
  campaignOperators: string[];
  campaignLocations: string[];
  campaignSlots: string[];
  operatorsText: string | undefined;
  action: string;
}

const createTriggers = (campaignConditions: Condition[]) => {
  const behavioralTriggers: any = [];
  const timeTriggers: any = [];
  const environmentalTriggers: any = [];

  campaignConditions.forEach((condition: Condition) => {
    if (condition.trigger.type === "Behavioral") {
      behavioralTriggers.push({
        type: "Behavioral",
        name: condition.trigger.name,
        segments: condition.segment,
        rule: condition.rule
      });
    } else if (condition.trigger.type === "Time") {
      timeTriggers.push({
        type: "Time",
        name: condition.trigger.name,
        segments: condition.segment,
        rule: condition.rule
      });
    } else if (condition.trigger.type === "Environmental") {
      environmentalTriggers.push({
        type: "Environmental",
        name: condition.trigger.name,
        segments: condition.segment,
        rule: condition.rule
      });
    }
  });

  return { behavioralTriggers, timeTriggers, environmentalTriggers };
};

export const CampaignPreview = ({
  campaignName,
  campaignChannel,
  campaignTargets,
  campaignStartDate,
  campaignEndDate,
  campaignGroupValue,
  campaignExperimentValue,
  availableIn,
  smsText,
  seconds,
  campaignOperators,
  campaignSlots,
  campaignLocations, // add to request when implemented,
  operatorsText,
  action,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [error, setError] = useState<string>();

  const {
    campaignConditions,
    rewardQuantity,
    campaignReward,
    secondRewardQuantity,
    reminder,
    cashOutRestriction,
    rewardPresentation,
    activationCondition,
    activationConditionValue,
    activationConditionTimeLimit,
    cashOutRestrictionValue,
    cashOutRestrictionTimeLimit,
    getValueByKey,
    numberOfVouchers,
    smsManualPhoneNumbers,
    status,
  } = useContext(TriggerContext);
  const campaignDuration: number = calculateCampaignDuration(
    campaignStartDate,
    campaignEndDate
  );

  const handleCampaignError = useCallback((error: any) => {
    if (!error.isAxiosError)
      return;

    setError(error.response.data.Code);
  }, []);

  const handleFinishLater = () => {
    const { behavioralTriggers, timeTriggers, environmentalTriggers } =
      createTriggers(campaignConditions);

    const campaignTarget = 100 - (Number(campaignGroupValue) + Number(campaignExperimentValue));

    const campaignControl = campaignGroupValue ? +campaignGroupValue : 0;
    const campaignExperiment = campaignExperimentValue
      ? +campaignExperimentValue
      : 0;

    const [smsCampaignDetails] = getValueByKey('allSMSDetails');

    const campaignBody: CampaignBody = {
      content: smsText,
      name: campaignName,
      status: "Draft",
      schedule: {
        startDate: campaignStartDate,
        endDate: campaignEndDate,
      },
      channel: campaignChannel,
      type: "Reward",
      reward: {
        kind: campaignReward === "" ? null : campaignReward,
        quantity: isEmptyRewardValue(secondRewardQuantity) ? null : secondRewardQuantity,
        value: isEmptyRewardValue(rewardQuantity) ? null : rewardQuantity,
        validity: 0,
        delay: seconds,
        activationCondition: {
          name: activationCondition,
          value: activationCondition === 'None' ? null : activationConditionValue,
          timeLimit: activationConditionTimeLimit
        },
        cashOutRestriction: {
          name: cashOutRestriction,
          value: cashOutRestriction === 'None' ? null : cashOutRestrictionValue,
          timeLimit: cashOutRestrictionTimeLimit
        },
        presentation: {
          type: rewardPresentation
        },
        ...smsCampaignDetails
      },
      voucherDetails: {
        numberOfVouchers
      },
      target: {
        allLocations: !campaignLocations || campaignLocations.length === 0,
        operators: campaignOperators,
        locations: campaignLocations,
        slots: campaignSlots
      },
      scenario: {
        behavioralTriggers: behavioralTriggers,
        timeTriggers: timeTriggers,
        environmentalTriggers: environmentalTriggers,
      },
      experimentSettings: {
        target: campaignTarget,
        control: campaignControl,
        experiment: campaignExperiment
      },
      smsManualDetails: {
        phoneNumbers: smsManualPhoneNumbers
      }
    };

    if (action === 'edit' && !!id) {
      putCampaign(campaignBody, id).then((res) => {
        navigate("/campaigns");
      }).catch(handleCampaignError);

      return;
    }

    postCampaign(campaignBody).then((res) => {
      navigate("/campaigns");
    }).catch(handleCampaignError);
  };

  const handleCreateCampaign = () => {
    const { behavioralTriggers, timeTriggers, environmentalTriggers } =
      createTriggers(campaignConditions);

    const campaignTarget = 100 - (Number(campaignGroupValue) + Number(campaignExperimentValue));


    const campaignControl = campaignGroupValue ? +campaignGroupValue : 0;
    const campaignExperiment = campaignExperimentValue
      ? +campaignExperimentValue
      : 0;

    const [smsCampaignDetails] = getValueByKey('allSMSDetails');

    const campaignBody: CampaignBody = {
      content: smsText,
      name: campaignName,
      status: ['Voucher', 'SmsManual'].includes(campaignChannel) ? "Manual" : "Scheduled",
      schedule: {
        startDate: campaignStartDate,
        endDate: campaignEndDate,
      },
      channel: campaignChannel,
      type: "Reward",
      reward: {
        kind: campaignReward,
        quantity: isEmptyRewardValue(secondRewardQuantity) ? null : secondRewardQuantity,
        value: rewardQuantity,
        validity: 0,
        delay: seconds,
        activationCondition: {
          name: activationCondition,
          value: activationCondition === 'None' ? null : activationConditionValue,
          timeLimit: activationConditionTimeLimit
        },
        cashOutRestriction: {
          name: cashOutRestriction,
          value: cashOutRestriction === 'None' ? null : cashOutRestrictionValue,
          timeLimit: cashOutRestrictionTimeLimit
        },
        presentation: {
          type: rewardPresentation
        },
        ...smsCampaignDetails
      },
      voucherDetails: {
        numberOfVouchers
      },
      target: {
        allLocations: !campaignLocations || campaignLocations.length === 0,
        operators: campaignOperators,
        locations: campaignLocations,
        slots: campaignSlots,
      },
      scenario: {
        behavioralTriggers: behavioralTriggers,
        timeTriggers: timeTriggers,
        environmentalTriggers: environmentalTriggers,
      },
      experimentSettings: {
        target: campaignTarget,
        control: campaignControl,
        experiment: campaignExperiment,
      },
      smsManualDetails: {
        phoneNumbers: smsManualPhoneNumbers
      }
    };

    if (action === 'edit' && !!id) {
      putCampaign(campaignBody, id).then((res) => {
        navigate("/campaigns");
      }).catch(handleCampaignError);

      return;
    }

    postCampaign(campaignBody).then((res) => {
      navigate("/campaigns");
    }).catch(handleCampaignError);
  };

  return (
    <Box
      sx={{
        bgcolor: "#FFFFFF",
        width: "100%",
        height: 800,
        mr: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        mt: 1,
        p: 2,
        boxShadow,
        borderRadius,
        mb: "24px"
      }}
    >
      <Box>
        <Box sx={{ fontWeight: "bold", fontSize: 15 }}>
          <Trans>Campaign Preview</Trans>
        </Box>
        {!campaignTargets &&
          !campaignName &&
          !campaignChannel &&
          !campaignDuration &&
          !campaignGroupValue &&
          !operatorsText &&
          campaignConditions[0].trigger.name === "" ? (
          <Box sx={{ mt: 2 }}>
            <Trans>
              There are no characteristics set up yet. <br />
              Start by choosing the name and the right channel
            </Trans>
            .
          </Box>
        ) : (
          <Box>
            {campaignName ? (
              <Box sx={{ fontWeight: "bold", fontSize: 14 }}>
                <Trans>Campaign Name</Trans> <br />
                <Box sx={{ fontWeight: "normal", fontSize: 14 }}>
                  {campaignName}
                </Box>
              </Box>
            ) : (
              <></>
            )}
            {campaignChannel ? (
              <Box sx={{ fontWeight: "bold", fontSize: 14, mt: 1 }}>
                <Trans>Channel</Trans> <br />
                <Box sx={{ fontWeight: "normal", fontSize: 14 }}>
                  {campaignChannel}
                </Box>
              </Box>
            ) : (
              <></>
            )}

            {(operatorsText || campaignTargets === "All Locations") && (
              <Box sx={{ fontWeight: "bold", fontSize: 14, mt: 1 }}>
                <Trans>Campaign Targets</Trans> <br />
                <Box sx={{ fontWeight: "normal", fontSize: 14 }}>
                  {campaignTargets === "Custom Locations" ? (
                    <>{operatorsText}</>
                  ) : (
                    <>
                      {t(
                        "This campaign will target all operators and locations"
                      )}
                    </>
                  )}
                </Box>
              </Box>
            )}
            {campaignConditions[0]?.segment?.[0] !== "" && (
              <Box sx={{ fontWeight: "bold", fontSize: 14, mt: 1 }}>
                <Trans>Campaign Triggers</Trans> <br />
                <Box sx={{ fontWeight: "normal", fontSize: 14 }}>
                  <TriggersPreview
                    conditions={campaignConditions}
                    rewardQuantity={rewardQuantity}
                    reward={campaignReward}
                    secondRewardQuantity={secondRewardQuantity}
                    availableIn={availableIn}
                    rewardPresentation={rewardPresentation}
                    campaignChannel={campaignChannel}
                  />
                </Box>
              </Box>
            )}
            {campaignDuration && campaignStartDate && campaignEndDate ? (
              <Box sx={{ fontWeight: "bold", fontSize: 14, mt: 1 }}>
                {t("Campaign Schedule")}
                <br />
                <Box sx={{ fontWeight: "normal", fontSize: 14 }}>
                  {createSchedulePreview(campaignStartDate, campaignEndDate, t)}
                </Box>
              </Box>
            ) : (
              <></>
            )}
            {campaignGroupValue !== 0 ? (
              <Box sx={{ fontWeight: "bold", fontSize: 14, mt: 1 }}>
                <Trans>Campaign Experiments Settings</Trans> <br />
                <Box sx={{ fontWeight: "normal", fontSize: 14 }}>
                  {campaignSlots.length === 0 && t('CAMPAIGN_WILL_REACH_ALL', {
                    campaignGroupValue,
                    campaignExperimentValue,
                  })}
                  {campaignSlots.length !== 0 && t('CAMPAIGN_WILL_REACH', {
                    numberOfSlots: campaignSlots.length,
                    campaignGroupValue,
                    campaignExperimentValue,
                  })}
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        )}
      </Box>
      <Box>
        {error && (<Alert severity="error" sx={{ mb: 1 }}><Trans>{error}</Trans></Alert>)}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
            gap: "40px",
          }}
        >
          {status !== "Scheduled" && (
            <FlatButton onClick={handleFinishLater}>
              <Trans>Finish later</Trans>
            </FlatButton>
          )}
          <MainButton
            onClick={handleCreateCampaign}
            disabled={
              !isCampaignValidForSubmit({
                campaignName,
                campaignChannel,
                campaignTargets,
                campaignStartDate,
                campaignEndDate,
                campaignGroupValue,
                campaignExperimentValue,
                smsText,
                campaignConditions,
                rewardQuantity,
                campaignReward,
                secondRewardQuantity,
                cashOutRestriction,
                rewardPresentation,
                activationCondition,
                activationConditionValue,
                cashOutRestrictionValue,
                numberOfVouchers,
                smsManualPhoneNumbers,
                getValueByKey,
              })
            }
          >
            {status === "Scheduled" ? (
              <Trans>Update Campaign</Trans>
            ) : (
              <Trans>Create Campaign</Trans>
            )}
          </MainButton>
        </Box>
      </Box>
    </Box>
  );
};
