import styled from "styled-components";
import { MAIN_COLOR } from "./constants.styles";

const DeleteButton = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: none;
  background-color: #f8f8f8;
  box-shadow: 0;
  letter-spacing: 0.4px;
  margin-right: 32px;
  color: white;
  background-color: ${MAIN_COLOR.EXTRA_LIGTH};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat, sans-serif;
  cursor: pointer;
  .buttonText {
    color: ${MAIN_COLOR.NORMAL};
    size: 14px;
    line-height: 17px;
    font-weight: 500;
  }
  &:hover {
    box-shadow: none;
    background-color: ${MAIN_COLOR.NORMAL};
  }
  &:disabled {
    background-color: #acaeb3;
  }
`;

export default DeleteButton;
