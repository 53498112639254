import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import StyledPagination from "components/styles/Pagination.styled";
import { Trans, useTranslation } from "react-i18next";
import {
    createAiPerformanceDetailsTable,
} from "shared/createColumns";
import { TableSortLabel } from '@mui/material';
import { ACCENT_LIGHT_COLOR, MAIN_COLOR, gap } from "components/styles/constants.styles";
import { getAIPerformanceLocations } from "api/aiperformance.service";

interface Props {
    operator?: string[];
    location?: string[];
    startDate?: Date | null;
    endDate?: Date | null;
    campaignId?: string[];
}

const DetailsTable = ({
    operator,
    location,
    startDate,
    endDate,
}: Props) => {
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState("operator");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
    const [aiPerformance, setAiPerformance] = useState<{
        data: [];
        totalPageNumber: number;
        currentPageNumber: number;
    }>();
    const { t } = useTranslation();
    const columns: any = createAiPerformanceDetailsTable(t);

    useEffect(() => {
        const fetchAiPerformance = async () => {
            try {
                const aiPerformance = await getAIPerformanceLocations({
                    orderBy: sortBy,
                    page: page,
                    pageSize: 7,
                    startDate,
                    endDate,
                    operators: location?.length === 0 ? operator : [],
                    locations: location,
                    descending: sortDirection === 'desc',
                    isPagingEnabled: true
                });

                setAiPerformance(aiPerformance);
                setPage(aiPerformance.currentPageNumber);
            } catch (error) {
                console.log(error);
                setAiPerformance({
                    data: [],
                    totalPageNumber: 0,
                    currentPageNumber: 0,
                });
            }
        };

        fetchAiPerformance();
    }, [operator, location, page, sortBy, startDate, endDate, sortDirection]);

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value);
    };


    const handleTableSortClick = (column: any) => {
        const { id } = column;
        if (column.id !== sortBy) {
            setSortBy(id);
            setSortDirection('asc');
            return;
        }

        if (sortDirection === 'asc') {
            setSortDirection('desc');
            return;
        }

        setSortDirection('asc');
    };

    if (!aiPerformance) {
        return <></>;
    }
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    bgcolor: ACCENT_LIGHT_COLOR,
                    mt: gap
                }}
            >
                <Typography sx={{ p: 2 }}>
                    <Trans>Details</Trans>
                </Typography>
            </Box>
            <Paper sx={{ width: "100%" }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map(
                                    (column: {
                                        id: string;
                                        label: string;
                                        minWidth: number;
                                    }) => (
                                        <TableCell
                                            sx={{ p: 2, bgcolor: MAIN_COLOR.EXTRA_EXTRA_LIGHT }}
                                            key={column.id}
                                            align="left"
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            <TableSortLabel
                                                onClick={() => handleTableSortClick(column)}
                                                active={column.id === sortBy}
                                                direction={sortDirection}
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    )
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {aiPerformance.data?.length === 0 && (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                >
                                    <Box sx={{ p: 2 }}>
                                        <Trans>No data available</Trans>
                                    </Box>
                                </TableRow>
                            )}
                            {aiPerformance.data?.map((row: any, i: number) => (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={`${row.name}-${i}`}
                                >
                                    {columns.map(
                                        (column: {
                                            id: string;
                                            label: string;
                                            minWidth: number;
                                            cell: (value: any) => React.ReactFragment;
                                        }) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    sx={{ height: 80, p: 2 }}
                                                    key={column.id}
                                                    align="left"
                                                >
                                                    <Box>
                                                        {column.id === "id" ? (
                                                            <Box
                                                                sx={{
                                                                    color: MAIN_COLOR.EXTRA_LIGTH,
                                                                    fontWeight: "bold"
                                                                }}
                                                            >
                                                                {7 * page + i - 6}
                                                            </Box>
                                                        ) : (
                                                            <>{column.cell(value)}</>
                                                        )}
                                                    </Box>
                                                </TableCell>
                                            );
                                        }
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {aiPerformance.data?.length !== 0 && (
                <Box
                    sx={{ display: "flex", justifyContent: "end", bgcolor: ACCENT_LIGHT_COLOR }}
                >
                    <StyledPagination
                        sx={{ mt: 3, mb: 2 }}
                        count={aiPerformance.totalPageNumber}
                        defaultPage={page}
                        onChange={handlePageChange}
                        size="small"
                    />
                </Box>
            )}
        </>
    );
};

export default DetailsTable;