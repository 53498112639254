import {
  Box,
  Divider,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { NavBar } from "components/NavBar";
import SaveButton from "components/styles/DeleteButton";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContentContainer from "components/styles/ContentContainer";
import { gap, boxShadow, borderRadius, MAIN_COLOR, ACCENT_LIGHT_COLOR, CONTENT_SPACING_GAP } from "../components/styles/constants.styles";

import {
    getCampaignSettings, saveCampaignSettings
  } from "api/campaign.service";


export const Settings: React.FC = () => {
  const [percentBase, setPercentBase] = useState<number>();
  const [percentVariations, setPercentVariations] = useState<number>();
  const [percentAI, setPercentAI] = useState<number>();
  const [percentControlGroup, setPercentControlGroup] = useState<number>();

  const { t } = useTranslation();
  const tabletView = useMediaQuery("(min-width:1220px)");
  const mobileView = useMediaQuery("(min-width:630px)");


  const handleChangePercentBase = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (+event.target.value < 0) {
      return;
    }

    setPercentBase(+event.target.value);
  };

  const handleChangePercentVariations = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (+event.target.value < 0) {
      return;
    }

    setPercentVariations(+event.target.value);
  };

  const handleChangePercentAI = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (+event.target.value < 0) {
      return;
    }

    setPercentAI(+event.target.value);
  };

  const handleChangePercentControlGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (+event.target.value < 0) {
      return;
    }

    setPercentControlGroup(+event.target.value);
  };

  useEffect(() => {
    const fetchSettings = async () => {
        const percentages = await getCampaignSettings();
        
        setPercentBase(percentages.data.base)
        setPercentVariations(percentages.data.variations)
        setPercentAI(percentages.data.ml)
        setPercentControlGroup(percentages.data.control)
    }

    fetchSettings();
    
  }, []);

  const handleSave = () => {
    const base: number = percentBase!;
    const variations: number = percentVariations!;
    const ml: number = percentAI!;
    const controlGroup: number = percentControlGroup!;

    saveCampaignSettings(base, variations, ml, controlGroup);
  };


  return (
    <Box>
      <NavBar activeTab="settings" />
      <ContentContainer>
        <Box sx={{ p: 4, gap }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",

            }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: 24 }}>
              {t("Settings")}
            </Typography>
            <Box sx={{ width: 120, gap }}>
              <SaveButton onClick={handleSave}>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <SaveOutlinedIcon sx={{ width: 19 }} />
                  <Typography sx={{ fontSize: 15 }}>{t("Save")}</Typography>
                </Box>
              </SaveButton>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              heigth: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap,
              mt: 2,
            }}
          >
            <Box
              sx={{
                bgcolor: ACCENT_LIGHT_COLOR,
                flex: "1 1 0",
                heigth: "100%",
                paddingTop: 3,
                width: tabletView ? "" : "100%",
                boxShadow,
                borderRadius,
              }}
            >
              <Box sx={{ paddingLeft: 5, mb: 1 }}>
                <Typography sx={{ fontWeight: 700, fontSize: 17 }}>
                  {t("Campaign General Settings")}
                </Typography>
                <Typography sx={{ fontSize: 15 }}>
                  {t("Define how the rewards should be targeted among different campaign types")}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ maxWidth: '780px' }}>
                <Box
                  sx={{
                    px: 5,
                    paddingTop: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 4
                  }}
                >
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 164px 40px',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: 700, fontSize: 17 }}>
                        {t("Base campaigns")}
                      </Typography>
                      <Typography sx={{ fontSize: 13 }}>
                        {t(
                          "The percentage of rewards that will originate in base campaigns"
                        )}
                      </Typography>
                    </Box>
                    <TextField
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      size="small"
                      sx={{ width: 164 }}
                      value={percentBase || 0}
                      onChange={handleChangePercentBase}
                    />
                    <Typography sx={{ fontWeight: 400, textAlign: 'center' }}>%</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    px: 5,
                    paddingTop: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 4
                  }}
                >
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 164px 40px',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: 700, fontSize: 17 }}>
                        {t("Variations")}
                      </Typography>
                      <Typography sx={{ fontSize: 13 }}>
                        {t(
                          "The percentage of rewards that will originate in variations"
                        )}
                      </Typography>
                    </Box>
                    <TextField
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      size="small"
                      sx={{ width: 164 }}
                      value={percentVariations || 0}
                      onChange={handleChangePercentVariations}
                    />
                    <Typography sx={{ fontWeight: 400, textAlign: 'center' }}>%</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    px: 5,
                    paddingTop: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 4
                  }}
                >
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 164px 40px',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: 700, fontSize: 17 }}>
                        {t("AI generated")}
                      </Typography>
                      <Typography sx={{ fontSize: 13 }}>
                        {t(
                          "The percentage of rewards that will originate in campaigns generated in real time by AI"
                        )}
                      </Typography>
                    </Box>
                    <TextField
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      size="small"
                      sx={{ width: 164 }}
                      value={percentAI || 0}
                      onChange={handleChangePercentAI}
                    />
                    <Typography sx={{ fontWeight: 400, textAlign: 'center' }}>%</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    px: 5,
                    paddingTop: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 4
                  }}
                >
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 164px 40px',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: 700, fontSize: 17 }}>
                        {t("Control Group")}
                      </Typography>
                      <Typography sx={{ fontSize: 13 }}>
                        {t(
                          "The percentage of rewards that will represent the control group"
                        )}
                      </Typography>
                    </Box>
                    <TextField
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      size="small"
                      sx={{ width: 164 }}
                      value={percentControlGroup || 0}
                      onChange={handleChangePercentControlGroup}
                    />
                    <Typography sx={{ fontWeight: 400, textAlign: 'center' }}>%</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    px: 5,
                    paddingTop: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 4
                  }}
                ></Box>                    
                <Box
                  sx={{
                    px: 5,
                    paddingTop: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 4
                  }}
                ></Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </ContentContainer>
    </Box>
  );
};
