export const splitInTheMiddleSpace = (str: string, maxLength = 12) => {
    if (str.length < maxLength) {
        return str;
    }
    const parts = str.split(' ');
    const shouldTruncate = parts.findIndex(item => item.length > maxLength) > -1;

    if (shouldTruncate) {
        return truncateString(str);
    }

    if (parts.length <= 2) {
        return parts;
    }

    if (parts.length > 3) {
        const { first, second, third } = parts.reduce((acc: { first: string; second: string; third: string; }, item: string, index: number) => {
            if (index < parts.length / 3) {
                return {
                    ...acc,
                    first: `${acc.first} ${item}`,
                };
            }

            if (index >= parts.length / 3 && index < 2 * parts.length / 3) {
                return {
                    ...acc,
                    second: `${acc.second} ${item}`,
                };
            }

            return {
                ...acc,
                third: `${acc.third} ${item}`,
            };
        }, { first: '', second: '', third: '' });

        return [
            first, second, third
        ];
    }

    const { first, second } = parts.reduce((acc: { first: string; second: string; }, item: string, index: number) => {
        if (index < parts.length / 2) {
            return {
                ...acc,
                first: `${acc.first} ${item}`,
            };
        }

        return {
            ...acc,
            second: `${acc.second} ${item}`,
        };
    }, { first: '', second: '' });

    return [
        first, second
    ];
};

export const truncateString = (str: string, maxLength = 10) => {
    return `${str.slice(0, maxLength)}...`;
};