import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ProfitIncreaseChart } from "./ProfitIncreaseChart";
import { ACCENT_DANGER_COLOR, GREY_COLOR, MAIN_COLOR, SUCCESS_COLOR } from "./styles/constants.styles";

interface Props {
  profitIncrease: number[];
  profitIncreaseLastMonth?: number[];
  total?: number;
  totalLastMonth?: number;
  increase?: number;
  startDate: number;
}

const parseProfit = (
  profitIncrease: number[],
  startDate: number,
  profitIncreaseLastMonth?: number[]
) => {
  const data: { name: any; pi: number; percent: string; pl?: number; }[] = [];

  profitIncrease.forEach((profit, index) => {
    data.push({
      name: `w${index + 1}`,
      pi: profit,
      ...(profitIncreaseLastMonth && {
        pl: profitIncreaseLastMonth[index]
      }),
      percent: `${profit}%`
    });
  });

  return data;
};

export const ProfitIncrease = ({
  profitIncrease,
  profitIncreaseLastMonth,
  total,
  totalLastMonth,
  increase,
  startDate
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ p: 2 }}>
      <Typography
        sx={{ color: MAIN_COLOR.DARK, fontSize: 15, fontWeight: 500, mb: 1 }}
      >
        {t(`Total Profit Increase`)}
      </Typography>
      <Box sx={{ display: "flex", gap: 6 }}>
        <Box>
          <Typography sx={{ color: MAIN_COLOR.DARK, fontSize: 13, fontWeight: 400 }}>
            {t(`This month`)}
          </Typography>
          <Typography sx={{ fontSize: 28, fontWeight: 600, color: MAIN_COLOR.EXTRA_LIGTH }}>
            {total?.toLocaleString()} {t(`LEI`)}
          </Typography>
          {increase ? (
            <Typography sx={{ color: increase > 0 ? SUCCESS_COLOR.LIGHT : ACCENT_DANGER_COLOR }}>
              {increase}%
            </Typography>
          ) : (
            <></>
          )}
        </Box>
        {totalLastMonth ? (
          <Box>
            <Typography
              sx={{ color: MAIN_COLOR.DARK, fontSize: 13, fontWeight: 400 }}
            >
              {t(`Last Month`)}
            </Typography>
            <Typography
              sx={{ fontSize: 22, fontWeight: 600, color: GREY_COLOR.LIGHT }}
            >
              {totalLastMonth?.toLocaleString()} {t(`LEI`)}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>

      <ProfitIncreaseChart
        data={parseProfit(profitIncrease, startDate, profitIncreaseLastMonth)}
      />
    </Box>
  );
};
