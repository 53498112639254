import { createSlice } from "@reduxjs/toolkit";
import { Campaign } from "Interfaces/ICampaign";
import { Reward } from "Interfaces/IRewards";
import { Trigger } from "Interfaces/ITriggers";

export interface ICampaignsState {
  data: Campaign[];
  paginationDetails: {
    pageSize: number;
    totalResults: number;
    currentPageNumber: number;
    sortBy?: string;
    descending?: boolean;
    statuses?: string[];
    channels?: string[];
    totalPageNumber: number;
    name?: string;
  };
  searchedCampaigns: {
    searchResult: [];
    searchString: string;
  };
  campaignParams: {
    rewards: Reward[];
    allTriggers: Trigger[];
    loading: boolean;
    dynamicValueSpecs: any;
  };
}

const emptyReward = {
  type: "",
  name: "",
  requiredInputs: [],
};

const initialState: ICampaignsState = {
  data: [],
  searchedCampaigns: {
    searchResult: [],
    searchString: ""
  },
  paginationDetails: {
    currentPageNumber: 1,
    pageSize: 25,
    totalResults: 0,
    sortBy: "id",
    descending: true,
    statuses: [],
    channels: [],
    totalPageNumber: 1,
    name: ""
  },
  campaignParams: {
    rewards: [emptyReward],
    allTriggers: [],
    loading: true,
    dynamicValueSpecs: {}
  }
};

const campaignsSlice = createSlice({
  initialState,
  name: "campaigns",
  reducers: {
    setCampaigns: (state, action) => {
      state.data = action.payload.data;
      state.paginationDetails = {
        currentPageNumber: action.payload.currentPageNumber,
        pageSize: action.payload.pageSize,
        totalResults: action.payload.totalResults,
        sortBy: action.payload.sortBy || state.paginationDetails.sortBy,
        descending: !!action.payload.descending,
        statuses: action.payload.statuses,
        channels: action.payload.channels,
        totalPageNumber: action.payload.totalPageNumber,
        name: action.payload.name
      };

      state.searchedCampaigns = {
        searchResult: [],
        searchString: ""
      };
    },
    setSearchedCampaigns: (state, action) => {
      state.searchedCampaigns = {
        searchResult: action.payload.searchResult,
        searchString: action.payload.searchString
      };
    },
    setTriggersDetails: (state, action) => {
      state.campaignParams = {
        rewards: action.payload.rewards,
        allTriggers: action.payload.allTriggers,
        loading: action.payload.loading,
        dynamicValueSpecs: action.payload.dynamicValueSpecs,
      };
    }
  }
});

export const { setCampaigns, setSearchedCampaigns, setTriggersDetails } = campaignsSlice.actions;
export default campaignsSlice.reducer;
