import { Box } from "@mui/system";
import { NavBar } from "../components/NavBar";
import React, { useEffect, useMemo, useState } from "react";
import { CreateCampaignInput } from "components/styles/CreateCampaignInput";
import { CampaignPreview } from "components/CampaignPreview";
import { SelectChannel } from "components/SelectChannel";
import { SelectTargets } from "components/SelectTargets";
import { SelectTriggers } from "components/SelectTriggers";
import { SelectSchedule } from "components/SelectSchedule";
import { SelectExperimentSettings } from "components/SelectExperimentSettings";
import { TriggerContext } from "contexts/TriggerContext";
import { Trigger } from "Interfaces/ITriggers";
import { Trans, useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, useParams } from "react-router-dom";
import { CampaignDetailsInterface } from "Interfaces/ICampaignDetails";
import { createTriggersArray } from "shared/helpers";
import { getCurrentDate } from "shared/constants";
import { SelectedTrigger } from "Interfaces/ISelectedTrigger";
import ContentContainer from "components/styles/ContentContainer";
import { FixedDynamicValueType, FIXED_DYNAMIC_VALUE_DEFAULT } from "Interfaces/CampaignBody";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { campaignParamsSelector } from "store/campaigns/campaigns.selector";
import { getCampaignParams } from "store/campaigns/campaign.actions";

export const emptyTrigger: Trigger = {
  id: "",
  type: "",
  name: "",
  description: "",
  rules: [],
  segments: []
};

export const emptyCondition: SelectedTrigger = {
  trigger: emptyTrigger,
  rule: "",
  segment: [""]
};

interface Props {
  campaignDetails?: CampaignDetailsInterface;
  action: string;
}

export const CreateCampaign = ({
  campaignDetails,
  action,
}: Props) => {
  const { id: campaignId } = useParams();
  const [campaignName, setCampaingName] = useState(
    `New Campaign - ${getCurrentDate()}`
  );
  const [campaignChannel, setCampaignChannel] = useState("Online");
  const [campaignTargets, setTargets] = useState("All Locations");
  const [campaignConditions, setCampaignConditions] = useState<any>([
    { ...emptyCondition }
  ]);
  const [rewardQuantity, setRewardQuantity] = useState<FixedDynamicValueType>(FIXED_DYNAMIC_VALUE_DEFAULT);
  const [secondRewardQuantity, setSecondRewardQuantity] = useState<FixedDynamicValueType>(FIXED_DYNAMIC_VALUE_DEFAULT);
  const [campaignReward, setCampaignReward] = useState("");
  const [campaignStartDate, setCampaignStartDate] = useState<any>(null);
  const [campaignEndDate, setCampaignEndDate] = useState<any>(null);
  const [campaignStatus, setCampaignStatus] = useState<any>(null);
  const [campaignGroupValue, setCampaigntGroupValue] = useState(20);
  const [campaignExperimentValue, setCampaignExperimentValue] = useState(10);
  const [availableIn, setAvailableIn] = useState("immediately");
  const [smsText, setSmsText] = useState("");
  const [seconds, setSeconds] = useState(0);
  const [campaignOperators, setCampaignOperators] = useState<string[]>([]);
  const [campaignLocations, setCampaignLocations] = useState<string[]>([]);
  const [campaignSlots, setCampaignSlots] = useState<string[]>([]);
  const [operatorsText, setOperatorsText] = useState<string>();
  const tabletView = useMediaQuery("(max-width:1420px)");
  const [expanded, setExpanded] = useState<string | false>(false);
  const [reminder, setReminder] = useState<boolean>(false);
  const [rewardPresentation, onRewardPresentationNameChange] = useState<string>('Direct');
  const [activationCondition, onActivationConditionNameChange] = useState<string>('None');
  const [cashOutRestriction, onCashOutRestrictionNameChange] = useState<string>('None');

  const [activationConditionValue, onActivationConditionValueChange] = useState<number | FixedDynamicValueType>(FIXED_DYNAMIC_VALUE_DEFAULT);
  const [cashOutRestrictionValue, onCashOutRestrictionValueChange] = useState<number | FixedDynamicValueType>(FIXED_DYNAMIC_VALUE_DEFAULT);

  const [activationConditionTimeLimit, onActivationConditionTimeLimitChange] = useState<number>(0);
  const [cashOutRestrictionTimeLimit, onCashOutRestrictionTimeLimitChange] = useState<number>(0);

  const dispatch = useDispatch();
  const [timeOfActivation, setTimeOfActivation] = useState<string | null>(null);
  const [delay, setDelay] = useState<string | null>(null);

  const [expiry, setExpiry] = useState<string | null>(null);
  const [timeOfExpiry, setTimeOfExpiry] = useState<string | null>(null);

  const [numberOfVouchers, setNumberOfVouchers] = useState<number>(0);
  const [smsManualPhoneNumbers, setSmsManualPhoneNumbers] = useState<string[]>([]);

  const [activationMessage, setActivationMessage] = useState<any>({
    text: null,
    relativeTime: null,
    absoluteTime: null
  });

  const [reminderMessage, setReminderMessage] = useState<any>({
    text: null,
    relativeTime: null,
    absoluteTime: null
  });

  const [expiryMessage, setExpiryMessage] = useState<any>({
    text: null,
    relativeTime: null,
    absoluteTime: null
  });

  const { allTriggers } = useSelector(campaignParamsSelector);

  useEffect(() => {
    dispatch(getCampaignParams());
  }, []);

  const getValueByKey = (key: string) => {
    switch (key) {
      case 'availability.delay':
        return [delay, setDelay];
      case "availability.timeOfActivation":
        return [timeOfActivation, setTimeOfActivation];
      case "availability.expiry":
        return [expiry, setExpiry];
      case "availability.timeOfExpiry":
        return [timeOfExpiry, setTimeOfExpiry];
      case "smsDetails.activationMessage":
        return [activationMessage, setActivationMessage];
      case "smsDetails.reminderMessage":
        return [reminderMessage, setReminderMessage];
      case "smsDetails.expiryMessage":
        return [expiryMessage, setExpiryMessage];
      case "allSMSDetails": {
        return [{
          smsDetails: {
            activationMessage,
            reminderMessage,
            expiryMessage,
          },
          availability: {
            delay,
            timeOfActivation,
            expiry,
            timeOfExpiry,
          }
        }];
      }
      case 'rewardQuantity':
        return [rewardQuantity, setRewardQuantity];
      case 'secondRewardQuantity':
        return [secondRewardQuantity, setSecondRewardQuantity];
      case 'cashOutRestriction':
        return [cashOutRestrictionValue, onCashOutRestrictionValueChange];
      case 'activationCondition':
        return [activationConditionValue, onActivationConditionValueChange];
      case 'activationConditionTimeLimit':
        return [activationConditionTimeLimit, onActivationConditionTimeLimitChange];
      case 'cashOutRestrictionTimeLimit':
        return [cashOutRestrictionTimeLimit, onCashOutRestrictionTimeLimitChange];
      default:
        return [];
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaingName(event.target.value);
  };

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (!campaignDetails || !campaignId) {
      return;
    }

    if (action === 'edit' && !["Scheduled", "Draft"].includes(campaignDetails.status)) {
      navigate(`/${campaignId}/campaign-details`);
    }

    if (campaignDetails && allTriggers?.length > 0 && campaignId) {
      const {
        name,
        channel,
        content,
        target,
        scenario: {
          behavioralTriggers,
          environmentalTriggers,
          timeTriggers,
        },
        experimentSettings,
        voucherDetails
      } = campaignDetails;

      if (campaignDetails.target?.operators) {
        setCampaignOperators(campaignDetails.target?.operators);
      }

      setCampaingName(action === 'edit' ? name : `${name} (copy)`);
      setCampaignChannel(channel);

      if (channel === "SMS") {
        setSmsText(content || "");
      }

      if (voucherDetails) {
        setNumberOfVouchers(voucherDetails.numberOfVouchers);
      }

      if (target) {
        setCampaignOperators(target.operators);
        setCampaignLocations(target.locations);
        setCampaignSlots(target.slots === null ? [] : target.slots);
      }
      setCampaigntGroupValue(experimentSettings.control);
      setCampaignExperimentValue(experimentSettings.experiment);
      setCampaignConditions(
        createTriggersArray(
          behavioralTriggers,
          environmentalTriggers,
          timeTriggers,
          allTriggers
        )
      );
      setTargets(
        campaignDetails.target?.allLocations
          ? "All Locations"
          : "Custom Locations"
      );
      setCampaignReward(campaignDetails.reward.kind);

      if (campaignDetails.schedule?.startDate) {
        const todayDate = moment();
        const scheduleStartDate = moment(campaignDetails.schedule?.startDate);

        const dDiff = scheduleStartDate.diff(todayDate);

        if (dDiff > 0 || campaignDetails.status === "Scheduled") {
          setCampaignStartDate(campaignDetails.schedule?.startDate);
          setCampaignEndDate(campaignDetails.schedule?.endDate);
        }
      }

      if (campaignDetails?.reminder?.content === 'string' && channel === 'SMS') {
        setReminder(true);
      }

      if (campaignDetails.reward.value) {
        if (typeof campaignDetails.reward.value === 'number') {
          setRewardQuantity({
            ...FIXED_DYNAMIC_VALUE_DEFAULT,
            fixedValue: campaignDetails.reward.value
          });
        } else {
          setRewardQuantity(campaignDetails.reward.value);
        }
      }

      if (campaignDetails.reward.quantity) {
        if (typeof campaignDetails.reward.quantity === 'number') {
          setSecondRewardQuantity({
            ...FIXED_DYNAMIC_VALUE_DEFAULT,
            fixedValue: campaignDetails.reward.quantity
          });
        } else {
          setSecondRewardQuantity(campaignDetails.reward.quantity);
        }
      }

      if (campaignDetails.reward) {
        onRewardPresentationNameChange(campaignDetails.reward.presentation.type);
        onActivationConditionNameChange(campaignDetails.reward.activationCondition.name);
        onCashOutRestrictionNameChange(campaignDetails.reward.cashOutRestriction.name);

        if (campaignDetails.reward.activationCondition.value !== null) {
          onActivationConditionValueChange(campaignDetails.reward.activationCondition.value);
        }

        if (campaignDetails.reward.cashOutRestriction.timeLimit !== null) {
          onActivationConditionTimeLimitChange(campaignDetails.reward.activationCondition.timeLimit);
        }

        if (campaignDetails.reward.cashOutRestriction.value !== null) {
          onCashOutRestrictionValueChange(campaignDetails.reward.cashOutRestriction.value);
        }

        if (campaignDetails.reward.cashOutRestriction.timeLimit !== null) {
          onCashOutRestrictionTimeLimitChange(campaignDetails.reward.cashOutRestriction.timeLimit);
        }
      }

      if (campaignDetails.reward.delay) {
        setSeconds(campaignDetails.reward.delay);
      }

      if (campaignDetails.reward) {
        const { availability, smsDetails } = campaignDetails.reward;
        setDelay(availability.delay);
        setTimeOfActivation(availability.timeOfActivation);
        setExpiry(availability.expiry);
        setTimeOfExpiry(availability.timeOfExpiry);

        setActivationMessage(smsDetails.activationMessage);
        setReminderMessage(smsDetails.reminderMessage);
        setExpiryMessage(smsDetails.expiryMessage);
      }
    }
  }, [campaignDetails, allTriggers, campaignId]);

  const resetRewardQuantity = () => {
    setRewardQuantity(FIXED_DYNAMIC_VALUE_DEFAULT);
    setSecondRewardQuantity(FIXED_DYNAMIC_VALUE_DEFAULT);
  };

  const handleSetCampaignChannel = (value: string) => {
    setCampaignChannel(value);

    if (['Sms', 'SmsManual'].includes(value)) {
      if (activationCondition === 'EnterPhoneNumber') {
        onActivationConditionNameChange('None');
      }

      if (cashOutRestriction === 'EnterPhoneNumber') {
        onCashOutRestrictionNameChange('None');
      }
    }

    resetRewardQuantity();
    onActivationConditionValueChange(FIXED_DYNAMIC_VALUE_DEFAULT);
    onCashOutRestrictionValueChange(FIXED_DYNAMIC_VALUE_DEFAULT);
  };

  const handleCampaignRewardChange = (value: any) => {
    resetRewardQuantity();
    setCampaignReward(value);
  };

  const providerValue = {
    secondRewardQuantity,
    setSecondRewardQuantity,
    rewardQuantity,
    setRewardQuantity,
    campaignReward,
    setCampaignReward: handleCampaignRewardChange,
    campaignConditions,
    setCampaignConditions,
    cashOutRestriction,
    rewardPresentation,
    activationCondition,
    onCashOutRestrictionNameChange,
    onRewardPresentationNameChange,
    onActivationConditionNameChange,
    activationConditionValue,
    onActivationConditionValueChange,
    activationConditionTimeLimit,
    onActivationConditionTimeLimitChange,
    cashOutRestrictionValue,
    onCashOutRestrictionValueChange,
    cashOutRestrictionTimeLimit,
    onCashOutRestrictionTimeLimitChange,
    getValueByKey,
    numberOfVouchers,
    setNumberOfVouchers,
    smsManualPhoneNumbers,
    setSmsManualPhoneNumbers,
    status: action === 'edit' ? campaignDetails?.status : undefined,
  };

  return (
    <Box sx={{ backgroundColor: "#F8F8F8", height: "100vh" }}>
      <NavBar activeTab="campaigns" />
      <ContentContainer>
        <Box sx={{ fontWeight: 500, fontSize: 24, ml: 4, mt: 4 }}>
          <Trans>New Campaign</Trans>
        </Box>
        <Box sx={{ ml: 4, mt: 3, fontWeight: 600 }}>
          <Trans>Campaign Name</Trans>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: tabletView ? "column" : ""
          }}
        >
          <Box sx={{ width: tabletView ? "92%" : "60%", ml: 4, mr: "24px", mt: 1 }}>
            <CreateCampaignInput
              onChange={handleNameChange}
              autoFocus={true}
              value={campaignName}
              fullWidth
            />
            <SelectChannel
              setChannel={handleSetCampaignChannel}
              handlePanelChange={handleChange}
              expanded={expanded}
              channel={campaignChannel}
            />
            <SelectTargets
              setTargets={setTargets}
              handlePanelChange={handleChange}
              expanded={expanded}
              setOperators={setCampaignOperators}
              setLocations={setCampaignLocations}
              setSlots={setCampaignSlots}
              setOperatorsText={setOperatorsText}
              allLocations={campaignDetails?.target?.allLocations}
              campaignTargets={campaignTargets}
              operators={campaignOperators}
              locations={campaignLocations}
              slots={campaignSlots}
            />
            <TriggerContext.Provider value={providerValue}>
              <SelectTriggers
                availableIn={availableIn}
                setAvailableIn={setAvailableIn}
                handlePanelChange={handleChange}
                seconds={seconds}
                setSeconds={setSeconds}
                expanded={expanded}
                campaignDetails={campaignDetails}
                campaignChannel={campaignChannel}
                reminder={reminder}
                setReminder={setReminder}
              />
            </TriggerContext.Provider>
            <SelectExperimentSettings
              handlePanelChange={handleChange}
              expanded={expanded}
              groupValue={campaignGroupValue}
              setGroupValue={setCampaigntGroupValue}
              experimentValue={campaignExperimentValue}
              setExperimentValue={setCampaignExperimentValue}
              slots={campaignSlots}
            />
            <SelectSchedule
              handlePanelChange={handleChange}
              expanded={expanded}
              startDate={campaignStartDate}
              setStartDate={setCampaignStartDate}
              endDate={campaignEndDate}
              setEndDate={setCampaignEndDate}
              disabled={!['Online', 'Lcd', 'Sms'].includes(campaignChannel)}
              campaignChannel={campaignChannel}
            />
          </Box>
          <TriggerContext.Provider value={providerValue}>
            <Box
              sx={{
                width: tabletView ? "92%" : "40%",
                ml: tabletView ? 4 : 0,
                mr: 4
              }}
            >
              <CampaignPreview
                campaignName={campaignName}
                campaignChannel={campaignChannel}
                campaignTargets={campaignTargets}
                campaignStartDate={campaignStartDate}
                campaignEndDate={campaignEndDate}
                campaignGroupValue={campaignGroupValue}
                campaignExperimentValue={campaignExperimentValue}
                availableIn={availableIn}
                smsText={smsText}
                seconds={seconds}
                campaignOperators={campaignOperators}
                campaignLocations={campaignLocations}
                campaignSlots={campaignSlots}
                operatorsText={operatorsText}
                action={action}
              />
            </Box>
          </TriggerContext.Provider>
        </Box>
      </ContentContainer>
    </Box>
  );
};
