import { GifBoxSharp } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { MAIN_COLOR } from "./styles/constants.styles";
import "./styles/RetentionStyle.css";

interface Props {
  retention: {
    withdraw: number;
    rewardActivation: number;
    moneyLost: number;
    deposit: number;
  };
}

const PlayersRetention = ({ retention }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ p: 2.3 }}>
        <Typography sx={{ color: MAIN_COLOR.DARK, fontSize: 15, fontWeight: 500 }}>
          Players Retention
        </Typography>
        <Box className="parentDiv">
          <Box>
            <Typography sx={{ color: MAIN_COLOR.EXTRA_LIGTH, fontWeight: 700 }}>
              {retention.withdraw}
            </Typography>
          </Box>
          <Box>
            <Typography>
              {t("Players wanted to withdraw money but continued playing")}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ color: MAIN_COLOR.EXTRA_LIGTH, fontWeight: 700 }}>
              {retention.rewardActivation}
            </Typography>
          </Box>
          <Box>
            <Typography>
              {t(
                "Players came in and played becase of the rewards we’ve sent by sms"
              )}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ color: MAIN_COLOR.EXTRA_LIGTH, fontWeight: 700 }}>
              {retention.moneyLost}
            </Typography>
          </Box>
          <Box>
            <Typography>
              {t(
                "Users decided to keep playing even if they lost money before"
              )}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ color: MAIN_COLOR.EXTRA_LIGTH, fontWeight: 700 }}>
              {retention.deposit}
            </Typography>
          </Box>
          <Box>
            <Typography>
              {t(
                "Players who decided to deposit more money when having no money left in the balance"
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PlayersRetention;
