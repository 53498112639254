import { FormControl, FormControlLabel, InputLabel, MenuItem, Radio, Select, TextField } from "@mui/material";
import { MAIN_COLOR } from "components/styles/constants.styles";
import { TypographyDescription } from "components/styles/TypographyCardTitle";
import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

const CenteredContent = styled.div<{ size?: number; }>`
    display: grid;
    grid-template-columns: 20px 54px 94px ${props => props.size}px 54px 2px 54px;
    align-items: center;
    grid-column-gap: 8px;
`;

const CenteredDateContent = styled.div`
    display: grid;
    grid-template-columns: 54px 94px;
    align-items: center;
    grid-column-gap: 8px;
`;

const CenteredTimeContent = styled.div`
    display: grid;
    grid-template-columns: 54px 2px 54px;
    align-items: center;
    grid-column-gap: 8px;
`;

interface PropsDateAndTimeLabel {
    checked?: boolean,
    onValueChange: (event: any) => void;
    value: string;
}

const parseDate = (delay: string) => {
    if (delay === null) {
        return {
            type: 'Days',
            numberValue: 0,
        };
    }
    const [days, restDelay] = delay.split('.');

    if (!restDelay) {
        if (!delay.includes('.') && delay.includes(':')) {
            const [hours] = delay.split(':');

            return {
                type: 'Hours',
                numberValue: Number(hours) > 0 ? Number(hours) : 0,
            };
        }

        return {
            type: Number(days) > 0 ? 'Days' : 'Hours',
            numberValue: Number(days) > 0 ? Number(days) : 0,
        };
    }

    const [delayHours] = restDelay.split(':');

    return {
        type: Number(days) > 0 ? 'Days' : 'Hours',
        numberValue: Number(days) > 0 ? Number(days) : Number(delayHours),
    };
};

export const DateLabel: React.FC<PropsDateAndTimeLabel> = ({ onValueChange, value, checked = true }) => {
    const defaultValues = parseDate(value);

    const { t } = useTranslation();
    const [numberValue, setNumberValue] = useState<number>(defaultValues.numberValue);
    const [type, setType] = useState<string>(defaultValues.type);

    useEffect(() => {
        if (value !== `${numberValue}.00:00:00`) {
            const updatedValues = parseDate(value);
            setNumberValue(updatedValues.numberValue);
            setType(updatedValues.type);
        }
    }, [value]);

    useEffect(() => {
        if (type === 'Days') {
            onValueChange(`${numberValue}.00:00:00`);
            return;
        }
        onValueChange(`00.${numberValue}:00:00`);
    }, [numberValue, type]);

    return (
        <TypographyDescription>
            <CenteredDateContent>
                <TextField
                    onChange={(e) => setNumberValue(Number(e.target.value))}
                    value={checked ? numberValue : 0}
                    sx={{ width: 55 }}
                    size="small"
                    disabled={!checked}
                />
                <FormControl>
                    <Select
                        labelId="period-select-label"
                        value={checked ? type : "Days"}
                        onChange={(e) => setType(e.target.value)}
                        size="small"
                        disabled={!checked}
                    >
                        <MenuItem value="Days"><Trans>Days</Trans></MenuItem>
                        <MenuItem value="Hours"><Trans>Hours</Trans></MenuItem>
                    </Select>
                </FormControl>
            </CenteredDateContent>
        </TypographyDescription>
    );
};

const parseTime = (time: string) => {
    if (time === null) {
        return {
            hours: 0,
            minutes: 0,
        };
    }

    const [hours = 0, minutes = 0] = time.split(':');

    return {
        hours: Number(hours),
        minutes: Number(minutes)
    };
};

export const TimeLabel: React.FC<PropsDateAndTimeLabel> = ({ onValueChange, value, checked = true }) => {
    const defaultValues = parseTime(value);
    const [hours, setHours] = useState<number>(defaultValues.hours);
    const [minutes, setMinutes] = useState<number>(defaultValues.minutes);

    useEffect(() => {
        if (value !== `${hours}:${minutes}:00`) {
            const updatedValues = parseTime(value);
            setHours(updatedValues.hours);
            setMinutes(updatedValues.minutes);
        }
    }, [value]);

    useEffect(() => {
        onValueChange(`${hours}:${minutes}:00`);
    }, [hours, minutes]);

    return (
        <TypographyDescription>
            <CenteredTimeContent>
                <TextField
                    onChange={(e) => setHours(Number(e.target.value))}
                    value={checked ? hours : 0}
                    sx={{ width: 55 }}
                    size="small"
                    disabled={!checked}
                />
                <span>:</span>
                <TextField
                    onChange={(e) => setMinutes(Number(e.target.value))}
                    value={checked ? minutes : 0}
                    sx={{ width: 55 }}
                    size="small"
                    disabled={!checked}
                />
            </CenteredTimeContent>
        </TypographyDescription>
    );
};

export const parseDateTime = (delay: string, time: string) => {
    if (delay === null && time === null) {
        return {
            type: 'Days',
            numberValue: 0,
            hours: 0,
            minutes: 0,
        };
    }
    const [days, restDelay] = delay.split('.');

    if (!restDelay) {
        if (!delay.includes('.') && delay.includes(':')) {
            const [hours] = delay.split(':');

            return {
                type: 'Hours',
                numberValue: Number(hours) > 0 ? Number(hours) : 0,
                hours: 0,
                minutes: 0
            };
        }

        return {
            type: Number(days) > 0 ? 'Days' : 'Hours',
            numberValue: Number(days) > 0 ? Number(days) : 0,
            hours: 0,
            minutes: 0
        };
    }

    const [delayHours] = restDelay.split(':');
    const [hours = 0, minutes = 0] = time.split(':');

    return {
        type: Number(days) > 0 ? 'Days' : 'Hours',
        numberValue: Number(days) > 0 ? Number(days) : Number(delayHours),
        hours: Number(hours),
        minutes: Number(minutes)
    };
};

interface PropsDateAndTime {
    checked?: boolean,
    onValueChange: (event: any) => void;
    value: {
        delay: string;
        time: string;
    };
}

export const DateAndTimeLabel: React.FC<PropsDateAndTime> = ({ onValueChange, value, checked = true }) => {
    const { i18n } = useTranslation();
    const size = useMemo(() => i18n.language === 'ro' ? 40 : 20, [i18n.language]);

    const defaultValues = parseDateTime(value.delay, value.time);

    const [numberValue, setNumberValue] = useState<number>(defaultValues.numberValue);
    const [type, setType] = useState<string>(defaultValues.type);

    const [hours, setHours] = useState<number>(defaultValues.hours);
    const [minutes, setMinutes] = useState<number>(defaultValues.minutes);

    useEffect(() => {
        if (value.delay !== `${numberValue}.00:00:00` || value.time !== `${hours}:${minutes}:00`) {
            const updatedValues = parseDateTime(value.delay, value.time);
            setNumberValue(updatedValues.numberValue);
            setType(updatedValues.type);
            setHours(updatedValues.hours);
            setMinutes(updatedValues.minutes);
        }
    }, [value]);
    useEffect(() => {
        if (type === 'Days') {
            onValueChange({
                delay: `${numberValue}.00:00:00`,
                time: `${hours}:${minutes}:00`
            });
        } else {
            onValueChange({
                delay: `00.${numberValue}:00:00`,
                time: "00:00:00"
            });
        }
    }, [numberValue, type, hours, minutes]);

    return (
        <TypographyDescription>
            <CenteredContent size={size}>
                <Trans>In</Trans>
                <TextField
                    onChange={(e) => setNumberValue(Number(e.target.value))}
                    value={checked ? numberValue : 0}
                    sx={{ width: 55 }}
                    size="small"
                    disabled={!checked}
                />
                <FormControl>
                    <Select
                        labelId="period-select-label"

                        value={checked ? type : "Days"}

                        onChange={(e) => setType(e.target.value)}
                        size="small"
                        disabled={!checked}
                    >
                        <MenuItem value="Days"><Trans>Days</Trans></MenuItem>
                        <MenuItem value="Hours"><Trans>Hours</Trans></MenuItem>
                    </Select>
                </FormControl>
                <Trans>at</Trans>
                <TextField
                    onChange={(e) => setHours(Number(e.target.value))}
                    value={checked ? hours : 0}
                    sx={{ width: 55 }}
                    size="small"
                    disabled={!checked || type === 'Hours'}
                />
                <span>:</span>
                <TextField
                    onChange={(e) => setMinutes(Number(e.target.value))}
                    value={checked ? minutes : 0}
                    sx={{ width: 55 }}
                    size="small"
                    disabled={!checked || type === 'Hours'}
                />
            </CenteredContent>
        </TypographyDescription>
    );
};
interface Props {
    checked?: boolean,
    name: string;
    onValueChange: (event: any) => void;
    value?: any;
}

const DaysAndTimeInputs = ({
    checked,
    name,
    onValueChange,
    value,
}: Props) => {
    return (
        <FormControlLabel
            value={name}
            name={name}
            control={
                <Radio
                    checked={checked}
                    sx={{
                        "&.Mui-checked": {
                            color: MAIN_COLOR.EXTRA_LIGTH
                        }
                    }}
                />
            }
            label={
                <DateAndTimeLabel
                    onValueChange={onValueChange}
                    value={value}
                    checked={checked}
                />
            }
        />
    );
};

export default DaysAndTimeInputs;