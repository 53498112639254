import { Navigate, useLocation } from "react-router-dom";
import React, { useMemo } from "react";
import { isExpired } from "react-jwt";
import { appUserDataSelector } from "./auth.selector";
import { useSelector } from "react-redux";

const RESTRICTION_ROUTE_BY_USER_TYPE: { [unit: string]: string; } = {
  Admin: '/dashboard/admin',
  AdminExpired: '/renew',
  AdminNewCustomer: '/subscribe'
};

export function RequireNonAuth({ children }: { children: JSX.Element; }) {
  const location = useLocation();
  const token = window.localStorage.getItem("token") || "";
  const appUserData = useSelector(appUserDataSelector);

  const isMyTokenExpired = useMemo(() => isExpired(token), [token]);

  if (!isMyTokenExpired) {
    const restrictionRoute = RESTRICTION_ROUTE_BY_USER_TYPE[appUserData.userType];
    if (restrictionRoute && location.pathname !== restrictionRoute) {
      return <Navigate to={restrictionRoute} replace />;
    }

    return <Navigate to="/campaigns" replace />;
  }

  return children;
}
