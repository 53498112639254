import styled from "styled-components";
import { border, boxShadow, borderRadius, MAIN_COLOR } from "./constants.styles";

const FilterSection = styled.div`
  background-color: #ffffff;
  display: Flex;
  align-items: center;
  margin: 24px 32px 28px 32px;
  padding: 1px;
  height: 60px;
  font-size: 14px;
  font-weight: bold;
  color: ${MAIN_COLOR.DARK};
  box-shadow: ${boxShadow};
  border-radius: ${borderRadius};
  border: ${border};
`;

export default FilterSection;
