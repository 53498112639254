import { Chip } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import { MAIN_COLOR, SUCCESS_COLOR } from "./styles/constants.styles";

interface Props {
  status: string | undefined;
}

export const CustomStatus = ({ status }: Props) => {
  if (status === "Running") {
    return (
      <Chip
        sx={{
          bgcolor: "rgba(40, 199, 111, 0.12)",
          color: SUCCESS_COLOR.LIGHT,
          border: "none",
          fontWeight: 600
        }}
        color="success"
        label={<Trans>Running</Trans>}
        size="small"
      />
    );
  } else if (status === "Scheduled") {
    return (
      <Chip
        sx={{
          bgcolor: "rgba(115, 103, 240, 0.12)",
          color: MAIN_COLOR.EXTRA_LIGTH,
          border: "none",
          fontWeight: 600
        }}
        variant="outlined"
        color="secondary"
        label={<Trans>Scheduled</Trans>}
        size="small"
      />
    );
  } else if (status === "Ended") {
    return (
      <Chip
        sx={{ fontWeight: 600 }}
        label={<Trans>Ended</Trans>}
        size="small"
      />
    );
  } else if (status === "Draft") {
    return (
      <Chip
        sx={{ border: 1, fontWeight: 600 }}
        label={<Trans>Draft</Trans>}
        size="small"
        variant="outlined"
      />
    );
  } else if (status === "Paused") {
    return (
      <Chip
        sx={{ border: 1, fontWeight: 600 }}
        label={<Trans>Paused</Trans>}
        size="small"
        variant="outlined"
      />
    );
  } else {
    return <></>;
  }
};
