import styled from "styled-components";

export const RenewSubscriptionButton = styled.button`
  background-color: black;
  color: white;
  border-radius: 39px;
  width: 232px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 4px;
`;
