import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  campaignsPaginationSelector,
  campaignsSearchedSelector
} from "store/campaigns/campaigns.selector";
import { useState } from "react";
import { StyledTextField } from "./styles/TextField.styled";
import {
  getCampaigns,
  getCampaignsByName
} from "store/campaigns/campaign.actions";
import { Box, Icon, InputAdornment } from "@mui/material";
import SearchIcon from "../assets/icons/search-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import { Campaign } from "Interfaces/ICampaign";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { ACCENT_LIGHT_COLOR, MAIN_COLOR } from "./styles/constants.styles";

export default function SearchInput() {
  const [open, setOpen] = useState(false);
  const searchData = useSelector(campaignsSearchedSelector);
  const paginationDetails = useSelector(campaignsPaginationSelector);
  const [inputValue, setInputValue] = useState(searchData.searchString);
  const loading = open && searchData.searchResult.length === 0 && !!inputValue;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      return;
    }
    dispatch(getCampaignsByName({ campaignName: event.target.value }));
  };

  const resultsBoldText = (result: string) => (
    <span>
      {result
        .split(" ")
        .map((word) =>
          word.localeCompare(inputValue, "en", { sensitivity: "base" }) ? (
            word + " "
          ) : (
            <strong key={word}>{word + " "}</strong>
          )
        )}
    </span>
  );

  const handleSeeAll = () => {
    setOpen(false);
    dispatch(
      getCampaigns({
        name: inputValue,
        page: 1,
        pageSize: paginationDetails.pageSize
      })
    );
  };

  const handleClear = () => {
    dispatch(getCampaigns({ page: 1, pageSize: 25 }));
    setInputValue("");
    setOpen(false);
  };

  const handleNavigate = (campaign: Campaign) => {
    navigate(`/${campaign.id}/campaign-details`, { state: campaign.id });
  };

  React.useEffect(() => {
    if (searchData.searchString) {
      handleClear();
    }
  }, []);

  return (
    <Autocomplete
      open={open}
      inputValue={inputValue}
      noOptionsText={t("No options")}
      clearOnBlur={false}
      openOnFocus={true}
      onInputChange={(event, newInputValue) => {
        setOpen(true);
        setInputValue(newInputValue);
      }}
      sx={{ maxWidth: 500, mr: 2 }}
      fullWidth
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option: any) =>
        option.id === "search" ? inputValue : option.name
      }
      // isOptionEqualToValue={() => true}
      filterOptions={(options, state) => options}
      options={
        searchData.searchResult.length > 0
          ? [
            ...searchData.searchResult.slice(0, 11),
            { name: "", id: "search" }
          ]
          : []
      }
      loading={loading}
      renderOption={(props, option) => (
        <Box className="SerchBox" key={`${option.id}-${option.name}`}>
          {option.id === "search" ? (
            <Box
              key={`${option.id}-${option.name}`}
              onClick={() => handleSeeAll()}
              sx={{
                backgroundColor: ACCENT_LIGHT_COLOR,
                display: "flex",
                justifyContent: "center",
                color: MAIN_COLOR.EXTRA_LIGTH,
                cursor: "pointer",
                mt: 2,
                mb: 1,
                fontSize: 12
              }}
            >
              See all results
            </Box>
          ) : (
            <li
              {...props}
              onClick={() => handleNavigate(option)}
              key={`${option.id}-${option.name}`}
            >
              <Box sx={{ color: MAIN_COLOR.EXTRA_LIGTH, fontSize: 14, mr: 1 }}>
                {option.id}
              </Box>
              <Box sx={{ fontSize: 14 }}>{resultsBoldText(option.name)}</Box>
            </li>
          )}
        </Box>
      )}
      renderInput={({ InputLabelProps, ...params }) => {
        return (
          <StyledTextField
            {...params}
            onClick={() => {
              setOpen(true);
            }}
            size="small"
            className="SearchBox"
            onChange={handleChange}
            sx={{ borderRadius: 25, mr: 3 }}
            placeholder={t("Search by campaign name or keywords...")}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {/* {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <CloseIcon />
                  )} */}
                  {inputValue ? (
                    <CloseIcon
                      sx={{ cursor: "pointer" }}
                      onClick={handleClear}
                      fontSize="small"
                    />
                  ) : null}
                </>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>
                    <img src={SearchIcon} alt="search" />
                  </Icon>
                </InputAdornment>
              )
            }}
          />
        );
      }}
    />
  );
}
