import styled from "styled-components";

const PauseButton = styled.button`
  width: 80px;
  height: 28px;
  background: #131826;
  border-radius: 24px;
  color: white;
  font-weight: 500;
  font-size: 11px;
  border: none;
  cursor: pointer;
  &:hover {
    box-shadow: none;
    /* background-color: rgba(115, 103, 240, 0.2); */
  }
`;

export default PauseButton;
