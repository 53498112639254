import { Box, useMediaQuery } from "@mui/material";
import CreateButton from "./styles/CreateButton.styled";
import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

export const CreateCampaignSection: React.FC = () => {
  const { t } = useTranslation();
  const mobileView = useMediaQuery("(min-width:612px)");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mt: 4
      }}
    >
      <Box sx={{ ml: 4, fontWeight: 500, fontSize: 24 }}>{t("Campaigns")}</Box>
      <Box
        sx={{ width: 220, mr: 4 }}
        component={Link}
        to={"/create"}
        style={{
          textDecoration: "none"
        }}
      >
        <CreateButton id="createButton">
          <AddOutlinedIcon fontSize="small" /><Trans>Create Campaign</Trans>
        </CreateButton>
      </Box>
    </Box>
  );
};
