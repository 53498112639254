import React, { useContext, useMemo } from "react";
import { Condition } from "Interfaces/ICondition";
import { createTriggersPreview, useActivationConditionTrans, useCashOutRestrictionTrans, useDelayTrans, useExpiryTrans, useRewardPresentationTrans } from "shared/constants";
import { createRewardsObject } from "shared/constants";
import { useTranslation } from "react-i18next";
import { FixedDynamicValueType } from "Interfaces/CampaignBody";
import { TriggerContext } from "contexts/TriggerContext";

interface Props {
  conditions: Condition[];
  reward: string;
  rewardQuantity: FixedDynamicValueType;
  secondRewardQuantity: FixedDynamicValueType;
  availableIn: string;
  rewardPresentation: string;
  campaignChannel: string;
}

export const TriggersPreview = ({
  conditions,
  reward,
  rewardQuantity,
  secondRewardQuantity,
  availableIn,
  campaignChannel,
}: Props) => {
  const {
    cashOutRestriction,
    rewardPresentation,
    activationCondition,
    activationConditionValue,
    cashOutRestrictionValue,
    getValueByKey,
  } = useContext(TriggerContext);

  const [delay] = getValueByKey('availability.delay');
  const [timeOfActivation] = getValueByKey('availability.timeOfActivation');
  const [expiry] = getValueByKey("availability.expiry");
  const [timeOfExpiry] = getValueByKey("availability.timeOfExpiry");

  const isImmediatly = [null, '0:0:00'].includes(timeOfActivation) && [null, '00.00:00:00', '0.00:00:00'].includes(delay);

  let previewText = "";
  const { t } = useTranslation();
  const rewardPresentationTrans = useRewardPresentationTrans();
  const activationConditionTrans = useActivationConditionTrans();
  const cashOutRestrictionTrans = useCashOutRestrictionTrans();
  const delayTrans = useDelayTrans(delay, timeOfActivation);
  const expiryTrans = useExpiryTrans(expiry, timeOfExpiry);

  if (conditions[0]?.trigger?.type === "Behavioral") {
    previewText = t("If the customer");
  } else {
    previewText = t("If");
  }

  const triggersPreviewObject = useMemo(() => {
    return createTriggersPreview(
      localStorage.getItem("lng")?.toString() || "en",
      t
    );
  }, [t]);

  const rewardsPreviewObject = useMemo(() => {
    return createRewardsObject(t, localStorage.getItem("lng")?.toString() || "en");
  }, [t]);

  const filteredConditions = [...conditions];

  filteredConditions.sort((a, b) => {
    const nameA = a.trigger.type.toUpperCase();
    const nameB = b.trigger.type.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  filteredConditions.forEach((condition, index) => {
    if (
      condition.trigger &&
      condition.rule &&
      condition.segment &&
      condition.trigger.name in triggersPreviewObject
    ) {
      const hasSelectedValue = condition?.segment instanceof Array ? condition?.segment.length > 0 : !!condition?.segment;

      if (hasSelectedValue) {
        if (triggersPreviewObject[condition.trigger.name]?.[condition.rule]) {
          previewText += triggersPreviewObject[condition.trigger.name][
            condition.rule
          ](condition.segment);
        }
        if (index !== conditions.length - 1) {
          previewText += t(" and");
        }
      }
    }
  });

  if (reward in rewardsPreviewObject) {
    previewText += rewardsPreviewObject[reward](
      rewardQuantity,
      secondRewardQuantity
    );
  }

  return (
    <div>
      {previewText}
      {reward && (
        <><br />{t("The reward is available")} {t(availableIn)}</>
      )}
      {rewardPresentation && (
        <><br />{rewardPresentationTrans[rewardPresentation]}</>
      )}
      {(activationCondition && activationCondition !== 'None') && (
        <><br />{activationConditionTrans[activationCondition](activationConditionValue)}</>
      )}
      {(cashOutRestriction && cashOutRestriction !== 'None') && (
        <><br />{cashOutRestrictionTrans[cashOutRestriction](cashOutRestrictionValue)}</>
      )}
      {['Sms', 'SmsManual'].includes(campaignChannel) && (
        <>
          <br />
          {delayTrans}{' '}
          {expiryTrans}
        </>
      )}
    </div>
  );
};
