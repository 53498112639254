import { Box } from "@mui/system";
import { CreateCampaignSection } from "components/CreateCampaignSection";
import { FilterSection } from "components/FilterSection";
import { NavBar } from "../components/NavBar";
import { CampaignsTable } from "../components/CampaignsTable";
import React from "react";
import ContentContainer from "components/styles/ContentContainer";

export default function Campaigns() {
  return (
    <Box sx={{ backgroundColor: "#F8F8F8" }}>
      <NavBar activeTab="campaigns" />
      <ContentContainer>
        <Box>
          <CreateCampaignSection />
          <FilterSection />
          <CampaignsTable />
        </Box>
      </ContentContainer>
    </Box>
  );
}
