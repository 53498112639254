import React from "react";
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import moment from "moment";
import { Box } from "@mui/system";
import { ACCENT_DANGER_COLOR, CONTENT_SPACING_GAP, GREY_COLOR, MAIN_COLOR, SUCCESS_COLOR } from "components/styles/constants.styles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TypographyCardTitle, TypographySmallTitle } from "components/styles/TypographyCardTitle";
import ChartCard from "pages/DashboardCards/ChartCard";
const DATA_MOCK = [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5];
const DEFAULT_NO_DATA = [
    {
        name: "This month",
        data: [0, 40, 28, 51, 42, 10, 100],
    },
];

const getSeries = (values: any) => {
    return [
        {
            name: "This month",
            data: values,
        },
    ];
};

const getOptions = (dateFrom: Date | null, dateTo: Date | null, values: any) => {
    const maxLength = values?.length;
    const options: ApexOptions = {
        chart: {
            zoom: {
                enabled: false,
            },
            toolbar: { show: false },

        },
        colors: [MAIN_COLOR.NORMAL, '#1E1E1E1F'],
        stroke: {
            curve: 'smooth',
            dashArray: [0, 5],
            width: [2, 2]
        },
        tooltip: {
            style: {
                fontSize: '12px', fontFamily: "Montserrat,sans-serif"
            },
            shared: false,
            x: {
                formatter: function (val: any, opts) {
                    const {
                        seriesIndex
                    } = opts;

                    const date = moment(dateFrom).subtract(seriesIndex * 30 - (val - 1), 'days').format('MMM Do');

                    return date;
                }
            },
            y: {
                formatter: function (val: any) {
                    return Math.abs(val) + "%";
                }
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#6E6B7B',
                    fontSize: '12px', fontFamily: "Montserrat, sans-serif"
                },
                formatter: function (val: any) {
                    return Math.abs(Math.round(Number(val))) + "%";
                }
            }
        },

        xaxis: {
            labels: {
                style: {
                    colors: '#6E6B7B',
                    fontSize: '12px', fontFamily: "Montserrat,sans-serif"
                },
                formatter: function (val: any, opts) {
                    if (maxLength <= 7) {
                        return moment(dateFrom).add(val - 1, 'days').format('MMM Do');
                    }
                    const nr = Number(val);
                    if ((nr - 1) % 7 === 0) {
                        return `w${(nr - 1) / 7 + 1}`;
                    }
                    return ' ';
                }
            }
        },
        legend: {
            fontSize: '12px',
            fontFamily: "Montserrat,sans-serif"
        },
    };

    return options;
};

type TotalProfitIncreaseDetailsProps = {
    percent: number;
    value: number;
    total: number;
};

const TotalProfitIncreaseDetails: React.FC<TotalProfitIncreaseDetailsProps> = ({ percent, value, total }) => {
    const { t } = useTranslation();
    return (
        <Box sx={{ display: "flex", gap: "40px", mx: CONTENT_SPACING_GAP }}>
            <Box>
                <TypographySmallTitle>{t(`Average over the selected period`)}</TypographySmallTitle>
                <TypographyCardTitle
                    sx={{
                        color: '#000',
                        mb: "2px",
                    }}>
                    {Math.floor(percent)}%
                </TypographyCardTitle>
                <TypographySmallTitle>{t(`{{value}} out of {{total}}`, { value, total })}</TypographySmallTitle>
            </Box>
        </Box>
    );
};

type Props = {
    title: string;
    data: any;
    dateFrom: Date | null;
    dateTo: Date | null;
    percent: number;
    value: number;
    total: number;
};

const SingleSmoothLine = ({ title, data, percent, value, total, dateFrom, dateTo }: Props) => {
    const { t } = useTranslation();

    const series = getSeries(data);
    const options = getOptions(dateFrom, dateTo, data);

    return (
        <ChartCard title={t(title)} noAvailableData={!data}>
            <TotalProfitIncreaseDetails
                total={total}
                value={value}
                percent={percent}
            />
            <Chart id='chart_1'
                type="line"
                options={options}
                series={series}
                height={250}
            />
        </ChartCard>
    );
};

export default SingleSmoothLine;