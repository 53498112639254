import React from "react";
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import ChartCard from "pages/DashboardCards/ChartCard";
import moment from "moment";
import { Box } from "@mui/system";
import { MAIN_COLOR, SUCCESS_COLOR } from "components/styles/constants.styles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TypographyCardTitle, TypographySmallTitle } from "components/styles/TypographyCardTitle";
const DATA_MOCK = [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5];
const DEFAULT_NO_DATA = [
    {
        name: "This month",
        data: [0, 40, 28, 51, 42, 10, 100],
    },
    {
        name: "Last month",
        data: [11, 32, 45, 32, 34, 52, 41],
    }
];

type LiveDetection = {
    precision: number;
    recall: number;
    date: string;
};

type LiveDetectionArr = {
    precision: number[];
    recall: number[];
    date: string[];
};

const getPrecisionRecall = (data: LiveDetection[]) => {
    return data.reduce((acc: LiveDetectionArr, currentData: LiveDetection) => {
        const { precision, recall, date } = currentData;
        return {
            precision: [...acc.precision, precision],
            recall: [...acc.recall, recall],
            date: [...acc.date, date]
        };
    }, { precision: [], recall: [], date: [] });
};

const getSeries = (precision: number[], recall: number[]) => {
    return [
        {
            name: "Precision",
            data: precision === null ? [] : precision,
        },
        {
            name: "Recall",
            data: recall === null ? [] : recall,
        }
    ];
};

const getOptions = (date: string[], precision: number[], recall: number[], chartOptions: any = {}) => {
    const maxLength = Math.max(precision?.length, recall?.length);
    const options: ApexOptions = {
        chart: {
            zoom: {
                enabled: false,
            },
            toolbar: { show: false },

        },
        colors: [MAIN_COLOR.EXTRA_DARK, SUCCESS_COLOR.LIGHT],
        stroke: {
            curve: 'smooth',
            width: [3, 3]
        },
        tooltip: {
            style: {
                fontSize: '12px', fontFamily: "Montserrat,sans-serif"
            },
            shared: false,
            x: {
                formatter: function (val: any, opts) {
                    return moment(date[val]).format('ddd, hA');
                }
            },
            y: {
                formatter: function (val: any) {
                    return Math.abs(val) + "%";
                }
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#6E6B7B',
                    fontSize: '12px', fontFamily: "Montserrat, sans-serif"
                },
                formatter: function (val: any) {
                    return Math.abs(Math.round(Number(val))) + "%";
                }
            }
        },

        xaxis: {
            labels: {
                style: {
                    colors: '#6E6B7B',
                    fontSize: '12px', fontFamily: "Montserrat,sans-serif"
                },
                formatter: function (val: any, opts: any) {
                    if (maxLength <= 7) {
                        return moment(date[val - 1]).format('ddd, hA');
                    }
                    const nr = Number(val);
                    if ((nr - 1) % 7 === 0) {
                        return moment(date[val - 1]).format('ddd, hA');
                    }
                    return '  ';
                }
            }
        },
        legend: {
            fontSize: '12px',
            fontFamily: "Montserrat,sans-serif"
        },
        ...chartOptions,
    };

    return options;
};

type Props = {
    data?: any;
    dateFrom: Date | null,
    dateTo: Date | null;
};

const LiveDetectionChart = ({ data, dateFrom, dateTo }: Props) => {
    const { t } = useTranslation();

    if (!data) {
        return (
            <ChartCard title={t("Live Detection Performance")} noAvailableData={!data}>
                No data
            </ChartCard>
        );
    }

    const { precision, recall, date } = getPrecisionRecall(data);
    const series = getSeries(precision, recall);
    const options = getOptions(date, precision, recall);

    return (
        <ChartCard title={t("Live Detection Performance")} noAvailableData={!data}>
            <Chart id='LiveDetectionChart__chart'
                type="line"
                options={options}
                series={series}
                height={370}
            />
        </ChartCard>
    );
};

export default LiveDetectionChart;