import { Box, Typography } from "@mui/material";
import { MAIN_COLOR } from "components/styles/constants.styles";
import { AvgProfitPerSessionCard, AvgSessionDurationCard } from "pages/DashboardCards/AvgCard";
import React from "react";
import { useTranslation } from "react-i18next";
import { AverageDurationIncrease } from "./Averages/AverageDurationIncrease";
import { AverageCard } from "./Averages/AverageProfitIncrease";
import SmallCard from "./styles/SmallCard";
import { gap } from "components/styles/constants.styles";

import MovingOutlinedIcon from '@mui/icons-material/MovingOutlined';

interface Props {
  averageProfit?: {
    value: number;
    increase: number;
  };
  averageDuration?: {
    value: number;
    increase: number;
  };
  averageTurnover?: {
    value: number;
    increase: number;
  };
  averageProfitPerLocation?: {
    value: number;
    increase: number;
  };
}

export const RightPanel = ({
  averageProfit,
  averageDuration,
  averageTurnover,
  averageProfitPerLocation
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: "85%" }}>
      <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
        {t("See what other similar operators using our services are making")}...
      </Typography>
      <Box
        sx={{ display: "flex", gap, mt: gap, justifyContent: "space-between" }}
      >
        <SmallCard>
          <Typography sx={{ color: MAIN_COLOR.NORMAL, fontWeight: 700, fontSize: '12px' }}>26,840</Typography>
          <Typography sx={{ fontSize: 12, mt: '4px' }}>
            Players wanted to withdraw money but continued playing
          </Typography>
        </SmallCard>
        <SmallCard>
          <Typography sx={{ color: MAIN_COLOR.NORMAL, fontWeight: 700, fontSize: '12px' }}>26,840</Typography>
          <Typography sx={{ fontSize: 12, mt: '4px' }}>
            Players wanted to withdraw money but continued playing
          </Typography>
        </SmallCard>
        <SmallCard>
          <Typography sx={{ color: MAIN_COLOR.NORMAL, fontWeight: 700, fontSize: '12px' }}>26,840</Typography>
          <Typography sx={{ fontSize: 12, mt: '4px' }}>
            Players wanted to withdraw money but continued playing
          </Typography>
        </SmallCard>
      </Box>
      <Box
        sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap, mt: gap }}
      >
        <AvgProfitPerSessionCard
          title={t("Your Avg Profit Increase could have been")}
          value={averageProfit?.value}
          increase={averageProfit?.increase}
          icon={<MovingOutlinedIcon />}
        />
        <AvgSessionDurationCard
          title={t("Avg Session Duration Increase")}
          value={averageDuration?.value}
          increase={averageDuration?.increase}
        />
        <AvgProfitPerSessionCard
          title={t("Avg Turnover Increase per Slot")}
          value={averageTurnover?.value}
          increase={averageTurnover?.increase}
          icon={<MovingOutlinedIcon />}
        />
        {/* <AverageCard
          mainText={t("Your Avg Profit Increase could have been")}
          value={averageProfitPerLocation.value}
          valueIncrease={averageProfitPerLocation.increase}
        /> */}
      </Box>
    </Box>
  );
};
