import { Box, Divider, Typography } from "@mui/material";
import { changeCampaignStatus } from "api/campaign.service";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ACCENT_LIGHT_COLOR, MAIN_COLOR, SUCCESS_COLOR } from "./styles/constants.styles";
import PauseButton from "./styles/PauseButton.styled";
import ResumeButton from "./styles/ResumeButton.styled";

interface Props {
  campaigns: {
    details: {
      name: string;
    };
    id: string;
    status: string;
  }[];
  setStatusChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileCampaignsTable = ({
  campaigns,
  setStatusChanged
}: Props) => {
  const navigate = useNavigate();
  const handleChangeStatus = (id: string, status: string) => {
    changeCampaignStatus(id, status);
    setStatusChanged(true);
  };

  const handleNavigate = (id: string) => {
    navigate(`/${id}/campaign-details`);
  };

  return (
    <Box sx={{ bgcolor: ACCENT_LIGHT_COLOR, width: "100%", ml: 4, mt: 1 }}>
      {campaigns?.map((campaign) => (
        <Box key={campaign.id}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 2
            }}
          >
            <Box sx={{ maxWidth: 192 }}>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  color: "black"
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    wordBreak: "break-all",
                    color: MAIN_COLOR.EXTRA_LIGTH
                  }}
                >
                  {campaign.id}
                </Typography>
                {campaign.status === ("Running" || "Scheduled") ? (
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color:
                        campaign.status === "Running" ? SUCCESS_COLOR.LIGHT : MAIN_COLOR.EXTRA_LIGTH
                    }}
                  >
                    {campaign.status}
                  </Typography>
                ) : (
                  <Typography sx={{ fontWeight: 600 }}>
                    {campaign.status}
                  </Typography>
                )}
              </Box>
              <Box
                onClick={() => handleNavigate(campaign.id)}
                sx={{ cursor: "pointer" }}
              >
                <Typography sx={{ wordBreak: "break-all", fontWeight: 600 }}>
                  {campaign.details.name}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              {campaign.status === "Running" ? (
                <PauseButton
                  onClick={() => handleChangeStatus(campaign.id, "Paused")}
                >
                  <Typography sx={{ fontWeight: 400, fontSize: 13 }}>
                    Pause
                  </Typography>
                </PauseButton>
              ) : (
                <></>
              )}
              {campaign.status === "Paused" ? (
                <ResumeButton
                  onClick={() => handleChangeStatus(campaign.id, "Running")}
                >
                  <Typography sx={{ fontWeight: 400, fontSize: 13 }}>
                    Resume
                  </Typography>
                </ResumeButton>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Divider variant="middle" />
        </Box>
      ))}
    </Box>
  );
};

export default MobileCampaignsTable;
