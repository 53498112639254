import { FixedDynamicValueType } from "Interfaces/CampaignBody";
import { Condition } from "Interfaces/ICondition";
import { calculateCampaignDuration } from "shared/helpers";

export const isFixedDynamicValueValid = (value: FixedDynamicValueType | null) => {
    if (!value) {
        return false;
    }

    const {
        type, fixedValue, basedOn, multipliedBy, multipleOf, minimum, maximum, aiAmount
    } = value;

    if (type === 'Fixed') {
        return fixedValue > 0;
    }

    return (
        basedOn !== '' &&
        multipliedBy > 0 &&
        multipleOf > 0 &&
        minimum < maximum &&
        aiAmount > 0
    );
};

export const isDelayEmpty = (delay: string) => [null, '00.00:00:00', '0.00:00:00'].includes(delay);

type SMSMessage = {
    absoluteTime: string;
    relativeTime: string;
    text: string;
};

export const areSMSDetailsValid = (value: {
    activationMessage: SMSMessage;
    reminderMessage: SMSMessage;
    expiryMessage: SMSMessage;
}) => {
    const {
        activationMessage,
        reminderMessage,
        expiryMessage,
    } = value;


    if (activationMessage.text?.length < 0) {
        return false;
    }

    if (!isDelayEmpty(reminderMessage.absoluteTime) && !isDelayEmpty(reminderMessage.relativeTime) && reminderMessage.text?.length === 0) {
        return false;
    }

    if (!isDelayEmpty(expiryMessage.absoluteTime) && !isDelayEmpty(expiryMessage.relativeTime) && expiryMessage.text?.length === 0) {
        return false;
    }

    return true;
};

type CampaignValidType = {
    campaignName: string;
    campaignChannel: string;
    campaignTargets: string;
    campaignStartDate: Date;
    campaignEndDate: Date;
    campaignGroupValue: number | null;
    campaignExperimentValue: number | null;
    smsText: string;
    campaignConditions: Condition[];
    rewardQuantity: FixedDynamicValueType | null;
    campaignReward: string;
    secondRewardQuantity: FixedDynamicValueType | null;
    cashOutRestriction: any;
    rewardPresentation: any;
    activationCondition: any;
    activationConditionValue: any;
    cashOutRestrictionValue: any;
    numberOfVouchers: number;
    smsManualPhoneNumbers: string[];
    getValueByKey: any;
};

export const isChannelValid = ({ campaignChannel }: { campaignChannel: string; }) => {
    if (!campaignChannel) {
        return false;
    }

    return true;
};


export const isCampaignTargetValid = ({ campaignTargets }: { campaignTargets: string | null; }) => {
    if (!campaignTargets) {
        return false;
    }

    return true;
};

type RewardOfferingValidType = {
    campaignChannel: string;
    campaignConditions: Condition[];
    rewardQuantity: FixedDynamicValueType | null;
    campaignReward: string;
    secondRewardQuantity: FixedDynamicValueType | null;
    cashOutRestriction: any;
    activationCondition: any;
    activationConditionValue: any;
    cashOutRestrictionValue: any;
    numberOfVouchers: number;
    smsManualPhoneNumbers: string[];
    getValueByKey: any;
};

export const isRewardOfferingValid = ({
    campaignChannel,
    campaignConditions,
    rewardQuantity,
    campaignReward,
    secondRewardQuantity,
    cashOutRestriction,
    activationCondition,
    activationConditionValue,
    cashOutRestrictionValue,
    numberOfVouchers,
    smsManualPhoneNumbers,
    getValueByKey,
}: RewardOfferingValidType) => {
    if (!campaignReward) {
        return false;
    }

    if (['Lcd', 'Sms'].includes(campaignChannel)) {
        campaignConditions.forEach((condition) => {
            if (condition?.segment[0] === "") {
                return false;
            }
        });
    }

    if (["Play", "Deposit"].includes(activationCondition) && !isFixedDynamicValueValid(activationConditionValue)) {
        return false;
    }

    if (["Play", "Deposit"].includes(cashOutRestriction) && !isFixedDynamicValueValid(cashOutRestrictionValue)) {
        return false;
    }

    if (campaignChannel === 'Voucher') {
        if (numberOfVouchers <= 0) {
            return false;
        }
    }

    if (campaignChannel === 'SmsManual') {
        if (!smsManualPhoneNumbers || smsManualPhoneNumbers.length <= 0) {
            return false;
        }
    }

    if (['Sms', 'SmsManual'].includes(campaignChannel)) {
        const [{ smsDetails }] = getValueByKey('allSMSDetails');

        if (!areSMSDetailsValid(smsDetails)) {
            return false;
        }
    }

    if (!isFixedDynamicValueValid(rewardQuantity)) {
        return false;
    }

    if (
        ["PercentBack", "BuyABonus", "FreeSpins"].includes(campaignReward) &&
        (!isFixedDynamicValueValid(rewardQuantity) || !isFixedDynamicValueValid(secondRewardQuantity))
    ) {
        return false;
    }

    if (["BonusCash", "FreeMoney"].includes(campaignReward) && !isFixedDynamicValueValid(rewardQuantity)) {
        return false;
    }

    return true;
};

type CampaignExperimentSettingsType = {
    campaignGroupValue: number | null;
    campaignExperimentValue: number | null;
};
export const isCampaignExperimentSettingsValid = ({ campaignGroupValue, campaignExperimentValue }: CampaignExperimentSettingsType) => {
    if ((Number(campaignGroupValue) + Number(campaignExperimentValue) > 100)) {
        return false;
    }

    return true;
};

type CampaignSchedulType = { campaignChannel: string; campaignStartDate: Date | null; campaignEndDate: Date | null; };
export const isCampaignScheduleValid = ({ campaignChannel, campaignStartDate, campaignEndDate }: CampaignSchedulType) => {
    const validDates = calculateCampaignDuration(campaignStartDate, campaignEndDate);

    if (!validDates) {
        return false;
    }

    if (['Lcd', 'Sms'].includes(campaignChannel)) {
        if (!campaignStartDate || !campaignEndDate || campaignEndDate < campaignStartDate) {
            return false;
        }
    }


    return true;
};

export const isCampaignValidForSubmit = ({
    campaignName,
    campaignChannel,
    campaignTargets,
    campaignStartDate,
    campaignEndDate,
    campaignGroupValue,
    campaignExperimentValue,
    smsText,
    campaignConditions,
    rewardQuantity,
    campaignReward,
    secondRewardQuantity,
    cashOutRestriction,
    rewardPresentation,
    activationCondition,
    activationConditionValue,
    cashOutRestrictionValue,
    numberOfVouchers,
    smsManualPhoneNumbers,
    getValueByKey,
}: CampaignValidType) => {
    if (!campaignName || !isCampaignTargetValid({ campaignTargets })) {
        return false;
    }

    if (!isCampaignExperimentSettingsValid({ campaignGroupValue, campaignExperimentValue })) {
        return false;
    }

    if (!isChannelValid({ campaignChannel })) {
        return false;
    }

    if (!isCampaignScheduleValid({ campaignChannel, campaignStartDate, campaignEndDate })) {
        return false;
    }

    if (!isRewardOfferingValid({
        campaignChannel,
        campaignConditions,
        rewardQuantity,
        campaignReward,
        secondRewardQuantity,
        cashOutRestriction,
        activationCondition,
        activationConditionValue,
        cashOutRestrictionValue,
        numberOfVouchers,
        smsManualPhoneNumbers,
        getValueByKey,
    })) {

        return false;
    }
    return true;
};