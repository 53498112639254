import React from "react";
import { FormControl, RadioGroup } from "@mui/material";
import { TypographyAlertText } from "components/styles/TypographyCardTitle";
import { Trans, useTranslation } from "react-i18next";
import TextRadioOption from "./TextRadioOption";
import RewardDetailsItem from "./RewardDetailsItem";

interface Props {
  onNameChange: (event: any) => void;
  name: string;
}

const RewardPresentation = ({
  name,
  onNameChange,
}: Props) => {
  const { t } = useTranslation();
  return (
    <RewardDetailsItem>
      <TypographyAlertText>
        <Trans>Reward Presentation</Trans>
      </TypographyAlertText>

      <FormControl>
        <RadioGroup onChange={onNameChange}>
          <TextRadioOption
            name="Direct"
            checked={name === "Direct"}
            label={t("Direct message")}
          />
          <TextRadioOption
            name="WheelOfFortune"
            checked={name === "WheelOfFortune"}
            label={t("Wheel of Fortune")}
            disabled
          />
        </RadioGroup>
      </FormControl>
    </RewardDetailsItem>
  );
};

export default RewardPresentation;