import { Box, Icon, Menu, MenuItem, Radio, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CampaignComparisonChart } from "./CampaignComparisonChart";
import ArrowIcon from "../assets/icons/arrow-icon.svg";
import mainColor from "../assets/icons/main-campaign-color.svg";
import secondColor from "../assets/icons/control-color.svg";
import thirdColor from "../assets/icons/experiment-1-color.svg";
import fourthColor from "../assets/icons/experiment-2-color.svg";
import fifthColor from "../assets/icons/experiment-3-color.svg";
import { MAIN_COLOR } from "./styles/constants.styles";
import ChartCard from "pages/DashboardCards/ChartCard";

const colorIcons = [
  mainColor,
  secondColor,
  thirdColor,
  fourthColor,
  fifthColor
];

interface Props {
  data: any;
}

const parseData = (comparisonData: { group: string; values: []; }[]) => {
  const data: any = [];

  if (!comparisonData) return;

  const dataLength = comparisonData[0].values.length;

  for (let i = 0; i < dataLength; i++) {
    data.push({ name: `Day ${i + 1}` });
    comparisonData.forEach(
      (compData: { group: string; values: []; }, index: number) => {
        data[i][`v${index + 1}`] = compData.values[i];
      }
    );
  }

  return data;
};

export const CampaignComparison = ({ data }: Props) => {
  const [comparisonData, setComparisonData] = useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sortBy, setSortBy] = useState("Avg Profit per session");
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const sortSessionsArray = [
    { id: 0, text: t("Avg Profit per session"), sortBy: "deposit" },
    { id: 1, text: t("Avg Session Duration"), sortBy: "winAmount" },
    { id: 2, text: t("Avg Turnover per slot"), sortBy: "profit" }
  ];

  if (!data) {
    <ChartCard
      title={t("Campaign Comparison")}
      noAvailableData
    >
      Loading
    </ChartCard>;
  }
  return (
    <ChartCard
      title={t("Campaign Comparison")}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box />
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center"
            }}
            onClick={handleClick}
          >
            {
              sortSessionsArray.find(
                (el: { id: number; text: string; sortBy: string; }) =>
                  el.sortBy === sortBy
              )?.text
            }
            <Icon sx={{ mb: 1.6 }}>
              <img src={ArrowIcon} alt="search" />
            </Icon>
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            onClick={() => setAnchorEl(null)}
            open={open}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Box
                sx={{
                  fontWeight: "bold",
                  p: 2
                }}
              >
                {t("Sort by")}
                {sortSessionsArray.map((el: any) => (
                  <MenuItem
                    sx={{
                      width: 300,
                      p: 0.2,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    key={el.id}
                    onClick={() => setSortBy(el.sortBy)}
                  >
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: MAIN_COLOR.EXTRA_LIGTH
                        }
                      }}
                      size="small"
                      value={el.sortBy}
                      checked={el.sortBy === sortBy}
                    />
                    <Box
                      sx={{
                        fontSize: 14,
                        color: el.sortBy === sortBy ? MAIN_COLOR.EXTRA_LIGTH : "black"
                      }}
                    >
                      {el.text}
                    </Box>
                  </MenuItem>
                ))}
              </Box>
            </Box>
          </Menu>
        </Box>
        <Box sx={{ display: "flex", gap: 2, flexFlow: "wrap" }}>
          {data.comparison?.map(
            (experiment: { group: string; }, index: number) => (
              <Box key={`${experiment.group}-${index}`}>
                <Icon sx={{ overflow: "visible" }}>
                  <img src={colorIcons[index]} alt="color" />
                </Icon>
                {experiment.group}
              </Box>
            )
          )}
        </Box>
        <CampaignComparisonChart data={parseData(data.comparison)} />
      </Box>
    </ChartCard>
  );
};
