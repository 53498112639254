import { Button } from "@mui/material";
import styled from "styled-components";
import { MAIN_COLOR, ACCENT_LIGHT_COLOR } from "./constants.styles";

const CreateTriggerButton = styled(Button)({
  display: "flex",
  justifyContent: "start",
  boxShadow: "none",
  width: 459,
  height: 38,
  textTransform: "none",
  fontSize: 12,
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: ACCENT_LIGHT_COLOR,
  borderColor: MAIN_COLOR.EXTRA_LIGTH,
  borderStyle: "dashed",
  color: MAIN_COLOR.EXTRA_LIGTH,
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "rgba(115, 103, 240, 0.2)"
  }
});

export default CreateTriggerButton;
