import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { CURRENCY_NAME } from "shared/constants";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        "Sort by": "Sort by",
        "Campaign Preview": "Campaign Preview",
        "Duplicate Campaign": "Duplicate Campaign",
        "Total Profit Increase": "Total Profit Growth",
        "SMS Campaigns Funnel": "SMS Campaigns Funnel",
        Pool: "Pool",
        "Message read": "Message read",
        "Code activated": "Code activated",
        "Reward usage": "Reward usage",
        "Avg Profit per session": "Avg Profit per session",
        "Avg Session Duration": "Avg Session Duration",
        "Avg Turnover per slot": "Avg Turnover per slot",
        "Ai Experiments": "Ai Experiments",
        "There were generated 3 different campaigns based on the settings chosen by you. All data is in comparison to the Main campaign":
          "There were generated 3 different campaigns based on the settings chosen by you. All data is in comparison to the Main campaign",
        "All Sessions": "All Sessions",
        "The campaign will last": "The campaign will last",
        DEPOSIT: "DEPOSIT",
        "WIN AMOUNT": "WIN AMOUNT",
        PROFIT: "PROFIT",
        "PLAY TIME": "PLAY TIME",
        LOCATION: "LOCATION",
        "DATE&TIME": "DATE&TIME",
        DOWNLOAD: "DOWNLOAD",
        "Campaign Overview": "Campaign Overview",
        "CAMPAIGN NAME": "CAMPAIGN NAME",
        CHANNEL: "CHANNEL",
        "CAMPAIGN TARGETS": "CAMPAIGN TARGETS",
        "CAMPAIGN TRIGGERS": "CAMPAIGN TRIGGERS",
        "CAMPAIGN SCHEDULE": "CAMPAIGN SCHEDULE",
        "CAMPAIGN EXPERIMENT SETTINGS": "CAMPAIGN EXPERIMENT SETTINGS",
        to: "to",
        Start: "Start",
        End: "End",
        LEI: CURRENCY_NAME,
        "CAMPAIGN DETAILS": "CAMPAIGN DETAILS",
        Locations: "Locations",
        Budgets: "Budgets",
        Show: "Show",
        "No results yet": "No results yet",
        "check back later": "check back later",
        "YOUR CAMPAIGN": "YOUR CAMPAIGN",
        "AI EXPERIMENTS": "AI EXPERIMENT",
        "No results yet,<br/> check back later":
          "No results yet,<br/> check back later",
        Conversion: "Conversion",
        Reach: "Reach",
        Profit: "Profit",
        "Average Profit/session": "Average Profit/session",
        Running: "Running",
        Scheduled: "Scheduled",
        Draft: "Draft",
        "Are you sure you want to delete this campaign?":
          "Are you sure you want to delete this campaign?",
        "Campaign Bonus On Cash Out will be deleted permanently. There is no posibility to restore it afterwards.":
          "Campaign Bonus On Cash Out will be deleted permanently. There is no posibility to restore it afterwards.",
        CAMPAIGN_WILL_BE_DELETED: 'Campaign {{campaignId}} will be deleted permanently. There is no posibility to restore it afterwards.',
        Campaigns: "Campaigns",
        "Create Campaign": "Create Campaign",
        Profile: "Profile",
        Filter: "Filter",
        Sort: "Sort",
        Dashboard: "Dashboard",
        STATUS: "STATUS",
        ACTIONS: "ACTIONS",
        "Campaign comparison": "Campaign comparison",
        "Select how the reward will be offered":
          "Select how the reward will be offered",
        "NEWTON LCD": "NEWTON LCD",
        SMS: "SMS",
        "Campaign Targets": "Campaign Targets",
        "Select the locations where the campaign will run":
          "Select the locations where the campaign will run",
        "All Locations": "All Locations",
        "Custom Locations": "Custom Locations",
        "Reward Offering": "Reward Offering",
        "Specify the reward, and when it will be offered":
          "Specify the reward, and when it will be offered",
        "Define campaign behaviours to trigger the reward":
          "Define campaign behaviours to trigger the reward",
        If: "If",
        And: "And",
        "Add a new condition": "Add a new condition",
        offer: "offer",
        ron: CURRENCY_NAME,
        get: "get",
        "% of the turnover of the next": "% of the turnover of the next",
        spins: "spins",
        "Exclude slot sessions that claimed other rewards from existing, running campaigns":
          " ----TO BE DELETED----",
        "Reward is available": "Reward is available",
        ", he receives": ", he receives",
        Hours: "hours",
        Days: "days",
        Day: "day",
        "Would you like to send a reminder ?":
          "Would you like to send a reminder?",
        "Campaign Schedule": "Campaign Schedule",
        "Set the period for the campaign": "Set the period for the campaign",
        "Time and Date Start": "Start Time and Date",
        "Time and Date End": "End Time and Date",
        "This campaign will last": "This campaign will last",
        " day": " day",
        " days": "days",
        bet_amount: "Amount bet",
        "Campaign Experiment Settings": "Campaign Experiment Settings",
        "Adjust the control group and the AI group size in your campaign":
          "Adjust the control group and the AI group size in your campaign",
        "Your campaign": "Your campaign",
        "Your campaign est. reach": "Your campaign est. reach",
        "Control group size": "Control group size",
        "Recommended values are between 15 - 20%":
          "Recommended values are between 15% and 20%",
        "AI experiment size": "AI experiment size",
        "The reward is available": "The reward is available",
        "Set the AI experiment percentage": "Set the AI experiment percentage",
        "Recommended values 25 - 35%":
          "Recommended values are between 25% and 35%",
        "There are no characteristics set up yet. <br />Start by choosing the name and the right channel":
          "There are no characteristics set up yet. <br />Start by choosing the name and the right channel",
        "Campaign Name": "Campaign Name",
        Channel: "Channel",
        "Campaign Triggers": "Campaign Triggers",
        "The campaign will reach around": "The campaign will reach around",
        "slot machines": "slot machines",
        "From these": "From these",
        "will be part of the Control Group and":
          "will be part of the Control Group and",
        "will be used for AI experiments": "will be used for AI experiments",
        "Finish later": "Finish later",
        "Campaign Experiments Settings": "Campaign Experiments Settings",
        "New Campaign": "New Campaign",
        "Online campaign": "Online campaign",
        "Newton LCD": "Newton LCD",
        "Offered via Newton LCD during gameplay": "Offered via Newton LCD during gameplay",
        "Estimated slots reached based on your criteria (location, channel, triggers) is <strong>aprox.</strong>":
          "Estimated slots reached based on your criteria (location, channel, triggers) is <strong>aprox.</strong>",
        "Distribute bellow how many % out of the total go to Control group and AI experiment":
          "Distribute bellow how many % out of the total go to Control group and AI experiment",
        "There are no characteristics set up yet. <br/>Start by choosing the name and the right channel":
          "There are no characteristics set up yet. </br>Start by choosing the name and the right channel.",
        "AVG PROFIT/SESSION": "AVG PROFIT/SESSION",
        "AVG PLAY TIME": "AVG PLAY TIME",
        CONVERSIONS: "CONVERSIONS",
        Immediately: "Immediately",
        "Profit Increase": "Profit Increase",
        "Search by campaign name or keywords...":
          "Search by campaign name or keywords...",
        same: "same",
        higher: "higher",
        lower: "lower",
        Yes: "Yes",
        "If the customer": "If the customer",
        No: "No",
        immediately: "immediately",
        average_bet_amount_vs_city: "Average bet amount vs city average",
        false_win_ratio: "False wins ratio",
        no_more_credits: "No more credits",
        deposit_amount: "Deposit amount",
        deposit_frequency: "Deposit frequency",
        deposit_trend: "Deposit trend",
        cash_out: "Cash out",
        bet_trend: "Bet trend",
        play_speed_trend: "Play speed trend",
        auto_spin_enabled: "Auto-spin enabled",
        round_credit: "Round credits amount",
        average_bet_amount_vs_location:
          "Average bet amount vs location average",
        break_piggy_bank: "Break the piggy bank",
        play_streak_several_days: "Play streak of several days",
        move_to_another_machine: "Move to another slot machine",
        online_player: "Online player",
        winning_streak: "Winning streak",
        losing_streak: "Losing streak",
        false_wins_ratio: "False wins ratio",
        true_wins_ratio: "True wins ratio",
        ggr_ratio: "GGR ratio",
        ggr_absolute: "Absolute GGR",
        highest_win_ratio: "Ratio of highest win vs bet",
        highest_win_absolute: "Absolute highest win",
        total_reward_value: "Total reward value",
        day_of_month: "Day of month",
        time_of_day: "Time of day",
        day_of_week: "Day of week",
        current_credit: "Current credit",
        payday: "Payday",
        holiday: "Holiday",
        season: "Season",
        city_size: "City size",
        weather: "Weather",
        FreeSpins: "Free spins",
        FreeMoney: "Free money",
        PercentBack: "Turnover percentage",
        WheelOfFortune: "Roll the wheel of fortune",
        BuyABonus: "Buy a bonus",
        RaffleTicket: "Raffle ticket",
        AfterWager: "After Wager",
        CashBack: "Cash Back",
        True: "true",
        False: "false",
        Today: "today",
        constant: "constant",
        increasing: "increasing",
        decreasing: "decreasing",
        "Coming Soon": "coming soon",
        "Just Passed": "just passed",
        "starting on": "starting on",
        Clear: "Clear",
        Status: "Status",
        Ended: "Ended",
        Location: "Location",
        Operator: "Operator",
        "Campaign ID Ascending": "Campaign ID Ascending",
        "Campaign ID Descending": "Campaign ID Descending",
        "Campaign Name A-Z": "Campaign Name A-Z",
        "Campaign Name Z-A": "Campaign Name Z-A",
        "Conversion Ascending": "Conversion Ascending",
        "Conversion Descending": "Conversion Descending",
        "Avg Profit/session Ascending": "Avg Profit/session Ascending",
        "Avg Profit/session Descending": "Avg Profit/session Descending",
        "Reach Ascending": "Reach Ascending",
        "Reach Descending": "Reach Descending",
        "We are working on gathering usefull information for you":
          "We are working on gathering usefull information for you",
        current_credit_description: "The amount of credit that the player has",
        no_more_credits_description: "The player has exhausted all the money",
        deposit_amount_description:
          "The amount of money deposited by the player",
        average_bet_amount_vs_city_description:
          " The average amount bet by the player, compared to the average amount players usually bet in that city",
        bet_amount_description: "The amount that the player has bet",
        average_bet_amount_vs_location_description:
          "The average amount bet by the player, compared to the average amount players usually bet at that location",
        total_reward_value_description:
          "The total value of all rewards that were offered to the player",
        false_win_ratio_description: "Raportul de câștiguri false",
        deposit_frequency_description:
          "The average number of deposits performed by the player in 10 minutes",
        deposit_trend_description:
          "The evolution of value of the player’s deposits",
        cash_out_description:
          "The player took all the money out of the slot machine",
        bet_trend_description:
          "The evolution of the value of the player’s bets",
        play_speed_trend_description:
          "The evolution of the player’s speed when playing",
        auto_spin_enabled_description: "The player uses the auto-spin feature",
        break_piggy_bank_description: "The player has broken the piggy-bank",
        play_streak_several_days_description:
          "The player has broken the piggy-bank",
        move_to_another_machine_description:
          "The player moved to another slot machine",
        online_player_description: "The player is also registered on Newton.ro",
        winning_streak_description:
          "The number of consecutive wins that the player had",
        losing_streak_description:
          "The number of consecutive loses that the player had",
        false_wins_ratio_description:
          "The ratio between the number of false wins and all wins, or how many % of all wins are false. A win is considered “real” if the amount won is greater than the amount bet",
        true_wins_ratio_description:
          "The ratio between the number of true wins and all wins, or how many % of all wins are false. A win is considered “real” if the amount won is greater than the amount bet",
        ggr_ratio_description:
          "A GGR greater than 1 means that the house is winning",
        ggr_absolute_description: `The actual GGR value in ${CURRENCY_NAME}`,
        highest_win_ratio_description:
          "The number of times that the player’s highest win was greater than the amount bet for that spin. E.g: If the bet was 10 and the win was 50, the ratio is 5",
        highest_win_absolute_description:
          "The highest amount of money won by the player",
        day_of_month_description: "e.g. for 12.02.2022, this will be 12",
        day_of_week_description: "1 for Monday, 2 for Tuesday etc",
        payday_description: "Is it likely that the current date is a payday?",
        holiday_description: "Is the current day a legal holiday (a day off)?",
        season_description: "The current season",
        city_size_description: "The number of inhabitants of the current city",
        weather_description: "The weather at the location",
        time_of_day_description: "The part of the day",
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        Sunday: "Sunday",
        Morning: "Morning",
        Noon: "Noon",
        Evening: "Evening",
        Night: "Night",
        "No options": "No options",
        "Please enter text here...": "Please enter text here...",
        "Set the control group size": "Set the control group size",
        "There is no data available yet": "There is no data available yet",
        "Please come back later": "Please come back later",
        "The campaign hasn’t started yet or doesn’t have enough data":
          "The campaign hasn’t started yet or doesn’t have enough data",
        "We are working on gathering useful information for you":
          "We are working on gathering useful information for you",
        Hello: "Hello",
        // Campaign types
        Online: 'ONLINE',
        Lcd: 'LCD',
        Sms: 'SMS',
        SmsManual: 'SMS Manual',
        Voucher: 'Voucher',
        'Code sent by SMS during gameplay, to be used later on': 'Code sent by SMS during gameplay, to be used later on',
        'Code sent manually by SMS to a list of players': 'Code sent manually by SMS to a list of players',
        'Voucher code offered as offline campaign': 'Voucher code offered as offline campaign',
        'Reward Presentation': 'Reward Presentation',
        'Direct message': 'Direct message',
        'Wheel of Fortune': 'Wheel of Fortune',
        'Player action needed for claiming the reward': 'Player action needed for claiming the reward',
        'No action needed': 'No action needed',
        'Pay': 'Deposit',
        'Run': 'Play',
        'Enter phone number': 'Enter phone number',
        'Player action needed for cashing out the reward': 'Player action needed for cashing out the reward',
        'No condition': 'No condition',

        'Reward becomes available': 'Reward becomes available',
        'Immediatly': 'Immediatly',
        'In': 'In',
        'at': 'at',
        'Reward expires': 'Reward expires',
        'SMS text': 'SMS text',
        "Type here the text that will be sent to the player's phone...": "Type here the text that will be sent to the player's phone...",
        'Reminder SMS': 'Reminder SMS',
        "Don't send": "Don't send",
        'before the reward is available': 'before the reward is available',
        'before the reward expires': 'before the reward expires',
        'on the day when the reward expires': 'on the day when the reward expires',

        'At': 'At',
        'on the day when the reward becomes available': 'on the day when the reward becomes available',
        'Reminder SMS text': 'Reminder SMS text',

        "Type here the reminder text that will be sent to the player's phone...": "Type here the reminder text that will be sent to the player's phone...",
        'Expiration Reminder SMS': 'Expiration Reminder SMS',
        'Expiration reminder SMS text': 'Expiration reminder SMS text',
        "Type here the expiration reminder text that will be sent to the player's phone": "Type here the expiration reminder text that will be sent to the player's phone",

        'the following list of phone numbers': 'the following list of phone numbers',
        'generated voucher codes': 'generated voucher codes',

        'Browse': 'Browse',
        'No file selected': 'No file selected',
        "BonusCash": "Bonus Cash",

        "Search for clients, locations, slots or games": "Search for clients, locations, slots or games",
        "Select": "Select locations",
        "reward_value": "Reward Value",
        "reward_quantity": "Reward Quantity",
        "Dynamic value": "Dynamic value",

        balance_ratio: "Balance ratio (%)",
        balance_ratio_description: "The ratio between the current amount and the total deposited amount",
        bet_trend_short_term: "Bet trend",
        bet_trend_short_term_description: "The evolution of bets",
        duration: "Session duration",
        duration_description: "Session duration, in minutes",
        number_of_spins: "Spin count",
        number_of_spins_description: "The number of spins performed by the player during the current session",
        play_speed_trend_short_term: "Play speed trend",
        play_speed_trend_short_term_description: "The evolution of the play speed",
        initial_deposit: "Initial deposit",
        initial_deposit_description: "The value of the initial deposit, in LEI",
        round_credit_description: "The current amount is a round number",

        Statistics: "Statistics",
        "Total Campaign Conversions": "Total Campaign Conversions",
        "Reward Usage": "Reward Usage",

        "phone number loaded": "phone number loaded",
        "phone numbers loaded": "phone numbers loaded",

        "Codes sent": "Codes sent",
        "Used codes": "Used codes",
        "Expired codes": "Expired codes",
        "Codes generated": "Codes generated",

        "This campaign will target {{numberOfLocations}} location(s) over {{numberOfOperators}} operator(s) ({{numberOfSlots}} slots)": "This campaign will target {{numberOfLocations}} location(s) over {{numberOfOperators}} operator(s) ({{numberOfSlots}} slots)",

        INVALID_CAMPAIGN_SCHEDULE: "Invalid Campaign Schedule",
        START_DATE_AFTER_END_DATE: "Invalid Campaign Schedule: Start Date cannot be after End Date",
        PAST_START_DATE: "Invalid Campaign Schedule: Start Date cannot be in the past",
        NULL_BEHAVIORAL_TRIGGERS: "Behavioral Triggers cannot be null",
        NULL_TIME_TRIGGERS: "Time Triggers cannot be null",
        NULL_ENVIRONMENTAL_TRIGGERS: "Environmental Triggers cannot be null",
        EMPTY_TARGET: "You must select at least 1 valid target",
        MANUAL_CAMPAIGN_STATUS: "Manual campaigns must have status: Manual",
        INVALID_CAMPAIGN_STATUS: "Campaign can't have status - {campaign.Status}",
        EMPTY_EXPERIMENT_SETTINGS: "Experiment Settings cannot be empty",
        INVALID_EXPERIMENT_SETTINGS: "Experiment Settings Validation Error",
        INVALID_TIME_TRIGGER_TYPE: "Time Triggers Type Validation Error",
        INVALID_TIME_TRIGGER_RULE: "Time Triggers Rule Validation Error",
        INVALID_ENVIRONMENTAL_TRIGGER_TYPE: "Environmental Triggers Type Validation Error",
        INVALID_ENVIRONMENTAL_TRIGGER_RULE: "Environmental Triggers Rule Validation Error",
        INVALID_BEHAVIORAL_TRIGGER_TYPE: "Behavioral Triggers Type Validation Error",
        INVALID_BEHAVIORAL_TRIGGER_RULE: "Behavioral Triggers Rule Validation Error",
        INVALID_REWARD_VALUE: "Invalid reward value",
        INVALID_REWARD_QUANTITY: "Invalid reward quantity",
        INVALID_ACTIVATION_CONDITION_VALUE: "Invalid activation condition value",
        INVALID_CASH_OUT_RESTRICTION_VALUE: "Invalid cash out restriction value",
        INVALID_NUMBER_OF_VOUCHERS: "Invalid number of vouchers",
        INVALID_PHONE_NUMBER: "Invalid phone number",
        CAMPAIGN_NOT_FOUND: "Campaign not found",
        CANNOT_UPDATE_MANUAL_CAMPAIGN: "Manual campaigns cannot be updated!",
        CAMPAIGN_ALREADY_STARTED: "Campaign has already started",

        ESTIMATED_SLOTS_REACHED: `Estimated slots reached based on your criteria (location, channel, triggers) is aprox. {{numberOfSlots}} slot machines.`,

        CAMPAIGN_WILL_REACH: `The campaign will reach around {{numberOfSlots}} slot machines. From these {{campaignGroupValue}}% will be part of the Control Group and {{campaignExperimentValue}}% will be used for AI experiments.`,
        CAMPAIGN_WILL_REACH_ALL: `The campaign will reach all slot machines. From these {{campaignGroupValue}}% will be part of the Control Group and {{campaignExperimentValue}}% will be used for AI experiments.`,

        'Delete Campaign': 'Delete Campaign',

        'Average over the selected period': 'Average over the selected period',
        'Activation Place': 'Activation Place',
        'Amount Offered vs Used per Location': 'Amount Offered vs Used per Location',

        'Download Voucher Code List': 'Download Voucher Code List',
        'Download Phone Number List': 'Download Phone Number List',
        'AI Performance': 'AI Performance',
        Details: 'Details',
        'No data available': 'No data available',
        Overview: 'Overview',
        'Total slots': 'Total slots',
        'Total played sessions': 'Total played sessions',
        'Total played spins': 'Total played spins',
        "Experiment Findings": "Insights discovered by AI"
      }
    },
    ro: {
      translation: {
        "Please come back later": "Te rugăm să revii mai târziu",
        "The campaign hasn’t started yet or doesn’t have enough data":
          "Campania nu a rulat suficient timp pentru a colecta date relevante",
        "There is no data available yet":
          "Încă nu există suficiente date pentru această campanie",
        "Please enter text here...": "Introdu textul SMS-ului...",
        "Set the control group size": "Alege dimensiunea grupului de control",
        Morning: "Dimineață",
        "We are working on gathering useful information for you": "",
        Noon: "Prânz",
        Evening: "Seară",
        Night: "Noapte",
        Monday: "Luni",
        Tuesday: "Marți",
        Wednesday: "Miercuri",
        Thursday: "Joi",
        Friday: "Vineri",
        Saturday: "Sâmbătă",
        Sunday: "Duminică",
        current_credit_description: "Valoarea creditului din slot",
        no_more_credits_description: "Jucătorul nu mai are bani",
        deposit_amount_description: "Suma depusă de jucător",
        average_bet_amount_vs_city_description:
          "Media sumelor pariate de jucător, în comparație cu media sumelor pariate de obicei în acel oraș",
        bet_amount_description: "Suma pariată de jucător",
        average_bet_amount_vs_location_description:
          "Media sumelor pariate de jucător, în comparație cu media sumelor pariate de obicei la acea locație",
        total_reward_value_description:
          "Valoarea totală a premiilor oferite jucătorului",
        false_win_ratio_description:
          "Raportul dintre numărul de câștiguri false și numărul total de câștiguri, sau cât % dintre câștiguri sunt false. Un câștig este considerat drept “real” dacă suma câștigată este mai mare decât cea pariată",
        deposit_frequency_description:
          "Numărul mediu de depuneri efectuate de către jucător într-un interval de 10 minute",
        deposit_trend_description:
          "Modul în care evoluează valoarea depozitelor jucătorului",
        cash_out_description: "Jucătorul a retras toți banii din aparat",
        bet_trend_description:
          "Modul în care evoluează sumele pariate de către jucător",
        play_speed_trend_description:
          "Modul în care evoluează viteza de joc a jucătorului",
        auto_spin_enabled_description:
          "Jucătorul folosește funcționalitatea de auto-spin",
        break_piggy_bank_description: "Jucătorul a spart pușculița",
        play_streak_several_days_description:
          "Numărul de zile la rând în care jucătorul a fost activ",
        move_to_another_machine_description:
          "Jucătorul s-a mutat la alt aparat",
        online_player_description: "Jucătorul are profil online",
        winning_streak_description:
          "Numărul de câștiguri consecutive realizate de către jucător",
        losing_streak_description:
          "Numărul de spin-uri consecutive în care jucătorul a pierdut",
        false_wins_ratio_description:
          "Raportul dintre numărul de câștiguri false și numărul total de câștiguri, sau cât % dintre câștiguri sunt false. Un câștig este considerat drept “real” dacă suma câștigată este mai mare decât cea pariată",
        true_wins_ratio_description:
          "Raportul dintre numărul de câștiguri adevărate și numărul total de câștiguri, sau cât % dintre câștiguri sunt false. Un câștig este considerat drept “real” dacă suma câștigată este mai mare decât cea pariată",
        ggr_ratio_description:
          "Un GGR mai mare decat 1 corespunde situației în care casa câștigă",
        ggr_absolute_description: `Valoarea GGR-ului în ${CURRENCY_NAME}`,
        highest_win_ratio_description:
          "De câte ori este mai mare suma maximă câștigată la un spin, decât suma pariată. De ex: dacă a pariat 10 si a câștigat 50, raportul este 5",
        highest_win_absolute_description:
          "Cea mai mare sumă câștigată de jucător",
        day_of_month_description: "de ex: pentru 12.02.2022, aceasta va fi 12",
        day_of_week_description: "1 pentru Luni, 2 pentru Marți etc.",
        payday_description:
          "Este probabil ca data curentă să fie o zi de salariu?",
        holiday_description: "Este data curentă o zi liberă legală?",
        season_description: "Anotimpul curent",
        city_size_description: "Numărul de locuitori ai orașului curent",
        weather_description: "Vremea de afară la locație",
        time_of_day_description: "Partea din zi",
        "Sort by": "Sorteaza după",
        "Campaign Preview": "Previzualizare campanie",
        "Duplicate Campaign": "Duplicare campanie",
        "Total Profit Increase": "Creștere profit totală",
        "Profit Increase": "Creștere profit",
        "SMS Campaigns Funnel": "Funnel campanie SMS",
        Pool: "Total",
        "Message read": "Mesaj citit",
        "Code activated": "Cod activat",
        "Reward usage": "Premiu folosit",
        "Avg Profit per session": "Profit mediu per sesiune",
        "Avg Session Duration": "Durată medie a sesiunilor",
        "Avg Turnover per slot": "Rulaj mediu per slot",
        "Avg Profit per location": "Profit mediu per locație",
        "Ai Experiments": "Experimente AI",
        "There were generated 3 different campaigns based on the settings chosen by you. All data is in comparison to the Main campaign":
          "Au fost generate 3 campanii diferite pe baza setărilor alese. Toate datele sunt prezentate în comparație cu campania principală",
        "All Sessions": "Toate sesiunile",
        "This month": "Luna curentă",
        "Last Month": "Luna anterioară",
        "Campaign Conversions": "Conversia campaniilor",
        "Revenue Report": "Raport profit",
        "Earning": "Venituri",
        "Expense": "Cheltuieli",
        "Rewards Contribution for Profit Increase": "Contribuția premiilor la creșterea profitului",
        "Top Performing Campaigns": "Cele mai performante campanii",
        "Main Campaign": "Campania principală",
        "Best Performing AI Experiment": "Cel mai performant experiment AI",
        "click to view campaign": "Dă click pentru detalii",
        "Experiment Findings": "Informații descoperite de AI",
        "Best Performing Locations": "Cele mai performante locații",
        "Players Retention": "Strategii de retenție",
        "Best Performing Operators": "Cei mai performanți operatori",
        "Players wanted to withdraw money but continued playing": "Jucători care ar fi retras banii dar au continuat să joace",
        "Players came in and played becase of the rewards we’ve sent by sms": "Jucători care au venit să joace în urma primirii unui SMS",
        "Users decided to keep playing even if they lost money before": "Jucători care au continuat să joace chiar dacă au pierdut bani",
        "Players who decided to deposit more money when having no money left in the balance": "Jucători care au depozitat bani după ce au rămas fără credite",
        "rewards claimed out of": "Premii revendicate din",
        DEPOSIT: "DEPOZIT",
        "WIN AMOUNT": "SUMA CÂȘTIGATĂ",
        PROFIT: "PROFIT",
        "PLAY TIME": "TIMP DE JOC",
        LOCATION: "LOCAȚIE",
        "DATE&TIME": "DATA ȘI ORA",
        DOWNLOAD: "DESCARCĂ",
        "Campaign Overview": "Privire de ansamblu",
        "CAMPAIGN NAME": "NUME CAMPANIE",
        CHANNEL: "CANAL",
        "CAMPAIGN TARGETS": "TARGET-URILE CAMPANIEI",
        "CAMPAIGN TRIGGERS": "TRIGGERI",
        "CAMPAIGN SCHEDULE": "PLANIFICARE",
        "CAMPAIGN EXPERIMENT SETTINGS": "SETĂRI EXPERIMENTE",
        to: "la",
        Start: "Început",
        End: "Sfârșit",
        LEI: CURRENCY_NAME,
        "CAMPAIGN DETAILS": "DETALII CAMPANIE",
        Locations: "Locații",
        "No results yet": "Momentan nu există rezultate",
        "check back later": "verifică din nou mai târziu",
        "YOUR CAMPAIGN": "CAMPANIA TA",
        "AI EXPERIMENTS": "EXPERIMENTE AI",
        "The campaign will last": "Campania va dura",
        "No results yet,<br/> check back later":
          "Încă nu sunt rezultate,<br/> revino mai târziu",
        Conversion: "Conversie",
        Reach: "Reach",
        Profit: "Profit",
        "Average Profit/session": "Profit mediu per sesiune",
        Running: "În curs",
        Scheduled: "Planificată",
        Draft: "Nefinalizată",
        "Are you sure you want to delete this campaign?":
          "Sigur dorești să ștergi această campanie?",
        "Campaign Bonus On Cash Out will be deleted permanently. There is no posibility to restore it afterwards.":
          "Bonusul oferit la retragerea banilor va fi șters definitiv. Nu va mai putea fi restabilit ulterior.",
        Campaigns: "Campanii",
        CAMPAIGN_WILL_BE_DELETED: 'Campaign {{campaignId}} will be deleted permanently. There is no posibility to restore it afterwards. (RO)',
        "Create Campaign": "Creează Campanie",
        Profile: "Profil",
        Filter: "Filtre",
        Sort: "Sortare",
        Dashboard: "Dashboard",
        STATUS: "STATUS",
        ACTIONS: "ACȚIUNI",
        "Online campaign": "Campanie Online",
        "Offered via Newton LCD during gameplay": "Oferite prin LCD-ul Newton în timpul jocului",
        "Code sent by SMS during gameplay, to be used later on": "Cod trimis prin SMS în timpul jocului, pentru a fi utilizat ulterior",
        "Code sent manually by SMS to a list of players": "Cod trimis manual prin SMS unei liste de jucători",
        "Voucher code offered as offline campaign": "Cod voucher oferit printr-o campanie offline",
        "Campaign Comparison": "Comparație campanii",
        "Select how the reward will be offered":
          "Alege cum va fi oferit premiul",
        "NEWTON LCD": "NEWTON LCD",
        SMS: "SMS",
        Today: "astăzi",
        "Coming Soon": "urmeaza",
        "Just Passed": "a trecut",
        "Campaign Targets": "Target-urile campaniei",
        "Select the locations where the campaign will run":
          "Alegeți locațiile unde va rula campania",
        "All Locations": "Toate locațiile",
        "Custom Locations": "Selecție personalizată",
        "Reward Offering": "Oferirea premiului",
        "Specify the reward, and when it will be offered":
          "Alege premiul și condițiile în care va fi oferit",
        "Define campaign behaviours to trigger the reward":
          "Definiți comportamentele care vor declanșa oferirea premiului",
        If: "Dacă",
        "If ": "Dacă ",
        And: "Și",
        "Add a new condition": "Adăugă o condiție",
        offer: "oferă",
        ron: CURRENCY_NAME,
        get: "primește",
        "% of the turnover of the next": "% din rulajul următoarelor",
        spins: "rotiri",
        "Exclude slot sessions that claimed other rewards from existing, running campaigns":
          "---TO BE DELETED---",
        "Reward is available": "Premiul este disponibil",
        Hours: "ore",
        Days: "zile",
        Day: "zi",
        "Would you like to send a reminder ?":
          "Dorești să trimitem jucătorului un mesaj de reamintire?",
        "Campaign Schedule": "Planificarea campaniei",
        "Set the period for the campaign": "Configurează perioada",
        "Time and Date Start": "Ziua și ora de început",
        "Time and Date End": "Ziua și ora de sfârșit",
        "This campaign will last": "Această campanie va dura",
        " Day": " zi",
        " Days": " zile",
        day: "zi",
        Hour: "ora",
        "Campaign Experiment Settings": "Setări Experimente AI",
        "Adjust the control group and the AI group size in your campaign":
          "Configurează mărimea grupului de control și a grupului pentru experimente AI",
        "Estimated slots reached based on your criteria (location, channel, triggers) is <strong>aprox. 120 slot machines.</strong> <br /> Distribute bellow how many % out of the total go to Control group and AI experiment.":
          "Pe baza criteriilor selectate (locații, canal, triggeri), reach-ul estimat este de <strong>aprox. 120 sloturi.</strong><br /> Mai jos, distribuiți procentele din utilizatorii țintă care să fie alocate pentru grupul de control și pentru experimentare.",
        "Your campaign": "Campania Ta",
        "Your campaign est. reach": "Reach-ul estimat al campaniei definite",
        "Control group size": "Mărimea grupului de control",
        "Recommended values are between 15 - 20%":
          "Valori recomandate: între 15% și 20%",
        "AI experiment size": "Mărimea grupului de experimentare",
        "Set the AI experiment percentage":
          "Setați procentul alocat pentru experimentare",
        "Recommended values 25 - 35%": "Valori recomandate: între 25% și 35%",
        "There are no characteristics set up yet. <br />Start by choosing the name and the right channel":
          "Nu ai configurat nicio caracteristică. <br />Începe prin a alege numele campaniei și canalul pe care va fi trimisă",
        "Campaign Name": "Numele campaniei",
        Channel: "Canalul",
        "Campaign Triggers": "Trigerii campaniei",
        "The campaign will reach around": "Campania va ajunge la aproximativ",
        "slot machines": "sloturi",
        "From these": "Dintre acestea",
        "will be part of the Control Group and":
          "vor fi parte a grupului de control, iar",
        "will be used for AI experiments": "vor fi folosite pentru experimente",
        "Finish later": "Finalizează mai târziu",
        "Campaign Experiments Settings": "Setări experimente",
        "New Campaign": "Campanie nouă",
        "Newton LCD": "Newton LCD",
        "Estimated slots reached based on your criteria (location, channel, triggers) is <strong>aprox.</strong>":
          "Pe baza criteriilor selectate (locații, canal, triggeri), reach-ul estimat este de <strong>aprox.</strong>",
        "Distribute bellow how many % out of the total go to Control group and AI experiment":
          "Mai jos, distribuie procentele din utilizatorii țintă care să fie alocate pentru grupul de control și pentru experimentare",
        "There are no characteristics set up yet. <br/>Start by choosing the name and the right channel":
          "Încă nu ai configurat nicio caracteristică. <br />Începe prin a alege numele campaniei și canalul pe care va fi trimisă.",
        Immediately: "Imediat",
        "Search by campaign name or keywords...": "Caută campanie dupa nume...",
        "AVG PROFIT/SESSION": "PROFIT MEDIU/SESIUNE",
        "AVG PLAY TIME": "TIMP JOC MEDIU",
        CONVERSIONS: "CONVERSII",
        Yes: "Da",
        No: "Nu",
        "If the customer": "Dacă jucătorul",
        " and": " și",
        ", he receives": ", primește",
        "The reward is available": "Premiul va fi acordat",
        immediately: "imediat",
        constant: "constant",
        increasing: "în creștere",
        decreasing: "în scădere",
        True: "adevărat",
        False: "fals",
        lower: "mai mică",
        same: "egală",
        higher: "mai mare",
        deposit: "introduce",
        winBack: "primește înapoi",
        quantity: "cantitate",
        average_bet_amount_vs_city: "Suma medie pariată vs media orașului",
        bet_amount: "Suma pariată",
        average_bet_amount_vs_location: "Suma medie pariată vs media locației",
        total_reward_value: "Valoare totală premii",
        false_win_ratio: "Raportul de câștiguri false",
        no_more_credits: "Credit epuizat",
        time_of_day: "Perioada din zi",
        deposit_amount: "Suma depozitată",
        deposit_frequency: "Frecvența depozitelor",
        deposit_trend: "Trend-ul depozitelor",
        cash_out: "Retragere bani",
        bet_trend: "Trend-ul sumelor pariate",
        play_speed_trend: "Trend-ul vitezei de joc",
        auto_spin_enabled: "Auto-spin activat",
        round_credit: "Sumă rotundă credite",
        break_piggy_bank: "Sparge pușculița",
        play_streak_several_days: "Jocuri mai multe zile la rând",
        move_to_another_machine: "Mutare la alt slot",
        online_player: "Jucător online",
        winning_streak: "Mai multe câștiguri la rând",
        losing_streak: "Mai multe pierderi la rând",
        false_wins_ratio: "Raportul de câștiguri false",
        true_wins_ratio: "Raportul de câștiguri adevărate",
        ggr_ratio: "Procentul GGR",
        ggr_absolute: "GGR absolut",
        highest_win_ratio: "Raport câștig maxim vs suma pariată",
        highest_win_absolute: "Câștigul cel mai mare",
        day_of_month: "Ziua din lună",
        day_of_week: "Ziua din săptămână",
        current_credit: "Credit curent",
        payday: "Zi de salariu",
        holiday: "Zi liberă legală",
        season: "Anotimp",
        city_size: "Mărimea orașului",
        weather: "Vremea de afară",
        FreeSpins: "Rotiri gratuite",
        FreeMoney: "Bonus în bani",
        PercentBack: "Procent din rulaj",
        WheelOfFortune: "Învârte roata norocului",
        BuyABonus: "Cumpără un bonus",
        RaffleTicket: "Bilet de tombolă",
        "starting on": "începând de pe",
        "and ending on": "până pe",
        January: "Ianuarie",
        February: "Februarie",
        March: "Martie",
        April: "Aprilie",
        May: "Mai",
        June: "Iunie",
        July: "Iulie",
        August: "August",
        September: "Septembrie",
        November: "Noiembrie",
        December: "Decembrie",
        "Download CSV": "Descarcã CSV",
        Clear: "Reseateazã",
        Status: "Stare",
        Ended: "Terminatã",
        Location: "Locație",
        Operator: "Operator",
        "Campaign ID Ascending": "ID Campanie - Crescãtor",
        "Campaign ID Descending": "ID Campanie - Descrescãtor",
        "Campaign Name A-Z": "Nume campanie A-Z",
        "Campaign Name Z-A": "Nume campanie Z-A",
        "Conversion Ascending": "Conversie - Crescãtor",
        "Conversion Descending": "Conversie - Descrescãtor",
        "Avg Profit/session Ascending": "Profit mediu/sesiune - Crescãtor",
        "Avg Profit/session Descending": "Profit mediu/sesiune - Descrescãtor",
        "Reach Ascending": "Reach - Crescãtor",
        "Reach Descending": "Reach - Descrescãtor",
        Show: "Afișează",
        "No options": "Niciun rezultat",
        "Multiple locations": "Locații multiple",
        Settings: "Setări",
        "Campaign General Settings": "Setări generale ale campaniilor",
        "Define how the rewards should be targeted among different campaign types": "Definește cum se vor targeta premiile între diferitele tipuri de campanii",
        "Base campaigns": "Campanii de bază",
        "The percentage of rewards that will originate in base campaigns": "Procentul de premii cu originea în campanii de bază",
        "Variations": "Variații",
        "The percentage of rewards that will originate in variations": "Procentul de premii cu originea în variații",
        "AI generated": "Generate de AI",
        "The percentage of rewards that will originate in campaigns generated in real time by AI": "Procentul de premii cu originea în campanii generate de AI",
        "Control Group": "Grup de control",
        "The percentage of rewards that will represent the control group": "Procentul de premii care vor reprezenta grupul de control",
        Budgets: "Bugete",
        "Budget Allocation": "Alocare buget",
        "Define the amount of money you are willing to invest in engagement strategies": "Stabilește câți bani vrei să investești în strategiile de player engagement",
        "The operator that you wish to configure": "Operatorul pe care dorești să-l configurezi",
        Investment: "Investiție",
        "The amount of money you are willing to spend for all campaigns": "Suma pe care ești dispus să o cheltui pentru premii, din bugetul propriu",
        "All operators": "Toți operatorii",
        "Reinvested winnings percentage": "Procentul de reinvestire a câștigurilor",
        "The percentage of winnings that you wish to reinvest in rewards": "Procentul din valoarea câștigurilor pe care dorești să-l reinvestești în premii",
        "Budget per Location": "Buget per locație",
        "Define how the budget should be used for each location": "Stabilește modul de distribuire a bugetului pe locații",
        "Equal spread among all locations": "Distribuie bugetul în mod egal pe toate locațiile",
        "Individual budget for each location": "Buget individual pe fiecare locație",
        "LOCATION NAME": "NUME LOCAȚIE",
        "Budget (LEI)": `Buget (${CURRENCY_NAME})`,
        Save: "Salvează",
        "Multiple values": "Valori multiple",
        run: 'ruleaza',
        "to get": "pentru a primi",
        "get back": "primește înapoi",
        "for the next": "pentru următoarele",
        amount: "suma",
        "Campaign details": "Detalii campanie",
        Duplicate: "Duplică",
        Edit: "Modifică",
        Pause: "Pauză",
        Delete: "Șterge",
        Hello: "Salut",
        // Campaign types
        Online: 'ONLINE',
        Lcd: 'LCD',
        Sms: 'SMS',
        SmsManual: 'SMS Manual',
        Voucher: 'Voucher',
        // Reward offering
        "Direct message": "Mesaj direct",
        "Wheel of Fortune": "Roata norocului",
        "Player action needed for claiming the reward": "Acțiunea jucătorului necesară pentru a revendica premiul",
        "No action needed": "Nicio acțiune necesară",
        "Pay": "Depune",
        "Run": "Rulează",
        "Enter phone number": "Introduce numărul de telefon",
        "Player action needed for cashing out the reward": "Acțiunea jucătorului necesară pentru a putea scoate banii aferenți premiului",
        "No condition": "Nicio acțiune necesară",
        "Reward becomes available": "Premiul devine disponibil",
        "Immediatly": "Imediat",
        "at": "la ora",
        "In": "În",
        "Reward expires": "Premiul expiră",
        "Time limit": "Limită timp",
        "Type here the text that will be sent to the player's phone": "Introdu aici textul care va fi trimis către telefonul jucătorului",
        "SMS text": "Conținut SMS",
        "Reminder SMS": "SMS de reamintire",
        "Don't send": "Nu trimite",
        "before the reward is available": "înainte ca premiul să devină disponibil",
        "At": "La ora",
        "on the day when the reward becomes available": "în ziua în care premiul devine disponibil",
        "Reminder SMS text": "Conținut SMS de reamintire",
        "Expiration Reminder SMS": "SMS de reamintire pentru expirare",
        "Expiration reminder SMS text": "Conținut SMS de reamintire pentru expirare",
        "To": "Către",
        "the following list of phone numbers": "următoarea listă de numere de telefon",
        "Browse": "Navighează",
        "Reward Presentation": "Prezentarea premiului",
        "generated voucher codes": "coduri de voucher generate",
        "This campaign will target all operators and locations": "Această campanie va targeta toți operatorii și toate locațiile acestora",
        "Total Machines enrolled": "Număr sloturi înrolate",
        "BonusCash": "Bonus Cash",
        "each value": "valoare individuală",
        "": "",
        "minutes": "minute",

        "Type here the text that will be sent to the player's phone...": "Introdu textul care va fi trimis către telefonul jucătorului",

        'before the reward expires': 'înainte ca premiul să expire',
        'on the day when the reward expires': 'în ziua în care premiul expiră',

        "Type here the reminder text that will be sent to the player's phone...": "Introdu textul de reamintire care va fi trimis către telefonul jucătorului",
        "Type here the expiration reminder text that will be sent to the player's phone...": "Introdu textul de reamintire a expirării care va fi trimis către telefonul jucătorului",

        'No file selected': 'Niciun fișier selectat',

        "Define the way the value will be calculated": "Definește modul în care va fi calculată valoarea",
        "Fixed amount of": "Valoare fixă de",
        "Dynamically calculated amount based on": "Valoare calculată dinamic în funcție de",
        "Multiplied by": "Înmulțit cu",
        "Minimum": "Minim",
        "Maximum": "Maxim",
        "Multiple of": "Multiplu de",
        "Variation +/-": "Variație +/-",
        "Rounding affects": "Rotunjirea afectează",
        "The multiplier": "Multiplicatorul",
        "The final amount": "Suma finală",
        "AI-generated Variations": "Variații generate de AI",

        "Search for clients, locations, slots or games": "Cautare după operatori, locații, sloturi sau jocuri",
        "Select": "Alege locațiile",
        "reward_value": "Valoarea premiului",
        "reward_quantity": "Cantitatea premiului",
        "Dynamic value": "Valoare dinamică",

        balance_ratio: "Raport procentual balanță",
        balance_ratio_description: "Raportul procentual dintre suma curentă și suma totală depozitată",
        bet_trend_short_term: "Trend-ul bet-urilor",
        bet_trend_short_term_description: "Modul în care evoluează bet-urile",
        duration: "Durata sesiunii",
        duration_description: "Durata sesiunii, în minute",
        number_of_spins: "Numărul de rotiri",
        number_of_spins_description: "Numărul total de rotiri pe care le-a efectuat jucătorul în cadrul sesiunii curente",
        play_speed_trend_short_term: "Trend-ul vitezei de joc",
        play_speed_trend_short_term_description: "Modul în care evoluează viteza de joc",
        initial_deposit: "Depunere inițială",
        initial_deposeit_description: "Valoarea depunerii inițiale în LEI",
        round_credit_description: "Valoarea creditului din aparat este o sumă rotundă",

        Statistics: "Statistics (RO)",
        "Total Campaign Conversions": "Total Campaign Conversions (RO)",
        "Reward Usage": "Reward Usage (RO)",
        "phone number loaded": "număr de telefon încărcat",
        "phone numbers loaded": "numere de telefon încărcate",

        "Codes sent": "Coduri trimise",
        "Used codes": "Coduri folosite",
        "Expired codes": "Coduri expirate",
        "Codes generated": "Coduri generate",

        "This campaign will target {{numberOfLocations}} location(s) over {{numberOfOperators}} operator(s) ({{numberOfSlots}} slots)": "This campaign will target {{numberOfLocations}} location(s) over {{numberOfOperators}} operator(s) ({{numberOfSlots}} slots) (RO)",

        INVALID_CAMPAIGN_SCHEDULE: "Invalid Campaign Schedule (RO)",
        START_DATE_AFTER_END_DATE: "Invalid Campaign Schedule: Start Date cannot be after End Date (RO)",
        PAST_START_DATE: "Invalid Campaign Schedule: Start Date cannot be in the past (RO)",
        NULL_BEHAVIORAL_TRIGGERS: "Behavioral Triggers cannot be null (RO)",
        NULL_TIME_TRIGGERS: "Time Triggers cannot be null (RO)",
        NULL_ENVIRONMENTAL_TRIGGERS: "Environmental Triggers cannot be null (RO)",
        EMPTY_TARGET: "You must select at least 1 valid target (RO)",
        MANUAL_CAMPAIGN_STATUS: "Manual campaigns must have status: Manual (RO)",
        INVALID_CAMPAIGN_STATUS: "Campaign can't have status - {campaign.Status} (RO)",
        EMPTY_EXPERIMENT_SETTINGS: "Experiment Settings cannot be empty (RO)",
        INVALID_EXPERIMENT_SETTINGS: "Experiment Settings Validation Error (RO)",
        INVALID_TIME_TRIGGER_TYPE: "Time Triggers Type Validation Error (RO)",
        INVALID_TIME_TRIGGER_RULE: "Time Triggers Rule Validation Error (RO)",
        INVALID_ENVIRONMENTAL_TRIGGER_TYPE: "Environmental Triggers Type Validation Error (RO)",
        INVALID_ENVIRONMENTAL_TRIGGER_RULE: "Environmental Triggers Rule Validation Error (RO)",
        INVALID_BEHAVIORAL_TRIGGER_TYPE: "Behavioral Triggers Type Validation Error (RO)",
        INVALID_BEHAVIORAL_TRIGGER_RULE: "Behavioral Triggers Rule Validation Error (RO)",
        INVALID_REWARD_VALUE: "Invalid reward value (RO)",
        INVALID_REWARD_QUANTITY: "Invalid reward quantity (RO)",
        INVALID_ACTIVATION_CONDITION_VALUE: "Invalid activation condition value (RO)",
        INVALID_CASH_OUT_RESTRICTION_VALUE: "Invalid cash out restriction value (RO)",
        INVALID_NUMBER_OF_VOUCHERS: "Invalid number of vouchers (RO)",
        INVALID_PHONE_NUMBER: "Invalid phone number (RO)",
        CAMPAIGN_NOT_FOUND: "Campaign not found (RO)",
        CANNOT_UPDATE_MANUAL_CAMPAIGN: "Manual campaigns cannot be updated! (RO)",
        CAMPAIGN_ALREADY_STARTED: "Campaign has already started (RO)",

        ESTIMATED_SLOTS_REACHED: `Estimated slots reached based on your criteria (location, channel, triggers) is aprox. {{numberOfSlots}} slot machines. (RO)`,

        CAMPAIGN_WILL_REACH: `The campaign will reach around {{numberOfSlots}} slot machines. From these {{campaignGroupValue}}% will be part of the Control Group and {{campaignExperimentValue}}% will be used for AI experiments. (RO)`,
        CAMPAIGN_WILL_REACH_ALL: `The campaign will reach all slot machines. From these {{campaignGroupValue}}% will be part of the Control Group and {{campaignExperimentValue}}% will be used for AI experiments. (RO)`,

        'Delete Campaign': 'Delete Campaign (RO)',

        'Average over the selected period': 'Average over the selected period (RO)',
        'Activation Place': 'Activation Place (RO)',
        'Amount Offered vs Used per Location': 'Amount Offered vs Used per Location (RO)',

        'AI Performance': 'AI Performance (RO)',
        Details: 'Details (RO)',
        'No data available': 'No data available (RO)',
        Overview: 'Overview (RO)',
        'Total slots': 'Total slots (RO)',
        'Total played sessions': 'Total played sessions (RO)',
        'Total played spins': 'Total played spins (RO)'
      }
    }
  },
  lng: document.querySelector("html").lang,
  fallbackLng: localStorage.getItem("lng") || "en",
  debug: false,
  bindI18n: "loaded languageChanged",
  bindI18nStore: "added",
  useSuspense: true
});

export default i18n;
