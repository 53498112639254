export type FixedDynamicValueType = {
  type: "Fixed" | 'Dynamic',
  fixedValue: number,
  basedOn: string,
  multipliedBy: number,
  minimum: number,
  maximum: number,
  multipleOf: number;
  aiAmount: number,
  roundingAffects: string
};

export const FIXED_DYNAMIC_VALUE_DEFAULT: FixedDynamicValueType = {
  type: "Fixed",
  fixedValue: 0,
  basedOn: "",
  multipliedBy: 0,
  minimum: 0,
  maximum: 0,
  multipleOf: 10,
  aiAmount: 0,
  roundingAffects: "multiplier"
};

export interface CampaignBody {
  name: string;
  status: string;
  schedule: {
    startDate: Date;
    endDate: Date;
  },
  scenario: {
    behavioralTriggers: any;
    timeTriggers: any;
    environmentalTriggers: any;
  },
  channel: string;
  type: string;
  content: string;
  reward: {
    kind: string | null;
    value: number,
    quantity: number,
    validity: number;
    delay: number;
    activationCondition?: {
      name: string,
      value?: number,
    },
    cashOutRestriction?: {
      name: string,
      value?: number,
    },
    presentation?: {
      type: string;
    },
  };
  voucherDetails: {
    numberOfVouchers: number;
  },
  target: {
    allLocations: boolean,
    operators: string[];
    locations: string[];
    slots: string[];
  };
  experimentSettings: {
    target: number;
    control: number;
    experiment: number;
  };
  smsManualDetails: {
    phoneNumbers: string[];
  };
}
