import { createSelector } from "@reduxjs/toolkit";

const selectSelf = (state: any) => state.campaigns;
export const campaignsSelector = createSelector(
  selectSelf,
  (state) => state.data
);

export const campaignsPaginationSelector = createSelector(
  selectSelf,
  (state) => state.paginationDetails
);

export const campaignsSearchedSelector = createSelector(
  selectSelf,
  (state) => state.searchedCampaigns
);

export const campaignParamsSelector = createSelector(
  selectSelf,
  (state) => state.campaignParams
);
