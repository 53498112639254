import { Box, Menu, MenuItem, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaigns } from "store/campaigns/campaign.actions";
import { campaignsPaginationSelector } from "store/campaigns/campaigns.selector";
import { sortArray } from "shared/constants";
import { useTranslation } from "react-i18next";
import { MAIN_COLOR } from "./styles/constants.styles";

interface Props {
  anchorEl: any;
  setAnchorEl: any;
}

export const SortingMenu = ({ anchorEl, setAnchorEl }: Props) => {
  const dispatch = useDispatch();
  const paginationDetails = useSelector(campaignsPaginationSelector);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClose = (sortBy: string, descending: boolean) => {
    dispatch(
      getCampaigns({
        page: 1,
        pageSize: paginationDetails.pageSize,
        sortBy: sortBy,
        descending: descending,
        statuses: paginationDetails.statuses,
        channels: paginationDetails.channels
      })
    );
  };

  const handleClear = () => {
    dispatch(
      getCampaigns({
        sortBy: " ",
        descending: false,
        page: 1,
        pageSize: paginationDetails.pageSize,
        channels: paginationDetails.channels,
        statuses: paginationDetails.statuses
      })
    );
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      onClick={() => setAnchorEl(null)}
      open={open}
      MenuListProps={{
        "aria-labelledby": "basic-button"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Box
          sx={{
            fontWeight: "bold",
            ml: 2,
            mt: 2,
            mb: 1
          }}
        >
          {t("Sort by")}
        </Box>
        <Box
          onClick={handleClear}
          sx={{
            fontWeight: "normal",
            fontSize: 12,
            mr: 2,
            cursor: "pointer"
          }}
        >
          {t("Clear")}
        </Box>
      </Box>
      <RadioGroup>
        {sortArray.map((el) => (
          <MenuItem
            onClick={() => handleClose(el.sortBy, el.descending)}
            sx={{
              ml: 1,
              width: 320,
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            key={el.id}
          >
            <Radio
              sx={{
                "&.Mui-checked": {
                  color: MAIN_COLOR.EXTRA_LIGTH
                }
              }}
              size="small"
              value={el.value}
              checked={
                el.sortBy === paginationDetails.sortBy &&
                el.descending === paginationDetails.descending
              }
            />
            <Box sx={{ fontSize: 14 }}>{t(el.text)}</Box>
          </MenuItem>
        ))}
      </RadioGroup>
    </Menu>
  );
};
