import { Box } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GREY_COLOR } from "./styles/constants.styles";

interface Props {
  name: string;
  startDate: string;
  endDate: string;
  numberOfDays: string;
  locations: string;
}

export const CustomDetails = ({
  name,
  startDate,
  endDate,
  numberOfDays,
  locations
}: Props) => {
  const { t } = useTranslation();

  const newStartDate = new Date(startDate);
  const newEndDate = new Date(endDate);
  const newStartMonth = new Intl.DateTimeFormat("en-US", {
    month: "long"
  }).format(newStartDate);
  const newStartYear = newStartDate.getFullYear();
  const newEndMonth = new Intl.DateTimeFormat("en-US", {
    month: "long"
  }).format(newEndDate);
  const newEndYear = newEndDate.getFullYear();

  const displayNrOfDays = Math.max(1, Number(numberOfDays));

  const dateDetails = `${displayNrOfDays} ${t(
    displayNrOfDays > 1 ? "Days" : "Day"
  )} (${newStartDate.getDate()} ${newStartMonth != t(newEndMonth) ? t(newStartMonth) : ""
    } ${newStartYear != newEndYear ? newStartYear : ""
    }- ${newEndDate.getDate()} ${t(newEndMonth)} ${newEndYear})`;

  return (
    <Box>
      <Box sx={{ fontWeight: 600, fontSize: 14, color: GREY_COLOR.EXTRA_DARK }}>{name}</Box>
      <Box sx={{ fontSize: 12, color: GREY_COLOR.LIGHT }}>{dateDetails}</Box>
      <br></br>
      <Box>
        <Trans>Locations</Trans>: {locations}
      </Box>
    </Box>
  );
};
