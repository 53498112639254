import styled from "styled-components";
import { MAIN_COLOR } from "./constants.styles";

const CreateButton = styled.button`
  width: 100%;
  height: 37px;
  background: ${MAIN_COLOR.EXTRA_LIGTH};
  border-radius: 10px;
  box-shadow: 0;
  letter-spacing: 0.4px;
  border-width: 0;
  margin-right: 32px;
  color: #FFFFFF;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .buttonText {
    color: ${MAIN_COLOR.NORMAL};
    size: 14px;
    line-height: 17px;
    font-weight: 501;
  }
  &:active, &:hover {
    background-color: ${MAIN_COLOR.NORMAL};
  }
`;

export default CreateButton;
