import { FormControlLabel, Radio, TextField } from "@mui/material";
import { MAIN_COLOR } from "components/styles/constants.styles";
import { TypographyDescription } from "components/styles/TypographyCardTitle";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import FixedDynamicValue from "./FixedDynamicValue";

const CenteredContent = styled.div<{ size?: number; }>`
    display: grid;
    grid-template-columns: ${props => props.size}px 192px 28px;
    align-items: center;
    grid-column-gap: 8px;
`;

interface Props {
    label: string,
    checked: boolean,
    name: string;
    onValueChange?: (event: any) => void;
    value?: number;
    valueKey: string;
    getValueByKey: (value: string) => void;
    disabled: boolean;
    dynamicValueSpecs: string[];
}

const TextInputOption = ({
    label,
    checked,
    name,
    getValueByKey,
    valueKey,
    disabled,
    dynamicValueSpecs,
}: Props) => {
    const { i18n } = useTranslation();
    const size = useMemo(() => i18n.language === 'ro' ? 86 : 86, [i18n.language]);

    return (
        <FormControlLabel
            value={name}
            control={
                <Radio
                    checked={checked}
                    sx={{
                        "&.Mui-checked": {
                            color: MAIN_COLOR.EXTRA_LIGTH
                        }
                    }}
                />
            }
            label={
                <TypographyDescription>
                    <CenteredContent size={size}>
                        {label}
                        <FixedDynamicValue
                            getValueByKey={getValueByKey}
                            valueKey={valueKey}
                            disabled={disabled}
                            dynamicValueSpecs={dynamicValueSpecs}
                        />
                        <Trans>LEI</Trans>
                    </CenteredContent>
                </TypographyDescription>
            }
        />
    );
};

export default TextInputOption;