import { takeEvery, put, all } from "redux-saga/effects";
import { LOGIN, APP_USERNAME } from "./auth.constants";
import { PayloadAction } from "@reduxjs/toolkit";
import { setAppUsername, setAuthData } from "./auth.reducer";
import { loginRequest } from "api/auth.service";
import { Token } from "Interfaces/IToken";

function* loginHandler(
  action: PayloadAction<{ email: string; password: string; }>
) {
  try {
    // make login request
    // save token in localStorage
    // save result
    const token: Token = yield loginRequest(
      action.payload.email,
      action.payload.password
    );

    yield put(
      setAuthData({
        token: token
      })
    );

    if (token.success) {
      localStorage.setItem("token", token.data.access_token);
      yield put(setAuthData({
        token: token,
        username: token.data.username
      }));
    }
  } catch (error) {
    console.warn(error);
  }
}

function* getAppUsernameHandler(action: PayloadAction<{
  username?: string;
  userType: string;
  profilePicture?: string;
}>) {
  yield put(setAppUsername({ userData: { ...action.payload } }));
}

export default function* authSaga() {
  yield all([
    takeEvery(LOGIN, loginHandler),
    takeEvery(APP_USERNAME, getAppUsernameHandler),
  ]);
}
