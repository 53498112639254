import { MAIN_COLOR } from "components/styles/constants.styles";
import styled from "styled-components";

const LeftBox = styled.button`
  background-color: ${MAIN_COLOR.EXTRA_LIGTH};
  color: white;
  border: none;
  max-width: 333px;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export default LeftBox;
