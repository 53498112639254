import {
  Box,
  FormControl,
  FormControlLabel,
  Icon,
  Radio,
  RadioGroup,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextareaAutosize
} from "@mui/material";
import React from "react";
import NumberIcon from "../assets/icons/number-one-icon.svg";
import CheckedIcon from "../assets/icons/checked-icon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Trans, useTranslation } from "react-i18next";
import { CampaignAccordionSection } from "./styles/CampaignAccordionSection";
import { MAIN_COLOR } from "./styles/constants.styles";
import { isChannelValid } from "./campaignPreviewUtils";
import BorderedAccordionSummary from "./BorderedAccordionSummary";

interface Props {
  setChannel: (
    channel: string
  ) => void;
  expanded: string | false;
  handlePanelChange: (
    panel: string
  ) => (event: any, isExpanded: boolean) => void;
  channel: string;
}

// Online,
// Lcd,
// Sms,
// SmsManual,
// Voucher

export const SelectChannel = ({
  setChannel,
  expanded,
  handlePanelChange,
  channel,
}: Props) => {
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChannel((event.target as HTMLInputElement).value);
  };

  return (
    <CampaignAccordionSection
      expanded={expanded === "channelPannel"}
      onChange={handlePanelChange("channelPannel")}
    >
      <BorderedAccordionSummary>
        <Box>
          <Box
            sx={{
              fontSize: 15,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center"
            }}
          >
            <Icon sx={{ overflow: "visible", mr: 1, mb: 0.8 }}>
              <img src={isChannelValid({ campaignChannel: channel }) ? CheckedIcon : NumberIcon} alt="1" />
            </Icon>
            <Box>
              <Trans>Channel</Trans>
            </Box>
          </Box>
          <Box sx={{ fontSize: 13, ml: 4 }}>
            <Trans>Select how the reward will be offered</Trans>.
          </Box>
        </Box>
      </BorderedAccordionSummary>
      <AccordionDetails>
        <FormControl>
          <RadioGroup onChange={handleChange}>
            <FormControlLabel
              value="Online"
              control={
                <Radio
                  checked={channel === "Online"}
                  sx={{
                    "&.Mui-checked": {
                      color: MAIN_COLOR.EXTRA_LIGTH
                    }
                  }}
                />
              }
              label={
                <strong>
                  <Trans>Online campaign</Trans>
                </strong>
              }
            />
          </RadioGroup>          
          <RadioGroup onChange={handleChange}>
            <FormControlLabel
              value="Lcd"
              control={
                <Radio
                  checked={channel === "Lcd"}
                  sx={{
                    "&.Mui-checked": {
                      color: MAIN_COLOR.EXTRA_LIGTH
                    }
                  }}
                />
              }
              label={
                <strong>
                  <Trans>Offered via Newton LCD during gameplay</Trans>
                </strong>
              }
            />
          </RadioGroup>
          <RadioGroup onChange={handleChange}>
            <FormControlLabel
              value="Sms"
              control={
                <Radio
                  checked={channel === "Sms"}
                  sx={{
                    "&.Mui-checked": {
                      color: MAIN_COLOR.EXTRA_LIGTH
                    }
                  }}
                />
              }
              label={
                <strong>
                  <Trans>Code sent by SMS during gameplay, to be used later on</Trans>
                </strong>
              }
            />
          </RadioGroup>
          <RadioGroup onChange={handleChange}>
            <FormControlLabel
              value="SmsManual"
              control={
                <Radio
                  checked={channel === "SmsManual"}
                  sx={{
                    "&.Mui-checked": {
                      color: MAIN_COLOR.EXTRA_LIGTH
                    }
                  }}
                />
              }
              label={
                <strong>
                  <Trans>Code sent manually by SMS to a list of players</Trans>
                </strong>
              }
            />
          </RadioGroup>
          <RadioGroup onChange={handleChange}>
            <FormControlLabel
              value="Voucher"
              control={
                <Radio
                  checked={channel === "Voucher"}
                  sx={{
                    "&.Mui-checked": {
                      color: MAIN_COLOR.EXTRA_LIGTH
                    }
                  }}
                />
              }
              label={
                <strong>
                  <Trans>Voucher code offered as offline campaign</Trans>
                </strong>
              }
            />
          </RadioGroup>
        </FormControl>
      </AccordionDetails>
    </CampaignAccordionSection>
  );
};
