import styled from "styled-components";
import { MAIN_COLOR } from "./constants.styles";

const CancelButton = styled.button`
  width: 180px;
  height: 37px;
  border-radius: 10px;
  background-color: #f8f8f8;
  box-shadow: 0;
  letter-spacing: 0.4px;
  border: 1.5px solid black;
  margin-right: 32px;
  color: black;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat, sans-serif;
  cursor: pointer;
  .buttonText {
    color: ${MAIN_COLOR.NORMAL};
    size: 14px;
    line-height: 17px;
    font-weight: 500;
  }
  &:hover {
    background-color: ${MAIN_COLOR.EXTRA_EXTRA_LIGHT};
  }
`;

export default CancelButton;
