import React from 'react';
import styled from "styled-components";

const ContentContainer = styled.div`
  max-width: 1700px;
  width: 100%;
  margin: 0 auto;
`;

const MainContainer = styled.div`
  margin-right: calc(-1 * (100vw - 100%));
`;

interface Props {
  children: any;
}

const NavBar = ({ children }: Props): JSX.Element => {
  return (
    <MainContainer>
      <ContentContainer>
        {children}
      </ContentContainer>
    </MainContainer>
  );
};

export default NavBar;
