import {
  Box,
  Icon,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField
} from "@mui/material";
import React from "react";
import NumberIcon from "../assets/icons/number-five-icon.svg";
import CheckedIcon from "../assets/icons/checked-icon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Trans, useTranslation } from "react-i18next";
import { CampaignAccordionSection } from "./styles/CampaignAccordionSection";
import { isCampaignExperimentSettingsValid } from "./campaignPreviewUtils";
import BorderedAccordionSummary from "./BorderedAccordionSummary";
import { getSlotsNumbers } from "./slotsUtils";

interface Props {
  expanded: string | false;
  handlePanelChange: (
    panel: string
  ) => (event: any, isExpanded: boolean) => void;
  groupValue: number | null;
  experimentValue: number | null;
  setGroupValue: any;
  setExperimentValue: any;
  slots: string[];
}

export const SelectExperimentSettings = ({
  expanded,
  handlePanelChange,
  groupValue,
  experimentValue,
  setGroupValue,
  setExperimentValue,
  slots,
}: Props) => {
  const { t } = useTranslation();

  let reach;
  if (groupValue && experimentValue) {
    reach = 100 - (+groupValue + +experimentValue);
  } else if (!groupValue && experimentValue) {
    reach = 100 - +experimentValue;
  } else if (groupValue && !experimentValue) {
    reach = 100 - +groupValue;
  } else {
    reach = 100;
  }

  const handleChangeGroup = (event: any) => {
    if (Number(event.target.value) > 100) {
      return;
    }

    if (
      isNaN(event.target.value) ||
      (groupValue &&
        experimentValue &&
        +event.target.value + +experimentValue > 100)
    ) {
      return;
    }

    // prevent having value starting with "0"
    const value = Number(event.target.value).toString();
    setGroupValue(value);
  };

  const handleChangeExperiment = (event: any) => {
    if (Number(event.target.value) > 100) {
      return;
    }

    if (
      isNaN(event.target.value) ||
      (groupValue && experimentValue && +event.target.value + +groupValue > 100)
    ) {
      return;
    }

    // prevent having value starting with "0"
    const value = Number(event.target.value).toString();
    setExperimentValue(value);
  };

  return (
    <CampaignAccordionSection
      expanded={expanded === "experimentPannel"}
      onChange={handlePanelChange("experimentPannel")}
    >
      <BorderedAccordionSummary>
        <Box>
          <Box
            sx={{
              fontSize: 15,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center"
            }}
          >
            <Icon sx={{ overflow: "visible", mr: 1, mb: 0.8 }}>
              <img
                src={isCampaignExperimentSettingsValid({ campaignGroupValue: groupValue, campaignExperimentValue: experimentValue }) ? CheckedIcon : NumberIcon}
              />
            </Icon>
            <Box>
              <Trans>Campaign Experiment Settings</Trans>
            </Box>
          </Box>
          <Box sx={{ fontSize: 13, ml: 4 }}>
            <Trans>
              Adjust the control group and the AI group size in your campaign
            </Trans>
            .
          </Box>
        </Box>
      </BorderedAccordionSummary>
      <AccordionDetails>
        <Box sx={{ ml: 4, mb: 3 }}>
          <Box sx={{ fontSize: 14 }}>
            {slots.length !== 0 && t('ESTIMATED_SLOTS_REACHED', { numberOfSlots: slots.length })}
            <br />{" "}
            <Trans>
              Distribute bellow how many % out of the total go to Control group
              and AI experiment
            </Trans>
            .
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ fontSize: 16, mt: 2, width: 290 }}>
              <strong>
                <Trans>Your campaign</Trans>
              </strong>
              <Box sx={{ fontSize: 13 }}>
                <Trans>Your campaign est. reach</Trans>
              </Box>
            </Box>
            <Box sx={{ ml: 21 }}> {reach}%</Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ fontSize: 16, mt: 2, width: 290 }}>
              <strong>
                <Trans>Control group size</Trans>
              </strong>
              <Box sx={{ fontSize: 13 }}>
                <Trans>Set the control group size</Trans>.
                <br /> <Trans>Recommended values are between 15 - 20%</Trans>.
              </Box>
            </Box>
            <Box sx={{ ml: 20, display: "flex", alignItems: "center" }}>
              <TextField
                onChange={handleChangeGroup}
                error={
                  groupValue == null
                    ? false
                    : groupValue > 100 || groupValue < 0
                }
                value={groupValue}
                sx={{ width: 55 }}
                size="small"
              />
              &nbsp;%
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ fontSize: 16, width: 290, mt: 2 }}>
              <strong>
                <Trans>AI experiment size</Trans>
              </strong>
              <Box sx={{ fontSize: 13 }}>
                <Trans>Set the AI experiment percentage</Trans>.
                <br /> <Trans>Recommended values 25 - 35%</Trans>.
              </Box>
            </Box>
            <Box sx={{ ml: 20, display: "flex", alignItems: "center" }}>
              <TextField
                value={experimentValue}
                error={
                  experimentValue == null
                    ? false
                    : experimentValue > 100 || experimentValue < 0
                }
                onChange={handleChangeExperiment}
                sx={{ width: 55 }}
                size="small"
              />
              &nbsp;%
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </CampaignAccordionSection>
  );
};
