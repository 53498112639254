import { styled, TextField } from "@mui/material";
import { ACCENT_LIGHT_COLOR } from "./constants.styles";

export const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 19
    }
  }
});

export const StyledCampaignNameTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    bgColor: ACCENT_LIGHT_COLOR,
    "& fieldset": {
      bgColor: ACCENT_LIGHT_COLOR
    }
  }
});
