import {
  Box,
  Icon,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Select,
  TextField,
  SelectChangeEvent,
  ListItemText,
  Autocomplete,
  Button,
  IconButton,
  Tooltip,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Backdrop,
  Skeleton
} from "@mui/material";
import React, { useEffect, useState, useContext, useMemo } from "react";
import { useCSVReader } from 'react-papaparse';
import NumberIcon from "../assets/icons/number-three-icon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCampaignParams } from "api/campaign.service";
import CreateTriggerButton from "./styles/AddTriggerButton.styled";
import { Trigger } from "Interfaces/ITriggers";
import { Reward } from "Interfaces/IRewards";
import { TriggerCondition } from "./TriggerCondition";
import CloseIcon from "@mui/icons-material/Close";
import { TriggerContext } from "contexts/TriggerContext";
import { Condition } from "Interfaces/ICondition";
import CheckedIcon from "../assets/icons/checked-icon.svg";
import "./styles/inputStyles.css";
import { Trans, useTranslation } from "react-i18next";
import { CampaignAccordionSection } from "./styles/CampaignAccordionSection";
import { ACCENT_LIGHT_COLOR, CONTENT_SPACING_GAP, MAIN_COLOR } from "./styles/constants.styles";
import RewardPresentation from "./RewardOffering/RewardPresentation";
import ActivationCondition from "./RewardOffering/ActivationCondition";
import CashOutRestriction from "./RewardOffering/CashOutRestriction";
import RewardAvailable from "./RewardOffering/SMSComponents/RewardAvailable";
import RewardExpires from "./RewardOffering/SMSComponents/RewardExpires";
import ReminderSMSText from "./RewardOffering/SMSComponents/ReminderSMSText";
import ReminderSelect from "./RewardOffering/SMSComponents/ReminderSelect";
import ExpirationReminderSelect from "./RewardOffering/SMSComponents/ExpirationReminderSelect";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import FixedDynamicValue from "./RewardOffering/FixedDynamicValue";
import { FixedDynamicValueType, FIXED_DYNAMIC_VALUE_DEFAULT } from "Interfaces/CampaignBody";
import { areSMSDetailsValid, isFixedDynamicValueValid } from "./campaignPreviewUtils";
import BorderedAccordionSummary from "./BorderedAccordionSummary";
import { useSelector } from "react-redux";
import { campaignParamsSelector } from "store/campaigns/campaigns.selector";

interface TriggerConditionBadgeProps {
  children: any;
  primary?: boolean,
  secondary?: boolean,
  transparent?: boolean,
}

const TriggerConditionBadge: React.FC<TriggerConditionBadgeProps> = ({ children, primary, transparent }) => {
  const { i18n } = useTranslation();
  const size = useMemo(() => i18n.language === 'ro' ? 68 : 38, [i18n.language]);

  const bgcolor = transparent ? 'transparent' : (primary ? MAIN_COLOR.EXTRA_LIGTH : MAIN_COLOR.EXTRA_EXTRA_LIGHT);
  const color = transparent ? 'transparent' : (primary ? ACCENT_LIGHT_COLOR : MAIN_COLOR.EXTRA_LIGTH);
  return (
    <Box
      sx={{
        width: size,
        height: 38,
        bgcolor,
        color,
        fontSize: 12,
        borderRadius: 1,
        fontWeight: 600,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mr: CONTENT_SPACING_GAP
      }}
    >
      {children}
    </Box>
  );
};

interface PropsConditionBoxBox {
  text?: string;
  primary?: boolean;
  secondary?: boolean;
  transparent?: boolean,
}

const ConditionBoxBox: React.FC<PropsConditionBoxBox> = ({ text, primary, secondary, transparent }) => {
  return (
    <TriggerConditionBadge primary={primary} secondary={secondary} transparent={transparent}>
      <Trans>{text}</Trans>
    </TriggerConditionBadge>
  );
};

const emptyTrigger: Trigger = {
  id: "",
  type: "",
  name: "",
  description: "",
  rules: [],
  segments: []
};

const emptyReward = {
  type: "",
  name: "",
  requiredInputs: [],
};

interface Props {
  expanded: string | false;
  handlePanelChange: (
    panel: string
  ) => (event: any, isExpanded: boolean) => void;
  availableIn: string;
  setAvailableIn: (value: string) => void;
  setSeconds: (value: number) => void;
  campaignDetails: any;
  seconds: number,
  campaignChannel: string,
  reminder: boolean,
  setReminder: (
    reminder: boolean
  ) => void;
}

const getInitialInput = (campaignDetails: any): string[] => {
  if (!campaignDetails) return [""];

  return [
    ...(campaignDetails.behavioralTriggers || []),
    ...(campaignDetails.environmentalTriggers || []),
    ...(campaignDetails.timeTriggers || [])
  ].map((x) => x.name);
};

const createRewardTemplate = (
  rewards: { name: string; requiredInputs: string[]; }[],
  rewardName: string,
): Reward => {
  if (!rewardName) return emptyReward;

  const selectedReward = rewards.find(r => r.name === rewardName);

  return {
    name: rewardName,
    type: "reward",
    requiredInputs: selectedReward?.requiredInputs || []
  };
};

export const getQuantityOfInputsByValue = (rewards: { name: string; requiredInputs: string[]; }[], kind: string, value: any) => {
  let tempValue = value;
  const isString = typeof value === 'string';
  const containsSpace = isString && value.indexOf(" ") >= 0;
  const selectedReward = rewards.find(r => r.name === kind);

  if (selectedReward) {
    return selectedReward.requiredInputs?.length;
  }

  if (isString && !containsSpace) {
    return Number(value) === 0 ? 0 : 1;
  }

  if (containsSpace) {
    tempValue = value.split(" ");
  }

  if (tempValue?.length === 3) {
    return 2;
  }

  if (tempValue?.length === 1) {
    return 1;
  }

  return 0;
};

const getDefaultValues = (seconds: number) => {
  if (seconds === 0) {
    return {
      defaultRadio: "immediately",
      defaultTime: "Hours",
      defaultHours: "0"
    };
  }

  const availableTime = seconds % 86400 === 0 ? "Days" : "Hours";

  return {
    defaultRadio: "in",
    defaultTime: availableTime,
    defaultHours: seconds / (availableTime === "Days" ? 86400 : 3600)
  };
};

type ValidateCampaign = {
  campaignChannel: string,
  campaignConditions: Condition[],
  rewardQuantity: FixedDynamicValueType | null,
  campaignReward: string,
  secondRewardQuantity: FixedDynamicValueType | null,
  cashOutRestriction: any,
  activationCondition: any,
  activationConditionValue: any,
  cashOutRestrictionValue: any,
  numberOfVouchers: number,
  smsManualPhoneNumbers: string[],
  getValueByKey: any,
};

const validateCampaign = ({
  campaignChannel,
  campaignConditions,
  rewardQuantity,
  campaignReward,
  secondRewardQuantity,
  cashOutRestriction,
  activationCondition,
  activationConditionValue,
  cashOutRestrictionValue,
  numberOfVouchers,
  smsManualPhoneNumbers,
  getValueByKey,
}: ValidateCampaign) => {
  if (!campaignReward) {
    return false;
  }

  if (['Online', 'Lcd', 'Sms'].includes(campaignChannel)) {
    campaignConditions.forEach((condition) => {
      if (condition?.segment[0] === "") {
        return false;
      }
    });
  }

  if (["Play", "Deposit"].includes(activationCondition) && !isFixedDynamicValueValid(activationConditionValue)) {
    return false;
  }

  if (["Play", "Deposit"].includes(cashOutRestriction) && !isFixedDynamicValueValid(cashOutRestrictionValue)) {
    return false;
  }

  if (campaignChannel === 'Voucher') {
    if (numberOfVouchers <= 0) {
      return false;
    }
  }

  if (campaignChannel === 'SmsManual') {
    if (!smsManualPhoneNumbers || smsManualPhoneNumbers.length <= 0) {
      return false;
    }
  }

  if (['Sms', 'SmsManual'].includes(campaignChannel)) {
    const [{ smsDetails }] = getValueByKey('allSMSDetails');

    if (!areSMSDetailsValid(smsDetails)) {
      return false;
    }
  }

  if (!isFixedDynamicValueValid(rewardQuantity)) {
    return false;
  }

  if (
    ["PercentBack", "BuyABonus", "FreeSpins"].includes(campaignReward) &&
    (!isFixedDynamicValueValid(rewardQuantity) || !isFixedDynamicValueValid(secondRewardQuantity))
  ) {
    return false;
  }

  if (["BonusCash", "FreeMoney"].includes(campaignReward) && !isFixedDynamicValueValid(rewardQuantity)) {
    return false;
  }

  return true;
};

export const SelectTriggers = ({
  expanded,
  handlePanelChange,
  availableIn,
  setAvailableIn,
  setSeconds,
  campaignDetails,
  seconds,
  campaignChannel,
}: Props) => {
  const {
    campaignConditions: conditions,
    setCampaignConditions: setConditions,
    setCampaignReward: setSelectedReward,
    campaignReward: selectedReward,
    setRewardQuantity,
    rewardQuantity,
    cashOutRestriction,
    rewardPresentation,
    activationCondition,
    onCashOutRestrictionNameChange,
    onRewardPresentationNameChange,
    onActivationConditionNameChange,
    getValueByKey,
    numberOfVouchers,
    setNumberOfVouchers,
    smsManualPhoneNumbers,
    setSmsManualPhoneNumbers,
    secondRewardQuantity,
    activationConditionValue,
    cashOutRestrictionValue,
    activationConditionTimeLimit,
    onActivationConditionTimeLimitChange,
    cashOutRestrictionTimeLimit,
    onCashOutRestrictionTimeLimitChange
  } = useContext(TriggerContext);

  const isRewardValid = validateCampaign({
    campaignChannel,
    campaignConditions: conditions,
    rewardQuantity,
    campaignReward: selectedReward,
    secondRewardQuantity,
    cashOutRestriction,
    activationCondition,
    activationConditionValue,
    cashOutRestrictionValue,
    numberOfVouchers,
    smsManualPhoneNumbers,
    getValueByKey,
  });

  const {
    defaultRadio,
    defaultTime,
    defaultHours
  } = getDefaultValues(seconds);

  const { rewards, allTriggers, loading, dynamicValueSpecs } = useSelector(campaignParamsSelector);

  const { CSVReader } = useCSVReader();

  const [reward, setReward] = useState<Reward>(
    { ...createRewardTemplate(rewards, selectedReward) }
  );
  const [availableRadio, setAvailableRadio] = useState(defaultRadio);
  const [availableTime, setAvailableTime] = useState(defaultTime);
  const [availableHours, setAvailableHours] = useState(defaultHours);
  const [inputValues, setInputValues] = useState<string[]>(
    getInitialInput(campaignDetails)
  );

  const [autocompleteValue, setAutocompleteValue] = useState<{ id: string; name: string; }[]>([]);

  console.log({ campaignDetails, autocompleteValue });

  const { t } = useTranslation();

  useEffect(() => {
    if (rewards.length <= 1 || selectedReward !== '')
      return;
    setReward(createRewardTemplate(rewards, "BonusCash"));
    setSelectedReward("BonusCash");
  }, [rewards]);

  useEffect(() => {
    if (availableRadio === "Immediately") {
      setAvailableIn("immediately");
      setSeconds(0);
    } else {
      setAvailableIn(
        `in ${availableHours} ${availableTime === "Days"
          ? availableHours === "1"
            ? t("Day")
            : t("Days")
          : availableHours === "1"
            ? t("Hour")
            : t("Hours")
        }`
      );
      setSeconds(+availableHours * (availableTime === "Days" ? 86400 : 3600));
    }
  }, [availableIn, availableTime, availableHours, availableRadio]);

  useEffect(() => {
    const {
      defaultRadio,
      defaultTime,
      defaultHours
    } = getDefaultValues(seconds);

    if (defaultRadio === "immediately") {
      setAvailableIn("immediately");
    } else {
      setAvailableIn(
        `in ${availableHours} ${availableTime === "Days"
          ? availableHours === "1"
            ? t("Day")
            : t("Days")
          : availableHours === "1"
            ? t("Hour")
            : t("Hours")
        }`
      );
    }

    setAvailableRadio(defaultRadio);
    setAvailableTime(defaultTime);
    setAvailableHours(defaultHours);
  }, [seconds]);
  useEffect(() => {
    const newInputs = conditions.length > 0 ? conditions.map((condition) => {
      return t(condition.trigger.name);
    }) : [];
    const newAutocompleteInputs = conditions.length > 0 ? conditions.map((condition) => {
      return options.find((op: any) => op.name === condition.trigger.name);
    }) : [];
    setAutocompleteValue(newAutocompleteInputs);

    setInputValues(newInputs);
  }, [t, conditions]);

  useEffect(() => {
    if (!campaignDetails) {
      return;
    }

    const { kind } = campaignDetails.reward;

    setInputValues(getInitialInput(campaignDetails));
    setReward(createRewardTemplate(rewards, kind));
  }, [rewards, campaignDetails]);

  const handleAddTrigger = () => {
    setConditions([
      ...conditions,
      { trigger: emptyTrigger, rule: "", segment: [""] }
    ]);
    setInputValues([...inputValues, ""]);
  };

  const handleChangeConditions = (event: any, i: number) => {
    const newConditions: Condition[] = [...conditions];
    if (!event) {
      return;
    }
    const newTrigger: Trigger | undefined = allTriggers.find(
      (trigger: Trigger) => trigger.name === event.name
    );
    if (newTrigger) {
      newConditions[i].trigger = newTrigger;
      newConditions[i].rule = newTrigger.rules[0];
      if (
        ["int", "decimal", "uint", "udecimal"].includes(newTrigger.segments[0])
      ) {
        newConditions[i].segment = ["1"];
      } else {
        // when changing a trigger type from create campaign
        // it needs to auto select the first value
        // and segments value should be cleared
        newConditions[i].segment = [newTrigger.segments[0]];
      }
      setConditions(newConditions);
    }
  };

  const setRule = (rule: string, index: number) => {
    const newConditions: Condition[] = [...conditions];
    newConditions[index].rule = rule;
    setConditions(newConditions);
  };

  const setSegment = (segment: string, index: number) => {
    const newConditions: Condition[] = [...conditions];
    newConditions[index].segment = [segment];
    setConditions(newConditions);
  };

  const deleteCondition = (id: number) => {
    setConditions(conditions.filter((condition, index) => index !== id));
    setInputValues(inputValues.filter((value, index) => index !== id));
  };

  const handlePrizeSelect = (event: SelectChangeEvent) => {
    setSelectedReward(event.target.value);

    if (rewardQuantity?.type === 'Fixed') {
      setRewardQuantity({
        ...FIXED_DYNAMIC_VALUE_DEFAULT,
        fixedValue: 0,
      });
    }
  };

  const options = useMemo(() => allTriggers.map((option: Trigger) => {
    const type = option.type;
    return {
      firstLetter: type,
      ...option
    };
  }).sort(
    (a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter)
  ), [allTriggers]);


  const handleSmsManualFileChange = (results: any) => {
    const smsNumbers = results.data.reduce((acc: string[], currentValue: any) => {
      return ([
        ...acc,
        ...currentValue,
      ]);
    }, []);

    setSmsManualPhoneNumbers(smsNumbers);
  };

  const removePhoneNumber = (phoneNumberToRemove: string) => {
    setSmsManualPhoneNumbers(smsManualPhoneNumbers.filter(phoneNumber => phoneNumber !== phoneNumberToRemove));
  };

  if (loading) {
    return (
      <CampaignAccordionSection
        expanded={false}
      >
        <BorderedAccordionSummary>
          <Box>
            <Box
              sx={{
                fontSize: 15,
                fontWeight: "bold",
                display: "flex",
                alignItems: "center"
              }}
            >
              <Box>
                <Skeleton variant="circular" width={20} height={20} />
              </Box>
              <Box sx={{ ml: '12px' }}>
                <Skeleton variant="rectangular" width={120} height={23} />
              </Box>
            </Box>
            <Box sx={{ ml: 4, mt: '6px' }}>
              <Skeleton variant="rectangular" width={303} height={20} />

            </Box>
          </Box>
        </BorderedAccordionSummary>
      </CampaignAccordionSection>);
  }

  console.log({ inputValues, options });
  return (
    <CampaignAccordionSection
      expanded={expanded === "triggersPanel"}
      onChange={handlePanelChange("triggersPanel")}
    >
      <BorderedAccordionSummary>
        <Box>
          <Box
            sx={{
              fontSize: 15,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center"
            }}
          >
            <Icon sx={{ overflow: "visible", mr: 1, mb: 0.8 }}>
              <img
                src={isRewardValid ? CheckedIcon : NumberIcon}
                alt="search"
              />
            </Icon>
            <Box>
              <Trans>Reward Offering</Trans>
            </Box>
          </Box>
          <Box sx={{ fontSize: 13, ml: 4 }}>
            <Trans>
              Specify the reward, and when it will be offered
            </Trans>
            .
          </Box>
        </Box>
      </BorderedAccordionSummary>
      <AccordionDetails>
        <Box sx={{ fontWeight: "bold", fontSize: 14, ml: 4 }}>
          <Trans>Define campaign behaviours to trigger the reward</Trans>
        </Box>
        {['Voucher'].includes(campaignChannel) && (
          <Box sx={{ display: "flex", ml: 4, mt: 2, alignItems: "center" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{ display: "flex", overflow: "hidden", mt: 2, alignItems: 'center' }}
                >
                  <ConditionBoxBox text="To" primary />
                  <TextField
                    id="nr-voucher"
                    placeholder="100"
                    variant="outlined"
                    size="small"
                    value={numberOfVouchers}
                    onChange={(e) => {
                      const newVoucherValue = Number(e.target.value);
                      setNumberOfVouchers(newVoucherValue > 10000 ? 10000 : newVoucherValue);
                    }}
                  />
                  <Box sx={{ marginLeft: CONTENT_SPACING_GAP }}>
                    <Trans>generated voucher codes</Trans>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {['SmsManual'].includes(campaignChannel) && (
          <Box sx={{ display: "flex", ml: 4, mt: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{ display: "flex", overflow: "hidden", mt: 2 }}
                >
                  <ConditionBoxBox text="To" primary />
                  <Box sx={{ display: "flex", flexDirection: 'column', lineHeight: '38px' }}>
                    <Trans>the following list of phone numbers</Trans>

                    <CSVReader
                      onUploadAccepted={(results: any) => {
                        console.log(results);
                        handleSmsManualFileChange(results);
                      }}
                    >
                      {({ getRootProps }: any) => (
                        <>
                          <Box sx={{ marginTop: '8px', display: 'flex', gap: CONTENT_SPACING_GAP }}>
                            <TextField
                              id="file-value"
                              placeholder={t("No file selected")}
                              variant="outlined"
                              size="small"
                              value={`${smsManualPhoneNumbers.length} ${smsManualPhoneNumbers.length === 1 ? t("phone number loaded") : t("phone numbers loaded")}`}
                              disabled
                            />
                            <Button
                              variant="outlined"
                              startIcon={<FileUploadOutlinedIcon />}
                              component="label"
                              sx={{ borderRadius: '20px', textTransform: 'capitalize' }}
                              type='button'
                              {...getRootProps()}
                            >
                              <Trans>Browse</Trans>...
                            </Button>
                          </Box>
                        </>
                      )}
                    </CSVReader>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {['Online', 'Lcd', 'Sms'].includes(campaignChannel) && (
          <Box sx={{ display: "flex", ml: 4, mt: 2, alignItems: "center" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex" }}>
                <Box>
                  {conditions?.map((condition: any, index) => (
                    <Box
                      key={index}
                      sx={{ display: "flex", overflow: "hidden", mt: 2 }}
                    >
                      {index === 0 ? <ConditionBoxBox text="If" primary /> : <ConditionBoxBox text="And" secondary />}
                      {options.length !== 0 && (
                        <Autocomplete
                          inputValue={inputValues[index] || ''}
                          isOptionEqualToValue={(option, value) => {
                            return option.name === value.name;
                          }}
                          value={autocompleteValue[index]}
                          sx={{ width: 320 }}
                          size="small"
                          id="grouped-demo"
                          disabled={options.length === 0}
                          loading={options.length === 0}
                          noOptionsText={t('Select a trigger')}
                          options={options}
                          groupBy={(option: any) => option.type}
                          getOptionLabel={(option) => t(`${option.name}`)}
                          renderOption={(props, option) => (
                            <li key={`${option.id}`} {...props}>
                              <ListItemText
                                key={option.id}
                                sx={{ cursor: "pointer" }}
                                primary={
                                  <Box sx={{ fontWeight: "bold" }}>
                                    {t(option.name)}
                                  </Box>
                                }
                                secondary={t(`${option.name}_description`)}
                              />
                            </li>
                          )}
                          renderInput={({ InputLabelProps, ...params }) => {
                            return <TextField {...params} />;
                          }}
                          onChange={(event: any, newValue) => {
                            handleChangeConditions(newValue, index);
                          }}
                          onInputChange={(event, newInputValue) => {
                            if (event === null) {
                              return;
                            }
                            const newInputs = [...inputValues];
                            newInputs[index] = newInputValue;
                            setInputValues(newInputs);
                          }}
                        />
                      )}
                      {condition.trigger.id !== "" ? (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <TriggerCondition
                            segments={condition.trigger.segments}
                            rules={condition.trigger.rules}
                            condition={condition}
                            setRule={(value: string) => setRule(value, index)}
                            setSegment={(value: string) =>
                              setSegment(value, index)
                            }
                            selectedSegments={condition.segment}
                            setSelectedSegments={(segments: string[]) => {
                              const newConditions = [...conditions];
                              newConditions[index].segment = segments;
                              setConditions(newConditions);
                            }}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}
                      {conditions.length > 1 ? (
                        <Box
                          onClick={() => {
                            deleteCondition(index);
                          }}
                          sx={{
                            bgcolor: MAIN_COLOR.EXTRA_EXTRA_LIGHT,
                            borderRadius: "50%",
                            width: 24,
                            height: 24,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            ml: 1,
                            mt: 1,
                            cursor: "pointer"
                          }}
                        >
                          <CloseIcon
                            sx={{
                              fontSize: "medium"
                            }}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box sx={{ display: "flex" }}>
                <ConditionBoxBox transparent />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <CreateTriggerButton
                    onClick={handleAddTrigger}
                    sx={{ maxWidth: '320px', mt: CONTENT_SPACING_GAP }}
                  >
                    <Box sx={{ ml: 1 }}>
                      + <Trans>Add a new condition</Trans>
                    </Box>
                  </CreateTriggerButton>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        <Box sx={{ display: "flex", ml: 4, mt: 2, alignItems: "center" }}>
          <ConditionBoxBox text="offer" primary />
          <Select
            sx={{
              width: reward.requiredInputs.length !== 0 ? "auto" : 460,
              height: 38,
              color: "black"
            }}
            value={selectedReward}
            onChange={handlePrizeSelect}
            autoWidth={reward.requiredInputs.length !== 0}
          >
            {rewards?.map((rewardItem: Reward, index: number) => (
              <MenuItem
                key={index + rewardItem.type + rewardItem.name}
                value={rewardItem.name}
                onClick={() => setReward(rewardItem)}
              >
                {t(`${rewardItem.name}`)}
              </MenuItem>
            ))}
          </Select>

          {reward?.requiredInputs.length === 1 ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ ml: 1 }}>
                {reward?.name === "FreeMoney" && <Trans>amount</Trans>}
                {reward?.name && reward?.name !== "FreeMoney" && <Trans>quantity</Trans>}
              </Box>
              <Box sx={{ ml: 1 }}></Box>
              <FixedDynamicValue
                getValueByKey={getValueByKey}
                valueKey="rewardQuantity"
                dynamicValueSpecs={dynamicValueSpecs['RewardValue'].basedOn}
              />
            </Box>
          ) : (
            <></>
          )}
          {reward?.name === "BuyABonus" ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ ml: 1 }}>
                <Trans>deposit</Trans>
              </Box>
              <FixedDynamicValue
                getValueByKey={getValueByKey}
                valueKey="rewardQuantity"
                dynamicValueSpecs={dynamicValueSpecs['RewardValue'].basedOn}
              />
              &nbsp; <Trans>ron</Trans> <Trans>to get</Trans>
              <FixedDynamicValue
                getValueByKey={getValueByKey}
                valueKey="secondRewardQuantity"
                dynamicValueSpecs={dynamicValueSpecs['RewardQuantity'].basedOn}
              />
              &nbsp; <Trans>ron</Trans>
            </Box>
          ) : (
            <></>
          )}
          {reward?.name === "AfterWager" ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ ml: 1 }}>
                <Trans>run</Trans>
              </Box>
              <FixedDynamicValue
                getValueByKey={getValueByKey}
                valueKey="rewardQuantity"
                dynamicValueSpecs={dynamicValueSpecs['RewardValue'].basedOn}
              />
              &nbsp;  <Trans>ron</Trans> <Trans>to get</Trans>
              <FixedDynamicValue
                getValueByKey={getValueByKey}
                valueKey="secondRewardQuantity"
                dynamicValueSpecs={dynamicValueSpecs['RewardQuantity'].basedOn}
              />
              &nbsp; <Trans>ron</Trans>
            </Box>
          ) : (
            <></>
          )}
          {reward?.name === "CashBack" ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ ml: 1 }}>
                <Trans>get back</Trans>
              </Box>
              <FixedDynamicValue
                getValueByKey={getValueByKey}
                valueKey="rewardQuantity"
                dynamicValueSpecs={dynamicValueSpecs['RewardValue'].basedOn}
              />
              &nbsp; <Trans>ron</Trans> <Trans>for the next</Trans>
              <FixedDynamicValue
                getValueByKey={getValueByKey}
                valueKey="secondRewardQuantity"
                dynamicValueSpecs={dynamicValueSpecs['RewardQuantity'].basedOn}
              />
              &nbsp; <Trans>spins</Trans>
            </Box>
          ) : (
            <></>
          )}
          {reward?.name === "PercentBack" ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FixedDynamicValue
                getValueByKey={getValueByKey}
                valueKey="rewardQuantity"
                dynamicValueSpecs={dynamicValueSpecs['RewardValue'].basedOn}
              />
              &nbsp; <Trans>% of the turnover of the next</Trans>
              <FixedDynamicValue
                getValueByKey={getValueByKey}
                valueKey="secondRewardQuantity"
                dynamicValueSpecs={dynamicValueSpecs['RewardQuantity'].basedOn}
              />
              &nbsp; <Trans>spins</Trans>
            </Box>
          ) : (
            <></>
          )}
          {reward?.name === "FreeSpins" ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: '8px', ml: '8px' }}>
              <Trans>quantity</Trans>
              <FixedDynamicValue
                getValueByKey={getValueByKey}
                valueKey="secondRewardQuantity"
                dynamicValueSpecs={dynamicValueSpecs['RewardQuantity'].basedOn}
              />
              <Trans>each value</Trans>
              <FixedDynamicValue
                getValueByKey={getValueByKey}
                valueKey="rewardQuantity"
                dynamicValueSpecs={dynamicValueSpecs['RewardValue'].basedOn}
              />
              <Trans>LEI</Trans>
            </Box>
          ) : (
            <></>
          )}
        </Box>
        {['Online', 'Lcd', 'Sms', 'SmsManual', 'Voucher'].includes(campaignChannel) && (
          <>
            <RewardPresentation
              onNameChange={(event) => {
                onRewardPresentationNameChange(event.target.value);
              }}
              name={rewardPresentation}
            />
            <ActivationCondition
              onNameChange={(event) => { onActivationConditionNameChange(event.target.value); }}
              name={activationCondition}
              campaignChannel={campaignChannel}
              getValueByKey={getValueByKey}
              dynamicValueSpecs={dynamicValueSpecs}
              timeLimit={activationConditionTimeLimit}
              onTimeLimitChange={onActivationConditionTimeLimitChange}
            />
            <CashOutRestriction
              onNameChange={(event) => { onCashOutRestrictionNameChange(event.target.value); }}
              name={cashOutRestriction}
              campaignChannel={campaignChannel}
              getValueByKey={getValueByKey}
              dynamicValueSpecs={dynamicValueSpecs}
              timeLimit={cashOutRestrictionTimeLimit}
              onTimeLimitChange={onCashOutRestrictionTimeLimitChange}
            />
          </>
        )}

        {['Sms', 'SmsManual', 'Voucher'].includes(campaignChannel) && (
          <>
            <RewardAvailable
              getValueByKey={getValueByKey}
            />
            <RewardExpires
              getValueByKey={getValueByKey}
            />
          </>
        )}
        {['Sms', 'SmsManual'].includes(campaignChannel) && (
          <>
            <ReminderSMSText
              title={t("SMS text")}
              placeholder={t("Type here the text that will be sent to the player's phone...")}
              getValueByKey={getValueByKey}
              valyeKey="smsDetails.activationMessage"
            />
            <ReminderSelect
              getValueByKey={getValueByKey}
            />
            <ReminderSMSText
              title={t("Reminder SMS text")}
              placeholder={t("Type here the reminder text that will be sent to the player's phone...")}
              getValueByKey={getValueByKey}
              valyeKey="smsDetails.reminderMessage"
            />
            <ExpirationReminderSelect
              getValueByKey={getValueByKey}
            />
            <ReminderSMSText
              title={t("Expiration reminder SMS text")}
              placeholder={t("Type here the expiration reminder text that will be sent to the player's phone...")}
              getValueByKey={getValueByKey}
              valyeKey="smsDetails.expiryMessage"
            />
          </>
        )}
        {/* <Box sx={{ mt: 3, ml: 2.5 }}>
              <Box sx={{ mt: 3, ml: 2.5, fontSize: 16, fontWeight: 600 }}>
                <Trans>Reward is available</Trans>
                <Box>
                  <FormControl>
                    <RadioGroup
                      value={availableRadio}
                      onChange={(event) =>
                        setAvailableRadio(event.target.value)
                      }
                    >
                      <FormControlLabel
                        value="immediately"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: MAIN_COLOR.EXTRA_LIGTH
                              }
                            }}
                          />
                        }
                        label={t("Immediately").toString()}
                      />
                      <Box sx={{ display: "flex" }}>
                        <FormControlLabel
                          value="in"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: MAIN_COLOR.EXTRA_LIGTH
                                }
                              }}
                            />
                          }
                          label={t("In").toString()}
                        />
                        <TextField
                          size="small"
                          sx={{ width: 55 }}
                          variant="outlined"
                          value={availableHours.toString()}
                          onChange={(event) => {
                            if (isNaN(+event.target.value)) return;
                            setAvailableHours(event.target.value);
                          }}
                        />
                        <Select
                          sx={{ ml: 1 }}
                          size="small"
                          value={availableTime}
                          onChange={(event) =>
                            setAvailableTime(event.target.value)
                          }
                        >
                          <MenuItem value={"Hours"}>
                            <Trans>Hours</Trans>
                          </MenuItem>
                          <MenuItem value={"Days"}>
                            <Trans>Days</Trans>
                          </MenuItem>
                        </Select>
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box sx={{ mt: 4 }}>
                  <Trans>Would you like to send a reminder ?</Trans>
                  <Box>
                    <FormControl disabled={isNewtonLCD}>
                      <RadioGroup
                        value={reminder && !isNewtonLCD ? "yes" : "no"}
                        onChange={handleReminderChange}
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: MAIN_COLOR.EXTRA_LIGTH
                                }
                              }}
                            />
                          }
                          disabled={isNewtonLCD}
                          label={t("Yes").toString()}
                        />
                        <FormControlLabel
                          value="no"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: MAIN_COLOR.EXTRA_LIGTH
                                }
                              }}
                            />
                          }
                          disabled={isNewtonLCD}
                          label={t("No").toString()}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box> */}
      </AccordionDetails>
    </CampaignAccordionSection>
  );
};
