import { Box, Icon, Typography } from "@mui/material";
import AvgProfitIcon from "../assets/icons/avg-profit.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import { ACCENT_LIGHT_COLOR, MAIN_COLOR, SUCCESS_COLOR, ACCENT_DANGER_COLOR } from "./styles/constants.styles";

interface Props {
  averageProfit: number;
  profitIncrease: number;
  mobileView?: boolean;
}

export const AverageProfit = ({
  averageProfit,
  profitIncrease,
  mobileView
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        bgcolor: ACCENT_LIGHT_COLOR,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "6px",
        p: 2
      }}
    >
      <Box>
        <Typography sx={{ color: MAIN_COLOR.DARK, fontSize: 15, fontWeight: 500 }}>
          {t(`Avg Profit per session`)}
        </Typography>
        <Typography sx={{ fontWeight: "bold", fontSize: 28 }}>
          {averageProfit} {t(`LEI`)} <br />
        </Typography>
        <Typography
          sx={{ color: profitIncrease > 0 ? SUCCESS_COLOR.LIGHT : ACCENT_DANGER_COLOR, fontSize: 14 }}
        >
          {profitIncrease > 0 ? "+" : null}
          {Math.floor(profitIncrease)}%
        </Typography>
      </Box>
      <Icon
        sx={{
          width: 47,
          height: 47,
          display: mobileView ? "inline-block" : "none"
        }}
      >
        <img src={AvgProfitIcon} alt="search" />
      </Icon>
    </Box>
  );
};
