import { Box, Icon, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import PurpleCircle from "../assets/icons/purple-circle-icon.svg";
import OrangeCircle from "../assets/icons/orange-circle-icon.svg";
import { MAIN_COLOR } from "./styles/constants.styles";

interface Props {
  revenueReports: {
    earning: number;
    expense: number;
    isFocused: boolean;
    month: string;
    reward: string;
  }[];
  labels: string[];
}

export const RevenueReport = ({ revenueReports, labels }: Props) => {
  const { t } = useTranslation();

  const data = revenueReports.map((revenue, index) => {
    return {
      name: revenue.reward || revenue.month,
      pv: revenue.earning,
      uv: -revenue.expense,
      amt: 0
    };
  });

  return (
    <Box sx={{ padding: 2 }}>
      <Typography sx={{ color: MAIN_COLOR.DARK, fontSize: 15, fontWeight: 500 }}>
        {t("Revenue Report")}
      </Typography>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
      >
        <Icon sx={{ overflow: "visible", mb: 2.4 }}>
          <img src={PurpleCircle} alt="1" />
        </Icon>
        <Typography>{t("Earning")}</Typography>
        <Icon sx={{ overflow: "visible", mb: 2.4 }}>
          <img src={OrangeCircle} alt="1" />
        </Icon>
        <Typography>{t("Expense")}</Typography>
      </Box>
      <ResponsiveContainer width="99%" height={300}>
        <BarChart
          width={100}
          // height={300}
          data={data}
          stackOffset="sign"
          margin={{
            top: 5,
            right: 30,
            bottom: 5,
            left: 20
          }}
        >
          <XAxis dataKey="name" axisLine={false} />
          <YAxis axisLine={false} unit="%" />
          <Bar dataKey="uv" fill={MAIN_COLOR.EXTRA_LIGTH} stackId="stack" barSize={16} />
          <Bar dataKey="pv" fill="#FF9F43" stackId="stack" barSize={16} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
