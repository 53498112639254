import { Box } from "@mui/material";
import styled from "styled-components";
import { borderRadius, CONTENT_SPACING_GAP, GREY_COLOR, MAIN_COLOR } from "./constants.styles";

const CampaignsTable = styled.table`
  width: 100%;
  background-color: white;
  border-collapse: collapse;
  border-radius: ${borderRadius} ${borderRadius} 0px 0px;
  border-collapse: separate;
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
  color: ${GREY_COLOR.LIGHT};
  .TableHeader {
    background-color: ${MAIN_COLOR.EXTRA_EXTRA_LIGHT};
    color: ${MAIN_COLOR.DARK};
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 15px;
    border-radius: 6px 6px 0px 0px;
    td:first-child {
      padding-left: 36px;
      width: 7%;
    }
    td:last-child {
      width: 10%;
    }
  }
  .TableHeaderCell {
    padding: 0px 0 18px;
  }
  .TableRow {
    border-bottom: 0;
    padding-top: 13px;
    padding-bottom: 13px;
  }


  .TableHeader {
    tr:first-child th:first-child,
    tr:first-child td:first-child {
       border-top-left-radius:6px;
       border-top-right-radius:6px;
       height: 6px;
   }
  }

    width: 100%;
    border-spacing: 0; 
    border-collapse: separate;


    tr:first-child th:first-child,
    tr:first-child td:first-child {
        border-top-left-radius:0;
    }

    tr:first-child th:last-child,
    tr:first-child td:last-child {
        border-top-right-radius:0;
    }

    tr th:first-child,
    tr td:first-child {
      border-left: 0;
    }
    tr:first-child th,
    tr:first-child td {
      border-top: 0;
    }
`;

export default CampaignsTable;


export const CampaignsTableFooter = styled.div`
  border-radius: 0 0 ${borderRadius} ${borderRadius};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  width: 100%;
  height: 60px;
  padding-bottom: 54px;
  padding-top: 54px;

  @media only screen and (max-width: 611px)  {
    margin-left: 32px;
    padding: ${CONTENT_SPACING_GAP};
  }

  @media only screen and (max-width: 420px)  {
    height: auto;
    flex-direction: column;
    align-items: start;
  }
`;

export const CampaignsTableFooterPerPage = styled(Box)`
  display: flex;
  alignItems: center;
  gap: 1;
  margin-left: 42px;
  flex-flow: wrap;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: 611px)  {
    margin-left: 0;
  }

  @media only screen and (max-width: 420px)  {
    margin-bottom: ${CONTENT_SPACING_GAP};
  }
`;
